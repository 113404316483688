import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8a8c272e"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "stats"
}
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 1 }
const _hoisted_4 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArchiveWorkerListFilter = _resolveComponent("ArchiveWorkerListFilter")!
  const _component_CleanerWidget = _resolveComponent("CleanerWidget")!
  const _component_ReportsCell = _resolveComponent("ReportsCell")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_ArchiveIcon = _resolveComponent("ArchiveIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    title: "Архивные клинеры",
    "allow-all": true,
    "header-buttons": [
      {
        text: 'Создать клинера',
        href: _ctx.getWorkerFormUrl({
          workerId: 'create',
        }),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_ArchiveWorkerListFilter, {
            value: _ctx.filterParams,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterParams) = $event)),
            "fetch-data": _ctx.fetchWorkerList
          }, null, 8, ["value", "fetch-data"]),
          (!_ctx.isRowDataLoading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createElementVNode("span", null, [
                  _createTextVNode("Средняя продолжительность работы: "),
                  _createElementVNode("b", null, _toDisplayString(_ctx.averageWorkingDays) + " дн.", 1)
                ])
              ]))
            : _createCommentVNode("", true)
        ]),
        "cell(company)": _withCtx(({ row }) => [
          (row.company)
            ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(row.company), 1))
            : (_openBlock(), _createElementBlock("i", _hoisted_3, "Без компании"))
        ]),
        "cell(cleaner)": _withCtx(({ row }) => [
          _createVNode(_component_CleanerWidget, {
            worker: row.worker
          }, null, 8, ["worker"])
        ]),
        "cell(reports)": _withCtx(({ row }) => [
          _createVNode(_component_ReportsCell, { row: row }, null, 8, ["row"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Edit",
            href: _ctx.getWorkerFormUrl({ workerId: row.id })
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["href"]),
          _createElementVNode("a", {
            class: "iconBlock",
            onClick: ($event: any) => (_ctx.notArchiveWorker(row.id))
          }, [
            _createVNode(_component_ArchiveIcon, { class: "icon" })
          ], 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}