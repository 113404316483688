
import { defineComponent } from "vue";

import { BaseButton } from "@tager/admin-ui";

export default defineComponent({
  name: "OrdersEditEditButtons",
  components: { BaseButton },
  props: {
    loading: Boolean,
    submitDisabled: Boolean,
  },
});
