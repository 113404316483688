import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/router/paths";

export function getCitiesCreateUrl() {
  return ROUTE_PATHS.CITIES_CREATE;
}

export function getCitiesUpdateUrl(id: number): string {
  return compile(ROUTE_PATHS.CITIES_EDIT)({ id });
}

export function getCitiesListUrl(): string {
  return ROUTE_PATHS.CITIES_LIST;
}
