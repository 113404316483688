
import { defineComponent } from "vue";

import OrdersList from "@/modules/orders/containers/orders-list/OrdersList.vue";
import { getOrdersByStatus } from "@/modules/orders/services";

export default defineComponent({
  name: "OrdersListActive",
  components: {
    OrdersList,
  },
  setup() {
    return {
      fetchOrders: getOrdersByStatus("WAITING"),
    };
  },
});
