import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c07163cc"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "orders-view-edit-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseButton, {
      variant: "secondary",
      disabled: _ctx.loading,
      onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('cancel')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Отменить ")
      ]),
      _: 1
    }, 8, ["disabled"]),
    _createVNode(_component_BaseButton, {
      variant: "primary",
      loading: _ctx.loading,
      disabled: _ctx.submitDisabled,
      onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('submit')))
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Применить ")
      ]),
      _: 1
    }, 8, ["loading", "disabled"])
  ]))
}