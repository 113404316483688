import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Статус подтверждения",
    type: "text",
    loading: _ctx.loading,
    value: _ctx.text,
    buttons: _ctx.order.status === 'WAITING' ? _ctx.buttons : []
  }, null, 8, ["loading", "value", "buttons"]))
}