import { resolveComponent as _resolveComponent, createVNode as _createVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersCreatePromoCode = _resolveComponent("OrdersCreatePromoCode")!
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_OrdersCreatePromoCode, {
      "user-id": _ctx.userId,
      date: _ctx.date,
      onChange: _ctx.onChangePromoCode,
      "onChange:loading": _ctx.onChangeLoading
    }, null, 8, ["user-id", "date", "onChange", "onChange:loading"]),
    _createVNode(_component_FormField, {
      value: _ctx.comment,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.comment) = $event)),
      label: "Комментарий",
      name: "comment",
      type: "textarea"
    }, null, 8, ["value"])
  ], 64))
}