import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2c2547de"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[6] || (_cache[6] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.fullName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.fullName) = $event)),
          name: "fullName",
          error: _ctx.errors.fullName,
          label: "Полное название"
        }, null, 8, ["value", "error"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormField, {
            value: _ctx.values.headName,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.headName) = $event)),
            name: "headName",
            error: _ctx.errors.headName,
            label: "Директор",
            class: "col"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.headNameParentCase,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.headNameParentCase) = $event)),
            name: "headNameParentCase",
            error: _ctx.errors.headNameParentCase,
            label: "Директор в родительском падеже",
            class: "col"
          }, null, 8, ["value", "error"])
        ]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormField, {
            value: _ctx.values.fromEmail,
            "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.fromEmail) = $event)),
            name: "fromEmail",
            error: _ctx.errors.fromEmail,
            label: "E-Mail отправителя",
            class: "col"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.fromName,
            "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.fromName) = $event)),
            name: "fromName",
            error: _ctx.errors.fromName,
            label: "Имя отправителя",
            class: "col"
          }, null, 8, ["value", "error"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}