
import { defineComponent, onMounted } from "vue";

import {
  BaseButton,
  BaseTable,
  ColumnDefinition,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { useResource, useResourceDelete } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { deleteTraining, getTrainingList } from "@/requests";
import { generateReport } from "@/modules/workers/requests";
import { TrainingType } from "@/types";
import { getTrainingFormUrl } from "@/utils/paths";
import { newDateFormat } from "@/utils/common";

import NotificationCell from "./components/NotificationCell.vue";

const COLUMN_DEFS: Array<ColumnDefinition<TrainingType>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Дата начала - окончания тренинга",
    field: "date",
    style: { width: "271px" },
  },
  {
    id: 3,
    name: "Данные кандидата",
    field: "name",
  },
  {
    id: 4,
    name: "Статус",
    field: "status",
    style: {},
  },
  {
    id: 5,
    name: "Код доступа",
    field: "code",
    style: { minWidth: "150px" },
  },
  {
    id: 6,
    name: "Оповещение",
    field: "notification",
    style: { width: "205px", textAlign: "center" },
    headStyle: { width: "205px", textAlign: "center" },
  },
  {
    id: 7,
    name: "Действия",
    field: "actions",
    style: { width: "100px", whiteSpace: "nowrap" },
    headStyle: { width: "100px" },
  },
];

export default defineComponent({
  name: "TrainingList",
  components: {
    DeleteIcon,
    EditIcon,
    BaseButton,
    BaseTable,
    Page,
    NotificationCell,
  },

  setup() {
    const [
      fetchTrainingList,
      { data: trainingList, loading: isProjectListLoading, error },
    ] = useResource<Array<TrainingType>>({
      fetchResource: getTrainingList,
      initialValue: [],
      resourceName: "Training list",
    });

    const { handleResourceDelete: handleTrainingDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deleteTraining,
        resourceName: "Training",
        onSuccess: fetchTrainingList,
      });

    onMounted(() => {
      fetchTrainingList();
    });

    function isBusy(trainingId: number): boolean {
      return isDeleting(trainingId) || isProjectListLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: trainingList,
      isRowDataLoading: isProjectListLoading,
      errorMessage: error,
      getTrainingFormUrl,
      generateReport,
      fetchTrainingList,
      handleTrainingDelete,
      isBusy,
      newDateFormat,
    };
  },
});
