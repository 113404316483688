
import { defineComponent, PropType, ref, watch } from "vue";

import {
  BaseSelect,
  CountInput,
  OptionsSwitcherTrueFalse,
  OptionType,
} from "@tager/admin-ui";

import {
  PrimaryService,
  SecondaryService,
  SecondaryServiceMode,
} from "@/modules/orders/views/orders-create/typings";
import { minutesToTimeString } from "@/utils/formatter";

export default defineComponent({
  name: "SelectServicesServiceInput",
  components: {
    BaseSelect,
    CountInput,
    OptionsSwitcherTrueFalse,
  },
  props: {
    value: {
      type: [Number, Boolean],
      default: null,
      required: false,
    },
    optional: {
      type: Boolean,
      default: false,
      required: false,
    },
    type: {
      type: String as PropType<SecondaryServiceMode>,
      default: "",
    },
    services: {
      type: Array as PropType<Array<PrimaryService>>,
      default: () => [],
    },
    service: {
      type: Object as PropType<SecondaryService>,
      default: null,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const valueRef = ref<OptionType<number> | number | boolean | null>(
      props.value
    );

    watch(
      () => props.value,
      () => {
        if (props.services && props.services.length > 0) {
          const foundService = props.services.find(
            (item) => item.id === props.value
          );
          if (foundService) {
            valueRef.value = {
              value: foundService.id,
              label: foundService.name,
            };
          }
        } else {
          valueRef.value = props.value;
        }
      }
    );

    const onChange = (value: any) => {
      emit("change", value);
    };

    return {
      valueRef,
      minutesToTimeString,
      onChange,
    };
  },
});
