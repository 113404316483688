
import { computed, defineComponent, onMounted } from "vue";

import {
  BaseButton,
  BaseTable,
  ColumnDefinition,
  DeleteIcon,
  EditIcon,
  NorthIcon,
  SouthIcon,
} from "@tager/admin-ui";
import {
  useResource,
  useResourceDelete,
  useResourceMove,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { SecondaryServiceType } from "@/types";
import {
  deleteAdditionalService,
  getSecondaryServiceList,
  moveAdditionalService,
} from "@/requests";
import {
  getSecondaryServiceFormUrl,
  getSecondaryServiceListUrl,
} from "@/modules/services";

const COLUMN_DEFS: Array<ColumnDefinition<SecondaryServiceType>> = [
  {
    id: 1,
    name: "Название",
    field: "name",
  },
  {
    id: 2,
    name: "Цена",
    field: "price",
    style: { width: "145px", whiteSpace: "nowrap" },
    headStyle: { width: "145px" },
  },
  {
    id: 3,
    name: "Трудоемкость, мин",
    field: "minutes",
    style: { width: "160px" },
    headStyle: { width: "160px", whiteSpace: "nowrap" },
  },
  {
    id: 4,
    name: "Тип услуги",
    field: "mode",
    style: { width: "160px" },
    headStyle: { width: "160px", whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "140px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "140px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "SecondaryServiceList",
  components: {
    DeleteIcon,
    EditIcon,
    NorthIcon,
    SouthIcon,
    BaseButton,
    BaseTable,
    Page,
  },
  setup() {
    const [
      fetchSecondaryServiceList,
      { data: secondaryServiceList, loading: isSecondaryServiceLoading, error },
    ] = useResource<Array<SecondaryServiceType>>({
      fetchResource: getSecondaryServiceList,
      initialValue: [],
      resourceName: "Service Category List",
    });

    onMounted(() => {
      fetchSecondaryServiceList();
    });

    const { handleResourceMove: handleSecondaryServiceMove, isMoving } =
      useResourceMove({
        moveResource: moveAdditionalService,
        resourceName: "Secondary Service",
        onSuccess: fetchSecondaryServiceList,
      });

    const { handleResourceDelete: handleSecondaryServiceDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deleteAdditionalService,
        resourceName: "Service Category",
        onSuccess: fetchSecondaryServiceList,
      });

    const isRowDataLoading = computed<boolean>(
      () => isSecondaryServiceLoading.value
    );

    function isBusy(productId: number): boolean {
      return (
        isDeleting(productId) || isMoving(productId) || isRowDataLoading.value
      );
    }

    function getModeLabel(mode: string) {
      if (mode === "QUANTITY") {
        return "Количественная";
      }
      if (mode === "TIME") {
        return "Минутная";
      }
      if (mode === "TRUE_FALSE") {
        return "Да/Нет";
      }
      if (mode === "DELIVERY_KEYS") {
        return "Доставка ключей";
      }
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: secondaryServiceList,
      errorMessage: error,
      isRowDataLoading,
      isBusy,
      handleSecondaryServiceDelete,
      getModeLabel,

      getSecondaryServiceFormUrl,
      handleSecondaryServiceMove,
      getSecondaryServiceListUrl,
    };
  },
});
