import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-195d8093"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      value: _ctx.value,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
      name: "date",
      label: "Дата:",
      type: "date"
    }, null, 8, ["value"])
  ]))
}