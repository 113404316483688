
import { computed, defineComponent, onMounted, onUnmounted } from "vue";

import { Page } from "@tager/admin-layout";

import { useCityStore } from "@/store/city";
import CitySelector from "@/components/CitySelector.vue";

export default defineComponent({
  name: "PageRegional",
  components: {
    CitySelector,
    Page,
  },
  props: {
    allowAll: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  setup(props) {
    const cityStore = useCityStore();

    onMounted(() => {
      cityStore.enableSelectCity();
      if (props.allowAll) {
        cityStore.enableSelectAll();
      } else {
        cityStore.disableSelectAll();
      }
    });

    onUnmounted(() => {
      cityStore.disableSelectAll();
      cityStore.disableSelectCity();
    });

    const cityId = computed<number>(() => cityStore.selectedCity);

    return {
      cityId,
    };
  },
});
