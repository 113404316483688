
import {
  computed,
  defineComponent,
  onMounted,
  PropType,
  ref,
  watch,
} from "vue";
import { useRoute } from "vue-router";

import {
  BaseCheckbox,
  BaseInput,
  BaseTable,
  ToggleSection,
  FieldValue,
  Nl2Br,
  BaseButton,
} from "@tager/admin-ui";
import { Nullable, useResource, useToast } from "@tager/admin-services";

import {
  attachOrderWorker,
  detachOrderWorker,
  getOrderAvailableWorkers,
  getOrderWorkers,
  getWorkersMessage,
  updateOrderWorker,
} from "@/modules/orders/services";
import {
  OrderAvailableWorker,
  OrderFullInterface,
  OrderWorker,
} from "@/modules/orders/typings";
import {
  availableWorkersColumnDefs,
  orderWorkersColumnDefs,
  rowAvailableWorkersCssClass,
  sortAvailableWorkers,
} from "@/modules/orders/views/orders-edit/containers/OrdersEditWorkers/OrdersEditWorkers.helpers";
import { getOrderUrl } from "@/modules/orders";

type FormValues = Array<{
  id: number;
  pylesos: boolean;
  keys: boolean;
  time: number;
  rate: number;
  fine: number;
}>;

export default defineComponent({
  name: "OrdersEditWorkers",
  components: {
    BaseTable,
    ToggleSection,
    BaseInput,
    BaseCheckbox,
    FieldValue,
    Nl2Br,
    BaseButton,
  },
  methods: { getOrderUrl },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const route = useRoute();

    const excludeIds = ref<number[]>([]);

    const orderId = computed<number>(() => +route.params.orderId);

    const [
      fetchAvailableWorkers,
      { data: availableWorkers, loading: isAvailableWorkersLoading },
    ] = useResource<Array<OrderAvailableWorker>>({
      fetchResource: () => getOrderAvailableWorkers(orderId.value),
      initialValue: [],
      resourceName: "getOrderAvailableWorkers",
    });

    const [
      fetchOrderWorkersMessage,
      { data: orderWorkersMessage, loading: isOrderWorkersMessage },
    ] = useResource<Nullable<{ text: string }>>({
      fetchResource: () => getWorkersMessage(orderId.value),
      initialValue: null,
      resourceName: "getWorkersMessage",
    });

    const [
      fetchOrderWorkers,
      { data: orderWorkers, loading: isOrderWorkersLoading },
    ] = useResource<Array<OrderWorker>>({
      fetchResource: () => getOrderWorkers(orderId.value),
      initialValue: [],
      resourceName: "getOrderWorkers",
    });

    onMounted(() => {
      fetchAvailableWorkers();
      fetchOrderWorkers();
      fetchOrderWorkersMessage();
    });

    const loading = computed<boolean>(
      () => isAvailableWorkersLoading.value || isOrderWorkersLoading.value
    );

    const availableWorkersFiltered = computed<Array<OrderAvailableWorker>>(
      () => {
        const excludeIds = orderWorkers.value.map((item) => item.id);
        return availableWorkers.value
          .filter((item) => !excludeIds.includes(item.id))
          .sort(sortAvailableWorkers);
      }
    );

    const onSelect = async (id: number) => {
      excludeIds.value = [...excludeIds.value, id];
      await attachOrderWorker(orderId.value, id);
      await fetchOrderWorkers();
    };

    const onDetach = async (id: number) => {
      excludeIds.value = excludeIds.value.filter((item) => item !== id);

      await detachOrderWorker(orderId.value, id);
      await fetchOrderWorkers();
    };

    const formValues = ref<FormValues>([]);

    watch(orderWorkers, () => {
      formValues.value = orderWorkers.value.map((item) => {
        return {
          id: item.id,
          pylesos: item.pylesos,
          keys: item.keys,
          time: item.time,
          rate: item.rate,
          fine: item.fine,
        };
      });
    });

    const onChangeRow = (index: number, value: Record<string, any>) => {
      formValues.value = formValues.value.map((item, ind) => {
        if (index !== ind) return item;
        return { ...item, ...value };
      });

      if (!(index in formValues.value)) {
        return;
      }

      const model = formValues.value[index];

      updateOrderWorker(orderId.value, model.id, model);
    };

    const onChangeFlag = (index: number, param: string, value: boolean) => {
      if (!value) return onChangeRow(index, { [param]: value });

      formValues.value = formValues.value.map((item, ind) => {
        return { ...item, [param]: ind === index };
      });

      if (!(index in formValues.value)) {
        return;
      }

      const model = formValues.value[index];

      updateOrderWorker(orderId.value, model.id, model);
    };

    const onUpdateWorkers = async (e: MouseEvent) => {
      e.preventDefault();

      await fetchOrderWorkers();
    };

    const onCopyClick = () => {
      if (!orderWorkersMessage.value?.text) return;
      navigator.clipboard.writeText(orderWorkersMessage.value?.text).then(
        () => {
          toast.show({
            variant: "success",
            title: "Успешно",
            body: "Описание заказа скопировано",
          });
        },
        () => {
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка копирования описания заказа",
          });
        }
      );
    };

    return {
      loading,
      orderWorkers,
      availableWorkers: availableWorkersFiltered,
      availableWorkersColumnDefs: availableWorkersColumnDefs,
      orderWorkersColumnDefs: orderWorkersColumnDefs,
      onSelect,
      onDetach,
      rowCssClass: rowAvailableWorkersCssClass(props.order.datetime),
      onChangeRow,
      onChangeFlag,
      formValues,
      onUpdateWorkers,
      orderWorkersMessage: computed<string>(
        () => orderWorkersMessage.value?.text || ""
      ),
      onCopyClick,
    };
  },
});
