
import { defineComponent } from "vue";

import { Spinner, ToggleSection } from "@tager/admin-ui";

export default defineComponent({
  name: "StatsWidgetWrapper",
  components: { ToggleSection, Spinner },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    maxWidth: {
      type: Number,
      required: false,
      default: 0,
    },
  },
  setup() {
    return {};
  },
});
