import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createSlots as _createSlots } from "vue"

const _hoisted_1 = { class: "row-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldAjaxSelect = _resolveComponent("FormFieldAjaxSelect")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isLoading
  }, _createSlots({ _: 2 }, [
    (!_ctx.isLoading)
      ? {
          name: "content",
          fn: _withCtx(() => [
            (!_ctx.isCreation)
              ? (_openBlock(), _createBlock(_component_FormFieldSelect, {
                  key: 0,
                  value: _ctx.values.status,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.status) = $event)),
                  options: _ctx.statusOptions,
                  name: "status",
                  error: _ctx.errors.status,
                  label: "Статус"
                }, null, 8, ["value", "options", "error"]))
              : _createCommentVNode("", true),
            _createElementVNode("div", _hoisted_1, [
              _createVNode(_component_FormField, {
                value: _ctx.values.date,
                "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.date) = $event)),
                type: "date",
                name: "date",
                error: _ctx.errors.date,
                label: "Дата"
              }, null, 8, ["value", "error"]),
              _createVNode(_component_FormField, {
                value: _ctx.values.time,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.time) = $event)),
                type: "time",
                name: "time",
                error: _ctx.errors.time,
                label: "Время"
              }, null, 8, ["value", "error"])
            ]),
            _createVNode(_component_FormFieldAjaxSelect, {
              value: _ctx.values.user,
              "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.user) = $event)),
              name: "user",
              label: "Клиент",
              "value-field": "value",
              "label-field": "label",
              "request-url": "/admin/users/ajax-search"
            }, null, 8, ["value"]),
            _createVNode(_component_FormField, {
              value: _ctx.values.message,
              "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.message) = $event)),
              type: "textarea",
              name: "message",
              error: _ctx.errors.message,
              label: "Сообщение",
              rows: "10"
            }, null, 8, ["value", "error"])
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.isLoading)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_FormFooter, {
              "back-href": _ctx.backButtonUrl,
              "is-submitting": _ctx.isSubmitting,
              "is-creation": _ctx.isCreation,
              "can-create-another": _ctx.isCreation,
              onSubmit: _ctx.submitForm
            }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["title", "is-content-loading"]))
}