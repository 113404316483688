
import { defineComponent } from "vue";

import { useUserPermission } from "@tager/admin-services";

import { newPhoneFormat } from "@/utils/common";
import { Scope } from "@/rbac/Scope";

export default defineComponent({
  name: "UserTableCell",
  props: {
    id: {
      type: Number,
      required: true,
    },
    name: {
      type: String,
      default: "",
    },
    phone: {
      type: String,
      default: "",
    },
  },
  setup() {
    return {
      canViewUser: useUserPermission(Scope.UsersView),
      newPhoneFormat,
    };
  },
});
