import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateRangeFilter = _resolveComponent("DateRangeFilter")!
  const _component_StatsWidget = _resolveComponent("StatsWidget")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    "allow-all": true,
    title: _ctx.pageTitle
  }, {
    default: _withCtx(() => [
      _createVNode(_component_DateRangeFilter, {
        disabled: _ctx.isLoading,
        onSubmit: _ctx.onDateChange
      }, null, 8, ["disabled", "onSubmit"]),
      _createVNode(_component_StatsWidget, {
        title: "Заказы",
        loading: _ctx.isOrdersStatsLoading,
        value: _ctx.ordersStats,
        opened: true,
        "max-width": 500
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Клинеры",
        loading: _ctx.isWorkersStatsLoading,
        value: _ctx.workersStats,
        "max-width": 500
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Клиенты",
        loading: _ctx.isUsersStatsLoading,
        value: _ctx.usersStats,
        "max-width": 550
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Рейтинг",
        loading: _ctx.isRatingStatsLoading,
        value: _ctx.ratingStatus,
        "max-width": 500
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "LTV",
        loading: _ctx.isLtvStatsLoading,
        value: _ctx.ltvStats,
        "max-width": 1000
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Основные услуги",
        loading: _ctx.isPrimaryServicesStatsLoading,
        value: _ctx.primaryServicesStats,
        "max-width": 500
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Дополнительные услуги",
        loading: _ctx.isServicesStatsLoading,
        value: _ctx.servicesStats,
        "max-width": 500
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Подписки",
        loading: _ctx.isSubscriptionsStatsLoading,
        value: _ctx.subscriptionsStats
      }, null, 8, ["loading", "value"]),
      _createVNode(_component_StatsWidget, {
        title: "Возвращаемость",
        loading: _ctx.isRetentionStatsLoading,
        value: _ctx.retentionStats,
        "max-width": 500
      }, null, 8, ["loading", "value"])
    ]),
    _: 1
  }, 8, ["title"]))
}