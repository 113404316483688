
import { defineComponent, onMounted, PropType, watch } from "vue";

import {
  AdvancedSearch,
  AdvancedSearchDateFilter,
  FormFieldSelect,
  FormField,
  useAdvancedSearch,
  useAdvancedSearchDateFilter,
  useAdvancedSearchTextFilter,
  useAdvancedSearchSingleOptionFilter,
} from "@tager/admin-ui";

import { useAdvancedSearchManagersFilters } from "@/modules/notes/notes-list/filters/useAdvancedSearchManagersFilters";
import { NOTE_STATUS_OPTIONS, NoteStatus } from "@/modules/notes/typings";

enum FilterName {
  Status = "status",
  User = "user",
  Manager = "manager",
}

export default defineComponent({
  name: "NotesListFilter",
  components: {
    FormFieldSelect,
    FormField,
    AdvancedSearchDateFilter,
    AdvancedSearch,
  },
  props: {
    fetchData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["update:value"],
  setup(props, { emit }) {
    const dateFilter = useAdvancedSearchDateFilter();

    const managerFilter = useAdvancedSearchManagersFilters(
      FilterName.Manager,
      "Менеджер"
    );

    const statusFilter = useAdvancedSearchSingleOptionFilter<NoteStatus>(
      FilterName.Status,
      "Статус",
      NOTE_STATUS_OPTIONS
    );

    const userFilter = useAdvancedSearchTextFilter(FilterName.User, "Клиент");

    const { filterParams, tags, tagRemovalHandler } = useAdvancedSearch({
      filters: [dateFilter, managerFilter, statusFilter, userFilter],
      fetchEntityList: props.fetchData,
    });

    watch(filterParams, () => {
      emit("update:value", filterParams.value);
    });

    onMounted(() => emit("update:value", filterParams.value));

    return {
      dateFilter: dateFilter.value,
      statusFilter: statusFilter.value,
      statusFilterOptions: NOTE_STATUS_OPTIONS,
      managerFilter: managerFilter.value,
      managerFilterOptions: managerFilter.options,
      userFilter: userFilter.value,
      tags,
      tagRemovalHandler,
    };
  },
});
