import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedSearchDateFilter = _resolveComponent("AdvancedSearchDateFilter")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!

  return (_openBlock(), _createBlock(_component_AdvancedSearch, {
    tags: _ctx.tags,
    "onClick:tag": _ctx.tagRemovalHandler
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AdvancedSearchDateFilter, {
        filter: _ctx.dateFilter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event))
      }, null, 8, ["filter"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.statusFilter,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.statusFilter) = $event)),
          label: "Статус:",
          name: "statusFilter",
          searchable: false,
          options: _ctx.statusFilterOptions,
          "no-error-padding": true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.managerFilter,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.managerFilter) = $event)),
          label: "Менеджер:",
          name: "managerFilter",
          searchable: false,
          options: _ctx.managerFilterOptions,
          "no-error-padding": true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormField, {
          value: _ctx.userFilter,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userFilter) = $event)),
          label: "Клиент:",
          name: "userFilter"
        }, null, 8, ["value"])
      ])
    ]),
    _: 1
  }, 8, ["tags", "onClick:tag"]))
}