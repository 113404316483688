import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = { class: "promo-code-result__inner" }
const _hoisted_2 = { class: "promo-code-result__code" }
const _hoisted_3 = { class: "promo-code-result__discount" }
const _hoisted_4 = {
  key: 0,
  class: "promo-code-result__footer"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.code), 1),
      _createElementVNode("span", _hoisted_3, _toDisplayString(`-${_ctx.discount}%`), 1),
      (!_ctx.noCancel && _ctx.withEdit)
        ? (_openBlock(), _createElementBlock("div", _hoisted_4, [
            _createElementVNode("button", {
              class: "promo-code-result__cancel",
              onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
            }, " Отменить ")
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}