
import { computed, defineComponent, onMounted } from "vue";

import {
  BaseButton,
  BaseTable,
  ColumnDefinition,
  CountButton,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import { useResource, useResourceDelete } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { ServiceCategoryType } from "@/types";
import { deleteServiceCategory, getServiceCategoryList } from "@/requests";
import {
  getAdditionalServiceCategoryListUrl,
  getServiceCategoryFormUrl,
} from "@/modules/services";

const COLUMN_DEFS: Array<ColumnDefinition<ServiceCategoryType>> = [
  {
    id: 1,
    name: "Название",
    field: "name",
  },
  {
    id: 2,
    name: "Системное название",
    field: "systemName",
  },
  {
    id: 3,
    name: "Услуги",
    field: "services",
    style: { width: "145px", whiteSpace: "nowrap" },
    headStyle: { width: "145px" },
  },
  {
    id: 4,
    name: "Действия",
    field: "actions",
    style: { width: "101px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "101px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "ServiceCategoryList",
  components: {
    DeleteIcon,
    EditIcon,
    BaseButton,
    CountButton,
    BaseTable,
    Page,
  },
  setup() {
    const [
      fetchServiceCategoryList,
      { data: serviceCategoryList, loading: isServiceCategoryLoading, error },
    ] = useResource<Array<ServiceCategoryType>>({
      fetchResource: getServiceCategoryList,
      initialValue: [],
      resourceName: "Service Category List",
    });

    onMounted(() => {
      fetchServiceCategoryList();
    });

    const { handleResourceDelete: handleServiceCategoryDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deleteServiceCategory,
        resourceName: "Service Category",
        onSuccess: fetchServiceCategoryList,
      });

    const isRowDataLoading = computed<boolean>(
      () => isServiceCategoryLoading.value
    );

    function isBusy(productId: number): boolean {
      return isDeleting(productId) || isRowDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: serviceCategoryList,
      errorMessage: error,
      isRowDataLoading,
      isBusy,
      handleServiceCategoryDelete,

      getServiceCategoryFormUrl,

      getAdditionalServiceCategoryListUrl,
    };
  },
});
