import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.PageTitle,
    "is-content-loading": _ctx.isContentLoading,
    footer: {
      backHref: _ctx.backButtonUrl,
      onSubmit: _ctx.submitForm,
      isSubmitting: _ctx.isSubmitting,
    }
  }, {
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "ФИО"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.phone,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.phone) = $event)),
          name: "phone",
          error: _ctx.errors.phone,
          label: "Телефон"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.email,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.email) = $event)),
          name: "email",
          error: _ctx.errors.email,
          label: "Почта"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.dateStart,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.dateStart) = $event)),
          name: "dateStart",
          error: _ctx.errors.dateStart,
          label: "Дата начала",
          type: "date"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.dateEnd,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.dateEnd) = $event)),
          name: "dateEnd",
          error: _ctx.errors.dateEnd,
          label: "Дата окончания",
          type: "date"
        }, null, 8, ["value", "error"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading", "footer"]))
}