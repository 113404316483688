import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "edit-mode-switcher"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OptionsSwitcherTrueFalse = _resolveComponent("OptionsSwitcherTrueFalse")!

  return (_ctx.canEditOrder)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_OptionsSwitcherTrueFalse, {
          value: _ctx.value,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
          "true-label": "Редактирование",
          "false-label": "Просмотр"
        }, null, 8, ["value"])
      ]))
    : _createCommentVNode("", true)
}