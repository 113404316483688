import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, resolveComponent as _resolveComponent, createVNode as _createVNode, createBlock as _createBlock, createTextVNode as _createTextVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-040d0932"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "work-content-block" }
const _hoisted_2 = {
  key: 0,
  class: "disabled-block"
}
const _hoisted_3 = { class: "time-select-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderList = _resolveComponent("OrderList")!
  const _component_CloseIcon = _resolveComponent("CloseIcon")!
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isDisable())
      ? (_openBlock(), _createElementBlock("div", _hoisted_2))
      : (!_ctx.isWork)
        ? (_openBlock(), _createElementBlock("div", {
            key: 1,
            class: "no-work-block",
            onClick: _cache[0] || (_cache[0] = ($event: any) => (
        _ctx.setWorkingDay(_ctx.id, {
          date: _ctx.timetable.date,
          value: { startAt: '08:00', finishAt: '20:00' },
        }).then(() => _ctx.toggleIsWork())
      ))
          }, [
            _createVNode(_component_OrderList, { "order-list": _ctx.orderList }, null, 8, ["order-list"])
          ]))
        : (_openBlock(), _createElementBlock("div", {
            key: 2,
            class: "work-block",
            style: _normalizeStyle(_ctx.orderList.length > 1 ? { paddingBottom: '35px' } : null)
          }, [
            (!_ctx.orderList.length)
              ? (_openBlock(), _createBlock(_component_CloseIcon, {
                  key: 0,
                  onClick: _cache[1] || (_cache[1] = ($event: any) => (
          _ctx.setWorkingDay(_ctx.id, { date: _ctx.timetable.date, value: null }).then(() =>
            _ctx.toggleIsWork()
          )
        ))
                }))
              : _createCommentVNode("", true),
            _createVNode(_component_OrderList, { "order-list": _ctx.orderList }, null, 8, ["order-list"]),
            _createElementVNode("div", _hoisted_3, [
              _createTextVNode(" c "),
              _createVNode(_component_BaseSelect, {
                value: _ctx.values.from,
                "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.from) = $event)),
                placeholder: "время",
                class: "time-select",
                options: _ctx.timeOptionList,
                clearable: false,
                onChange: _ctx.onFromChange
              }, null, 8, ["value", "options", "onChange"]),
              _createTextVNode(" до "),
              _createVNode(_component_BaseSelect, {
                value: _ctx.values.to,
                "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.to) = $event)),
                placeholder: "время",
                class: "time-select",
                options: _ctx.timeOptionList,
                clearable: false,
                onChange: _ctx.onToChange
              }, null, 8, ["value", "options", "onChange"])
            ])
          ], 4))
  ]))
}