import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_SubscriptionsViewPeriod = _resolveComponent("SubscriptionsViewPeriod")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_SubscriptionsViewLastOrder = _resolveComponent("SubscriptionsViewLastOrder")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldValue, {
      label: "Дата создания",
      type: "datetime",
      value: _ctx.subscription.createdAt
    }, null, 8, ["value"]),
    (_ctx.subscription.isCompleted)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          _createVNode(_component_FieldValue, {
            label: "Дата завершения",
            type: "datetime",
            value: _ctx.subscription.cancelledAt
          }, null, 8, ["value"]),
          (_ctx.subscription.cancelledReason)
            ? (_openBlock(), _createBlock(_component_FieldValue, {
                key: 0,
                label: "Причина",
                type: "text",
                value: _ctx.subscription.cancelledReason
              }, null, 8, ["value"]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true),
    _createVNode(_component_SubscriptionsViewPeriod, { subscription: _ctx.subscription }, null, 8, ["subscription"]),
    _createVNode(_component_FieldValue, { label: "Клиент" }, {
      value: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: `${'/users/' + _ctx.subscription.user.id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" ID " + _toDisplayString(_ctx.subscription.user.id), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(" - " + _toDisplayString(_ctx.subscription.user.name) + " - " + _toDisplayString(_ctx.newPhoneFormat(_ctx.subscription.user.phone)), 1)
      ]),
      _: 1
    }),
    _createVNode(_component_SubscriptionsViewLastOrder, { subscription: _ctx.subscription }, null, 8, ["subscription"]),
    _createVNode(_component_FieldValue, {
      label: "Адрес",
      value: _ctx.subscription.address.text,
      buttons: [
      {
        label: 'На карте',
        variant: 'primary-link',
        to: _ctx.getMapsUrl(
          _ctx.subscription.address.latitude,
          _ctx.subscription.address.longitude
        ),
        useRouter: false,
      },
    ]
    }, null, 8, ["value", "buttons"]),
    _createVNode(_component_FieldValue, {
      label: "Услуги",
      value: _ctx.subscription.services,
      type: "list"
    }, null, 8, ["value"])
  ], 64))
}