import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";

import OrdersListActive from "./views/OrdersListActive.vue";
import OrdersListCompleted from "./views/OrdersListCompleted.vue";
import OrdersListCancelled from "./views/OrdersListCancelled.vue";
import OrdersMap from "./views/orders-map";
import OrdersView from "./views/orders-view";
import OrdersEdit from "./views/orders-edit";
import OrdersForDay from "./views/orders-for-day";
import OrdersCreate from "./views/orders-create";

export const ORDERS_LIST_ACTIVE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_ACTIVE_LIST,
  component: OrdersListActive,
  name: "Список заказов - Активные",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.ORDERS_ACTIVE_LIST],
  },
};

export const ORDERS_LIST_COMPLETED_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_COMPLETED_LIST,
  component: OrdersListCompleted,
  name: "Список заказов - Завершенные",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.ORDERS_COMPLETED_LIST],
  },
};

export const ORDERS_LIST_CANCELLED_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_CANCELLED_LIST,
  component: OrdersListCancelled,
  name: "Список заказов - Отмененные",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.ORDERS_CANCELLED_LIST],
  },
};

export const ORDERS_VIEW_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_VIEW,
  component: OrdersView,
  name: "Просмотр заказа",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.ORDERS_COMPLETED_LIST],
  },
};

export const ORDERS_EDIT_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_EDIT,
  component: OrdersEdit,
  name: "Редактирование заказа",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.ORDERS_ACTIVE_LIST],
  },
};

export const ORDERS_MAP_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_MAP,
  component: OrdersMap,
  name: "Карта заказов",
  meta: {
    getBreadcrumbs: () => [
      LINKS.HOME,
      LINKS.ORDERS_ACTIVE_LIST,
      LINKS.ORDERS_MAP,
    ],
  },
};

export const ORDERS_FOR_DAY_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_FOR_DAY,
  component: OrdersForDay,
  name: "Заказы за день",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.ORDERS_ACTIVE_LIST,
      LINKS.ORDERS_FOR_DAY,
    ],
  },
};

export const ORDERS_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ORDERS_CREATE,
  component: OrdersCreate,
  name: "Новый заказ",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.ORDERS_ACTIVE_LIST,
      LINKS.ORDERS_CREATE,
    ],
  },
};
