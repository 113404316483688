import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-b426cc8c"),n=n(),_popScopeId(),n)
const _hoisted_1 = ["href"]
const _hoisted_2 = { class: "workers" }
const _hoisted_3 = { class: "row-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_OrdersEditUserText = _resolveComponent("OrdersEditUserText")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(_component_FieldValue, { label: "Клиент" }, {
      value: _withCtx(() => [
        _createVNode(_component_router_link, {
          to: `${'/users/' + _ctx.order.user.id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode("ID " + _toDisplayString(_ctx.order.user.id), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createTextVNode(" - " + _toDisplayString(_ctx.order.user.name) + " - ", 1),
        _createElementVNode("a", {
          href: `tel:${_ctx.order.user.phone?.replace(/^d*/g, '')}`
        }, _toDisplayString(_ctx.newPhoneFormat(_ctx.order.user.phone)), 9, _hoisted_1)
      ]),
      _: 1
    }),
    _createVNode(_component_OrdersEditUserText, { order: _ctx.order }, null, 8, ["order"]),
    _createVNode(_component_FieldValue, {
      label: "Клинеры",
      "hide-value-on-edit": true,
      "with-edit": true,
      "with-edit-actions": true,
      "edit-active": _ctx.isEditWorkersOpened,
      loading: _ctx.workersLoading,
      "edit-actions-loading": _ctx.editWorkersSaveLoading,
      onEdit: _ctx.openEditWorkers,
      onCancel: _ctx.cancelEditWorkers,
      onSave: _ctx.saveEditWorkers
    }, {
      value: _withCtx(() => [
        _createElementVNode("ul", _hoisted_2, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.likeWorkersSelected, (worker) => {
            return (_openBlock(), _createElementBlock("li", {
              key: worker,
              class: "workers__item workers__item--like"
            }, _toDisplayString(worker), 1))
          }), 128)),
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.dislikeWorkersSelected, (worker) => {
            return (_openBlock(), _createElementBlock("li", {
              key: worker,
              class: "workers__item workers__item--dislike"
            }, _toDisplayString(worker), 1))
          }), 128))
        ])
      ]),
      edit: _withCtx(() => [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_FormFieldMultiSelect, {
            "selected-options": _ctx.likeWorkers,
            "onUpdate:selected-options": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.likeWorkers) = $event)),
            options: _ctx.workersOptions,
            label: "Любимые клинеры",
            name: "likeWorkers",
            "no-error-padding": true
          }, null, 8, ["selected-options", "options"]),
          _createVNode(_component_FormFieldMultiSelect, {
            "selected-options": _ctx.dislikeWorkers,
            "onUpdate:selected-options": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dislikeWorkers) = $event)),
            options: _ctx.workersOptions,
            label: "Нелюбимые клинеры",
            name: "dislikeWorkers",
            "no-error-padding": true
          }, null, 8, ["selected-options", "options"])
        ])
      ]),
      _: 1
    }, 8, ["edit-active", "loading", "edit-actions-loading", "onEdit", "onCancel", "onSave"])
  ], 64))
}