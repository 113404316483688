
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { types } from "sass";

import { Page } from "@tager/admin-layout";
import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FormFooter,
  FormFieldCheckbox,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";

import { getUsersListUrl } from "@/modules/users";
import { createCity, getCity, updateCity } from "@/modules/cities/services";
import { CityInterface } from "@/modules/cities/typings";
import { getCitiesListUrl, getCitiesUpdateUrl } from "@/modules/cities";

import {
  convertFormValuesToUpdateCityPayload,
  FormValues,
  getInitialFormValues,
} from "./CitiesForm.helpers";

export default defineComponent({
  name: "UsersEdit",
  components: {
    FormField,
    FormFieldCheckbox,
    FormFooter,
    Page,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const cityId = computed<number>(() => +route.params.id);

    const isCreation = computed<boolean>(() => !cityId.value);

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);
    const values = ref<FormValues>(getInitialFormValues(null));

    const [fetchModel, { data: model, loading: isModelLoading }] = useResource<
      Nullable<CityInterface>
    >({
      fetchResource: () =>
        cityId.value
          ? getCity(cityId.value)
          : new Promise((resolve) => resolve({ data: null })),
      initialValue: null,
      resourceName: "city",
    });

    onMounted(fetchModel);
    watch([cityId], fetchModel);

    watch([model], () => (values.value = getInitialFormValues(model.value)));

    const pageTitle = computed<string>(() => {
      return isCreation.value ? `Добавить город` : `Редактировать город`;
    });

    const submitForm = async (event: TagerFormSubmitEvent) => {
      isSubmitting.value = true;
      errors.value = {};

      const payload = convertFormValuesToUpdateCityPayload(values.value);

      const requestPromise = isCreation.value
        ? createCity(payload)
        : updateCity(cityId.value, payload);

      try {
        const response = await requestPromise;

        if (event.type === "create") {
          await router.push(getCitiesUpdateUrl(+response.data.id));
        } else if (event.type === "create_exit" || event.type === "save_exit") {
          navigateBack(router, getCitiesListUrl());
        } else if (event.type === "create_create-another") {
          values.value = getInitialFormValues(null);
        }

        toast.show({
          variant: "success",
          title: "Success",
          body: "Город успешно сохранен",
        });
      } catch (error: any) {
        errors.value = convertRequestErrorToMap(error);
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения города",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    return {
      isCreation,
      model,
      errors,
      values,
      backButtonUrl: getUsersListUrl(),
      pageTitle,
      isLoading: isModelLoading,
      isSubmitting,
      submitForm,
    };
  },
});
