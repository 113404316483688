
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { computed, defineComponent, onMounted, watch } from "vue";

import { useResource } from "@tager/admin-services";

import { createOrderPlacemark, MapOrder } from "@/utils/orders-map";
import mapCleanerIcon from "@/assets/images/map-cleaner.png";
import { useActiveCity, useCityId } from "@/store/city";
import { OrderInterface } from "@/modules/orders/typings";
import { getOrdersByDate } from "@/modules/orders/services";

function getLineColor(distance: number) {
  if (distance >= 5) {
    return "#ff0000";
  } else if (distance >= 3) {
    return "#003bff";
  } else {
    return "#009600";
  }
}

const convertOrderToMapOrder = (order: OrderInterface): MapOrder => {
  return {
    id: order.id,
    datetime: order.datetime,
    address: order.address.raw,
    latitude: order.address.latitude,
    longitude: order.address.longitude,
    workers: order.workers.map((item) => {
      return {
        id: item.id,
        name: item.name,
        number: String(item.number),
        distanceMeters: item.distance * 1000,
        isArchive: item.isArchived,
      };
    }),
  };
};

let myMap;
export default defineComponent({
  name: "MapView",
  components: {},
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const city = useActiveCity();
    const cityId = useCityId();

    const [fetchOrders, { data: orders }] = useResource<OrderInterface[]>({
      fetchResource: () => getOrdersByDate(props.date, cityId.value)(),
      initialValue: [],
      resourceName: "Orders by Date",
    });

    const dateValue = computed<string>(() => props.date);

    watch([cityId, dateValue], () => {
      fetchOrders();
    });

    onMounted(fetchOrders);

    const updateMap = () => {
      const workersAdded: number[] = [];

      myMap.geoObjects.removeAll();

      orders.value.forEach((order) => {
        myMap.geoObjects.add(
          createOrderPlacemark(convertOrderToMapOrder(order))
        );

        for (let i = 0; i < order.workers.length; i++) {
          const worker = order.workers[i];

          if (!workersAdded.includes(worker.id)) {
            const placeMark = new window.ymaps.Placemark(
              [worker.latitude, worker.longitude],
              {},
              {
                iconLayout: "default#imageWithContent",
                iconImageSize: [32, 32],
                iconImageOffset: [-16, -16],
                iconImageHref: mapCleanerIcon,
              }
            );
            myMap.geoObjects.add(placeMark);

            workersAdded.push(worker.id);
          }

          const polyline = new window.ymaps.Polyline(
            [
              [order.address.latitude, order.address.longitude],
              [worker.latitude, worker.longitude],
            ],
            {},
            {
              strokeColor: getLineColor(worker.distance),
              strokeWidth: 2,
            }
          );

          myMap.geoObjects.add(polyline);
        }
      });
    };

    watch(orders, () => {
      window.ymaps.ready(() => {
        updateMap();
      });
    });

    watch(city, () => {
      window.ymaps.ready(() => {
        if (!myMap) {
          myMap = new window.ymaps.Map(
            "map",
            {
              center: [city.value.latitude, city.value.longitude],
              zoom: city.value.zoom,
              controls: ["zoomControl"],
            },
            {
              suppressMapOpenBlock: true,
            }
          );
        } else {
          myMap.setCenter(
            [city.value.latitude, city.value.longitude],
            city.value.zoom
          );
        }
      });
    });
  },
});
