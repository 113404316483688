import { Nullable, request, ResponseBody } from "@tager/admin-services";
import { getFilterParams } from "@tager/admin-ui";

import { CountData } from "@/requests";
import { OrderHouseType } from "@/enums/OrderHouseType";

import {
  OrderAvailableWorker,
  OrderDetailAccepted,
  OrderDetailSMS,
  OrderFullInterface,
  OrderInterface,
  OrderPromocode,
  OrderStatsByDate,
  OrderWorker,
  SecondaryServiceValue,
} from "./typings";

export function getActiveOrdersCount(
  city?: number
): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/orders/count", params: query });
}

export function getOrder(
  orderId: number
): Promise<ResponseBody<Nullable<OrderFullInterface>>> {
  return request.get({ path: "/admin/orders/" + orderId });
}

type GetOrdersParams = {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string;
};

type GetOrdersFunction = (
  params?: GetOrdersParams
) => Promise<ResponseBody<OrderInterface[]>>;

export const getOrders: GetOrdersFunction = (params) =>
  request.get({ path: "/admin/orders", params });

export const createGetOrdersFunction = (
  filters: Record<string, string | number | Array<string> | Array<number>>,
  disablePagination = false
): GetOrdersFunction => {
  return (params) =>
    getOrders({
      ...getFilterParams(filters),
      ...params,
      ...(disablePagination
        ? {
            pageNumber: 1,
            pageSize: 10000,
          }
        : {}),
    });
};

export const getOrdersByDate = (
  date: string,
  city: number
): GetOrdersFunction =>
  createGetOrdersFunction({ date, city, status: "ACTIVE_COMPLETED" });

export const getOrdersByStatus = (status: string): GetOrdersFunction =>
  createGetOrdersFunction({ status });

export const getOrdersByUser = (userId: number): GetOrdersFunction =>
  createGetOrdersFunction({ user: userId }, true);

export const getOrdersByWorker = (workerId: number): GetOrdersFunction =>
  createGetOrdersFunction(
    { worker: workerId, status: "ACTIVE_COMPLETED" },
    true
  );

export const getOrdersBySubscription = (
  subscriptionId: number
): GetOrdersFunction =>
  createGetOrdersFunction(
    {
      subscription: subscriptionId,
    },
    true
  );

export async function changeOrderDateTime(
  orderId: number,
  date: string,
  time: string
) {
  return request.post({
    path: "/admin/orders/" + orderId + "/datetime",
    body: {
      date,
      time,
    },
  });
}

export async function changeOrderServices(
  orderId: number,
  primary: number[],
  secondary: SecondaryServiceValue[],
  keysDeliveryAddress: string | null,
  houseType: OrderHouseType
) {
  return request.post({
    path: "/admin/orders/" + orderId + "/services",
    body: {
      primary,
      secondary,
      keysDeliveryAddress,
      type: houseType,
    },
  });
}

export async function setOrderPromoCode(
  orderId: number,
  code: string
): Promise<ResponseBody<OrderPromocode>> {
  return request.post({
    path: "/admin/orders/" + orderId + "/promocode",
    body: {
      code,
    },
  });
}

export async function deleteOrderPromoCode(
  orderId: number
): Promise<ResponseBody<OrderPromocode>> {
  return request.delete({
    path: "/admin/orders/" + orderId + "/promocode",
  });
}

export type SetOrderAddressFields = {
  city: number | null;
  street: Nullable<string>;
  house: Nullable<number>;
  apartment: Nullable<string>;
  wing: Nullable<string>;
  entrance: Nullable<string>;
  intercomCode: Nullable<string>;
  floor: Nullable<number>;
};

export async function setOrderAddress(
  orderId: number,
  body: SetOrderAddressFields
): Promise<ResponseBody<{ address: string }>> {
  return request.post({
    path: "/admin/orders/" + orderId + "/address",
    body,
  });
}

export async function getOrderStatsByDate(
  date: string,
  city: number
): Promise<ResponseBody<OrderStatsByDate>> {
  return request.get({
    path: `/admin/orders/stats-by-date/${date}`,
    params: { city },
  });
}

export async function setOrderReview(
  orderId: number,
  rating: number,
  text: string,
  solution: string
) {
  return request.post({
    path: "/admin/orders/" + orderId + "/review",
    body: {
      rating,
      text,
      solution,
    },
  });
}

export async function deleteOrderReview(orderId: number) {
  return request.delete({
    path: "/admin/orders/" + orderId + "/review",
  });
}

export async function setOrderAdminComment(orderId: number, comment: string) {
  return request.post({
    path: "/admin/orders/" + orderId + "/admin-comment",
    body: {
      comment,
    },
  });
}

export async function getOrderAvailableWorkers(
  orderId: number
): Promise<ResponseBody<Array<OrderAvailableWorker>>> {
  return request.get({
    path: "/admin/orders/" + orderId + "/available-workers",
  });
}

export async function getOrderWorkers(
  orderId: number
): Promise<ResponseBody<Array<OrderWorker>>> {
  return request.get({
    path: "/admin/orders/" + orderId + "/workers",
  });
}

export async function attachOrderWorker(orderId: number, workerId: number) {
  return request.post({
    path: `/admin/orders/${orderId}/workers/${workerId}`,
  });
}

type OrderWorkerPayload = {
  pylesos: boolean;
  keys: boolean;
  time: number;
  rate: number;
  fine: number;
};

export async function updateOrderWorker(
  orderId: number,
  workerId: number,
  body: OrderWorkerPayload
) {
  return request.put({
    path: `/admin/orders/${orderId}/workers/${workerId}`,
    body,
  });
}

type SetOrderPricePayload = {
  isFree: boolean;
  isHourPayment: boolean;
  price: number;
  discount: number;
  coefficient: number;
};

export async function setOrderPrice(
  orderId: number,
  body: SetOrderPricePayload
) {
  return request.post({
    path: `/admin/orders/${orderId}/price`,
    body,
  });
}

export async function detachOrderWorker(orderId: number, workerId: number) {
  return request.delete({
    path: `/admin/orders/${orderId}/workers/${workerId}`,
  });
}

export async function acceptOrder(
  orderId: number
): Promise<ResponseBody<OrderDetailAccepted>> {
  return request.post({
    path: `/admin/orders/${orderId}/accept`,
  });
}

export async function sendOrderSms(
  orderId: number
): Promise<ResponseBody<OrderDetailSMS>> {
  return request.post({
    path: `/admin/orders/${orderId}/sms`,
  });
}

export async function acceptCancelOrder(
  orderId: number
): Promise<ResponseBody<OrderDetailAccepted>> {
  return request.delete({
    path: `/admin/orders/${orderId}/accept`,
  });
}

export async function cancelOrder(orderId: number) {
  return request.post({
    path: `/admin/orders/${orderId}/cancel`,
  });
}

export async function setOrderAsSingle(orderId: number) {
  return request.post({
    path: `/admin/orders/${orderId}/set-single`,
  });
}

export async function restoreOrder(orderId: number) {
  return request.post({
    path: `/admin/orders/${orderId}/restore`,
  });
}

export async function getWorkersMessage(
  orderId: number
): Promise<ResponseBody<{ text: string }>> {
  return request.get({
    path: `/admin/orders/${orderId}/workers-message`,
  });
}
