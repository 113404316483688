
import { defineComponent, onMounted, PropType, watch } from "vue";

import { AdvancedSearch, AdvancedSearchDateFilter } from "@tager/admin-ui";

import { useAdvancedSearch } from "./useAdvancedSearch";

export default defineComponent({
  name: "ArchiveWorkerListFilter",
  components: {
    AdvancedSearchDateFilter,
    AdvancedSearch,
  },
  props: {
    fetchData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["update:loading", "update:value"],
  setup(props, context) {
    const {
      dateArchivedFilter,
      dateCreatedFilter,
      filterParams,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({ fetchEntityList: props.fetchData });

    watch(filterParams, () => context.emit("update:value", filterParams.value));
    onMounted(() => context.emit("update:value", filterParams.value));

    return {
      dateArchivedFilter,
      dateCreatedFilter,
      tags,
      tagRemovalHandler,
    };
  },
});
