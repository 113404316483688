
import { defineComponent } from "vue";

import { minutesToTimeString } from "@/utils/formatter";

export default defineComponent({
  name: "OrderFooterInfo",
  props: {
    price: {
      type: Number,
      required: true,
    },
    minutes: {
      type: Number,
      required: false,
      default: null,
    },
  },
  methods: { minutesToTimeString },
});
