
import { computed, defineComponent, PropType, ref } from "vue";

import { FieldValue, formatDateTime } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { OrderDetailSMS, OrderFullInterface } from "@/modules/orders/typings";
import { sendOrderSms } from "@/modules/orders/services";

export default defineComponent({
  name: "OrdersEditSMS",
  components: { FieldValue },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const loading = ref<boolean>(false);
    const value = ref<OrderDetailSMS>(props.order);

    const buttons = computed(() => [
      {
        label: !value.value.smsSent ? "Отправить SMS" : "Отправить еще раз",
        variant: !value.value.smsSent ? "green" : "secondary-link",

        onClick: async () => {
          const confirmMessage = !value.value.smsSent
            ? "Вы уверены, что хотите отправить SMS?"
            : "Вы уверены, что хотите отправить ПОВТОРНО SMS?";

          if (!confirm(confirmMessage)) return;

          try {
            loading.value = true;

            const response = await sendOrderSms(props.order.id);

            toast.show({
              variant: "success",
              title: "Успешно",
              body: "SMS отправлено",
            });

            value.value = response.data;
          } catch (e: any) {
            toast.show({
              variant: "danger",
              title: "Ошибка",
              body: e.message,
            });
          } finally {
            loading.value = false;
          }
        },
      },
    ]);

    const text = computed<string>(() => {
      if (!value.value.smsSent) {
        return "Не отправлено";
      } else {
        return "Отправлено " + formatDateTime(new Date(value.value.smsSentAt));
      }
    });

    return {
      loading,
      buttons,
      text,
    };
  },
});
