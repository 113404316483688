import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!

  return (_openBlock(), _createBlock(_component_BaseSelect, {
    options: _ctx.citiesOptions,
    value: _ctx.selected,
    clearable: false,
    searchable: false,
    onChange: _ctx.onChangeCity
  }, null, 8, ["options", "value", "onChange"]))
}