
import { watch, computed, defineComponent, onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { FormFooter, TabType } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import { Nullable, useResource, useToast } from "@tager/admin-services";

import { getWebsiteOrigin } from "@/utils/common";
import OrdersListTable from "@/modules/orders/containers/orders-list/OrdersList.table.vue";
import { getOrdersBySubscription } from "@/modules/orders/services";

import { cancelSubscription, getSubscription } from "../services";
import { subscriptionPeriodOptions } from "../constants";
import { SubscriptionFullInterface } from "../typings";

import SubscriptionsViewCommon from "./components/SubscriptionsViewCommon.vue";

export default defineComponent({
  name: "OrdersView",
  components: {
    OrdersListTable,
    SubscriptionsViewCommon,
    Page,
    FormFooter,
  },
  setup() {
    const toast = useToast();
    const route = useRoute();

    const isCancelLoading = ref<boolean>(false);
    const isSubmitting = ref<boolean>(false);

    const subscriptionId = computed<number>(() => +route.params.subscriptionId);

    const [
      fetchSubscription,
      {
        data: subscription,
        loading: loadingStatus,
        status: subscriptionLoadingStatus,
      },
    ] = useResource<Nullable<SubscriptionFullInterface>>({
      fetchResource: () =>
        subscriptionId.value
          ? getSubscription(subscriptionId.value)
          : new Promise((resolve) => resolve({ data: null })),
      initialValue: null,
      resourceName: "Загрузка подписки",
    });

    onMounted(() => fetchSubscription());
    watch([subscriptionId], () => fetchSubscription());

    const isContentLoading = computed<boolean>(() => loadingStatus.value);

    const tabList: Array<TabType> = [
      { id: "common", label: "Основное" },
      { id: "orders", label: "Заказы" },
    ];

    const selectedTabId = ref<string>(tabList[0].id);

    const onCancelClick = async () => {
      if (!subscription.value) return;

      if (!confirm("Вы уверены, что хотите отменить подписку?")) {
        return;
      }

      const cancelledReason = prompt("Причина отмены") || "";

      isCancelLoading.value = true;

      try {
        await cancelSubscription(subscription.value.id, cancelledReason);

        toast.show({
          variant: "success",
          title: "Success",
          body: `Подписка успешно отменена`,
        });

        await fetchSubscription();
      } catch {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: `Ошибка отмены подписки`,
        });
      } finally {
        isCancelLoading.value = false;
      }
    };

    const update = () => fetchSubscription();

    return {
      isCancelLoading,

      subscription,
      getWebsiteOrigin,
      loadingStatus: subscriptionLoadingStatus,
      subscriptionPeriodOptions,
      subscriptionId,
      isContentLoading,
      isSubmitting,

      tabList,
      selectedTabId,
      onCancelClick,

      update,

      fetchOrders: () => getOrdersBySubscription(subscriptionId.value)(),
    };
  },
});
