
import { defineComponent } from "vue";

import OrdersList from "@/modules/orders/containers/orders-list/OrdersList.vue";

export default defineComponent({
  name: "OrdersListActive",
  components: {
    OrdersList,
  },
});
