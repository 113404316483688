import { defineStore } from "pinia";
import { computed, ComputedRef, onMounted, Ref } from "vue";

import { useDataTable } from "@tager/admin-ui";

import { CityInterface } from "@/modules/cities/typings";
import { getCities } from "@/modules/cities/services";

export const useCityStore = defineStore("city", {
  state: () => ({
    loading: false,
    selectedCity: 0,
    selectEnabled: false,
    selectAllDisabled: false,
  }),
  getters: {
    activeCityId: (state) => state.selectedCity || null,
    isSelectEnabled: (state) => state.selectEnabled,
    isSelectAllDisabled: (state) => state.selectAllDisabled,
  },
  actions: {
    selectCity(cityId: number) {
      this.selectedCity = cityId;
    },
    disableSelectCity() {
      this.selectEnabled = false;
    },
    enableSelectCity() {
      this.selectEnabled = true;
    },

    disableSelectAll() {
      this.selectAllDisabled = true;
    },
    enableSelectAll() {
      this.selectAllDisabled = false;
    },
  },
});

type UseCitiesHookResult = {
  loading: Ref<boolean>;
  items: Ref<CityInterface[]>;
};

export function useCities(): UseCitiesHookResult {
  const {
    isLoading: isDataLoading,
    fetchEntityList,
    rowData: cities,
  } = useDataTable<CityInterface>({
    fetchEntityList: getCities,
    initialValue: [],
    resourceName: "Header City List",
  });

  onMounted(() => fetchEntityList());

  return {
    loading: isDataLoading,
    items: cities,
  };
}

export function useActiveCity(): ComputedRef<CityInterface | null> {
  const activeId = useCityId();

  const { loading, items } = useCities();

  return computed<CityInterface | null>(() => {
    if (loading.value) {
      return null;
    }

    return items.value.find((item) => item.id === activeId.value) || null;
  });
}

export function useCityId(): Ref<number> {
  const cityStore = useCityStore();
  return computed<number>(() => cityStore.selectedCity);
}
