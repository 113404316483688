
import { defineComponent, ref } from "vue";

import { BaseNumberInput } from "@tager/admin-ui";

import { setWorkingDays } from "@/requests";

export default defineComponent({
  name: "WorkDayBlock",

  components: {
    BaseNumberInput,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    workingDays: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const newWorkingDays = ref(props.workingDays);

    return {
      setWorkingDays,
      newWorkingDays,
    };
  },
});
