import { createApp } from "vue";
import { createPinia } from "pinia";
import Maska from "maska";

import { configStore, i18n, i18nPlugin } from "@tager/admin-services";

import "@/styles";
import { router } from "@/router/router";
import config from "@/config/config.json";
import App from "@/modules/App.vue";
import { populateEnvironment } from "@/env";

populateEnvironment();

configStore.setConfig(config);

const pinia = createPinia();
const app = createApp(App);

app.use(router);
app.use(i18nPlugin);
app.use(pinia);
app.use(Maska);

i18n.init({ debug: false }).then(() => {
  app.mount("#app");
});
