
import { defineComponent, PropType, ref, watch } from "vue";

import { FieldValue, OptionType } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import {
  AddressFormFields,
  OrderFullInterface,
} from "@/modules/orders/typings";
import AddressForm from "@/modules/orders/components/AddressForm.vue";
import { setOrderAddress } from "@/modules/orders/services";
import { useCities } from "@/modules/cities";

import OrdersEditEditButtons from "../views/orders-edit/components/OrdersEditEditButtons.vue";

const getAddressFieldsFromOrder = (
  order: OrderFullInterface,
  cityOptions: Array<OptionType<number>>
): AddressFormFields => {
  return {
    city: cityOptions.find((item) => item.value === order.address.city) || null,
    street: order.address.street,
    house: order.address.house,
    apartment: order.address.apartment,
    wing: order.address.wing,
    floor: order.address.floor,
    entrance: order.address.entrance,
    intercomCode: order.address.intercomCode,
  };
};

export default defineComponent({
  name: "OrdersEditAddress",
  components: {
    FieldValue,
    OrdersEditEditButtons,
    AddressForm,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const { options: citiesOptions } = useCities();

    const addressFields = ref<AddressFormFields>(
      getAddressFieldsFromOrder(props.order, citiesOptions.value)
    );

    watch([citiesOptions], () => {
      addressFields.value = getAddressFieldsFromOrder(
        props.order,
        citiesOptions.value
      );
    });

    const addressLoading = ref<boolean>(false);
    const isEdit = ref<boolean>(false);
    const addressTextValue = ref<string>(props.order.address.text);

    const onEditChange = () => {
      isEdit.value = !isEdit.value;

      addressLoading.value = false;
      // addressFields.value = getAddressFieldsFromOrder(props.order);
    };

    const onSubmit = async () => {
      addressLoading.value = true;

      try {
        const response = await setOrderAddress(props.order.id, {
          ...addressFields.value,
          city: addressFields.value.city?.value || null,
        });

        addressTextValue.value = response.data.address;
        isEdit.value = false;

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Адрес успешно изменен",
        });
      } catch (error) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения адреса",
        });
      } finally {
        addressLoading.value = false;
      }
    };

    return {
      addressTextValue:
        (props.order.houseType === "HOUSE" ? "Частный дом, " : "") +
        addressTextValue.value,
      isEdit,
      onEditChange,
      onSubmit,
      addressFields,
      addressLoading,
    };
  },
});
