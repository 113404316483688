export enum Scope {
  OrdersCreate = "orders.create",
  OrdersUpdate = "orders.update",
  OrdersViewActive = "orders.view-active",
  OrdersViewCompleted = "orders.view-completed",
  OrdersViewCancelled = "orders.view-cancelled",
  OrdersViewGuest = "orders.view-guest",
  OrdersViewReviews = "orders.view-reviews",

  SubscriptionsView = "subscriptions.view",
  SubscriptionsUpdate = "subscriptions.update",

  WorkersView = "workers.view",
  WorkersEdit = "workers.edit",
  WorkersDelete = "workers.delete",
  WorkersStatsView = "workers.stats",
  WorkersTimetableView = "workers.timetable.view",
  WorkersTimetableUpdate = "workers.timetable.update",
  WorkersTrainingsView = "workers.trainings.view",
  WorkersTrainingsEdit = "workers.trainings.edit",
  WorkersTrainingsDelete = "workers.trainings.delete",
  WorkersCompaniesView = "workers.companies.view",
  WorkersCompaniesCreate = "workers.companies.create",
  WorkersCompaniesEdit = "workers.companies.edit",
  WorkersCompaniesDelete = "workers.companies.delete",

  ServicesCategoryView = "services.categories.view",
  ServicesCategoryCreate = "services.categories.create",
  ServicesCategoryEdit = "services.categories.edit",
  ServicesCategoryDelete = "services.categories.delete",

  ServicesView = "services.view",
  ServicesCreate = "services.create",
  ServicesEdit = "services.edit",
  ServicesDelete = "services.delete",

  UsersView = "users.view",
  UsersUpdate = "users.update",

  PromocodesView = "promocode.view",
  PromocodesCreate = "promocode.create",
  PromocodesUpdate = "promocode.update",
  PromocodesDelete = "promocode.delete",

  CitiesCreate = "cities.create",
  CitiesUpdate = "cities.update",
  CitiesDelete = "cities.delete",

  CalendarView = "calendar.view",
  CalendarCoefficient = "calendar.coefficient",
  CalendarClose = "calendar.close",

  PagesView = "pages.view",
  PagesCreate = "pages.create",
  PagesEdit = "pages.edit",
  PagesDelete = "pages.delete",

  NotesView = "notes.view",
  NotesCreate = "notes.create",
  NotesDelete = "notes.delete",

  CommonStats = "common.stats",
  CommonImport = "common.import",
  CommonExport = "common.export",

  SettingsView = "settings.view",
  SettingsEdit = "settings.edit",

  MailViewTemplates = "mail.view-templates",
  MailEditTemplates = "mail.edit-templates",
  MailViewLogs = "mail.view-logs",

  SmsViewTemplates = "sms.view-templates",
  SmsEditTemplates = "sms.edit-templates",
  SmsViewLogs = "sms.edit-logs",

  AdministratorsView = "administrators.view",
  AdministratorsCreate = "administrators.create",
  AdministratorsEdit = "administrators.edit",
  AdministratorsDelete = "administrators.delete",
}
