import { Nullable, request, ResponseBody } from "@tager/admin-services";

import {
  FindUserResponse,
  CheckPromoResponse,
  CheckDateResponse,
  UserAddress,
  SecondaryService,
  PrimaryServiceCategory,
  CreateOrderRequest,
  OrderFullInterface,
} from "./typings";

export function findUser(
  phone: string
): Promise<ResponseBody<Nullable<FindUserResponse>>> {
  return request.get({ path: "/admin/orders/find-user", params: { phone } });
}

export function checkDate(
  date: string
): Promise<ResponseBody<CheckDateResponse>> {
  return request.get({ path: "/admin/orders/check-date/" + date });
}

export function getUserAddresses(
  id: number
): Promise<ResponseBody<Array<UserAddress>>> {
  return request.get({ path: "/admin/orders/user-addresses/" + id });
}

export function getPrimaryServices(): Promise<
  ResponseBody<Array<PrimaryServiceCategory>>
> {
  return request.get({ path: "/admin/orders/primary-services" });
}

export function getSecondaryServices(): Promise<
  ResponseBody<Array<SecondaryService>>
> {
  return request.get({ path: "/admin/orders/secondary-services" });
}

export function checkPromo(
  code: string,
  date: string,
  userId: number | null
): Promise<ResponseBody<CheckPromoResponse>> {
  return request.get({
    path: "/admin/orders/check-promo",
    params: userId ? { code, date, userId } : { code, date },
  });
}

export function createOrder(
  body: CreateOrderRequest
): Promise<ResponseBody<OrderFullInterface>> {
  return request.post({
    path: "/admin/orders",
    body,
  });
}
