
import { defineComponent, PropType } from "vue";

import {
  getSubscriptionPeriodLabel,
  OrderListItemSubscription,
} from "@/modules/orders/typings";

export default defineComponent({
  name: "OrdersTableCellSubscription",
  methods: { getSubscriptionPeriodLabel },
  props: {
    model: {
      type: Object as PropType<OrderListItemSubscription>,
      required: true,
      nullable: true,
    },
  },
});
