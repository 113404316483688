
import { defineComponent, PropType, ref } from "vue";

import {
  FieldValue,
  FormFieldSelect,
  BaseButton,
  OptionType,
} from "@tager/admin-ui";
import { Nullable, useToast } from "@tager/admin-services";

import {
  OrderSubscriptionPeriod,
  subscriptionPeriodLabel,
} from "@/modules/orders/typings";
import { updateSubscriptionPeriod } from "@/modules/subscriptions/services";

import { SubscriptionFullInterface } from "../../typings";
import { subscriptionPeriodOptions } from "../../constants";

export default defineComponent({
  name: "SubscriptionsViewCommon",
  components: {
    FieldValue,
    BaseButton,
    FormFieldSelect,
  },
  props: {
    subscription: {
      type: Object as PropType<SubscriptionFullInterface>,
      required: true,
    },
  },
  setup: (props) => {
    const toast = useToast();

    const textValue = ref<string>(
      subscriptionPeriodLabel(props.subscription.period)
    );
    const isEdit = ref<boolean>(false);
    const loading = ref<boolean>(false);
    const value = ref<Nullable<OptionType>>(
      subscriptionPeriodOptions.find(
        (item) => item.value === props.subscription.period
      ) || null
    );

    const onEdit = () => (isEdit.value = true);
    const onCancel = () => (isEdit.value = false);

    const onSave = async (e: FormDataEvent) => {
      e.preventDefault();

      if (!value.value) return;

      loading.value = true;

      try {
        await updateSubscriptionPeriod(
          props.subscription.id,
          value.value.value
        );

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Периодичность успешно изменена",
        });

        textValue.value = subscriptionPeriodLabel(
          value.value.value as OrderSubscriptionPeriod
        );

        isEdit.value = false;
      } catch (error: any) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения периода",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      textValue,
      value,
      isEdit,
      loading,
      onEdit,
      onCancel,
      onSave,
      subscriptionPeriodLabel,
      subscriptionPeriodOptions,
    };
  },
});
