
import { defineComponent, ref, watch } from "vue";
import { useRouter } from "vue-router";

import { OptionsSwitcherTrueFalse } from "@tager/admin-ui";
import { useUserPermission } from "@tager/admin-services";

import { getOrdersEditUrl, getOrdersViewUrl } from "@/modules/orders";
import { Scope } from "@/rbac/Scope";

export default defineComponent({
  name: "OrdersEditModeSwitcher",
  components: { OptionsSwitcherTrueFalse },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    isEditActive: {
      type: Boolean,
      required: true,
    },
  },
  setup(props) {
    const router = useRouter();
    const value = ref<boolean>(props.isEditActive);

    watch(value, () => {
      if (value.value) {
        router.push(getOrdersEditUrl(props.orderId));
      } else {
        router.push(getOrdersViewUrl(props.orderId));
      }
    });

    const canEditOrder = useUserPermission(Scope.OrdersUpdate);

    return {
      value,
      canEditOrder,
    };
  },
});
