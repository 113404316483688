
import { defineComponent, ref, watch } from "vue";

import { BaseTextArea } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { saveReviewSolution } from "@/modules/reviews/services";

export default defineComponent({
  name: "ReviewListSolutionCell",
  components: { BaseTextArea },
  props: {
    id: {
      type: Number,
      required: true,
    },
    value: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const toast = useToast();
    const loading = ref<boolean>(false);
    const value = ref<string>(props.value);

    const save = async () => {
      loading.value = true;

      try {
        await saveReviewSolution(props.id, value.value);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Решение сохранено",
        });
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения решения",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      data: value,
      disabled: loading,
      onBlur: save,
    };
  },
});
