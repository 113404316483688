
import { computed, defineComponent, onMounted, watch } from "vue";
import { useRoute } from "vue-router";

import {
  BaseButton,
  BaseTable,
  ColumnDefinition,
  DeleteIcon,
  EditIcon,
} from "@tager/admin-ui";
import {
  Nullable,
  useResource,
  useResourceDelete,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import {
  AdditionalServiceCategoryType,
  FullServiceCategoryType,
} from "@/types";
import {
  deleteAdditionalService,
  getAdditionalServiceCategoryList,
  getServiceCategory,
} from "@/requests";
import {
  getAdditionalServiceCategoryFormUrl,
  getAdditionalServiceCategoryListUrl,
  getServiceCategoryMultipleChangeUrl,
} from "@/modules/services";

const COLUMN_DEFS: Array<ColumnDefinition<AdditionalServiceCategoryType>> = [
  {
    id: 1,
    name: "Название",
    field: "name",
  },
  {
    id: 2,
    name: "Количество",
    field: "quantity",
    style: { width: "145px", whiteSpace: "nowrap" },
    headStyle: { width: "145px" },
  },
  {
    id: 3,
    name: "Цена",
    field: "price",
    style: { width: "145px", whiteSpace: "nowrap" },
    headStyle: { width: "145px" },
  },
  {
    id: 4,
    name: "Трудоемкость, мин",
    field: "minutes",
    style: { width: "160px" },
    headStyle: { width: "160px", whiteSpace: "nowrap" },
  },
  {
    id: 5,
    name: "Действия",
    field: "actions",
    style: { width: "101px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "101px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "AdditionalServiceCategoryList",
  components: { DeleteIcon, EditIcon, BaseButton, BaseTable, Page },
  setup() {
    const route = useRoute();

    const serviceCategoryId = computed<number>(
      () => +route.params.serviceCategoryId
    );

    const [
      fetchServiceCategory,
      { data: serviceCategory, loading: isServiceCategoryLoading },
    ] = useResource<Nullable<FullServiceCategoryType>>({
      fetchResource: () => getServiceCategory(serviceCategoryId.value),
      initialValue: null,
      resourceName: "serviceCategory",
    });

    const [
      fetchAdditionalServiceCategoryList,
      {
        data: additionalServiceCategoryList,
        loading: isAdditionalServiceCategoryLoading,
        error,
      },
    ] = useResource<Array<AdditionalServiceCategoryType>>({
      fetchResource: () =>
        getAdditionalServiceCategoryList(serviceCategoryId.value),
      initialValue: [],
      resourceName: "Service Category List",
    });

    onMounted(() => {
      fetchServiceCategory();
      fetchAdditionalServiceCategoryList();
    });

    const {
      handleResourceDelete: handleAdditionalServiceCategoryDelete,
      isDeleting,
    } = useResourceDelete({
      deleteResource: deleteAdditionalService,
      resourceName: "Service Category",
      onSuccess: fetchAdditionalServiceCategoryList,
    });

    const isRowDataLoading = computed<boolean>(
      () =>
        isAdditionalServiceCategoryLoading.value ||
        isServiceCategoryLoading.value
    );

    function isBusy(productId: number): boolean {
      return isDeleting(productId) || isRowDataLoading.value;
    }

    return {
      serviceCategory,
      columnDefs: COLUMN_DEFS,
      rowData: additionalServiceCategoryList,
      errorMessage: error,
      isRowDataLoading,
      isBusy,
      handleAdditionalServiceCategoryDelete,
      serviceCategoryId,

      getAdditionalServiceCategoryFormUrl,
      getAdditionalServiceCategoryListUrl,
      getServiceCategoryMultipleChangeUrl,
    };
  },
});
