
import { defineComponent, PropType, ref } from "vue";

import { FieldValue } from "@tager/admin-ui";
import { formatDateTime } from "@tager/admin-ui";
import { isNotNullish, useToast } from "@tager/admin-services";

import { subscriptionPeriodLabel } from "@/modules/orders/typings";
import { getOrdersViewUrl, getOrderUrl } from "@/modules/orders";
import { newPhoneFormat } from "@/utils/common";
import { getMapsUrl } from "@/utils/address";
import { skipSubscriptionNextOrder } from "@/modules/subscriptions/services";
import OrderDateTimeEdit from "@/components/OrderDateTimeEdit.vue";

import { SubscriptionFullInterface } from "../../typings";

export default defineComponent({
  name: "SubscriptionsViewLastOrder",
  components: {
    OrderDateTimeEdit,
    FieldValue,
  },
  methods: { getOrderUrl, isNotNullish },
  props: {
    subscription: {
      type: Object as PropType<SubscriptionFullInterface>,
      required: true,
    },
  },
  emits: ["change"],
  setup: (props, { emit }) => {
    const toast = useToast();

    const lastOrderDateTime = ref<string>(
      props.subscription.lastOrder.datetime
    );
    const lastOrderId = ref<number>(props.subscription.lastOrder.id);
    const lastOrderNumber = ref<number>(props.subscription.lastOrder.number);

    const skipLoading = ref<boolean>(false);
    const editDateActive = ref<boolean>(false);

    const onSkipClick = async () => {
      if (
        !props.subscription ||
        !confirm("Вы уверены, что хотите отменить следующий заказ?")
      ) {
        return;
      }

      skipLoading.value = true;

      try {
        const response = await skipSubscriptionNextOrder(props.subscription.id);

        lastOrderId.value = response.data.id;
        lastOrderNumber.value = response.data.number;
        lastOrderDateTime.value = response.data.datetime;

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Заказ успешно отменен",
        });
      } catch {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка отмены заказа",
        });
      } finally {
        skipLoading.value = false;
      }
    };

    const onEditStart = () => {
      editDateActive.value = true;
    };

    const onEditCancel = () => {
      editDateActive.value = false;
    };

    const onEditSubmit = (newValue: string) => {
      lastOrderDateTime.value = newValue;
      editDateActive.value = false;
    };

    return {
      onEditStart,
      onEditCancel,
      onEditSubmit,

      lastOrderDateTime,
      lastOrderId,
      lastOrderNumber,

      editDateActive,
      skipLoading,

      onSkipClick,
      subscriptionPeriodLabel,
      formatDateTime,
      newPhoneFormat,
      getOrdersViewUrl,
      getMapsUrl,
    };
  },
});
