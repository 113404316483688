
import { computed, defineComponent } from "vue";

import CitySelector from "@/components/CitySelector.vue";
import { useCityStore } from "@/store/city";

export default defineComponent({
  name: "HeaderCitySelector",
  components: { CitySelector },
  setup() {
    const store = useCityStore();
    const enabled = computed<boolean>(() => store.isSelectEnabled);

    return {
      enabled,
    };
  },
});
