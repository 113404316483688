
import { defineComponent, ref, watch } from "vue";

import { FormField } from "@tager/admin-ui";

export default defineComponent({
  name: "DateSelect",
  components: {
    FormField,
  },
  props: {
    selectedDate: {
      type: String,
      default: null,
    },
    onChange: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const value = ref<string>();

    watch(
      () => props.selectedDate,
      () => {
        value.value = props.selectedDate;
      }
    );

    watch([value], () => {
      if (props.onChange) {
        props.onChange(value.value);
      }
    });

    return {
      value,
    };
  },
});
