
import { defineComponent, onMounted, ref, watch } from "vue";

import { FormField } from "@tager/admin-ui";

import { PromoCode } from "@/modules/orders/views/orders-create/typings";

import OrdersCreatePromoCode from "./OrdersCreatePromoCode.vue";

export default defineComponent({
  name: "OrdersCreateAdditional",
  components: {
    OrdersCreatePromoCode,
    FormField,
  },
  props: {
    userId: {
      type: Number,
      required: true,
    },
    date: {
      type: String,
      required: true,
    },
  },
  emits: ["change", "change:loading"],
  setup(props, { emit }) {
    const promoCode = ref<PromoCode>();
    const comment = ref<string>("");

    watch([comment, promoCode], () => {
      emit("change", {
        comment,
        promoCode,
      });
    });

    const onChangePromoCode = (newPromo: PromoCode) =>
      (promoCode.value = newPromo);

    const onChangeLoading = (value: boolean) => emit("change:loading", value);

    return {
      comment,
      onChangePromoCode,
      onChangeLoading,
    };
  },
});
