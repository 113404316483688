import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/router/paths";

export function getOrdersForDayUrl() {
  return ROUTE_PATHS.ORDERS_FOR_DAY;
}

export function getOrdersCreateUrl(): string {
  return compile(ROUTE_PATHS.ORDERS_CREATE)();
}

export function getOrdersMapUrl() {
  return ROUTE_PATHS.ORDERS_MAP;
}

/** Orders */

export function getActiveOrdersListUrl() {
  return ROUTE_PATHS.ORDERS_ACTIVE_LIST;
}

export function getOrdersCompletedListUrl() {
  return ROUTE_PATHS.ORDERS_COMPLETED_LIST;
}

export function getOrdersCancelledListUrl() {
  return ROUTE_PATHS.ORDERS_CANCELLED_LIST;
}

export function getOrdersViewUrl(orderId: number): string {
  return compile(ROUTE_PATHS.ORDERS_VIEW)({ orderId });
}

export function getOrdersEditUrl(orderId: number): string {
  return compile(ROUTE_PATHS.ORDERS_EDIT)({ orderId });
}

export function getOrderUrl(id: number, status?: string): string {
  return status === "ACTIVE" ? "/orders/edit/" + id : "/orders/view/" + id;
}
