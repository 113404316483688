import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AddressForm = _resolveComponent("AddressForm")!
  const _component_OrdersEditEditButtons = _resolveComponent("OrdersEditEditButtons")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Адрес",
    value: _ctx.addressTextValue,
    "edit-active": _ctx.isEdit,
    "with-edit": _ctx.withEdit && _ctx.order.status === 'WAITING',
    onEdit: _ctx.onEditChange
  }, {
    edit: _withCtx(() => [
      _createVNode(_component_AddressForm, {
        value: _ctx.addressFields,
        disabled: _ctx.addressLoading
      }, null, 8, ["value", "disabled"]),
      _createVNode(_component_OrdersEditEditButtons, {
        loading: _ctx.addressLoading,
        onCancel: _ctx.onEditChange,
        onSubmit: _ctx.onSubmit
      }, null, 8, ["loading", "onCancel", "onSubmit"])
    ]),
    _: 1
  }, 8, ["value", "edit-active", "with-edit", "onEdit"]))
}