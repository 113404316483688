import { request, ResponseBody } from "@tager/admin-services";

import {
  NoteCreatePayload,
  NoteFullInterface,
  NoteInterface,
  NoteUpdatePayload,
} from "./typings";

export function getTodayNotesCount(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<{ count: number }>> {
  return request.get({ path: "/admin/notes/today-count", params });
}

export function getNotes(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<NoteInterface>>> {
  return request.get({ path: "/admin/notes", params });
}

export function getNote(id: number): Promise<ResponseBody<NoteFullInterface>> {
  return request.get({ path: "/admin/notes/" + id });
}

export function createNote(
  payload: NoteCreatePayload
): Promise<ResponseBody<NoteFullInterface>> {
  return request.post({ path: "/admin/notes", body: payload });
}

export function updateNote(
  id: number,
  payload: NoteUpdatePayload
): Promise<ResponseBody<NoteFullInterface>> {
  return request.put({
    path: `/admin/notes/${id}`,
    body: payload,
  });
}
