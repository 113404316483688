
import { defineComponent, PropType, ref } from "vue";

import { FieldValue } from "@tager/admin-ui";
import { formatDateTime } from "@tager/admin-ui";

import { subscriptionPeriodLabel } from "@/modules/orders/typings";
import { getOrdersViewUrl } from "@/modules/orders";
import { newPhoneFormat } from "@/utils/common";
import { getMapsUrl } from "@/utils/address";

import { SubscriptionFullInterface } from "../../typings";

import SubscriptionsViewPeriod from "./SubscriptionsViewPeriod.vue";
import SubscriptionsViewLastOrder from "./SubscriptionsViewLastOrder.vue";

export default defineComponent({
  name: "SubscriptionsViewCommon",
  components: {
    FieldValue,
    SubscriptionsViewPeriod,
    SubscriptionsViewLastOrder,
  },
  props: {
    subscription: {
      type: Object as PropType<SubscriptionFullInterface>,
      required: true,
    },
  },
  emits: ["change"],
  setup: () => {
    return {
      subscriptionPeriodLabel,
      formatDateTime,
      newPhoneFormat,
      getOrdersViewUrl,
      getMapsUrl,
    };
  },
});
