import { createWebHistory, type RouteRecordRaw } from "vue-router";

import { createRouter } from "@tager/admin-layout";
import { TAGER_MAIL_ROUTES } from "@tager/admin-mail";
import { MENU_EDITOR_ROUTE } from "@tager/admin-menus";
import { PAGE_FORM_ROUTE, PAGE_LIST_ROUTE } from "@tager/admin-pages";
import {
  SETTINGS_ITEM_FORM_ROUTE,
  SETTINGS_ITEM_LIST_ROUTE,
} from "@tager/admin-settings";
import {
  SMS_LOG_LIST_ROUTE,
  SMS_TEMPLATE_FORM_ROUTE,
  SMS_TEMPLATE_LIST_ROUTE,
} from "@tager/admin-sms";
import { SEO_SETTINGS_ROUTE } from "@tager/admin-seo";
import {
  ADMIN_FORM_ROUTE,
  ADMIN_LIST_ROUTE,
  ROLE_FORM_ROUTE,
  ROLE_LIST_ROUTE,
} from "@tager/admin-administrators";
import { EXPORT_LIST_ROUTE, EXPORT_FORM_ROUTE } from "@tager/admin-export";

import StatisticList from "@/modules/workers/StatisticList";
import {
  PromocodesForm,
  PromocodesList,
} from "@/modules/promocodes/Promocodes";
import TrainingForm from "@/modules/trainings/TrainingForm";
import TrainingList from "@/modules/trainings/TrainingList";
import CompanyForm from "@/modules/companies/CompanyForm";
import CompanyList from "@/modules/companies/CompanyList";
import ArchiveWorkerList from "@/modules/workers/ArchiveWorkerList";
import WorkerForm from "@/modules/workers/WorkerForm";
import ActiveWorkerList from "@/modules/workers/ActiveWorkerList";
import Home from "@/modules/Home.vue";
import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";
import TimetableList from "@/modules/timetable/TimetableList";
import Calendar from "@/modules/calendar/Calendar";
import { STATS_ROUTE } from "@/modules/stats";
import { GUEST_ORDERS_LIST_ROUTE } from "@/modules/guest-orders";
import { REVIEWS_LIST_ROUTE } from "@/modules/reviews";
import {
  NOTES_LIST_ROUTE,
  NOTES_CREATE_ROUTE,
  NOTES_EDIT_ROUTE,
} from "@/modules/notes";
import {
  CITIES_CREATE_ROUTE,
  CITIES_EDIT_ROUTE,
  CITIES_LIST_ROUTE,
} from "@/modules/cities";
import {
  ADDITIONAL_SERVICE_CATEGORY_FORM_ROUTE,
  ADDITIONAL_SERVICE_CATEGORY_LIST_ROUTE,
  SECONDARY_SERVICE_FORM_ROUTE,
  SERVICE_CATEGORY_LIST_ROUTE,
  SECONDARY_SERVICE_LIST_ROUTE,
  SERVICE_CATEGORY_FORM_ROUTE,
  SERVICE_CATEGORY_MULTIPLE_CHANGE_ROUTE,
} from "@/modules/services";
import {
  USERS_CREATE_ROUTE,
  USERS_LIST_ROUTE,
  USERS_EDIT_ROUTE,
} from "@/modules/users";
import {
  ORDERS_VIEW_ROUTE,
  ORDERS_MAP_ROUTE,
  ORDERS_LIST_ACTIVE_ROUTE,
  ORDERS_LIST_COMPLETED_ROUTE,
  ORDERS_LIST_CANCELLED_ROUTE,
  ORDERS_CREATE_ROUTE,
  ORDERS_FOR_DAY_ROUTE,
  ORDERS_EDIT_ROUTE,
} from "@/modules/orders";
import {
  SUBSCRIPTIONS_LIST_ROUTE,
  SUBSCRIPTIONS_VIEW_ROUTE,
  SUBSCRIPTIONS_LIST_COMPLETED_ROUTE,
} from "@/modules/subscriptions";

export const HOME_ROUTE: RouteRecordRaw = {
  path: "/",
  component: Home,
  name: "Home",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME],
  },
};

export const WORKER_ACTIVE_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.WORKER_ACTIVE_LIST,
  component: ActiveWorkerList,
  name: "ActiveWorkerList",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.WORKERS_ACTIVE],
  },
};

export const WORKER_ARCHIVE_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.WORKER_ARCHIVE_LIST,
  component: ArchiveWorkerList,
  name: "CleanerArchiveList",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.WORKERS_ARCHIVE],
  },
};

export const WORKER_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.WORKER_FORM,
  component: WorkerForm,
  name: "WorkerForm",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      { url: ROUTE_PATHS.WORKER_ACTIVE_LIST, text: "Worker list" },
      { url: route.path, text: "Worker form" },
    ],
  },
};

export const TRAINING_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.TRAINING_LIST,
  component: TrainingList,
  name: "TrainingList",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.TRAINING_LIST],
  },
};

export const TRAINING_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.TRAINING_FORM,
  component: TrainingForm,
  name: "TrainingForm",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      { url: ROUTE_PATHS.TRAINING_LIST, text: "Training list" },
      { url: route.path, text: "Training form" },
    ],
  },
};

export const TIMETABLE_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.TIMETABLE_LIST,
  component: TimetableList,
  name: "Расписание",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.TIMETABLE_LIST],
  },
};

export const STATISTIC_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.STATISTIC_LIST,
  component: StatisticList,
  name: "Статистика клинеров",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.STATISTIC_LIST],
  },
};

export const CALENDAR_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CALENDAR,
  component: Calendar,
  name: "Календарь",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.CALENDAR],
  },
};

export const COMPANY_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.COMPANY_LIST,
  component: CompanyList,
  name: "Компании",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.COMPANY_LIST],
  },
};

export const COMPANY_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.COMPANY_FORM,
  component: CompanyForm,
  name: "Форма компании",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.COMPANY_LIST,
      { url: route.path, text: "Форма компании" },
    ],
  },
};

export const PROMOCODE_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PROMOCODE_LIST,
  component: PromocodesList,
  name: "Промокоды",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.PROMOCODE_LIST],
  },
};

export const PROMOCODE_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.PROMOCODE_FORM,
  component: PromocodesForm,
  name: "Форма промокода",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      LINKS.PROMOCODE_LIST,
      { url: route.path, text: "Форма промокода" },
    ],
  },
};

export const router = createRouter({
  history: createWebHistory(process.env.VUE_APP_PUBLIC_PATH || "/"),
  routes: [
    HOME_ROUTE,
    ...TAGER_MAIL_ROUTES,

    PAGE_LIST_ROUTE,
    PAGE_FORM_ROUTE,
    MENU_EDITOR_ROUTE,
    WORKER_ACTIVE_LIST_ROUTE,
    WORKER_ARCHIVE_LIST_ROUTE,
    STATISTIC_LIST_ROUTE,
    WORKER_FORM_ROUTE,
    TRAINING_LIST_ROUTE,
    TRAINING_FORM_ROUTE,
    TIMETABLE_LIST_ROUTE,

    CALENDAR_ROUTE,
    STATS_ROUTE,

    REVIEWS_LIST_ROUTE,
    GUEST_ORDERS_LIST_ROUTE,

    ADDITIONAL_SERVICE_CATEGORY_FORM_ROUTE,
    ADDITIONAL_SERVICE_CATEGORY_LIST_ROUTE,
    SECONDARY_SERVICE_FORM_ROUTE,
    SECONDARY_SERVICE_LIST_ROUTE,
    SERVICE_CATEGORY_LIST_ROUTE,
    SERVICE_CATEGORY_FORM_ROUTE,
    SERVICE_CATEGORY_MULTIPLE_CHANGE_ROUTE,

    COMPANY_LIST_ROUTE,
    COMPANY_FORM_ROUTE,
    ROLE_LIST_ROUTE,
    ROLE_FORM_ROUTE,
    ADMIN_LIST_ROUTE,
    ADMIN_FORM_ROUTE,
    SETTINGS_ITEM_LIST_ROUTE,
    SETTINGS_ITEM_FORM_ROUTE,
    SMS_TEMPLATE_LIST_ROUTE,
    SMS_TEMPLATE_FORM_ROUTE,
    SMS_LOG_LIST_ROUTE,
    SEO_SETTINGS_ROUTE,
    EXPORT_LIST_ROUTE,
    EXPORT_FORM_ROUTE,
    PROMOCODE_LIST_ROUTE,
    PROMOCODE_FORM_ROUTE,

    SUBSCRIPTIONS_LIST_ROUTE,
    SUBSCRIPTIONS_LIST_COMPLETED_ROUTE,
    SUBSCRIPTIONS_VIEW_ROUTE,

    ORDERS_LIST_ACTIVE_ROUTE,
    ORDERS_LIST_COMPLETED_ROUTE,
    ORDERS_LIST_CANCELLED_ROUTE,

    ORDERS_VIEW_ROUTE,
    ORDERS_EDIT_ROUTE,
    ORDERS_FOR_DAY_ROUTE,
    ORDERS_CREATE_ROUTE,
    ORDERS_MAP_ROUTE,

    USERS_LIST_ROUTE,
    USERS_CREATE_ROUTE,
    USERS_EDIT_ROUTE,

    CITIES_CREATE_ROUTE,
    CITIES_EDIT_ROUTE,
    CITIES_LIST_ROUTE,

    NOTES_LIST_ROUTE,
    NOTES_CREATE_ROUTE,
    NOTES_EDIT_ROUTE,
  ],
});
