
import { defineComponent, PropType, computed } from "vue";

import { FieldValue } from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";
import { useFetchServices } from "@/modules/orders/hooks/useFetchServices";

export default defineComponent({
  name: "OrdersViewServices",
  components: {
    FieldValue,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["click:services"],
  setup: (props, { emit }) => {
    const { primary, secondary, loading: servicesLoading } = useFetchServices();

    const onServicesChangeClick = () => {
      emit("click:services");
    };

    const servicesItems = computed<string[]>(() => {
      const result: string[] = [];

      props.order.services.primary.forEach((orderPrimaryServiceId) => {
        primary.value.forEach((primaryServiceCategory) => {
          primaryServiceCategory.services.forEach((service) => {
            if (service.id === orderPrimaryServiceId) {
              result.push(service.name);
            }
          });
        });
      });

      props.order.services.secondary.forEach((orderSecondary) => {
        const service = secondary.value.find(
          (item) => item.id === orderSecondary.id
        );
        if (service) {
          let value = null;
          if (service.mode === "TIME") {
            value = orderSecondary.value + " мин.";
          } else if (service.mode === "QUANTITY") {
            value = orderSecondary.value + " шт.";
          }

          result.push(service.name + (value ? ": " + value : ""));
        }
      });

      return result;
    });

    return {
      onServicesChangeClick,
      servicesItems,
    };
  },
});
