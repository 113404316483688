
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import { FormField, BaseButton } from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";

import { findUser } from "@/modules/orders/views/orders-create/services";
import { FindUserResponse } from "@/modules/orders/views/orders-create/typings";
import { validatePhoneNumber } from "@/modules/orders/views/orders-create/helpers";
import { getUsersUpdateUrl } from "@/modules/users";

export default defineComponent({
  name: "OrdersCreateCustomer",
  components: { FormField, BaseButton },

  props: {},
  emits: ["change"],

  setup(props, { emit }) {
    const phoneInputRef = ref<HTMLDivElement>();
    const customerNameInputRef = ref<HTMLDivElement>();

    const customerName = ref<string>("");
    const phone = ref<string>("");
    const loaded = ref<boolean>(false);
    const customer = ref<FindUserResponse | null | false>(false);

    const reset = () => {
      customer.value = false;
      loaded.value = false;
      emit("change", null);
    };

    onMounted(() => {
      phoneInputRef.value?.querySelector("input")?.focus();
    });

    const [fetchUser, { data: userData, loading: isUserLoading }] = useResource<
      Nullable<FindUserResponse>
    >({
      fetchResource: () =>
        findUser(
          String(phone.value).startsWith("375")
            ? phone.value
            : "375" + phone.value
        ),
      initialValue: {
        id: 0,
        name: "",
        phone: "",
      },
      resourceName: "User Data",
    });

    watch([userData], () => {
      loaded.value = true;

      if (!userData.value) {
        customer.value = null;
        customerName.value = "";
        setTimeout(() => {
          console.log(customerNameInputRef.value);
          customerNameInputRef.value?.querySelector("input")?.focus();
        });
        emit("change", null);
      } else {
        customer.value = userData.value;
        emit("change", userData.value?.id);
      }
    });

    watch([customerName], () => {
      if (customer.value === null) {
        if (customerName.value.trim().length) {
          emit("change", {
            phone: phone.value,
            name: customerName.value,
          });
        } else {
          emit("change", null);
        }
      }
    });

    const onSearch = (e: FormDataEvent) => {
      e.preventDefault();
      loaded.value = true;
      fetchUser();
    };

    const searchDisabled = computed<boolean>(() => {
      if (isUserLoading.value) return true;

      return !validatePhoneNumber(String(phone.value));
    });

    return {
      phoneInputRef,
      customerNameInputRef,
      phone,
      loaded,
      loading: isUserLoading,
      searchDisabled,
      customer,
      onSearch,
      reset,
      customerName,
      getUsersUpdateUrl,
    };
  },
});
