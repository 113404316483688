import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-940e92ea"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content" }
const _hoisted_2 = {
  key: 0,
  class: "spinner-wrapper"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Spinner = _resolveComponent("Spinner")!
  const _component_ToggleSection = _resolveComponent("ToggleSection")!

  return (_openBlock(), _createBlock(_component_ToggleSection, { label: _ctx.title }, {
    default: _withCtx(() => [
      _createElementVNode("div", {
        class: "widget",
        style: _normalizeStyle({ 'max-width': _ctx.maxWidth ? _ctx.maxWidth + 'px' : '100%' })
      }, [
        _createElementVNode("div", _hoisted_1, [
          (_ctx.loading)
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_Spinner, {
                  size: 40,
                  "stroke-width": 3
                })
              ]))
            : _renderSlot(_ctx.$slots, "default", { key: 1 }, undefined, true)
        ])
      ], 4)
    ]),
    _: 3
  }, 8, ["label"]))
}