import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-58bcc31d"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { class: "promocode-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PromocodeBadge = _resolveComponent("PromocodeBadge")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_OrdersEditEditButtons = _resolveComponent("OrdersEditEditButtons")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Промокод",
    "edit-active": _ctx.editActive,
    "with-edit": 
      _ctx.withEdit && _ctx.order.status === 'WAITING' && !_ctx.promoCodeCancelLoading
    ,
    onEdit: _ctx.onChangeEditActive
  }, {
    value: _withCtx(() => [
      (_ctx.model)
        ? (_openBlock(), _createBlock(_component_PromocodeBadge, {
            key: 0,
            code: _ctx.model.code,
            "no-cancel": _ctx.promoCodeCancelLoading,
            discount: _ctx.model.discount,
            "with-edit": _ctx.withEdit,
            "cancel-confirm": "Вы уверены, что хотите удалить промокод из заказа?",
            onCancel: _ctx.onCancelPromoCode
          }, null, 8, ["code", "no-cancel", "discount", "with-edit", "onCancel"]))
        : (_openBlock(), _createElementBlock("span", _hoisted_1, "-"))
    ]),
    edit: _withCtx(() => [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_FormField, {
          value: _ctx.promoCode,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.promoCode) = $event)),
          label: "Новый промокод",
          disabled: _ctx.promoCodeLoading,
          error: _ctx.promoCodeError
        }, null, 8, ["value", "disabled", "error"]),
        _createVNode(_component_OrdersEditEditButtons, {
          loading: _ctx.promoCodeLoading,
          onCancel: _ctx.onChangeEditActive,
          onSubmit: _ctx.onPromoCodeSubmit
        }, null, 8, ["loading", "onCancel", "onSubmit"])
      ])
    ]),
    _: 1
  }, 8, ["edit-active", "with-edit", "onEdit"]))
}