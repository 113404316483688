import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTableCell = _resolveComponent("UserTableCell")!
  const _component_AddressTableCell = _resolveComponent("AddressTableCell")!
  const _component_OrdersTableCellWorkers = _resolveComponent("OrdersTableCellWorkers")!
  const _component_OrdersTableCellSubscription = _resolveComponent("OrdersTableCellSubscription")!
  const _component_DataTable = _resolveComponent("DataTable")!

  return (_openBlock(), _createBlock(_component_DataTable, {
    "column-defs": _ctx.columnDefs,
    "row-data": _ctx.rowData,
    loading: _ctx.isLoading,
    "error-message": _ctx.errorMessage,
    "use-search": false
  }, {
    "cell(user)": _withCtx(({ row }) => [
      _createVNode(_component_UserTableCell, {
        id: row.user.id,
        name: row.user.name,
        phone: row.user.phone
      }, null, 8, ["id", "name", "phone"])
    ]),
    "cell(address)": _withCtx(({ row }) => [
      _createVNode(_component_AddressTableCell, {
        address: row.address.raw,
        latitude: row.address.latitude,
        longitude: row.address.longitude
      }, null, 8, ["address", "latitude", "longitude"])
    ]),
    "cell(workers)": _withCtx(({ row }) => [
      _createVNode(_component_OrdersTableCellWorkers, {
        workers: row.workers
      }, null, 8, ["workers"])
    ]),
    "cell(subscription)": _withCtx(({ row }) => [
      _createVNode(_component_OrdersTableCellSubscription, {
        model: row.subscription
      }, null, 8, ["model"])
    ]),
    _: 1
  }, 8, ["column-defs", "row-data", "loading", "error-message"]))
}