
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  EditIcon,
  DataTable,
  useDataTable,
  getFilterParams,
} from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { ArchiveIcon } from "@/assets/icons";
import {
  generateReport,
  getArchiveWorkerList,
  setAsNotArchiveWorker,
} from "@/modules/workers/requests";
import { WorkerTypeList } from "@/types";
import { getWorkerFormUrl } from "@/utils/paths";
import ReportsCell from "@/components/table-cells/ReportsCell.vue";
import CleanerWidget from "@/components/CleanerWidget.vue";
import { useCityStore } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";
import ArchiveWorkerListFilter from "@/modules/workers/ArchiveWorkerList/ArchiveWorkerList.filter.vue";

const COLUMN_DEFS: Array<ColumnDefinition<WorkerTypeList>> = [
  {
    id: 1,
    name: "Клинер",
    field: "cleaner",
    headStyle: { width: "280px" },
    style: { minWidth: "280px" },
  },
  {
    id: 2,
    name: "Город",
    field: "city",
    width: "100px",
  },
  {
    id: 4,
    name: "Дата архивации",
    field: "archivedDate",
    width: "168px",
    type: "date",
  },
  {
    id: 5,
    name: "Причина архивации",
    field: "archivedReason",
  },
  {
    id: 5,
    name: "Заказы",
    field: "ordersCount",
    width: "90px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 7,
    name: "Рейтинг",
    field: "rating",
    format: ({ row }) => String(row.rating ? row.rating.toFixed(2) : "-"),
    width: "90px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 9,
    name: "Отчет",
    field: "reports",
    width: "271px",
  },
  {
    id: 10,
    name: "Действия",
    field: "actions",
    width: "120px",
  },
];

export default defineComponent({
  name: "WorkerList",
  components: {
    ArchiveWorkerListFilter,
    PageRegional,
    DataTable,
    EditIcon,
    BaseButton,
    ArchiveIcon,
    ReportsCell,
    CleanerWidget,
  },
  setup() {
    const filterParams = ref<Record<string, string | string[]>>({});

    const toast = useToast();

    const cityStore = useCityStore();
    const cityId = computed<number>(() => cityStore.selectedCity);

    watch(filterParams, () => {
      fetchWorkerList();
    });

    const {
      isLoading: isWorkerListLoading,
      rowData: workerList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      meta,
      fetchEntityList: fetchWorkerList,
    } = useDataTable<WorkerTypeList, { averageWorkingDays: number }>({
      fetchEntityList: (params) => {
        return getArchiveWorkerList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...Object.assign(
            getFilterParams({
              city: cityId.value,
            }),
            filterParams.value
          ),
        });
      },
      initialValue: [],
      resourceName: "Worker list",
    });

    onMounted(fetchWorkerList);
    watch([cityId], fetchWorkerList);

    function notArchiveWorker(entityId: number) {
      const shouldRestoreWorker = window.confirm(
        `Вы уверены, что хотите восстановить клинера?`
      );

      if (shouldRestoreWorker) {
        setAsNotArchiveWorker(entityId)
          .then(() => {
            fetchWorkerList();
            toast.show({
              variant: "success",
              title: "Success",
              body: `Клинер успешно востановлен`,
            });
          })
          .catch((error) => {
            console.error(error);
            toast.show({
              variant: "danger",
              title: "Error",
              body: `Ошибка при восстановлении клинера`,
            });
          });
      }
    }

    const averageWorkingDays = computed<number>(() => {
      return meta.value?.averageWorkingDays || 0;
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: workerList,
      isRowDataLoading: isWorkerListLoading,
      errorMessage,
      getWorkerFormUrl,
      generateReport,
      fetchWorkerList,
      notArchiveWorker,

      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      filterParams,

      averageWorkingDays,
    };
  },
});
