
import { defineComponent, onMounted, ref } from "vue";

import { Page } from "@tager/admin-layout";
import { BaseButton, DataTable, EyeIcon } from "@tager/admin-ui";
import { useDataTable, AdvancedSearchFilterParams } from "@tager/admin-ui";

import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import NotesListFilter from "@/modules/notes/notes-list/NotesList.filter.vue";
import { useNotesStore } from "@/store/notes";

import { getNotesCreateUrl, getNotesUpdateUrl } from "../paths";
import { getNotes } from "../services";
import { NoteInterface } from "../typings";

import { COLUMN_DEFS } from "./NotesList.helpers";

export default defineComponent({
  name: "NotesList",
  components: {
    NotesListFilter,
    UserTableCell,
    Page,
    EyeIcon,
    DataTable,
    BaseButton,
  },
  setup() {
    const filterQuery = ref<AdvancedSearchFilterParams>({});

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<NoteInterface>({
      fetchEntityList: (params) =>
        getNotes({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterQuery.value,
        }),
      initialValue: [],
      resourceName: "Список заметок",
    });

    const notesStore = useNotesStore();

    onMounted(() => {
      notesStore.update();
      fetchEntityList();
    });

    function isBusy(): boolean {
      return isDataLoading.value;
    }

    const rowCssClass = (row: NoteInterface) => {
      if (row.status === "DONE") {
        return "notes-list-status notes-list-status--done";
      }
      if (row.isToday) {
        return "notes-list-status notes-list-status--today";
      }
      if (row.status === "ACTIVE") {
        return "notes-list-status notes-list-status--active";
      }
    };

    return {
      columnDefs: COLUMN_DEFS,
      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getNotesCreateUrl,
      getNotesUpdateUrl,
      fetchEntityList,
      filterQuery,
      rowCssClass,
    };
  },
});
