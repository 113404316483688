import { ColumnDefinition } from "@tager/admin-ui";

import { subscriptionPeriodLabel } from "@/modules/subscriptions/constants";

import { SubscriptionInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<SubscriptionInterface>> = [
  {
    id: 1,
    name: "",
    type: "name",
    field: "",
    format: ({ row }) => ({
      adminLink: {
        url: "/subscriptions/" + row.id,
        text: "№ " + row.id,
      },
      websiteLink: null,
      paramList: [
        { name: "Периодичность", value: subscriptionPeriodLabel(row.period) },
        { name: "Количество заказов", value: String(row.ordersCount) },
      ],
    }),
    style: { width: "200px" },
    headStyle: { width: "200px" },
  },
  {
    id: 2,
    name: "Дата создания",
    field: "createdAt",
    type: "date",
    style: { width: "160px" },
    headStyle: { width: "160px" },
  },
  {
    id: 3,
    name: "Клиент",
    field: "user",
    format: ({ row }) => row.user.name + " - " + row.user.phone,
    style: { width: "200px" },
    headStyle: { width: "200px" },
  },
  {
    id: 4,
    name: "Адрес",
    field: "address",
  },
  {
    id: 5,
    name: "След. заказ",
    field: "order",
    style: { width: "200px" },
    headStyle: { width: "200px" },
  },
];
