
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";

import {
  Nullable,
  useResource,
  useUserPermission,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import { FieldValue, FormFooter } from "@tager/admin-ui";

import { getWebsiteOrigin } from "@/utils/common";
import {
  OrderFullInterface,
  subscriptionPeriodLabel,
} from "@/modules/orders/typings";
import { getOrder } from "@/modules/orders/services";
import OrdersEditModeSwitcher from "@/modules/orders/containers/OrdersEditModeSwitcher.vue";
import OrdersEditAddress from "@/modules/orders/containers/OrdersViewAddress.vue";
import OrdersEditReview from "@/modules/orders/containers/OrdersEditReview.vue";
import OrdersEditPromocode from "@/modules/orders/containers/OrdersEditPromocode.vue";
import OrdersEditAdminComment from "@/modules/orders/views/orders-edit/components/OrdersEditAdminComment.vue";
import { Scope } from "@/rbac/Scope";
import OrdersViewServices from "@/modules/orders/containers/OrdersViewServices.vue";

import OrdersEditStatusBadge from "../../containers/OrdersEditStatusBadge.vue";

export default defineComponent({
  name: "OrdersView",
  components: {
    OrdersViewServices,
    OrdersEditAdminComment,
    OrdersEditPromocode,
    OrdersEditReview,
    OrdersEditAddress,
    FormFooter,
    FieldValue,
    OrdersEditModeSwitcher,
    OrdersEditStatusBadge,
    Page,
  },
  setup() {
    const route = useRoute();
    const orderId = computed<number>(() => +route.params.orderId);

    const [
      fetchOrder,
      {
        data: order,
        loading: isOrderLoading,
        error: orderLoadingError,
        errorMessage: orderLoadingMessageError,
        status: orderLoadingStatus,
      },
    ] = useResource<Nullable<OrderFullInterface>>({
      fetchResource: () => {
        return orderId.value && !isNaN(orderId.value)
          ? getOrder(orderId.value)
          : new Promise((resolve) => resolve({ data: null }));
      },
      initialValue: null,
      resourceName: "Загрузка заказа",
    });

    onMounted(() => fetchOrder());
    watch([orderId], () => fetchOrder());

    const isContentLoading = computed<boolean>(() => isOrderLoading.value);

    const canSubscriptionEdit = useUserPermission(Scope.SubscriptionsView);

    return {
      getWebsiteOrigin,
      orderId,
      isContentLoading,
      orderLoadingError,
      orderLoadingMessageError,
      orderLoadingStatus,
      order,
      canSubscriptionEdit,
    };
  },
  methods: { subscriptionPeriodLabel },
});
