import { ColumnDefinition } from "@tager/admin-ui";

import { OrderAvailableWorker, OrderWorker } from "@/modules/orders/typings";

export const availableWorkersColumnDefs: ColumnDefinition<OrderAvailableWorker>[] =
  [
    {
      id: 1,
      name: "#",
      field: "number",
      width: "60px",
      headStyle: { textAlign: "center" },
      style: { textAlign: "center" },
    },
    {
      id: 2,
      name: "Имя",
      type: "name",
      field: "name",
    },
    {
      id: 3,
      name: "Пыл.",
      type: "boolean",
      field: "availablePylesos",
      width: "60px",
      view: "TICK",
    },
    {
      id: 4,
      name: "Окна",
      type: "boolean",
      field: "availableWindows",
      width: "60px",
      view: "TICK",
    },
    {
      id: 5,
      name: "Расстояние",
      field: "distance",
      type: "string",
      width: "100px",
      headStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      format: ({ row }) => {
        const km = row.distance / 1000;
        return Math.round(km * 100) / 100 + " км.";
      },
    },
    {
      id: 6,
      name: "Время работы",
      field: "workingTime",
      type: "string",
      width: "140px",
      headStyle: { textAlign: "center" },
      style: { textAlign: "center" },
      format: ({ row }) => {
        if (!row.workingTime) {
          return "-";
        }
        return `${row.workingTime[0]} - ${row.workingTime[1]}`;
      },
    },

    {
      id: 7,
      name: "Занятость",
      field: "orders",
      width: "200px",
      headStyle: { textAlign: "center" },
      style: { textAlign: "center" },
    },
  ];

export const orderWorkersColumnDefs: ColumnDefinition<OrderWorker>[] = [
  {
    id: 1,
    name: "#",
    field: "number",
    width: "60px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 2,
    name: "Имя",
    type: "name",
    field: "name",
  },
  {
    id: 3,
    name: "Пыл.",
    field: "pylesos",
    width: "60px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 4,
    name: "Ключи",
    field: "keys",
    width: "60px",

    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 5,
    name: "Расстояние",
    field: "distance",
    type: "string",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
    format: ({ row }) => {
      const km = row.distance / 1000;
      return Math.round(km * 100) / 100 + " км.";
    },
  },
  {
    id: 6,
    name: "Время, ч.",
    field: "time",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
    format: ({ row }) => {
      return (
        Math.floor(row.time / 60) +
        " ч." +
        (row.time % 60 > 0 ? " " + (row.time % 60) + " мин." : "")
      );
    },
  },
  {
    id: 5,
    name: "Доп.время",
    field: "additionalWorkTime",
    type: "string",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
    format: ({ row }) => {
      return row.additionalWorkTime ? row.additionalWorkTime + " мин." : "-";
    },
  },
  {
    id: 7,
    name: "Руб. в час",
    field: "rate",
    type: "string",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 8,
    name: "Штраф, руб.",
    field: "fine",
    type: "string",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
  {
    id: 9,
    name: "Итого, руб.",
    field: "reward",
    type: "string",
    width: "100px",
    headStyle: { textAlign: "center" },
    style: { textAlign: "center" },
  },
];

const timeToMinutes = (hoursMinutes: string): number => {
  const parts = hoursMinutes.split(":");
  if (parts.length !== 2) return 0;
  return +parts[0] * 60 + +parts[1];
};

export const rowAvailableWorkersCssClass =
  (datetime: string) => (row: OrderAvailableWorker) => {
    if (row.userMode === "GOOD") {
      return "worker-row--good";
    }

    if (row.userMode === "BAD") {
      return "worker-row--bad";
    }

    if (!row.workingTime) {
      return "worker-row--dayoff";
    }

    if (row.isOutOfWorkingTime) {
      return "worker-row--out";
    }

    return "";
  };

export const sortAvailableWorkers = (
  a: OrderAvailableWorker,
  b: OrderAvailableWorker
) => {
  if (a.userMode === "BAD") return 1;
  if (b.userMode === "BAD") return -1;

  if (a.userMode === "GOOD") return -1;
  if (b.userMode === "GOOD") return 1;

  if (!a.workingTime) return 1;
  if (!b.workingTime) return -1;

  if (a.isOutOfWorkingTime) return 1;
  if (b.isOutOfWorkingTime) return -1;

  return a.distance < b.distance ? -1 : 1;
};
