import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, normalizeClass as _normalizeClass, createVNode as _createVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0be6b05c"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "option-list",
  "data-multi-select-list": ""
}
const _hoisted_2 = { class: "option-inner" }
const _hoisted_3 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.options.length > 0)
      ? (_openBlock(), _createElementBlock("ul", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.options, (option, index) => {
            return (_openBlock(), _createElementBlock("li", {
              key: option.value,
              class: _normalizeClass([
          'option',
          {
            selected: _ctx.isCheckedOption(option),
            focused: _ctx.isFocusedOption(option),
            noEffect: !_ctx.isDisabled,
            isFullDay: _ctx.isFullDay,
          },
        ])
            }, [
              _createElementVNode("div", _hoisted_2, [
                _createVNode(_component_BaseCheckbox, {
                  id: `${_ctx.name}[${index}]`,
                  checked: _ctx.isCheckedOption(option),
                  disabled: !_ctx.isDisabled,
                  class: _normalizeClass({ defaultCursor: !_ctx.isDisabled }),
                  onChange: ($event: any) => (_ctx.toggleOption(option)),
                  onFocus: ($event: any) => (_ctx.handleOptionFocus(option)),
                  onBlur: ($event: any) => (_ctx.handleOptionBlur(option))
                }, null, 8, ["id", "checked", "disabled", "class", "onChange", "onFocus", "onBlur"]),
                _createElementVNode("label", {
                  for: `${_ctx.name}[${index}]`,
                  class: _normalizeClass({ defaultCursor: !_ctx.isDisabled })
                }, _toDisplayString(option.label), 11, _hoisted_3)
              ])
            ], 2))
          }), 128))
        ]))
      : _createCommentVNode("", true)
  ]))
}