import { defineStore } from "pinia";

import { useResource } from "@tager/admin-services";

import { getTodayNotesCount } from "@/modules/notes/services";

export const useNotesStore = defineStore("notes", {
  state: () => ({
    loadingState: false,
    counter: 0,
  }),
  getters: {
    getCounterLoadingState: (state) => state.loadingState,
    getCounterValue: (state) => state.counter,
  },
  actions: {
    setCounterValue(value: number) {
      this.counter = value;
      console.log("setCounterValue", value);
    },
    setLoadingState(value: boolean) {
      this.loadingState = value;
    },
    update() {
      console.log("update");

      const [fetch, { data, loading }] = useResource<{ count: number }>({
        fetchResource: getTodayNotesCount,
        initialValue: { count: 0 },
        resourceName: "Today notes count",
      });

      fetch().then(() => {
        console.log("setCounterValue", data.value.count);
        this.setCounterValue(data.value.count);
      });
    },
  },
});
