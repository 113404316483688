import { computed } from "vue";

import { useUserPermission } from "@tager/admin-services";

import { Scope } from "@/rbac/Scope";

export const useOnlyCompletedOrdersAllowed = () => {
  return computed<boolean>(
    () =>
      useUserPermission(Scope.OrdersViewCompleted).value &&
      !useUserPermission(Scope.OrdersViewActive).value &&
      !useUserPermission(Scope.OrdersViewCancelled).value
  );
};
