import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, normalizeClass as _normalizeClass, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4a3cafd2"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "filters" }
const _hoisted_2 = { class: "code" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Промокоды",
    "header-buttons": [
      {
        text: 'Создать промокод',
        href: _ctx.getPromocodeFormUrl({
          promocodeId: 'create',
        }),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldMultiSelect, {
                  values: _ctx.modeFilter,
                  "onUpdate:values": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.modeFilter) = $event)),
                  options: _ctx.promocodeModesList,
                  name: "modeFilter",
                  label: "Статус",
                  class: "filter"
                }, null, 8, ["values", "options"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(info)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_FormFieldCheckbox, {
              checked: !!row.reusable,
              name: "reusable",
              label: "Многоразовый",
              disabled: ""
            }, null, 8, ["checked"])
          ])
        ]),
        "cell(code)": _withCtx(({ row }) => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(row.code), 1)
        ]),
        "cell(mode)": _withCtx(({ row }) => [
          _createElementVNode("span", {
            class: _normalizeClass(row.mode === 'ARCHIVED' ? 'mode-archived' : 'mode-active')
          }, _toDisplayString(_ctx.promocodeModesList.find((item) => item.value === row.mode).label), 3)
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Edit",
            href: _ctx.getPromocodeFormUrl({ promocodeId: row.id }),
            disabled: row.mode !== 'ACTIVE'
          }, {
            default: _withCtx(() => [
              _createVNode(_component_EditIcon)
            ]),
            _: 2
          }, 1032, ["href", "disabled"]),
          _createVNode(_component_BaseButton, {
            variant: "icon",
            title: "Delete",
            disabled: _ctx.isBusy(row.id) || row.mode === 'ACTIVE',
            onClick: ($event: any) => (_ctx.handlePromocodeDelete(row.id))
          }, {
            default: _withCtx(() => [
              _createVNode(_component_DeleteIcon)
            ]),
            _: 2
          }, 1032, ["disabled", "onClick"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}