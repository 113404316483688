import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, createVNode as _createVNode, createElementBlock as _createElementBlock, Fragment as _Fragment } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseSelect = _resolveComponent("BaseSelect")!
  const _component_CountInput = _resolveComponent("CountInput")!
  const _component_OptionsSwitcherTrueFalse = _resolveComponent("OptionsSwitcherTrueFalse")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createElementVNode("div", null, [
      (_ctx.services.length)
        ? (_openBlock(), _createBlock(_component_BaseSelect, {
            key: 0,
            value: _ctx.valueRef,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueRef) = $event)),
            options: 
        _ctx.services.map((item) => {
          return {
            value: item.id,
            label: item.name,
          };
        })
      ,
            onChange: _cache[1] || (_cache[1] = (value) => _ctx.onChange(value?.value || null))
          }, null, 8, ["value", "options"]))
        : _createCommentVNode("", true)
    ]),
    (_ctx.service)
      ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
          (_ctx.service.mode === 'QUANTITY')
            ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
                _createVNode(_component_CountInput, {
                  value: _ctx.valueRef,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueRef) = $event)),
                  min: 0,
                  max: 30,
                  onChange: _ctx.onChange
                }, null, 8, ["value", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.service.mode === 'TIME')
            ? (_openBlock(), _createElementBlock("div", _hoisted_2, [
                _createVNode(_component_CountInput, {
                  value: _ctx.valueRef,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valueRef) = $event)),
                  step: _ctx.service.step,
                  min: 0,
                  "label-formatter": _ctx.minutesToTimeString,
                  onChange: _ctx.onChange
                }, null, 8, ["value", "step", "label-formatter", "onChange"])
              ]))
            : _createCommentVNode("", true),
          (_ctx.service.mode === 'TRUE_FALSE' || _ctx.service.mode === 'DELIVERY_KEYS')
            ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
                _createVNode(_component_OptionsSwitcherTrueFalse, {
                  value: _ctx.valueRef,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valueRef) = $event)),
                  onChange: _ctx.onChange
                }, null, 8, ["value", "onChange"])
              ]))
            : _createCommentVNode("", true)
        ], 64))
      : _createCommentVNode("", true)
  ], 64))
}