
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Page } from "@tager/admin-layout";
import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import {
  FormField,
  FieldValue,
  FormFooter,
  TagerFormSubmitEvent,
  FormFieldMultiSelect,
  FormFieldCheckbox,
  useDataTable,
  OptionType,
  TabType,
} from "@tager/admin-ui";

import { getUsersListUrl, getUsersUpdateUrl } from "@/modules/users";
import { getUser, updateUser } from "@/modules/users/services";
import { UserFullInterface } from "@/modules/users/typings";
import {
  getInitialFormValues,
  FormValues,
  convertFormValuesToUpdateUserPayload,
} from "@/modules/users/users-edit/UsersEdit.helpers";
import { WorkerTypeList } from "@/types";
import { getAllWorkersList } from "@/modules/workers/requests";
import { getOrdersByUser } from "@/modules/orders/services";
import OrdersListTable from "@/modules/orders/containers/orders-list/OrdersList.table.vue";

export default defineComponent({
  name: "UsersEdit",
  components: {
    OrdersListTable,
    FormFooter,
    FieldValue,
    FormField,
    FormFieldCheckbox,
    FormFieldMultiSelect,
    Page,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const userId = computed<number>(() => Number(route.params.id));

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);
    const values = ref<FormValues>(getInitialFormValues(null, []));

    const [fetchUser, { data: model, loading: isUserLoading }] = useResource<
      Nullable<UserFullInterface>
    >({
      fetchResource: () =>
        userId.value
          ? getUser(userId.value)
          : new Promise((resolve) => resolve({ data: null })),
      initialValue: null,
      resourceName: "user",
    });

    const {
      isLoading: isWorkersListLoading,
      rowData: workersList,
      fetchEntityList: fetchWorkersList,
    } = useDataTable<WorkerTypeList>({
      fetchEntityList: getAllWorkersList,
      initialValue: [],
      resourceName: "Workers list",
    });

    onMounted(() => {
      fetchUser();
      fetchWorkersList();
    });

    watch([userId], () => fetchUser());

    const workersOptions = computed<Array<OptionType<number>>>(() => {
      return workersList.value.map((item) => {
        return {
          value: item.id,
          label: `№ ${item.worker.number} - ${
            item.worker.name + " " + item.worker.surname
          }`,
        };
      });
    });

    watch([model, workersOptions], () => {
      if (model.value) {
        values.value = getInitialFormValues(model.value, workersOptions.value);
      }
    });

    const submitForm = async (event: TagerFormSubmitEvent) => {
      isSubmitting.value = true;
      errors.value = {};

      try {
        const response = await updateUser(
          userId.value,
          convertFormValuesToUpdateUserPayload(values.value)
        );

        if (event.type === "create") {
          await router.push(getUsersUpdateUrl(response.data.id));
        } else if (event.type === "create_exit" || event.type === "save_exit") {
          navigateBack(router, getUsersListUrl());
        }

        toast.show({
          variant: "success",
          title: "Success",
          body: "Клиент успешно сохранен",
        });
      } catch (error: any) {
        errors.value = convertRequestErrorToMap(error);
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения клиента",
        });
      } finally {
        isSubmitting.value = false;
      }
    };

    const isLoading = computed<boolean>(
      () => isUserLoading.value || isWorkersListLoading.value
    );

    const tabList: Array<TabType> = [
      { id: "common", label: "Основное" },
      { id: "orders", label: "Заказы" },
    ];

    const selectedTabId = ref<string>(tabList[0].id);

    const fetchOrders = () => {
      return getOrdersByUser(userId.value)();
    };

    return {
      submitForm,
      userId,
      tabList,
      isLoading,
      selectedTabId,
      isSubmitting,
      values,
      errors,
      model,
      workersOptions,
      backButtonUrl: getUsersListUrl(),

      fetchOrders,
    };
  },
});
