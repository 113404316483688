import { request, ResponseBody } from "@tager/admin-services";

export type OrdersStatsResponse = {
  revenue: number;
  salary: number;
  orders: number;
  averageOrdersPerCustomer: number;
  hours: number;
  income: number;
  averageRevenuePerOrder: number;
};

export function getOrdersStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<OrdersStatsResponse>> {
  return request.get({
    path: `/admin/stats/orders/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type RatingStatsResponse = {
  ordersWithRating: number;
  averageRating: number;
  ordersWithRating_1: number;
  ordersWithRating_2: number;
  ordersWithRating_3: number;
  ordersWithRating_4: number;
  ordersWithRating_5: number;
};

export function getRatingStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<RatingStatsResponse>> {
  return request.get({
    path: `/admin/stats/rating/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type WorkersStatsResponse = {
  averageOrdersCount: number;
  averageTotalSalary: number;
  averageOrderSalary: number;
  averageDistance: number;
};

export function getWorkersStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<WorkersStatsResponse>> {
  return request.get({
    path: `/admin/stats/workers/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type UsersStatsResponse = {
  newUsersCount: number;
  newUsersCountWithMoreThanOrder: number;
};

export function getUsersStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<UsersStatsResponse>> {
  return request.get({
    path: `/admin/stats/users/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

type PrimaryServicesStatsItem = {
  count: number;
  count_no_services: number;
};

export type PrimaryServicesStats = {
  rooms_1: PrimaryServicesStatsItem;
  rooms_2: PrimaryServicesStatsItem;
  rooms_3: PrimaryServicesStatsItem;
  rooms_4: PrimaryServicesStatsItem;
  rooms_5: PrimaryServicesStatsItem;
  rooms_6_plus: PrimaryServicesStatsItem;
};

export function getPrimaryServicesStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<PrimaryServicesStats>> {
  return request.get({
    path: `/admin/stats/primary-services/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type SubscriptionsStats = {
  oldClients: number;
  newClients: number;
  percent: number;
};

export function getSubscriptionsStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<SubscriptionsStats>> {
  return request.get({
    path: `/admin/stats/subscriptions/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type ServicesStats = Array<{
  name: string;
  count: number;
}>;

export function getServicesStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<ServicesStats>> {
  return request.get({
    path: `/admin/stats/additional-services/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type LtvStats = {
  usersCount: number;
  ordersRevenue: number;
  ordersRevenueWithDiscount: number;
  ltv: number;
  ltvUser: number;
};

export function getLtvStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<LtvStats>> {
  return request.get({
    path: `/admin/stats/ltv/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}

export type RetentionStats = {
  moreFourTime: number;
  moreThreeTime: number;
  sixTime: number;
  fiveTime: number;
  fourTime: number;
  threeTime: number;
  twoTime: number;
  oneTime: number;
  withSubscription1: number;
  withSubscription2: number;
  withSubscription3: number;
  withSubscription4: number;
  withoutSubscription1: number;
  withoutSubscription2: number;
  withoutSubscription3: number;
  withoutSubscription4: number;
};

export function getRetentionStats(
  dateFrom: string,
  dateTo: string,
  city: number | null
): Promise<ResponseBody<RetentionStats>> {
  return request.get({
    path: `/admin/stats/retention/${dateFrom}/${dateTo}`,
    params: city
      ? {
          city,
        }
      : {},
  });
}
