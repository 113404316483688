
import { defineComponent, ref } from "vue";

import { BaseButton, FormField } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { setOrderReview, deleteOrderReview } from "@/modules/orders/services";

export default defineComponent({
  name: "OrdersEditReviewForm",
  components: {
    BaseButton,
    FormField,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    rating: {
      type: Number,
      default: 0,
    },
    text: {
      type: String,
      default: "",
    },
    solution: {
      type: String,
      default: "",
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const toast = useToast();

    const ratingValue = ref<number | null>(props.rating);
    const textValue = ref<string | null>(props.text);
    const solutionValue = ref<string | null>(props.solution);

    const canDelete = ref<boolean>(!!props.rating);

    const loading = ref<boolean>(false);

    const onDelete = async () => {
      if (!confirm("Вы уверены, что хотите удалить отзыв?")) return;
      loading.value = true;
      try {
        await deleteOrderReview(props.orderId);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Отзыв успешно удален",
        });

        emit("submit", { rating: 0, text: "" });
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка удаления отзыва",
        });
      } finally {
        loading.value = false;
      }
    };

    const onSubmit = async () => {
      loading.value = true;
      try {
        await setOrderReview(
          props.orderId,
          ratingValue.value || 0,
          textValue.value || "",
          solutionValue.value || ""
        );

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Отзыв успешно изменен",
        });

        emit("submit", {
          rating: ratingValue.value,
          text: textValue.value,
          solution: solutionValue.value,
        });
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения отзыва",
        });
      } finally {
        loading.value = false;
      }
    };

    const onCancel = () => {
      emit("cancel");
    };

    return {
      loading,
      ratingValue,
      textValue,
      solutionValue,
      canDelete,
      onSubmit,
      onCancel,
      onDelete,
    };
  },
});
