import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-68c8207e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "item-price" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "item-price" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.workers, (item) => {
    return (_openBlock(), _createElementBlock("div", {
      key: item.id,
      class: "worker"
    }, [
      (_ctx.canViewWorker)
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
            _createVNode(_component_router_link, {
              to: _ctx.getWorkerFormUrl({ workerId: item.id })
            }, {
              default: _withCtx(() => [
                _createTextVNode("№ " + _toDisplayString(item.number), 1)
              ]),
              _: 2
            }, 1032, ["to"]),
            _createTextVNode(" - " + _toDisplayString(item.name) + " - ", 1),
            _createElementVNode("span", _hoisted_1, _toDisplayString(item.reward) + " BYN", 1)
          ], 64))
        : (_openBlock(), _createElementBlock("span", _hoisted_2, [
            _createElementVNode("span", null, "№ " + _toDisplayString(item.number), 1),
            _createTextVNode(" - " + _toDisplayString(item.name) + " - ", 1),
            _createElementVNode("span", _hoisted_3, _toDisplayString(item.reward) + " BYN", 1)
          ]))
    ]))
  }), 128))
}