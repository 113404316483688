import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: `Изменение параметров - ${_ctx.serviceCategory?.name}`,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "submit-label": "Применить",
        "is-creation": true,
        "can-save-and-exit": false,
        "can-create-another": false,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[2] || (_cache[2] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.price,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.price) = $event)),
          name: "price",
          error: _ctx.errors.price,
          label: "Цена за 1 eд., руб."
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.reward,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.reward) = $event)),
          name: "reward",
          error: _ctx.errors.reward,
          label: "Вознаграждение клинера за 1 eд., руб."
        }, null, 8, ["value", "error"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}