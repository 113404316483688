
import { defineComponent, ref } from "vue";

import { BaseTextArea } from "@tager/admin-ui";

import { setTimetableInfo } from "@/requests";

export default defineComponent({
  name: "InfoForTimetable",

  components: {
    BaseTextArea,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    number: {
      type: String,
      required: true,
    },
    infoForTimetable: {
      type: String,
      required: true,
    },
  },

  setup(props) {
    const newInfoForTimetable = ref(props.infoForTimetable);

    return {
      setTimetableInfo,
      newInfoForTimetable,
    };
  },
});
