
import { computed, defineComponent, PropType, ref } from "vue";

import { FieldValue, formatDateTime } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import {
  OrderDetailAccepted,
  OrderFullInterface,
} from "@/modules/orders/typings";
import { acceptCancelOrder, acceptOrder } from "@/modules/orders/services";

export default defineComponent({
  name: "OrdersEditApproved",
  components: { FieldValue },

  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const loading = ref<boolean>(false);
    const value = ref<OrderDetailAccepted>(props.order);

    const buttons = computed(() => [
      {
        label: !value.value.accepted ? "Подтвердить" : "Отменить",
        variant: !value.value.accepted ? "green" : "red-link",

        onClick: async () => {
          const confirmMessage = !value.value.accepted
            ? "Вы уверены, что хотите подтвердить заказ?"
            : "Вы уверены, что хотите отменить подтверждение заказа?";

          if (!confirm(confirmMessage)) return;

          try {
            let response;
            loading.value = true;

            if (!value.value.accepted) {
              response = await acceptOrder(props.order.id);
            } else {
              response = await acceptCancelOrder(props.order.id);
            }

            toast.show({
              variant: "success",
              title: "Успешно",
              body: !value.value.accepted
                ? "Заказ подтвержден"
                : "Подтверждение заказа отменено",
            });

            value.value = response.data;
          } catch (e: any) {
            toast.show({
              variant: "danger",
              title: "Ошибка",
              body: e.message,
            });
          } finally {
            loading.value = false;
          }
        },
      },
    ]);

    const text = computed<string>(() => {
      console.log("computed", value.value);

      if (!value.value.accepted) {
        return "Не подтвержден";
      } else if (value.value.acceptedBy === "USER") {
        return (
          "Подтвержден пользователем в " +
          formatDateTime(new Date(value.value.acceptedAt))
        );
      } else if (value.value.acceptedBy === "ADMIN") {
        return (
          "Подтвержден менеджером (" +
          value.value.acceptedManagerName +
          ") в " +
          formatDateTime(new Date(value.value.acceptedAt))
        );
      } else {
        return "Неизвестно";
      }
    });

    return {
      loading,
      buttons,
      text,
    };
  },
});
