import { ServiceMultipleChangePayload } from "@/modules/services/services";

export type FormValues = {
  price: number | null;
  reward: number | null;
};

export function getInitialFormValues(): FormValues {
  return {
    price: null,
    reward: null,
  };
}

export function convertFormValuesToPayload(
  formValues: FormValues
): ServiceMultipleChangePayload {
  return {
    price: formValues.price ? +formValues.price : null,
    reward: formValues.reward ? +formValues.reward : null,
  };
}
