import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Badge = _resolveComponent("Badge")!

  return (_ctx.order.status === 'CANCELLED')
    ? (_openBlock(), _createBlock(_component_Badge, {
        key: 0,
        class: "order-status-cancelled"
      }, {
        default: _withCtx(() => [
          _createTextVNode(" Заказ отменен " + _toDisplayString(_ctx.order.cancelledBy === "USER"
        ? "клиентом"
        : `менеджером (${_ctx.order.cancelledManagerName})`) + " в " + _toDisplayString(_ctx.formatDateTime(new Date(_ctx.order.cancelledAt))), 1)
        ]),
        _: 1
      }))
    : (_ctx.order.status === 'COMPLETED')
      ? (_openBlock(), _createBlock(_component_Badge, {
          key: 1,
          class: "order-status-completed"
        }, {
          default: _withCtx(() => [
            _createTextVNode("Заказ выполнен")
          ]),
          _: 1
        }))
      : _createCommentVNode("", true)
}