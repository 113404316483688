import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-54919b52"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-3" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedSearchDateFilter = _resolveComponent("AdvancedSearchDateFilter")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!
  const _component_CleanerWidget = _resolveComponent("CleanerWidget")!
  const _component_ReviewsListSolutionCell = _resolveComponent("ReviewsListSolutionCell")!
  const _component_UserTableCell = _resolveComponent("UserTableCell")!
  const _component_OrderTableCell = _resolveComponent("OrderTableCell")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    title: "Отзывы",
    "allow-all": true
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        "row-css-class": 
          (row) =>
            row.rating <= 3 ? 'row-bad-rating' : 'row-rating-' + row.rating
        ,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_AdvancedSearch, {
            tags: _ctx.tags,
            "onClick:tag": _ctx.handleTagRemove
          }, {
            default: _withCtx(() => [
              _createVNode(_component_AdvancedSearchDateFilter, {
                filter: _ctx.dateFilter,
                "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event)),
                label: "Дата отзыва"
              }, null, 8, ["filter"]),
              _createVNode(_component_AdvancedSearchDateFilter, {
                filter: _ctx.dateOrderFilter,
                "onUpdate:filter": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateOrderFilter) = $event)),
                label: "Дата уборки"
              }, null, 8, ["filter"]),
              _createElementVNode("div", _hoisted_1, [
                _createVNode(_component_FormFieldSelect, {
                  value: _ctx.ratingFilter,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.ratingFilter) = $event)),
                  options: _ctx.filterRatingOptions,
                  searchable: false,
                  name: "ratingFilter",
                  label: "Рейтинг"
                }, null, 8, ["value", "options"]),
                _createVNode(_component_FormField, {
                  value: _ctx.workerFilter,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.workerFilter) = $event)),
                  name: "workerFilter",
                  label: "Клинер"
                }, null, 8, ["value"])
              ])
            ]),
            _: 1
          }, 8, ["tags", "onClick:tag"])
        ]),
        "cell(workers)": _withCtx(({ row }) => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(row.order.workers, (item) => {
            return (_openBlock(), _createElementBlock("div", {
              key: item.id
            }, [
              _createVNode(_component_CleanerWidget, { worker: item }, null, 8, ["worker"])
            ]))
          }), 128))
        ]),
        "cell(solution)": _withCtx(({ row }) => [
          _createVNode(_component_ReviewsListSolutionCell, {
            id: row.id,
            value: row.solution
          }, null, 8, ["id", "value"])
        ]),
        "cell(user)": _withCtx(({ row }) => [
          _createVNode(_component_UserTableCell, {
            id: row.user.id,
            name: row.user.name,
            phone: row.user.phone
          }, null, 8, ["id", "name", "phone"])
        ]),
        "cell(order)": _withCtx(({ row }) => [
          _createVNode(_component_OrderTableCell, {
            id: row.order.id,
            datetime: row.order.datetime
          }, null, 8, ["id", "datetime"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "row-css-class", "pagination", "onChange"])
    ]),
    _: 1
  }))
}