import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-146d718f"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "info-timetable-block" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseTextArea, {
      id: _ctx.number,
      value: _ctx.newInfoForTimetable,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.newInfoForTimetable) = $event)),
      class: "info-timetable-input",
      type: "textarea",
      onBlur: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setTimetableInfo(_ctx.id, { text: _ctx.newInfoForTimetable }).then()))
    }, null, 8, ["id", "value"])
  ]))
}