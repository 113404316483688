import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-08984af8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-range-form" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseInput = _resolveComponent("BaseInput")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_BaseInput, {
      value: _ctx.from,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.from) = $event)),
      name: "dateFrom",
      label: "Начало периода",
      type: "date",
      class: "date-input",
      disabled: _ctx.disabled
    }, null, 8, ["value", "disabled"]),
    _createVNode(_component_BaseInput, {
      value: _ctx.to,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.to) = $event)),
      name: "dateTo",
      label: "Конец периода",
      type: "date",
      class: "date-input",
      disabled: _ctx.disabled
    }, null, 8, ["value", "disabled"]),
    _createVNode(_component_BaseButton, {
      class: "submit-button",
      disabled: _ctx.disabled || !_ctx.from || !_ctx.to,
      onClick: _ctx.handleSubmit
    }, {
      default: _withCtx(() => [
        _createTextVNode(" Применить ")
      ]),
      _: 1
    }, 8, ["disabled", "onClick"])
  ]))
}