import { request, ResponseBody } from "@tager/admin-services";

export type ServiceMultipleChangePayload = {
  price: number | null;
  reward: number | null;
};

export function servicesMultipleChange(
  serviceCategoryId: number,
  payload: ServiceMultipleChangePayload
): Promise<ResponseBody> {
  return request.put({
    path: `/admin/services/${serviceCategoryId}/multiple-change`,
    body: payload,
  });
}
