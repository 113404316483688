
import { defineComponent } from "vue";

import { formatDateTime } from "@tager/admin-ui";

export default defineComponent({
  name: "AddressTableCell",
  props: {
    address: {
      type: String,
      required: true,
    },
    latitude: {
      type: Number,
      default: null,
    },
    longitude: {
      type: Number,
      default: null,
    },
    type: {
      type: String,
      default: null,
    },
  },
  setup() {
    return {
      formatDateTime,
    };
  },
});
