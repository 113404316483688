import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrderDateTimeEdit = _resolveComponent("OrderDateTimeEdit")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Дата и время уборки",
    type: "datetime",
    value: _ctx.dateTimeValue,
    "with-edit": _ctx.order.status === 'WAITING',
    "edit-active": _ctx.formOpened,
    onEdit: _ctx.onStart
  }, {
    edit: _withCtx(() => [
      _createVNode(_component_OrderDateTimeEdit, {
        "order-id": _ctx.order.id,
        datetime: _ctx.dateTimeValue,
        onCancel: _ctx.onCancel,
        onSubmit: _ctx.onSubmit
      }, null, 8, ["order-id", "datetime", "onCancel", "onSubmit"])
    ]),
    _: 1
  }, 8, ["value", "with-edit", "edit-active", "onEdit"]))
}