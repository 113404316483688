
import { defineComponent } from "vue";

export default defineComponent({
  name: "Step",
  props: {
    number: {
      type: Number,
    },
    name: {
      type: String,
    },
    opened: {
      type: Boolean,
      default: false,
    },
  },
});
