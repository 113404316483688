import { resolveComponent as _resolveComponent, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.quantity,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.quantity) = $event)),
          type: "number",
          name: "quantity",
          error: _ctx.errors.quantity,
          label: "Количество "
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.price,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.price) = $event)),
          type: "number",
          name: "price",
          error: _ctx.errors.price,
          label: "Цена"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.minutes,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.minutes) = $event)),
          type: "number",
          name: "minutes",
          error: _ctx.errors.minutes,
          label: "Трудоемкость, мин"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.fullWorkerPrice,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.fullWorkerPrice) = $event)),
          type: "number",
          name: "fullWorkerPrice",
          error: _ctx.errors.fullWorkerPrice,
          label: "Вознаграждение клинера"
        }, null, 8, ["value", "error"]),
        (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.cities, (city) => {
          return (_openBlock(), _createBlock(_component_FormField, {
            key: city.id,
            value: _ctx.values.rewardPrimaryOrder[city.id],
            "onUpdate:value": ($event: any) => ((_ctx.values.rewardPrimaryOrder[city.id]) = $event),
            type: "number",
            name: `workerRewardForMainServicesOrder${city.id}`,
            error: _ctx.errors.workerRewardForMainServicesOrder,
            label: `Вознаграждение клинера на заказе, в котором нет дополнительных услуг - ${city.name}`
          }, null, 8, ["value", "onUpdate:value", "name", "error", "label"]))
        }), 128))
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}