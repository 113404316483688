
import { computed, defineComponent, onMounted, ref, watch } from "vue";

import { Nullable, useResource } from "@tager/admin-services";

import { useCityId } from "@/store/city";
import { getOrderStatsByDate } from "@/modules/orders/services";
import { OrderStatsByDate } from "@/modules/orders/typings";

export default defineComponent({
  name: "StatsView",
  props: {
    date: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const cityId = useCityId();

    const [
      fetchStats,
      { data: stats, loading: statsLoading, error: statsError },
    ] = useResource<Nullable<OrderStatsByDate>>({
      fetchResource: () => getOrderStatsByDate(props.date, cityId.value),
      initialValue: null,
    });

    watch([cityId, computed<string>(() => props.date)], fetchStats);
    onMounted(fetchStats);

    return {
      stats,
      statsLoading,
      statsError,
    };
  },
});
