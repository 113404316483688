import { toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00cc15ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "footer__info" }
const _hoisted_2 = {
  key: 0,
  class: "footer__price"
}
const _hoisted_3 = {
  key: 1,
  class: "footer__time"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.price)
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, _toDisplayString(_ctx.price.toFixed(2)) + " BYN ", 1))
      : _createCommentVNode("", true),
    (_ctx.minutes)
      ? (_openBlock(), _createElementBlock("span", _hoisted_3, _toDisplayString(_ctx.minutesToTimeString(_ctx.minutes)), 1))
      : _createCommentVNode("", true)
  ]))
}