
import { defineComponent, onMounted, watch } from "vue";

import { DataTable, getFilterParams } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";

import { getOrdersCreateUrl } from "@/modules/orders";
import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import OrderTableCell from "@/components/table-cells/OrderTableCell.vue";
import AddressTableCell from "@/components/table-cells/AddressTableCell.vue";
import { useCityId } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";

import { getActiveSubscriptions } from "../services";
import { SubscriptionInterface } from "../typings";

import { COLUMN_DEFS } from "./SubscriptionsList.helpers";

export default defineComponent({
  name: "SubscriptionsList",
  components: {
    PageRegional,
    OrderTableCell,
    DataTable,
    UserTableCell,
    AddressTableCell,
  },
  setup() {
    const cityId = useCityId();

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<SubscriptionInterface>({
      fetchEntityList: (params) =>
        getActiveSubscriptions({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...getFilterParams({
            city: cityId.value,
          }),
        }),
      initialValue: [],
      resourceName: "Список подписок",
    });

    onMounted(() => {
      fetchEntityList();
    });

    watch(cityId, fetchEntityList);

    function isBusy(): boolean {
      return isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      getOrdersCreateUrl,
    };
  },
});
