import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeStyle as _normalizeStyle, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-71baf520"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "header" }
const _hoisted_2 = { class: "content-wrapper" }
const _hoisted_3 = { class: "tabs-wrapper" }
const _hoisted_4 = { class: "content-inner" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_DateSelect = _resolveComponent("DateSelect")!
  const _component_StatsView = _resolveComponent("StatsView")!
  const _component_TabList = _resolveComponent("TabList")!
  const _component_OrdersListTable = _resolveComponent("OrdersListTable")!
  const _component_MapView = _resolveComponent("MapView")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, { title: "Заказы за день" }, {
    content: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_DateSelect, {
          "selected-date": _ctx.date,
          "on-change": _ctx.onDateChange
        }, null, 8, ["selected-date", "on-change"]),
        _createVNode(_component_StatsView, { date: _ctx.date }, null, 8, ["date"])
      ]),
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_TabList, {
            "tab-id": _ctx.selectedTabId,
            "onUpdate:tab-id": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.selectedTabId) = $event)),
            "tab-list": _ctx.tabList
          }, null, 8, ["tab-id", "tab-list"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createElementVNode("div", {
            style: _normalizeStyle({ display: _ctx.selectedTabId === 'list' ? 'block' : 'none' })
          }, [
            _createVNode(_component_OrdersListTable, { "fetch-orders": _ctx.fetchOrders }, null, 8, ["fetch-orders"])
          ], 4),
          _createElementVNode("div", {
            style: _normalizeStyle({ display: _ctx.selectedTabId === 'map' ? 'block' : 'none' })
          }, [
            _createVNode(_component_MapView, { date: _ctx.date }, null, 8, ["date"])
          ], 4)
        ])
      ])
    ]),
    _: 1
  }))
}