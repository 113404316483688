
import { defineComponent, PropType } from "vue";

import { Badge, formatDateTime } from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";

export default defineComponent({
  name: "OrdersEditStatusBadge",
  components: { Badge },
  methods: { formatDateTime },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
});
