import { OptionType } from "@tager/admin-ui";

export enum OrderHouseType {
  Apartment = "APARTMENT",
  House = "HOUSE",
}

export const orderHouseTypeOptions: Array<OptionType<OrderHouseType>> = [
  { label: "Квартира", value: OrderHouseType.Apartment },
  { label: "Дом", value: OrderHouseType.House },
];
