
import { defineComponent, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  AdvancedSearch,
  AdvancedSearchDateFilter,
  DataTable,
  FormField,
  FormFieldSelect,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";

import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import OrderTableCell from "@/components/table-cells/OrderTableCell.vue";
import PageRegional from "@/pages/PageRegional.vue";
import { useCityId } from "@/store/city";
import CleanerWidget from "@/components/CleanerWidget.vue";

import { getReviews } from "../services";
import { ReviewInterface } from "../typings";

import {
  filterRatingOptions,
  useReviewsAdvancedSearch,
} from "./ReviewsList.search";
import { COLUMN_DEFS } from "./ReviewsList.helpers";
import ReviewsListSolutionCell from "./components/ReviewListSolutionCell.vue";

export default defineComponent({
  name: "ReviewsList",
  components: {
    CleanerWidget,
    AdvancedSearchDateFilter,
    AdvancedSearch,
    PageRegional,
    ReviewsListSolutionCell,
    OrderTableCell,
    UserTableCell,
    DataTable,
    FormFieldSelect,
    FormField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cityId = useCityId();

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<ReviewInterface>({
      fetchEntityList: (params) =>
        getReviews({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,

          ...{ ...filterParams.value, "filter[city]": cityId.value },
        }),
      initialValue: [],
      resourceName: "Список отзывов",
    });

    onMounted(() => {
      fetchEntityList();
    });

    watch([cityId], () => {
      fetchEntityList();
    });

    function isBusy(): boolean {
      return isDataLoading.value;
    }

    const {
      dateFilter,
      dateOrderFilter,
      ratingFilter,
      workerFilter,
      filterParams,
      tags,
      handleTagRemove,
    } = useReviewsAdvancedSearch({
      route,
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchEntityList();
      }
    });

    return {
      columnDefs: COLUMN_DEFS,
      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      dateFilter,
      dateOrderFilter,
      ratingFilter,
      workerFilter,
      filterParams,
      tags,
      handleTagRemove,
      filterRatingOptions,
    };
  },
});
