
import { defineComponent, PropType, ref } from "vue";

import { FieldValue } from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";
import OrderDateTimeEdit from "@/components/OrderDateTimeEdit.vue";

export default defineComponent({
  name: "OrdersEditDateTime",
  components: {
    OrderDateTimeEdit,
    FieldValue,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const dateTimeValue = ref<string>(props.order.datetime);
    const formOpened = ref<boolean>(false);

    const onStart = () => (formOpened.value = true);
    const onCancel = () => (formOpened.value = false);
    const onSubmit = async (value: string) => {
      dateTimeValue.value = value;
      formOpened.value = false;
    };

    return {
      formOpened,
      onStart,
      onCancel,
      onSubmit,
      dateTimeValue,
    };
  },
});
