
import { computed, defineComponent, onMounted, watch } from "vue";

import {
  ColumnDefinition,
  useDataTable,
  DataTable,
  BaseButton,
  EditIcon,
  DeleteIcon,
  getFilterParams,
} from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import {
  getActiveWorkerList,
  setArchiveWorker,
} from "@/modules/workers/requests";
import { WorkerTypeList } from "@/types";
import { getWorkerFormUrl } from "@/utils/paths";
import ReportsCell from "@/components/table-cells/ReportsCell.vue";
import CleanerWidget from "@/components/CleanerWidget.vue";
import { useCityStore } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";

const COLUMN_DEFS: Array<ColumnDefinition<WorkerTypeList>> = [
  {
    id: 1,
    name: "Клинер",
    field: "cleaner",
    headStyle: { width: "280px" },
    style: { minWidth: "280px" },
  },
  {
    id: 2,
    name: "Город",
    field: "city",
    width: "100px",
  },
  {
    id: 4,
    name: "Электронная почта",
    field: "email",
    headStyle: { width: "262px" },
  },
  {
    id: 5,
    name: "Рейтинг",
    field: "rating",
    width: "120px",
  },
  {
    id: 6,
    name: "Адрес",
    field: "address",
    type: ({ row }) => (row.addressUrl ? "link" : "string"),
    format: ({ row }) => {
      const text = row.address ? row.address : "Не указан";
      if (row.addressUrl) {
        return {
          url: row.addressUrl ?? "",
          text,
        };
      }

      return text;
    },
    options: {
      shouldOpenNewTab: true,
    },
  },
  {
    id: 7,
    name: "Отчет",
    field: "reports",
    style: { minWidth: "271px", width: "271px" },
  },
  {
    id: 8,
    name: "Действия",
    field: "actions",
    style: { width: "100px", whiteSpace: "nowrap" },
    headStyle: { width: "100px" },
  },
];

export default defineComponent({
  name: "WorkerList",
  components: {
    PageRegional,
    DeleteIcon,
    EditIcon,
    BaseButton,
    DataTable,
    ReportsCell,
    CleanerWidget,
  },
  setup() {
    const toast = useToast();
    const cityStore = useCityStore();
    const cityId = computed<number>(() => cityStore.selectedCity);

    const filterParams = computed(() => {
      if (!cityId.value) return null;

      return getFilterParams({
        city: cityId.value,
      });
    });

    const {
      isLoading: isWorkerListLoading,
      rowData: workerList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchWorkerList,
    } = useDataTable<WorkerTypeList>({
      fetchEntityList: (params) =>
        getActiveWorkerList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Worker list",
    });

    onMounted(fetchWorkerList);
    watch([cityId], fetchWorkerList);

    function archiveWorker(entityId: number) {
      const shouldArchiveWorker = window.confirm(
        `Вы уверены, что хотите добавить клинера в архив?`
      );

      if (shouldArchiveWorker) {
        setArchiveWorker(entityId)
          .then(() => {
            fetchWorkerList();
            toast.show({
              variant: "success",
              title: "Success",
              body: `Клинер успешно добавлен в архив`,
            });
          })
          .catch((error) => {
            console.error(error);
            toast.show({
              variant: "danger",
              title: "Error",
              body: `Ошибка при добавлении клинера в архив`,
            });
          });
      }
    }

    function isBusy(): boolean {
      return isWorkerListLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: workerList,
      isRowDataLoading: isWorkerListLoading,
      errorMessage,
      getWorkerFormUrl,
      fetchWorkerList,
      archiveWorker,
      isBusy,

      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});
