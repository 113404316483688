
import { defineComponent, PropType, ref } from "vue";

import { FieldValue, FormField } from "@tager/admin-ui";
import {
  convertRequestErrorToMap,
  Nullable,
  useToast,
} from "@tager/admin-services";

import { OrderFullInterface, OrderPromocode } from "@/modules/orders/typings";
import PromocodeBadge from "@/modules/orders/components/PromocodeBadge.vue";
import {
  deleteOrderPromoCode,
  setOrderPromoCode,
} from "@/modules/orders/services";

import OrdersEditEditButtons from "../views/orders-edit/components/OrdersEditEditButtons.vue";

export default defineComponent({
  name: "OrdersEditDateTime",
  components: {
    PromocodeBadge,
    FieldValue,
    OrdersEditEditButtons,
    FormField,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const model = ref<Nullable<OrderPromocode>>(props.order.promoCode);

    const promoCode = ref<string>("");
    const promoCodeError = ref<string>();
    const editActive = ref<boolean>(false);
    const promoCodeLoading = ref<boolean>(false);
    const promoCodeCancelLoading = ref<boolean>(false);

    const onChangeEditActive = () => {
      promoCode.value = "";
      promoCodeLoading.value = false;
      promoCodeError.value = "";
      editActive.value = !editActive.value;
    };

    const onPromoCodeSubmit = async () => {
      promoCodeLoading.value = true;

      try {
        const result = await setOrderPromoCode(props.order.id, promoCode.value);
        editActive.value = false;
        model.value = result.data;
      } catch (error: any) {
        promoCodeError.value =
          convertRequestErrorToMap(error).code || "Промокод недоступен";
      } finally {
        promoCodeLoading.value = false;
      }
    };

    const onCancelPromoCode = async () => {
      promoCodeCancelLoading.value = true;
      try {
        await deleteOrderPromoCode(props.order.id);

        toast.show({
          variant: "success",
          title: "Промокод",
          body: "Промокод успешно удален",
        });

        model.value = null;
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка удаления прокомокода",
        });
      } finally {
        promoCodeCancelLoading.value = false;
      }
    };

    return {
      model,

      promoCode,
      onPromoCodeSubmit,
      onCancelPromoCode,
      promoCodeLoading,
      promoCodeCancelLoading,
      promoCodeError,
      editActive,
      onChangeEditActive,
    };
  },
});
