import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-35211bae"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "subscription-edit" }
const _hoisted_2 = { class: "subscription-edit__actions" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Периодичность",
    value: _ctx.textValue,
    "with-edit": !_ctx.subscription.isCompleted,
    "edit-active": _ctx.isEdit,
    onEdit: _ctx.onEdit
  }, {
    edit: _withCtx(() => [
      _createElementVNode("div", _hoisted_1, [
        _createElementVNode("form", {
          onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSave && _ctx.onSave(...args)))
        }, [
          _createVNode(_component_FormFieldSelect, {
            value: _ctx.value,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value) = $event)),
            options: _ctx.subscriptionPeriodOptions,
            "no-error-padding": true
          }, null, 8, ["value", "options"]),
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_BaseButton, {
              variant: "secondary",
              disabled: _ctx.loading,
              onClick: _ctx.onCancel
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Отменить ")
              ]),
              _: 1
            }, 8, ["disabled", "onClick"]),
            _createVNode(_component_BaseButton, {
              type: "submit",
              variant: "primary",
              loading: _ctx.loading
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Применить ")
              ]),
              _: 1
            }, 8, ["loading"])
          ])
        ], 32)
      ])
    ]),
    _: 1
  }, 8, ["value", "with-edit", "edit-active", "onEdit"]))
}