
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { convertRequestErrorToMap } from "@tager/admin-services";
import {
  DynamicField,
  universalFieldUtils,
  RepeaterField,
  RepeaterIncomingValue,
  FieldShortType,
} from "@tager/admin-dynamic-field";
import {
  FormField,
  FormFieldCheckbox,
  FormFooter,
  TagerFormSubmitEvent,
} from "@tager/admin-ui";
import { FieldUnion } from "@tager/admin-dynamic-field";
import { Page } from "@tager/admin-layout";

import { PromocodeFullType } from "@/types";
import { convertPromocodeToFormValues } from "@/modules/promocodes/Promocodes/Promocodes.helpers";
import {
  convertFormValuesToPromocodeCreationPayload,
  convertFormValuesToPromocodeUpdatePayload,
  datesFieldConfig,
  emailsFieldConfig,
} from "@/modules/promocodes/Promocodes/Promocodes.helpers";
import { getPromocodeFormUrl, getPromocodesListUrl } from "@/utils/paths";
import { createPromocode, getPromocode, updatePromocode } from "@/requests";

import { FormValues } from "../Promocodes.helpers";

export default defineComponent({
  name: "PromocodesForm",
  components: {
    FormFieldCheckbox,
    FormField,
    Page,
    FormFooter,
    DynamicField,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    const promocodeId = computed<string>(
      () => route.params.promocodeId as string
    );

    const isCreation = computed<boolean>(() => promocodeId.value === "create");

    const [
      fetchPromocode,
      { data: promocodeData, loading: isPromocodeLoading },
    ] = useResource<Nullable<PromocodeFullType>>({
      fetchResource: () => getPromocode(promocodeId.value),
      initialValue: null,
      resourceName: "getPromocode",
    });

    const pageTitle = computed<string>(() =>
      isCreation.value ? "Новый промокод" : "Редактировать промокод"
    );

    const dates = ref<FieldUnion>(
      universalFieldUtils.createFormField(datesFieldConfig, null)
    );

    const emails = ref<FieldUnion>(
      universalFieldUtils.createFormField(emailsFieldConfig, null)
    );

    function updateTemplateValues() {
      if (promocodeData.value) {
        const incomingFieldList: Array<string> =
          promocodeData.value?.dates ?? [];

        if (promocodeData.value) {
          dates.value = universalFieldUtils.createFormField(
            datesFieldConfig,
            incomingFieldList.map((item) => {
              return [{ name: "date", value: item }];
            })
          );
        }

        const incomingEmailsList: Array<string> =
          promocodeData.value?.emails ?? [];
        if (incomingEmailsList) {
          emails.value = universalFieldUtils.createFormField(
            emailsFieldConfig,
            incomingEmailsList.map((item) => {
              return [{ name: "item", value: item }];
            })
          );
        }
      }
    }

    onMounted(() => {
      if (isCreation.value) return;
      fetchPromocode();
    });

    const values = ref<FormValues>(
      convertPromocodeToFormValues(promocodeData.value)
    );

    watch(promocodeData, () => {
      values.value = convertPromocodeToFormValues(promocodeData.value);
      updateTemplateValues();
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const creationBody = convertFormValuesToPromocodeCreationPayload(
        values.value,
        dates.value,
        emails.value
      );

      const updateBody = convertFormValuesToPromocodeUpdatePayload(
        values.value,
        dates.value,
        emails.value
      );

      const requestPromise = isCreation.value
        ? createPromocode(creationBody)
        : updatePromocode(promocodeId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(
              getPromocodeFormUrl({
                promocodeId: promocodeId.value,
              })
            );
          } else if (
            event.type === "create_exit" ||
            event.type === "save_exit"
          ) {
            navigateBack(router, getPromocodesListUrl());
          }

          if (event.type === "create_create-another") {
            values.value = convertPromocodeToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? `Промокод успешно добавлен`
              : "Промокод успешно обновлен",
          });
        })
        .catch((error) => {
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? `Ошибка при добавление промокода`
              : "Ошибка при редактировании промокода",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() => isPromocodeLoading.value);

    return {
      values,
      errors,
      isSubmitting,
      submitForm,
      promocodeData,
      isCreation,
      isContentLoading,
      pageTitle,

      dates,
      emails,
    };
  },
});
