import { onMounted, computed, Ref } from "vue";

import { useResource } from "@tager/admin-services";

import {
  PrimaryServiceCategory,
  SecondaryService,
} from "@/modules/orders/views/orders-create/typings";
import {
  getPrimaryServices,
  getSecondaryServices,
} from "@/modules/orders/views/orders-create/services";

type Result = {
  loading: Ref<boolean>;
  primary: Ref<Array<PrimaryServiceCategory>>;
  secondary: Ref<Array<SecondaryService>>;
};

export function useFetchServices(): Result {
  const [
    fetchPrimaryServices,
    {
      data: primaryServiceCategories,
      loading: primaryServiceCategoriesLoading,
    },
  ] = useResource<Array<PrimaryServiceCategory>>({
    fetchResource: getPrimaryServices,
    initialValue: [],
    resourceName: "Primary Services",
  });

  const [
    fetchSecondaryServices,
    { data: secondaryServices, loading: secondaryServicesLoading },
  ] = useResource<Array<SecondaryService>>({
    fetchResource: getSecondaryServices,
    initialValue: [],
    resourceName: "Secondary Services",
  });

  onMounted(() => {
    fetchPrimaryServices();
    fetchSecondaryServices();
  });

  const loading = computed<boolean>(
    () =>
      primaryServiceCategoriesLoading.value || secondaryServicesLoading.value
  );

  return {
    primary: primaryServiceCategories,
    secondary: secondaryServices,
    loading: loading,
  };
}
