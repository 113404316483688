import { CityInterface, CitySavePayload } from "@/modules/cities/typings";

export type FormValues = {
  name: string;
  nameWhere: string;
  ipinfoName: string;
  urlAlias: string;
  default: boolean;
  latitude: number | null;
  longitude: number | null;
  zoom: number | null;
};

export const getInitialFormValues = (
  city: CityInterface | null
): FormValues => {
  if (!city) {
    return {
      name: "",
      nameWhere: "",
      urlAlias: "",
      ipinfoName: "",
      default: false,
      latitude: null,
      longitude: null,
      zoom: null,
    };
  }

  return {
    name: city.name,
    nameWhere: city.nameWhere,
    ipinfoName: city.ipinfoName,
    urlAlias: city.urlAlias,
    default: city.default,
    latitude: city.latitude,
    longitude: city.longitude,
    zoom: city.zoom,
  };
};

export function convertFormValuesToUpdateCityPayload(
  values: FormValues
): CitySavePayload {
  return {
    name: values.name,
    nameWhere: values.nameWhere,
    ipinfoName: values.ipinfoName,
    urlAlias: values.urlAlias,
    default: values.default,
    zoom: values.zoom,
    latitude: values.latitude,
    longitude: values.longitude,
  };
}
