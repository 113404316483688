import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { WorkerSavePayload } from "@/modules/workers/requests";
import { CompanyType, FullWorkerType } from "@/types";

export type FormValues = {
  isArchived: boolean;
  archivedDate: string | null;
  archivedReason: string;

  number: string;
  name: string;
  surname: string;
  patronymic: string;
  birthday: string;
  address: string;
  email: string;
  phone: string;
  company: Nullable<OptionType<Nullable<number>>>;
  city: Nullable<OptionType<number>>;
  contactMethod: Nullable<OptionType>;
  contractNumber: string;
  contractDate: string;
  passport: string;
  isHighReward: boolean;
  hourRate: string;
  percent: string;
  hasWindowsTools: boolean;
  hasHoover: boolean;
  workingDaysPerMonth: string;
  additionalInformation: string;
  infoForTimetable: string;
};

export const communicationOptionList = [
  {
    value: "phone",
    label: "Телефон",
  },
  {
    value: "viber",
    label: "Вайбер",
  },
  {
    value: "telegram",
    label: "Телеграм",
  },
];

export function convertWorkerToFormValues(
  worker: Nullable<FullWorkerType>
): FormValues {
  if (!worker) {
    return {
      isArchived: false,
      archivedDate: null,
      archivedReason: "",
      number: "",
      name: "",
      surname: "",
      patronymic: "",
      birthday: "",
      address: "",
      email: "",
      phone: "",
      company: { value: null, label: "Без компании" },
      city: null,
      contactMethod: null,
      contractNumber: "",
      contractDate: "",
      passport: "",
      isHighReward: false,
      hourRate: "",
      percent: "",
      hasWindowsTools: false,
      hasHoover: false,
      workingDaysPerMonth: "",
      additionalInformation: "",
      infoForTimetable: "",
    };
  }

  const currentCommunicationOption = communicationOptionList.find(
    (option) => option.value === worker?.contactMethod
  );

  return {
    isArchived: worker.isArchived,
    archivedDate: worker.archivedDate,
    archivedReason: worker.archivedReason ?? "",
    number: worker.number,
    name: worker.name,
    surname: worker.surname,
    patronymic: worker.patronymic,
    birthday: worker.birthday,
    address: worker.address,
    email: worker.email,
    phone: worker.phone,
    contactMethod: currentCommunicationOption ?? null,
    company: worker.company
      ? { value: worker.company.id, label: worker.company.name }
      : { value: null, label: "Без компании" },

    city: worker.city
      ? { value: worker.city.id, label: worker.city.name }
      : null,
    contractNumber: worker.contractNumber,
    contractDate: worker.contractDate,
    passport: worker.passport,
    isHighReward: worker.isHighReward,
    hourRate: worker.hourRate ? worker.hourRate.toString() : "0",
    percent: worker.percent ? worker.percent.toString() : "0",
    hasWindowsTools: worker.hasWindowsTools,
    hasHoover: worker.hasHoover,
    workingDaysPerMonth: worker.workingDaysPerMonth
      ? worker.workingDaysPerMonth.toString()
      : "0",
    additionalInformation: worker.additionalInformation,
    infoForTimetable: worker.infoForTimetable ?? "",
  };
}

export function convertFormValuesToWorkerCreationPayload(
  values: FormValues
): WorkerSavePayload {
  return {
    isArchived: values.isArchived,
    archivedDate: values.archivedDate,
    archivedReason: values.archivedReason ?? null,

    number: values.number,
    name: values.name,
    surname: values.surname,
    patronymic: values.patronymic,
    birthday: values.birthday,
    address: values.address,
    email: values.email,
    phone:
      values.phone.split("+").length === 1 ? "+" + values.phone : values.phone,
    contactMethod: values.contactMethod?.value ?? null,
    company: values.company?.value ?? null,
    city: values.city?.value ?? null,
    contractNumber: values.contractNumber,
    contractDate: values.contractDate,
    passport: values.passport,
    isHighReward: values.isHighReward,
    hourRate: Number(values.hourRate),
    percent: Number(values.percent),
    hasWindowsTools: values.hasWindowsTools,
    hasHoover: values.hasHoover,
    workingDaysPerMonth: Number(values.workingDaysPerMonth),
    additionalInformation: values.additionalInformation,
    infoForTimetable: values.infoForTimetable,
  };
}

export function convertFormValuesToWorkerUpdatePayload(
  values: FormValues
): WorkerSavePayload {
  return { ...convertFormValuesToWorkerCreationPayload(values) };
}

export function convertCompanyListToOption(
  companyList: Array<CompanyType>
): Array<OptionType<Nullable<number>>> {
  const companyOptionList: Array<OptionType<Nullable<number>>> =
    companyList.map((company) => ({
      value: company.id,
      label: company.name,
    }));
  companyOptionList.unshift({ value: null, label: "Без компании" });
  return companyOptionList;
}
