
import { defineComponent, onMounted } from "vue";

import { Page } from "@tager/admin-layout";
import {
  BaseButton,
  DataTable,
  DeleteIcon,
  EditIcon,
  NorthIcon,
  SouthIcon,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { useResourceDelete, useResourceMove } from "@tager/admin-services";

import { getCitiesCreateUrl, getCitiesUpdateUrl } from "@/modules/cities";

import { deleteCity, getCities, moveCity } from "../services";
import { CityInterface } from "../typings";

import { COLUMN_DEFS } from "./CitiesList.helpers";

export default defineComponent({
  name: "UsersList",
  components: {
    Page,

    NorthIcon,
    DeleteIcon,
    SouthIcon,
    EditIcon,
    DataTable,
    BaseButton,
  },
  setup() {
    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<CityInterface>({
      fetchEntityList: (params) =>
        getCities({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Список городов",
    });

    onMounted(() => {
      fetchEntityList();
    });

    const { handleResourceMove, isMoving } = useResourceMove({
      moveResource: moveCity,
      resourceName: "Move City",
      onSuccess: fetchEntityList,
    });

    const { handleResourceDelete, isDeleting } = useResourceDelete({
      deleteResource: deleteCity,
      resourceName: "Delete City",
      onSuccess: fetchEntityList,
    });

    function isBusy(id: number): boolean {
      return isDeleting(id) || isMoving(id) || isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getCitiesCreateUrl,
      getCitiesUpdateUrl,
      handleResourceMove,
      handleResourceDelete,
    };
  },
});
