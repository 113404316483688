import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-424ea11a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "date-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_FormField, {
      value: _ctx.fromValue,
      "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fromValue) = $event)),
      name: "from",
      label: "Начало периода:",
      type: "date"
    }, null, 8, ["value"]),
    _createVNode(_component_FormField, {
      value: _ctx.toValue,
      "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.toValue) = $event)),
      name: "to",
      label: "Конец периода:",
      type: "date"
    }, null, 8, ["value"])
  ]))
}