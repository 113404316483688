import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

import {
  AdvancedSearchDateFilterType,
  FilterTagType,
  getFilterParamAsString,
  getFilterParams,
  useAdvancedSearchDateFilter,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

interface Params {
  route: RouteLocationNormalizedLoaded;
}

interface State {
  dateFilter: Ref<AdvancedSearchDateFilterType>;
  withoutOrderFilter: Ref<boolean>;
  withoutCommentFilter: Ref<boolean>;
  withSourceFilter: Ref<boolean>;

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;

  tagRemovalHandler(event: FilterTagType): void;
}

enum FilterTypes {
  WithoutOrder = "without-order",
  WithoutComment = "without-comment",
  WithSource = "with-source",
}

export function useAdvancedSearch({ route }: Params): State {
  const { value: dateFilter, ...dateFilterData } =
    useAdvancedSearchDateFilter();

  const initialWithoutOrderFilter = computed<boolean>(() => {
    return (
      getFilterParamAsString(route.query, FilterTypes.WithoutOrder) === "1"
    );
  });

  const withoutOrderFilter = ref<boolean>(initialWithoutOrderFilter.value);
  watch(initialWithoutOrderFilter, () => {
    withoutOrderFilter.value = initialWithoutOrderFilter.value;
  });

  const initialWithoutCommentFilter = computed<boolean>(
    () =>
      getFilterParamAsString(route.query, FilterTypes.WithoutComment) === "1"
  );
  const withoutCommentFilter = ref<boolean>(initialWithoutCommentFilter.value);
  watch(initialWithoutCommentFilter, () => {
    withoutCommentFilter.value = initialWithoutCommentFilter.value;
  });

  const initialWithSourceFilter = computed<boolean>(
    () => getFilterParamAsString(route.query, FilterTypes.WithSource) === "1"
  );
  const withSourceFilter = ref<boolean>(initialWithSourceFilter.value);
  watch(initialWithSourceFilter, () => {
    withSourceFilter.value = initialWithSourceFilter.value;
  });

  /** Params **/

  const filterParams = computed(() =>
    getFilterParams({
      ...dateFilterData.filterParams(),
      [FilterTypes.WithoutOrder]: withoutOrderFilter.value ? "1" : "0",
      [FilterTypes.WithoutComment]: withoutCommentFilter.value ? "1" : "0",
      [FilterTypes.WithSource]: withSourceFilter.value ? "1" : "0",
    })
  );

  const tagRemovalHandler = (event: FilterTagType): void => {
    dateFilterData.tagRemovalHandler(event);
    if (event.name === FilterTypes.WithoutOrder) {
      withoutOrderFilter.value = false;
    }
    if (event.name === FilterTypes.WithoutComment) {
      withoutCommentFilter.value = false;
    }
    if (event.name === FilterTypes.WithSource) {
      withSourceFilter.value = false;
    }
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      ...dateFilterData.tags(),
      withoutOrderFilter.value
        ? {
            value: String(withoutOrderFilter.value),
            label: "Да",
            name: FilterTypes.WithoutOrder,
            title: "Без заказа",
          }
        : null,
      withoutCommentFilter.value
        ? {
            value: String(withoutCommentFilter.value),
            label: "Да",
            name: FilterTypes.WithoutComment,
            title: "Без комментария",
          }
        : null,
      withSourceFilter.value
        ? {
            value: String(withSourceFilter.value),
            label: "Да",
            name: FilterTypes.WithSource,
            title: "C Источником",
          }
        : null,
    ].filter(isNotNullish)
  );

  return {
    dateFilter,
    withSourceFilter,
    withoutOrderFilter,
    withoutCommentFilter,
    filterParams,
    tags,
    tagRemovalHandler,
  };
}
