import { format } from "date-fns";

import { Nullable } from "@tager/admin-services";
import {
  FieldConfigUnion,
  FieldUnion,
  universalFieldUtils,
} from "@tager/admin-dynamic-field";

import { PromocodeFullType } from "@/types";
import { PromocodeCreationPayload } from "@/requests";

export const datesFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Даты заказа, на которые работает промокод",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "date",
      type: "DATE",
      label: "Дата",
      meta: {},
    },
  ],
};

export const emailsFieldConfig: FieldConfigUnion = {
  name: "",
  type: "REPEATER",
  label: "Электронные почта, доменные имена, для которых действует промокод",
  meta: {
    view: "TABLE",
  },
  fields: [
    {
      name: "item",
      type: "STRING",
      label: "Домен / Адрес e-mail",
      meta: {},
    },
  ],
};

export const promocodeModesList = [
  {
    value: "ARCHIVED",
    label: "Архивный",
  },
  {
    value: "ACTIVE",
    label: "Активный",
  },
];

export type FormValues = {
  code: string;
  discount: string;
  dateStart: string;
  dateEnd: string;
  reusable: boolean;
};

export function convertPromocodeToFormValues(
  promocode: Nullable<PromocodeFullType>
): FormValues {
  if (!promocode) {
    return {
      code: "",
      discount: "20",
      dateStart: format(new Date(), "yyyy-MM-dd"),
      dateEnd: "",
      reusable: false,
    };
  }

  return {
    code: promocode.code,
    discount: promocode.discount.toString(),
    dateStart: promocode.dateStart,
    dateEnd: promocode.dateEnd,
    reusable: promocode.reusable,
  };
}

export function convertFormValuesToPromocodeCreationPayload(
  values: FormValues,
  dates: FieldUnion,
  emails: FieldUnion
): PromocodeCreationPayload {
  return {
    code: values.code,
    discount: Number(values.discount),
    dateStart: values.dateStart,
    dateEnd: values.dateEnd,
    dates:
      universalFieldUtils.getOutgoingRepeaterValueAsSingleArray<string>(dates),
    emails:
      universalFieldUtils.getOutgoingRepeaterValueAsSingleArray<string>(emails),
    reusable: values.reusable,
  };
}

export const convertFormValuesToPromocodeUpdatePayload =
  convertFormValuesToPromocodeCreationPayload;
