
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { format } from "date-fns";

import { TabList, TabType } from "@tager/admin-ui";

import PageRegional from "@/pages/PageRegional.vue";
import { useCityId } from "@/store/city";
import OrdersListTable from "@/modules/orders/containers/orders-list/OrdersList.table.vue";
import { getOrdersByDate } from "@/modules/orders/services";

import StatsView from "./components/StatsView.vue";
import MapView from "./components/MapView.vue";
import DateSelect from "./components/DateSelect.vue";

export default defineComponent({
  name: "OrdersForDay",
  components: {
    StatsView,
    OrdersListTable,
    PageRegional,
    MapView,
    DateSelect,
    TabList,
  },
  setup() {
    const date = ref<string>(format(new Date(), "yyyy-MM-dd"));
    const cityId = useCityId();

    const fetchOrders = computed(() => {
      return getOrdersByDate(date.value || "", cityId.value);
    });

    onMounted(() => {
      date.value = format(new Date(), "yyyy-MM-dd");
    });

    const tabList: Array<TabType> = [
      { id: "list", label: "Список" },
      { id: "map", label: "Карта" },
    ];

    const selectedTabId = ref<string>(tabList[0].id);

    return {
      date,
      onDateChange: (value: string) => (date.value = value),

      fetchOrders,

      tabList,
      selectedTabId,
    };
  },
});
