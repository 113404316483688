
import { defineComponent, ref } from "vue";

import { BaseButton } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { sendTraining } from "@/requests";
import { sendReport } from "@/modules/workers/requests";

export default defineComponent({
  name: "NotificationCell",
  components: { BaseButton },
  props: {
    log: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const toast = useToast();

    const isOpenFields = ref<boolean>(false);

    function notification(trainingId: number) {
      sendTraining(trainingId)
        .then(() => {
          toast.show({
            variant: "success",
            title: "Success",
            body: `Тренинг успешно отправлен`,
          });

          setTimeout(() => (isOpenFields.value = !isOpenFields.value), 6000);
          isOpenFields.value = !isOpenFields.value;
        })
        .catch(() =>
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка при отправке тренинга",
          })
        );
    }

    return {
      isOpenFields,
      notification,
      sendReport,
    };
  },
  methods: {
    redirect: function (url: string) {
      window.open(url, "_blank");
    },
  },
});
