import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_UserTableCell = _resolveComponent("UserTableCell")!
  const _component_AddressTableCell = _resolveComponent("AddressTableCell")!
  const _component_OrderTableCell = _resolveComponent("OrderTableCell")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    title: "Завершенные подписки",
    "allow-all": true
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        "cell(user)": _withCtx(({ row }) => [
          _createVNode(_component_UserTableCell, {
            id: row.user.id,
            name: row.user.name,
            phone: row.user.phone
          }, null, 8, ["id", "name", "phone"])
        ]),
        "cell(address)": _withCtx(({ row }) => [
          _createVNode(_component_AddressTableCell, {
            address: row.address.text,
            latitude: row.address.latitude,
            longitude: row.address.longitude
          }, null, 8, ["address", "latitude", "longitude"])
        ]),
        "cell(order)": _withCtx(({ row }) => [
          (row.lastOrder)
            ? (_openBlock(), _createBlock(_component_OrderTableCell, {
                key: 0,
                id: row.lastOrder.id,
                datetime: row.lastOrder.datetime,
                number: row.lastOrder.number
              }, null, 8, ["id", "datetime", "number"]))
            : _createCommentVNode("", true)
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }))
}