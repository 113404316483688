
import { defineComponent } from "vue";

import { BaseTextArea } from "@tager/admin-ui";

import { updateGuestOrder } from "@/modules/guest-orders/requests";

export default defineComponent({
  name: "GuestOrderCommentCell",
  components: { BaseTextArea },
  props: {
    value: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const onChange = (value: string) => {
      updateGuestOrder(props.id, {
        comment: value,
      });
    };

    return {
      onChange,
    };
  },
});
