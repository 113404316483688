import { compile } from "path-to-regexp";
import { ca } from "date-fns/locale";

import { ROUTE_PATHS } from "@/router/paths";

export function getServiceCategoryMultipleChangeUrl(categoryId: number) {
  return compile(ROUTE_PATHS.SERVICE_CATEGORY_MULTIPLE_CHANGE)({
    categoryId: String(categoryId),
  });
}

export function getServiceCategoryListUrl() {
  return ROUTE_PATHS.SERVICE_CATEGORY_LIST;
}

export function getServiceCategoryFormUrl(params: {
  serviceCategoryId: string | number;
}): string {
  return compile(ROUTE_PATHS.SERVICE_CATEGORY_FORM)(params);
}

export function getSecondaryServiceListUrl() {
  return ROUTE_PATHS.SECONDARY_SERVICE_LIST;
}

export function getSecondaryServiceFormUrl(params: {
  additionalServiceId: string | number;
}): string {
  return compile(ROUTE_PATHS.SECONDARY_SERVICE_FORM)(params);
}

export function getAdditionalServiceCategoryListUrl(params: {
  serviceCategoryId: string | number;
}) {
  return compile(ROUTE_PATHS.ADDITIONAL_SERVICE_CATEGORY_LIST)(params);
}

export function getAdditionalServiceCategoryFormUrl(params: {
  serviceCategoryId: string | number;
  additionalServiceId: string | number;
}): string {
  return compile(ROUTE_PATHS.ADDITIONAL_SERVICE_CATEGORY_FORM)(params);
}
