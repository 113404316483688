import { ColumnDefinition, OptionType } from "@tager/admin-ui";

import { GuestOrderType } from "@/modules/guest-orders/typings";

export const COLUMN_DEFS: Array<ColumnDefinition<GuestOrderType>> = [
  {
    id: 4,
    name: "Телефон",
    field: "phone",
    width: "150px",
  },
  {
    id: 2,
    name: "Дата и время",
    field: "datetime",
    type: "datetime",
    width: "168px",
  },
  {
    id: 3,
    name: "Город",
    field: "city",
    width: "100px",
  },
  {
    id: 5,
    name: "Клиент",
    field: "customer",
    width: "200px",
  },
  {
    id: 6,
    name: "Заказ",
    field: "order",
    width: "200px",
  },
  {
    id: 8,
    field: "source",
    name: "Источник / Кампания",
    width: "200px",
  },
  {
    id: 7,
    name: "Комментарий",
    field: "comment",
  },
];

export const SORT_OPTIONS: Array<OptionType> = [
  {
    value: "date_desc",
    label: "По дате - по убыванию",
  },
  {
    value: "date_asc",
    label: "По дате - по возрастанию",
  },
];
