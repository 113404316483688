import { ColumnDefinition } from "@tager/admin-ui";

import { newPhoneFormat } from "@/utils/common";

import { UserInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<UserInterface>> = [
  {
    id: 1,
    name: "",
    type: "name",
    field: "",
    format: ({ row }) => ({
      adminLink: {
        url: "/users/" + row.id,
        text: "№ " + row.id,
      },
      websiteLink: null,
      paramList: [
        { name: "Количество заказов", value: String(row.ordersCount) },
        { name: "Баланс", value: String(row.balance) + " руб." },
        { name: "Реферальный код", value: row.referralCode },
      ],
    }),
  },
  {
    id: 2,
    name: "Дата регистрации",
    field: "createdAt",
    type: "datetime",
    width: "180px",
  },
  {
    id: 3,
    name: "Телефон",
    field: "phone",
    width: "180px",
    format: ({ row }) => newPhoneFormat(row.phone),
  },
  {
    id: 4,
    name: "Имя",
    field: "name",
  },
  {
    id: 5,
    name: "E-Mail",
    field: "email",
  },

  {
    id: 6,
    name: "",
    field: "actions",
    width: "80px",
    style: { textAlign: "center" },
  },
];
