
import { computed, defineComponent, ref, watch } from "vue";
import { format } from "date-fns";

import { BaseCheckbox, OptionType } from "@tager/admin-ui";

import { useCityId, useCityStore } from "@/store/city";

import { closeFullDay, closeTimeSlotsDay, openFullDay } from "../../requests";

import CustomMultiSelect from "./CustomMultiSelect.vue";

export default defineComponent({
  name: "CheckBoxBlock",
  components: { BaseCheckbox, CustomMultiSelect },
  props: {
    day: {
      type: Date,
      required: true,
    },
    dayParam: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const timeOptionList = computed<Array<OptionType>>(() => [
      {
        value: "08:00",
        label: "08:00",
      },
      {
        value: "08:30",
        label: "08:30",
      },
      {
        value: "09:00",
        label: "09:00",
      },
      {
        value: "09:30",
        label: "09:30",
      },
      {
        value: "10:00",
        label: "10:00",
      },
      {
        value: "10:30",
        label: "10:30",
      },
      {
        value: "11:00",
        label: "11:00",
      },
      {
        value: "11:30",
        label: "11:30",
      },
      {
        value: "12:00",
        label: "12:00",
      },
      {
        value: "12:30",
        label: "12:30",
      },
      {
        value: "13:00",
        label: "13:00",
      },
      {
        value: "13:30",
        label: "13:30",
      },
      {
        value: "14:00",
        label: "14:00",
      },
      {
        value: "14:30",
        label: "14:30",
      },
      {
        value: "15:00",
        label: "15:00",
      },
      {
        value: "15:30",
        label: "15:30",
      },
      {
        value: "16:00",
        label: "16:00",
      },
      {
        value: "16:30",
        label: "16:30",
      },
      {
        value: "17:00",
        label: "17:00",
      },
      {
        value: "17:30",
        label: "17:30",
      },
      {
        value: "18:00",
        label: "18:00",
      },
      {
        value: "18:30",
        label: "18:30",
      },
      {
        value: "19:00",
        label: "19:00",
      },
      {
        value: "19:30",
        label: "19:30",
      },
      {
        value: "20:00",
        label: "20:00",
      },
    ]);

    const closeTimeSlotsValues = ref<string[]>([]);

    const fullClose = ref<boolean>(false);
    const closeTimeSlots = ref<boolean>(false);
    const newDateFormat = ref<string>(format(props.day, "yyyy-MM-dd"));

    watch(props, () => {
      fullClose.value = !!props.dayParam.closed;
      closeTimeSlots.value = props.dayParam.closedTimeSlots
        ? !!props.dayParam.closedTimeSlots.length
        : false;
      closeTimeSlotsValues.value = props.dayParam.closedTimeSlots;
      newDateFormat.value = format(props.day, "yyyy-MM-dd");
    });

    function closeOrOpenFull() {
      const cityId = useCityId();
      if (cityId) {
        if (fullClose.value) {
          openFullDay(cityId.value, newDateFormat.value);
        } else {
          closeFullDay(cityId.value, newDateFormat.value);
          closeTimeSlots.value = false;
          closeTimeSlotsValues.value = [];
        }
      }
    }

    function openDay() {
      const cityId = useCityId();
      if (cityId) {
        if (!closeTimeSlots.value) {
          openFullDay(cityId.value, newDateFormat.value);
          closeTimeSlotsValues.value = [];
        }
        fullClose.value = false;
      }
    }

    function closeOrOpenTimeSlot(newSelectedOptions: Array<string>) {
      const cityId = useCityId();
      if (Array.isArray(newSelectedOptions) && cityId) {
        closeTimeSlotsValues.value = newSelectedOptions;

        closeTimeSlotsDay(newDateFormat.value, {
          times: closeTimeSlotsValues.value,
          city: cityId.value,
        });
      }
    }

    return {
      fullClose,
      closeTimeSlots,
      closeOrOpenFull,
      timeOptionList,
      closeTimeSlotsValues,
      closeOrOpenTimeSlot,
      newDateFormat,
      openDay,
    };
  },
});
