
import { computed, defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { FormField, FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";
import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";

import {
  getAdditionalServiceCategoryListUrl,
  getServiceCategoryFormUrl,
  getServiceCategoryListUrl,
} from "@/modules/services";
import { FullServiceCategoryType } from "@/types";
import { getServiceCategory } from "@/requests";
import { servicesMultipleChange } from "@/modules/services/services";

import {
  convertFormValuesToPayload,
  FormValues,
  getInitialFormValues,
} from "./ServiceCategoryMultipleChange.helpers";

export default defineComponent({
  name: "ServiceCategoryMultipleChange",
  components: { FormField, Page, FormFooter },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const categoryId = computed<number>(() => +route.params.categoryId);

    const values = ref<FormValues>(getInitialFormValues());
    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    const [
      fetchServiceCategory,
      { data: serviceCategory, loading: isServiceCategoryLoading },
    ] = useResource<Nullable<FullServiceCategoryType>>({
      fetchResource: () => getServiceCategory(categoryId.value),
      initialValue: null,
      resourceName: "serviceCategory",
    });

    onMounted(() => {
      fetchServiceCategory();
    });

    const isContentLoading = computed<boolean>(
      () => isServiceCategoryLoading.value
    );

    function submitForm() {
      isSubmitting.value = true;

      servicesMultipleChange(
        categoryId.value,
        convertFormValuesToPayload(values.value)
      )
        .then(() => {
          errors.value = {};

          router.push(
            getAdditionalServiceCategoryListUrl({
              serviceCategoryId: categoryId.value,
            })
          );

          toast.show({
            variant: "success",
            title: "Success",
            body: "Параметры успешно изменены",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: `Ошибка применения параметров`,
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    return {
      values,
      errors,
      isSubmitting,
      serviceCategory,
      isContentLoading,
      submitForm,
      backButtonUrl: getServiceCategoryListUrl(),
    };
  },
});
