
import { defineComponent, ref, watch } from "vue";

import { BaseButton, BaseInput, Spinner } from "@tager/admin-ui";

import { CloseIcon } from "@/assets/icons";
import { generateReport, sendReport } from "@/modules/workers/requests";

export default defineComponent({
  name: "Report",
  components: { Spinner, BaseInput, BaseButton, CloseIcon },
  props: {
    from: {
      type: String,
      required: true,
    },
    to: {
      type: String,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    email: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const isOpenFields = ref<boolean>(false);
    const isOpenSendReport = ref<boolean>(false);
    const isSuccessReport = ref<boolean>(false);
    const firstLoader = ref<boolean>(false);
    const secondLoader = ref<boolean>(false);
    const values = ref<{ from: string; to: string }>({
      from: props.from,
      to: props.to,
    });

    const reportId = ref<number>(0);
    watch(props, () => {
      console.log(props.from);
      values.value = {
        from: props.from,
        to: props.to,
      };
    });

    function toggleShowFields() {
      isOpenFields.value = !isOpenFields.value;
    }

    function toggleShowSendReport(show: boolean) {
      isOpenSendReport.value = show;
    }

    function toggleShowSuccessReport(show: boolean) {
      isSuccessReport.value = show;
    }

    function redirect(url: string) {
      window.open(url, "_blank");
    }

    function closeReportBlock() {
      toggleShowFields();
      toggleShowSendReport(false);
      toggleShowSuccessReport(false);
      values.value.from = "";
      values.value.to = "";
    }

    function createPDFReport(id: number, values: { from: string; to: string }) {
      firstLoader.value = !firstLoader.value;
      generateReport(id, { ...values }).then((response) => {
        redirect(response.data.fileUrl);
        toggleShowSendReport(true);
        toggleShowSuccessReport(false);
        firstLoader.value = !firstLoader.value;
        reportId.value = response.data.id;
      });
    }

    function sendReportByEmail(id: number) {
      secondLoader.value = !secondLoader.value;
      sendReport(id, reportId.value).then(() => {
        toggleShowSuccessReport(true);
        secondLoader.value = !secondLoader.value;
      });
    }

    function isActiveReportButton(): boolean {
      return (values.value.from &&
        values.value.to &&
        values.value.from < values.value.to) as boolean;
    }

    watch(values.value, () => {
      toggleShowSendReport(false);
      toggleShowSuccessReport(false);
    });

    return {
      isOpenFields,
      isOpenSendReport,
      isSuccessReport,
      toggleShowFields,
      toggleShowSendReport,
      toggleShowSuccessReport,
      generateReport,
      sendReport,
      values,
      redirect,
      closeReportBlock,
      createPDFReport,
      reportId,
      sendReportByEmail,
      firstLoader,
      secondLoader,
      isActiveReportButton,
    };
  },
});
