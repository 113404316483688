import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  FilterTagType,
  getFilterParamAsNumber,
  getFilterParamAsString,
  getFilterParams,
  OptionType,
  type AdvancedSearchDateFilterType,
  useAdvancedSearchDateFilter,
} from "@tager/admin-ui";
import { isNotNullish, Nullable, useResource } from "@tager/admin-services";

import { getAllWorkersList } from "@/modules/workers/requests";
import { Scope } from "@/rbac/Scope";

interface Params {
  fetchEntityList: () => void;
}

enum OrdersFilterApproved {
  Approved = "approved",
  ApprovedByManager = "approved-manager",
  ApprovedByUser = "approved-user",
  NotApproved = "not-approved",
}

export const ordersFilterApprovedOptions: Array<
  OptionType<Nullable<OrdersFilterApproved>>
> = [
  { value: null, label: "Все" },
  { value: OrdersFilterApproved.Approved, label: "Подтвержден" },
  {
    value: OrdersFilterApproved.ApprovedByManager,
    label: "Подтвержден менеджером",
  },
  { value: OrdersFilterApproved.ApprovedByUser, label: "Подтвержден клиентом" },
  { value: OrdersFilterApproved.NotApproved, label: "Не подтвержден" },
];

interface State {
  dateFilter: Ref<AdvancedSearchDateFilterType>;

  approvedFilter: Ref<OptionType<OrdersFilterApproved | null>>;
  workerFilter: Ref<Nullable<OptionType<number>>>;
  workerFilterOptions: Ref<Array<OptionType<number>>>;
  promocodeFilter: Ref<string>;
  noSmsFilter: Ref<boolean>;
  noWorkersFilter: Ref<boolean>;

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;
  tagRemovalHandler(event: FilterTagType): void;

  load: () => void;
  dataLoading: Ref<boolean>;
}

enum FilterTypes {
  Approved = "approved",
  Promocode = "promocode",
  NoSms = "no-sms",
  NoWorkers = "no-workers",
  Worker = "worker",
}

export function useAdvancedSearch({ fetchEntityList }: Params): State {
  const route = useRoute();
  const router = useRouter();

  const [
    fetchWorkers,
    { data: workersList, loading: workerFilterOptionsLoading },
  ] = useResource({
    fetchResource: () => getAllWorkersList(),
    initialValue: [],
    resourceName: "Workers List",
  });

  const load = () => fetchWorkers();

  const { value: dateFilter, ...dateFilterData } = useAdvancedSearchDateFilter({
    label: "Дата уборки",
  });

  const initialPromocodeFilter = computed<string>(
    () => getFilterParamAsString(route.query, FilterTypes.Promocode) ?? ""
  );
  const promocodeFilter = ref<string>(initialPromocodeFilter.value);
  watch(initialPromocodeFilter, () => {
    promocodeFilter.value = initialPromocodeFilter.value;
  });

  const initialApprovedFilter = computed<
    OptionType<OrdersFilterApproved | null>
  >(
    () =>
      ordersFilterApprovedOptions.find(
        (item) =>
          item.value ===
          getFilterParamAsString(route.query, FilterTypes.Approved)
      ) ?? ordersFilterApprovedOptions[0]
  );
  const approvedFilter = ref<OptionType<OrdersFilterApproved | null>>(
    initialApprovedFilter.value
  );
  watch(initialApprovedFilter, () => {
    approvedFilter.value = initialApprovedFilter.value;
  });

  const workerFilterOptions = computed<Array<OptionType<number>>>(() => {
    return workersList.value.map((item) => {
      return {
        value: item.id,
        label:
          "#" +
          item.worker.number +
          " - " +
          item.worker.name +
          " " +
          item.worker.surname +
          (item.worker.isArchive ? " (Архивный)" : ""),
      };
    });
  });

  const initialWorkerFilter = computed<Nullable<OptionType<number>>>(
    () =>
      workerFilterOptions.value.find(
        (item) =>
          item.value === getFilterParamAsNumber(route.query, FilterTypes.Worker)
      ) ?? null
  );
  const workerFilter = ref<Nullable<OptionType<number>>>(
    initialWorkerFilter.value
  );
  watch(initialWorkerFilter, () => {
    workerFilter.value = initialWorkerFilter.value;
  });

  const initialNoSmsFilter = computed<boolean>(() => {
    return getFilterParamAsString(route.query, FilterTypes.NoSms) === "1";
  });

  const noSmsFilter = ref<boolean>(initialNoSmsFilter.value);
  watch(initialNoSmsFilter, () => {
    noSmsFilter.value = initialNoSmsFilter.value;
  });

  const initialNoWorkersFilter = computed<boolean>(() => {
    return getFilterParamAsString(route.query, FilterTypes.NoWorkers) === "1";
  });

  const noWorkersFilter = ref<boolean>(initialNoWorkersFilter.value);
  watch(initialNoWorkersFilter, () => {
    noWorkersFilter.value = initialNoWorkersFilter.value;
  });

  /** Params **/

  const filterParams = computed(() => {
    return getFilterParams({
      ...dateFilterData.filterParams(),
      [FilterTypes.Promocode]: promocodeFilter.value || "",
      [FilterTypes.NoSms]: noSmsFilter.value ? "1" : "0",
      [FilterTypes.NoWorkers]: noWorkersFilter.value ? "1" : "0",
      [FilterTypes.Approved]:
        (approvedFilter.value || ordersFilterApprovedOptions[0]).value || "",
      [FilterTypes.Worker]: workerFilter.value
        ? String(workerFilter.value.value)
        : "",
    });
  });

  const tagRemovalHandler = (event: FilterTagType): void => {
    dateFilterData.tagRemovalHandler(event);

    if (event.name === FilterTypes.Approved) {
      approvedFilter.value = ordersFilterApprovedOptions[0];
    }
    if (event.name === FilterTypes.Worker) {
      workerFilter.value = null;
    }
    if (event.name === FilterTypes.Promocode) {
      promocodeFilter.value = "";
    }
    if (event.name === FilterTypes.NoSms) {
      noSmsFilter.value = false;
    }
    if (event.name === FilterTypes.NoWorkers) {
      noWorkersFilter.value = false;
    }
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      ...dateFilterData.tags(),
      promocodeFilter.value
        ? {
            value: promocodeFilter.value,
            label: promocodeFilter.value,
            name: FilterTypes.Promocode,
            title: "Промокод",
          }
        : null,
      approvedFilter.value && approvedFilter.value.value
        ? {
            value: approvedFilter.value.value || "",
            label: approvedFilter.value.label,
            name: FilterTypes.Approved,
            title: "Подтвержден",
          }
        : null,
      workerFilter.value && workerFilter.value.value
        ? {
            value: String(workerFilter.value.value),
            label: workerFilter.value.label,
            name: FilterTypes.Worker,
            title: "Клинер",
          }
        : null,
      noSmsFilter.value
        ? {
            value: String(noSmsFilter.value),
            label: "Да",
            name: FilterTypes.NoSms,
            title: "Без SMS",
          }
        : null,
      noWorkersFilter.value
        ? {
            value: String(noWorkersFilter.value),
            label: "Да",
            name: FilterTypes.NoWorkers,
            title: "Без клинеров",
          }
        : null,
    ].filter(isNotNullish)
  );

  watch([filterParams, workerFilterOptionsLoading], () => {
    if (workerFilterOptionsLoading.value) return;

    const newQuery = {
      ...pick(route.query, ["query", "pageNumber", "sort"]),
      ...filterParams.value,
    };

    if (!isEqual(route.query, newQuery)) {
      router.replace({ query: newQuery });
    } else {
      fetchEntityList();
    }
  });

  return {
    dateFilter,
    noSmsFilter,
    noWorkersFilter,
    promocodeFilter,
    approvedFilter,
    workerFilter,
    workerFilterOptions,
    dataLoading: workerFilterOptionsLoading,
    filterParams,
    tags,
    tagRemovalHandler,

    load,
  };
}
