import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_NotesListFilter = _resolveComponent("NotesListFilter")!
  const _component_UserTableCell = _resolveComponent("UserTableCell")!
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Заметки",
    "header-buttons": [
      {
        text: 'Новая заметка',
        href: _ctx.getNotesCreateUrl(),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        "row-css-class": _ctx.rowCssClass,
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_NotesListFilter, {
            value: _ctx.filterQuery,
            "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.filterQuery) = $event)),
            "fetch-data": _ctx.fetchEntityList
          }, null, 8, ["value", "fetch-data"])
        ]),
        "cell(user)": _withCtx(({ row }) => [
          (row.user)
            ? (_openBlock(), _createBlock(_component_UserTableCell, {
                key: 0,
                id: row.user.id,
                name: row.user.name,
                phone: row.user.phone
              }, null, 8, ["id", "name", "phone"]))
            : _createCommentVNode("", true)
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "View",
              href: _ctx.getNotesUpdateUrl(row.id)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EyeIcon)
              ]),
              _: 2
            }, 1032, ["href"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "row-css-class", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}