
import { defineComponent, onMounted, watch, ref } from "vue";

import { DataTable, getFilterParams } from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";
import { useUserPermission } from "@tager/admin-services";

import { getOrdersCreateUrl } from "@/modules/orders";
import { OrderInterface } from "@/modules/orders/typings";
import { useCityId } from "@/store/city";
import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import PageRegional from "@/pages/PageRegional.vue";
import OrdersTableCellWorkers from "@/modules/orders/components/OrdersTableCellWorkers.vue";
import AddressTableCell from "@/components/table-cells/AddressTableCell.vue";
import OrdersTableCellSubscription from "@/modules/orders/components/OrdersTableCellSubscription.vue";
import { getOrders } from "@/modules/orders/services";
import { Scope } from "@/rbac/Scope";

import OrdersListActiveFilter from "./OrdersList.filter.vue";
import { getColumnDefs, SORT_OPTIONS } from "./OrdersList.helpers";

export default defineComponent({
  name: "OrdersList",
  components: {
    OrdersListActiveFilter,
    OrdersTableCellSubscription,
    AddressTableCell,
    OrdersTableCellWorkers,
    PageRegional,
    DataTable,
    UserTableCell,
  },
  props: {
    title: {
      type: String,
      required: false,
      default: "",
    },
    orderStatus: {
      type: String,
      required: false,
      default: "",
    },
  },
  setup(props) {
    const filterParams = ref<Record<string, string | string[]>>({});
    const searchLoading = ref<boolean>(true);

    const cityId = useCityId();

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<OrderInterface>({
      fetchEntityList: (params) => {
        return getOrders({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort || "",
          ...Object.assign(
            getFilterParams({
              status: props.orderStatus || "",
              city: cityId.value,
            }),
            filterParams.value
          ),
        });
      },
      initialValue: [],
      resourceName: props.title,
      defaultSort: SORT_OPTIONS[0].value,
    });

    watch([cityId], () => {
      fetchEntityList();
    });

    onMounted(() => {
      fetchEntityList();
    });

    const rowCssClass = (row: OrderInterface) =>
      !row.accepted
        ? "_not-accepted"
        : row.acceptedBy === "USER"
        ? "_accepted-user"
        : "";

    const canCreateOrder = useUserPermission(Scope.OrdersCreate);

    return {
      columnDefs: getColumnDefs(),
      sortOptions: SORT_OPTIONS,
      sortValue: sort,

      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      rowCssClass,
      getOrdersCreateUrl,

      fetchEntityList,
      searchLoading,
      filterParams,

      canCreateOrder,
    };
  },
});
