
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProgressBar",
  props: {
    percent: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: "",
    },
  },
});
