
import { defineComponent, PropType, ref } from "vue";

import { FormField, FormFieldSelect } from "@tager/admin-ui";

import { AddressFormFields } from "@/modules/orders/typings";
import { useCities } from "@/modules/cities";

export default defineComponent({
  name: "AddressForm",
  components: {
    FormField,
    FormFieldSelect,
  },
  props: {
    disabled: Boolean,
    value: { type: Object as PropType<AddressFormFields>, required: true },
  },
  emits: ["change"],

  setup(props, { emit }) {
    const { options: citiesOptions } = useCities();

    const valueRef = ref<AddressFormFields>(props.value);

    const onChange = () => {
      emit("change", valueRef.value);
    };

    return { valueRef, citiesOptions, onChange };
  },
});
