
import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch,
  onMounted,
} from "vue";
import { clone } from "lodash";

import { BaseInput } from "@tager/admin-ui";

import {
  PrimaryServiceCategory,
  SecondaryService,
} from "@/modules/orders/views/orders-create/typings";
import { minutesToTimeString } from "@/utils/formatter";
import { OrderType } from "@/modules/orders/typings";

import SelectServicesServiceInput from "./components/SelectServicesServiceInput.vue";

export default defineComponent({
  name: "SelectServices",
  components: {
    BaseInput,
    SelectServicesServiceInput,
  },
  props: {
    type: {
      type: String as PropType<OrderType>,
      required: true,
    },
    primary: {
      type: Array as PropType<Array<PrimaryServiceCategory>>,
      required: true,
    },
    secondary: {
      type: Array as PropType<Array<SecondaryService>>,
      required: true,
    },
    primaryValue: {
      type: Array as PropType<number[]>,
      default: () => [],
      required: false,
    },
    secondaryValue: {
      type: Array as PropType<
        Array<{
          id: number;
          value: number | boolean;
        }>
      >,
      required: false,
      default: () => [],
    },
    deliveryKeysAddress: {
      type: String,
      default: "",
      required: false,
    },
  },
  emits: ["change"],
  setup(props, { emit }) {
    const primaryServicesValues = ref<Array<number | null>>([]);
    const secondaryServicesValues = ref<Array<boolean | number>>([]);
    const deliveryKeysAddressValue = ref<string>("");

    const primaryServices = computed<Array<PrimaryServiceCategory>>(() => {
      const systemNames =
        props.type === "ROOMS"
          ? ["ROOMS", "BATHROOMS"]
          : ["WINDOWS", "BALCONIES"];

      return props.primary.filter((item) =>
        systemNames.includes(item.systemName)
      );
    });

    const updateValues = () => {
      const primaryResult: Array<null | number> = [];

      primaryServices.value.forEach((primaryServiceCategory, ind) => {
        primaryResult[ind] = null;

        primaryServiceCategory.services.map((service) => {
          if (props.primaryValue?.includes(service.id)) {
            primaryResult[ind] = service.id;
          }
        });
      });

      primaryServicesValues.value = primaryResult;

      secondaryServicesValues.value = [];
      if (props.type === "ROOMS") {
        const secondaryValue: Array<boolean | number> = [];

        props.secondary.forEach((service, ind) => {
          const isBoolean =
            service.mode === "DELIVERY_KEYS" || service.mode === "TRUE_FALSE";

          const foundInValue = props.secondaryValue.find(
            (item) => item.id === service.id
          );

          secondaryValue[ind] = foundInValue?.value || (isBoolean ? false : 0);
        });

        secondaryServicesValues.value = secondaryValue;
      }

      deliveryKeysAddressValue.value = props.deliveryKeysAddress;
    };

    onMounted(updateValues);

    watch([() => props.type, () => props.primary], () => {
      primaryServicesValues.value = [null, null];
      emit("change", null);
    });

    watch(
      () => props.secondary,
      () => {
        secondaryServicesValues.value =
          props.secondary?.map((item) =>
            item.mode === "TRUE_FALSE" || item.mode === "DELIVERY_KEYS"
              ? false
              : 0
          ) || [];
      }
    );

    const primaryServicesData = computed<
      Array<{
        price: number;
        minutes: number;
      }>
    >(() => {
      return (
        primaryServices.value.map((item, index) => {
          const found = item.services.find(
            (item) => item.id === primaryServicesValues.value[index]
          );

          return {
            price: found?.price || 0,
            minutes: found?.minutes || 0,
          };
        }) || []
      );
    });

    const secondaryServicesData = computed<
      Array<{
        price: number;
        minutes: number;
      }>
    >(() => {
      return (
        props.secondary?.map((item, ind) => {
          const value: number | boolean = secondaryServicesValues.value[ind];

          if (item.mode === "TRUE_FALSE" || item.mode === "DELIVERY_KEYS") {
            return {
              price: value ? item.price : 0,
              minutes: value ? item.minutes : 0,
            };
          } else if (item.mode === "QUANTITY") {
            return {
              price: value ? +value * item.price : 0,
              minutes: value ? +value * item.minutes : 0,
            };
          } else {
            return {
              price: value ? (+value / item.step) * item.price : 0,
              minutes: value ? (+value / item.step) * item.minutes : 0,
            };
          }
        }) || []
      );
    });

    const secondaryServicesVisible = computed<boolean>(() => {
      if (
        props.type !== "ROOMS" ||
        !props.primary ||
        !props.secondary ||
        props.secondary.length === 0
      )
        return false;

      return (
        primaryServicesValues.value.length === 2 &&
        primaryServicesValues.value.filter((item: any) => item === null)
          .length === 0
      );
    });

    const onServiceInputChange = (
      type: "primary" | "secondary",
      ind: number,
      value: boolean | number
    ) => {
      if (type === "primary") {
        const newPrimaryValue = clone(primaryServicesValues.value);
        newPrimaryValue[ind] = +value;
        primaryServicesValues.value = newPrimaryValue;
      } else if (type === "secondary") {
        const newValue = clone(secondaryServicesValues.value);
        newValue[ind] = value;
        secondaryServicesValues.value = newValue;
      }
    };

    watch(
      [
        primaryServicesValues,
        secondaryServicesValues,
        deliveryKeysAddressValue,
      ],
      () => {
        if (
          primaryServicesValues.value.filter((item: any) => item === null)
            .length === 0 ||
          (props.type === "WINDOWS" && primaryServicesValues.value[0])
        ) {
          const payload = {
            primary: primaryServices.value?.map(
              (category, ind) => primaryServicesValues.value[ind] ?? null
            ),
            secondary: Object.keys(secondaryServicesValues.value)
              .filter((serviceId) => secondaryServicesValues.value[+serviceId])
              .map((serviceId) => {
                return {
                  id: props.secondary?.[+serviceId].id,
                  value: secondaryServicesValues.value[+serviceId],
                };
              }),
            deliveryKeysAddress: deliveryKeysAddressValue.value,
          };

          emit("change", payload);
        } else {
          emit("change", null);
        }
      }
    );

    return {
      deliveryKeysAddressValue,

      primaryServices,
      primaryServicesValues,
      primaryServicesData,
      secondaryServicesVisible,
      secondaryServicesValues,
      secondaryServicesData,
      minutesToTimeString,
      onServiceInputChange,
    };
  },
});
