import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/router/paths";
import { LINKS } from "@/router/links";

import StatsView from "./StatsView/StatsView.vue";

export const STATS_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.STATS,
  component: StatsView,
  name: "Статистика",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.STATS],
  },
};
