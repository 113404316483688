import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "checkboxes" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedSearchDateFilter = _resolveComponent("AdvancedSearchDateFilter")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!

  return (_openBlock(), _createBlock(_component_AdvancedSearch, {
    tags: _ctx.tags,
    "onClick:tag": _ctx.tagRemovalHandler
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AdvancedSearchDateFilter, {
        filter: _ctx.dateFilter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateFilter) = $event)),
        label: "Дата уборки"
      }, null, 8, ["filter"]),
      _createElementVNode("div", _hoisted_1, [
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.approvedFilter,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.approvedFilter) = $event)),
          label: "Подтвержден:",
          name: "approvedFilter",
          searchable: false,
          options: _ctx.ordersFilterApprovedOptions,
          "no-error-padding": true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.workerFilter,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.workerFilter) = $event)),
          label: "Клинер:",
          name: "workerFilter",
          searchable: true,
          options: _ctx.workerFilterOptions,
          "no-error-padding": true
        }, null, 8, ["value", "options"]),
        _createVNode(_component_FormField, {
          value: _ctx.promocodeFilter,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.promocodeFilter) = $event)),
          label: "Промокод",
          name: "promocodeFilter",
          type: "text",
          "no-error-padding": true
        }, null, 8, ["value"]),
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_FormFieldCheckbox, {
            checked: _ctx.noSmsFilter,
            "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.noSmsFilter) = $event)),
            label: "Без SMS",
            "no-error-padding": true
          }, null, 8, ["checked"]),
          _createVNode(_component_FormFieldCheckbox, {
            checked: _ctx.noWorkersFilter,
            "onUpdate:checked": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.noWorkersFilter) = $event)),
            label: "Без клинеров",
            "no-error-padding": true
          }, null, 8, ["checked"])
        ])
      ])
    ]),
    _: 1
  }, 8, ["tags", "onClick:tag"]))
}