
import { defineComponent, PropType, ref, watch } from "vue";

import { Spinner, OptionType, FormFieldSelect } from "@tager/admin-ui";

import {
  PrimaryServiceCategory,
  SecondaryService,
} from "@/modules/orders/views/orders-create/typings";
import { orderTypeOptions } from "@/modules/orders/views/orders-create/OrdersCreate.helpers";
import SelectServices from "@/modules/orders/components/SelectServices/SelectServices.vue";
import { orderHouseTypeOptions } from "@/enums/OrderHouseType";

export default defineComponent({
  name: "OrdersCreateServices",
  components: {
    Spinner,
    FormFieldSelect,
    SelectServices,
  },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    primary: {
      type: Array as PropType<Array<PrimaryServiceCategory>>,
      required: true,
    },
    secondary: {
      type: Array as PropType<Array<SecondaryService>>,
      required: true,
    },
  },
  emits: ["change", "change:house_type"],
  setup(_props, { emit }) {
    const typeValue = ref<OptionType>();
    const houseTypeValue = ref<OptionType>(orderHouseTypeOptions[0]);

    const onServicesChange = (value: any) => {
      emit("change", value);
    };

    watch(houseTypeValue, () => {
      emit("change:house_type", houseTypeValue.value.value);
    });

    return {
      typeValue,
      houseTypeValue,
      orderTypeOptions,
      houseTypeOptions: orderHouseTypeOptions,
      onServicesChange,
    };
  },
});
