
import { defineComponent } from "vue";

import { formatDateTime } from "@tager/admin-ui";

import { getOrderUrl } from "@/modules/orders";

export default defineComponent({
  name: "OrderTableCell",
  props: {
    id: {
      type: Number,
      required: true,
    },
    datetime: {
      type: String,
      default: "",
    },
    number: {
      type: Number,
      default: null,
    },
  },
  setup() {
    return {
      getOrderUrl,
      formatDateTime,
    };
  },
});
