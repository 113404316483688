import { format } from "date-fns";

import { createId, FileType, Nullable } from "@tager/admin-services";
import { SingleFileInputValueType } from "@tager/admin-ui";

export function dateToString(date: Date): string {
  return date.toISOString().slice(0, 10);
}

export function createFileInputValue(
  file: Nullable<FileType>
): Nullable<SingleFileInputValueType> {
  return file
    ? {
        id: createId(),
        file,
        caption: null,
      }
    : null;
}

export function newDateFormat(date: string): string {
  return format(new Date(date), "dd.MM.yyyy");
}

export function getTime(date: string) {
  const newDate = new Date(date);

  const currHours = newDate.getHours();

  const currMinutes = newDate.getMinutes();

  return (
    (currHours < 10 ? "0" + currHours : currHours) +
    ":" +
    (currMinutes < 10 ? "0" + currMinutes : currMinutes)
  );
}

export function newPhoneFormat(phone: string) {
  const splitPhone = phone.split("");
  splitPhone.splice(0, 0, "+");
  splitPhone.splice(4, 0, "(");
  splitPhone.splice(7, 0, ") ");
  splitPhone.splice(11, 0, "-");
  splitPhone.splice(14, 0, "-");
  return splitPhone.join("");
}

export function getWebsiteOrigin(): string {
  return process.env.VUE_APP_WEBSITE_URL || window.location.origin;
}
