import { resolveComponent as _resolveComponent, createVNode as _createVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-302ae0aa"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "submit-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Комментарий менеджера на заказ",
    value: _ctx.value || '-',
    "with-edit": _ctx.withEdit,
    "edit-active": _ctx.formOpened,
    onEdit: _ctx.onStart
  }, {
    edit: _withCtx(() => [
      _createElementVNode("form", {
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.formValue,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.formValue) = $event)),
          disabled: _ctx.loading,
          name: "comment",
          type: "textarea"
        }, null, 8, ["value", "disabled"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_BaseButton, {
            variant: "secondary",
            disabled: _ctx.loading,
            onClick: _ctx.onCancel
          }, {
            default: _withCtx(() => [
              _createTextVNode("Отменить")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]),
          _createVNode(_component_BaseButton, {
            variant: "primary",
            type: "submit",
            loading: _ctx.loading
          }, {
            default: _withCtx(() => [
              _createTextVNode("Применить")
            ]),
            _: 1
          }, 8, ["loading"])
        ])
      ], 32)
    ]),
    _: 1
  }, 8, ["value", "with-edit", "edit-active", "onEdit"]))
}