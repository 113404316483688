import { request, ResponseBody } from "@tager/admin-services";

import { CalendarType } from "@/modules/calendar/types";

export function getDayList(
  city: number,
  dateFrom: string,
  dateTo: string
): Promise<ResponseBody<Array<CalendarType>>> {
  return request.get({
    path: `/admin/calendar`,
    params: { city, dateFrom, dateTo },
  });
}

export function updateCoefficientRequest(
  date: string,
  payload: { city: number | null; value: number }
): Promise<ResponseBody<Array<CalendarType>>> {
  return request.post({
    path: `/admin/calendar/${date}/coefficient`,
    body: payload,
  });
}

export function closeFullDay(
  city: number,
  date: string
): Promise<ResponseBody<Array<CalendarType>>> {
  return request.post({
    path: `/admin/calendar/${date}/close`,
    body: { city },
  });
}

export function closeTimeSlotsDay(
  date: string,
  payload: { times: Array<string>; city: number }
): Promise<ResponseBody<Array<CalendarType>>> {
  return request.post({
    path: `/admin/calendar/${date}/close`,
    body: payload,
  });
}

export function openFullDay(
  city: number,
  date: string
): Promise<ResponseBody<Array<CalendarType>>> {
  return request.post({
    path: `/admin/calendar/${date}/open`,
    body: { city },
  });
}
