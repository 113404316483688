
import { computed, defineComponent, onMounted, PropType, watch } from "vue";

import { DataTable, useDataTable } from "@tager/admin-ui";
import { ResponseBody } from "@tager/admin-services";

import { OrderInterface } from "@/modules/orders/typings";
import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import OrdersTableCellWorkers from "@/modules/orders/components/OrdersTableCellWorkers.vue";
import AddressTableCell from "@/components/table-cells/AddressTableCell.vue";
import OrdersTableCellSubscription from "@/modules/orders/components/OrdersTableCellSubscription.vue";

import { getColumnDefs } from "./OrdersList.helpers";

export default defineComponent({
  name: "OrdersListTable",
  components: {
    DataTable,
    OrdersTableCellSubscription,
    AddressTableCell,
    OrdersTableCellWorkers,
    UserTableCell,
  },
  props: {
    fetchOrders: {
      type: Function as PropType<
        (requestParams: any) => Promise<ResponseBody<OrderInterface[]>>
      >,
      required: true,
    },
  },
  setup(props) {
    const rowCssClass = (row: OrderInterface) =>
      !row.accepted
        ? "_not-accepted"
        : row.acceptedBy === "USER"
        ? "_accepted-user"
        : "";

    const { isLoading, fetchEntityList, rowData, errorMessage } =
      useDataTable<OrderInterface>({
        fetchEntityList: () => props.fetchOrders({}),
        initialValue: [],
        resourceName: "Список заказов пользователя",
      });

    onMounted(() => {
      fetchEntityList();
    });

    watch(
      computed(() => props.fetchOrders),
      () => {
        fetchEntityList();
      }
    );

    return {
      columnDefs: getColumnDefs(),
      rowCssClass,
      isLoading,
      rowData,
      errorMessage,
    };
  },
});
