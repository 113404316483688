import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CountButton = _resolveComponent("CountButton")!
  const _component_RouterLink = _resolveComponent("RouterLink")!
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_BaseTable = _resolveComponent("BaseTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Основые услуги",
    "header-buttons": [
      {
        text: 'Добавить категорию',
        href: _ctx.getServiceCategoryFormUrl({
          serviceCategoryId: 'create-category',
        }),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_BaseTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        enumerable: true
      }, {
        "cell(services)": _withCtx(({ row }) => [
          _createVNode(_component_RouterLink, {
            to: {
              path: _ctx.getAdditionalServiceCategoryListUrl({
                serviceCategoryId: row.id,
              }),
            }
          }, {
            default: _withCtx(() => [
              _createVNode(_component_CountButton, {
                variant: "outline-secondary",
                count: row.servicesCount
              }, {
                default: _withCtx(() => [
                  _createTextVNode(" Услуги ")
                ]),
                _: 2
              }, 1032, ["count"])
            ]),
            _: 2
          }, 1032, ["to"])
        ]),
        "cell(actions)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Edit",
              disabled: _ctx.isBusy(row.id),
              href: _ctx.getServiceCategoryFormUrl({ serviceCategoryId: row.id })
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EditIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "href"]),
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Delete",
              disabled: _ctx.isBusy(row.id) || !row.canDelete,
              onClick: ($event: any) => (_ctx.handleServiceCategoryDelete(row.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeleteIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}