import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-20162cb4"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-table-cell" }
const _hoisted_2 = { class: "order-table-cell__name" }
const _hoisted_3 = {
  key: 0,
  class: "order-table-cell__number"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_router_link, {
      to: _ctx.getOrderUrl(_ctx.id),
      class: "order-table-cell__id"
    }, {
      default: _withCtx(() => [
        _createTextVNode(" № " + _toDisplayString(_ctx.id), 1)
      ]),
      _: 1
    }, 8, ["to"]),
    _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.formatDateTime(new Date(_ctx.datetime))), 1),
    (_ctx.number)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, "Номер " + _toDisplayString(_ctx.number), 1))
      : _createCommentVNode("", true)
  ]))
}