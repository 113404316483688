
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineComponent, onMounted, ref, watch, computed } from "vue";
import { format, lastDayOfMonth, startOfMonth, subMonths } from "date-fns";

import { useResource } from "@tager/admin-services";

import { newDateFormat } from "@/utils/common";
import { useActiveCity } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";

import { OrderType } from "./typings";
import { getOrderList } from "./requests";
import PeriodSelect from "./components/PeriodSelect.vue";
import Map from "./components/Map.vue";

export default defineComponent({
  name: "OrdersMap",
  components: { PageRegional, Map, PeriodSelect },
  setup() {
    const activeCity = useActiveCity();

    const selectedPeriod = ref<{ dateFrom: string; dateTo: string }>({
      dateFrom: format(startOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
      dateTo: format(lastDayOfMonth(subMonths(new Date(), 1)), "yyyy-MM-dd"),
    });

    onMounted(() => {
      if (activeCity.value) {
        fetchOrderList();
      }
    });

    const [fetchOrderList, { data: orderList, loading: isOrderListLoading }] =
      useResource<Array<OrderType>>({
        fetchResource: () =>
          getOrderList(
            selectedPeriod.value.dateFrom,
            selectedPeriod.value.dateTo,
            activeCity?.value.id || null
          ),
        initialValue: [],
        resourceName: "Order list",
      });

    watch([selectedPeriod, activeCity], () => fetchOrderList());

    function submit(value) {
      selectedPeriod.value = {
        dateFrom: value[0],
        dateTo: value[1],
      };
    }

    const pageTitle = computed<string>(() =>
      selectedPeriod.value
        ? "Карта заказов с " +
          newDateFormat(selectedPeriod.value.dateFrom) +
          " по " +
          newDateFormat(selectedPeriod.value.dateTo)
        : ""
    );

    return {
      orderList,
      isBusy: isOrderListLoading,
      submit,
      pageTitle,
      selectedPeriod,
      orders: orderList,
      activeCity,
    };
  },
});
