
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  isFirstDayOfMonth,
  isLastDayOfMonth,
  format,
  getMonth,
} from "date-fns";

import { ColumnDefinition, useDataTable, DataTable } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { getStatisticList, setWorkingDays } from "@/requests";
import { generateReport } from "@/modules/workers/requests";
import { StatsType, TimetableStatsType } from "@/types";
import { newDateFormat, newPhoneFormat } from "@/utils/common";
import { getWorkerFormUrl } from "@/utils/paths";
import CleanerWidget from "@/components/CleanerWidget.vue";
import DateRangeFilter, {
  DateRangeFilterSubmitEvent,
} from "@/components/DateRangeFilter.vue";
import PageRegional from "@/pages/PageRegional.vue";
import { useCityId } from "@/store/city";

const COLUMN_DEFS: Array<ColumnDefinition<TimetableStatsType>> = [
  {
    id: 1,
    name: "Клинер",
    field: "cleaner",
    headStyle: {
      width: "250px",
    },
    style: { maxWidth: "250px", padding: "0" },
  },
  {
    id: 2,
    name: "Кол-во заказов",
    field: "ordersCount",
    headStyle: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    style: {
      textAlign: "center",
      padding: "0",
    },
  },
  {
    id: 3,
    name: "Вознаграждение",
    field: "revenue",
    headStyle: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    style: {
      textAlign: "center",
      padding: "0",
    },
  },
  {
    id: 4,
    name: "Вознаграждение за заказ",
    field: "averageSalary",
    headStyle: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    style: {
      textAlign: "center",
      padding: "0",
    },
  },
  {
    id: 5,
    name: "Вознаграждение в час",
    field: "salaryPerHour",
    headStyle: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    style: {
      textAlign: "center",
      padding: "0",
    },
  },
  {
    id: 6,
    name: "Рейтинг средний",
    field: "rating",
    headStyle: {
      textAlign: "center",
      whiteSpace: "nowrap",
    },
    style: {
      textAlign: "center",
      padding: "0",
    },
  },
];

export default defineComponent({
  name: "StatisticList",
  components: {
    PageRegional,
    DateRangeFilter,
    CleanerWidget,
    DataTable,
  },
  setup() {
    const city = useCityId();
    const dateFrom = ref<Date>(new Date());
    const dateTo = ref<Date>(new Date());

    const pageTitle = computed<string>(
      () =>
        "Статистика клинеров с " +
        (dateFrom.value ? format(dateFrom.value as Date, "dd.MM.yyyy") : "") +
        " по " +
        (dateFrom.value ? format(dateTo.value as Date, "dd.MM.yyyy") : "")
    );

    const {
      isLoading: isStatisticListLoading,
      rowData: statisticList,
      errorMessage: error,
      handleChange,
      fetchEntityList: fetchStatisticList,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<StatsType>({
      fetchEntityList: (params) =>
        getStatisticList(
          format(dateFrom.value as Date, "yyyy-MM-dd"),
          format(dateTo.value as Date, "yyyy-MM-dd"),
          city.value,
          {
            pageNumber: params.pageNumber,
            pageSize: params.pageSize,
          }
        ),
      initialValue: [],
      resourceName: "Statistic list",
    });

    onMounted(() => {
      if (city.value) {
        fetchStatisticList();
      }
    });

    watch([city], () => {
      if (city.value) {
        fetchStatisticList();
      }
    });

    function isBusy(): boolean {
      return isStatisticListLoading.value;
    }

    function shouldHighlightCell(
      workingDays: number,
      ordersCount: number
    ): boolean {
      const isFirstDay = isFirstDayOfMonth(dateFrom.value);
      const isLastDay = isLastDayOfMonth(dateTo.value);
      const isSameMonth = getMonth(dateFrom.value) === getMonth(dateTo.value);

      const isFilterIncludesExactlyOneMonth = isFirstDay && isLastDay;
      return (
        workingDays < ordersCount &&
        isFilterIncludesExactlyOneMonth &&
        isSameMonth
      );
    }

    function submit(range: DateRangeFilterSubmitEvent) {
      dateFrom.value = range.from;
      dateTo.value = range.to;
      fetchStatisticList();
    }

    const isRowDataLoading = computed<boolean>(
      () => isStatisticListLoading.value
    );

    return {
      columnDefs: COLUMN_DEFS,
      rowData: statisticList,
      isRowDataLoading,
      errorMessage: error,
      generateReport,
      fetchStatisticList,
      isBusy,
      newDateFormat,
      getWorkerFormUrl,
      setWorkingDays,
      pageTitle,
      newPhoneFormat,
      submit,
      shouldHighlightCell,

      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    };
  },
});
