
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { defineComponent } from "vue";

import { createOrderPlacemark, MapOrder } from "@/utils/orders-map";
import { CityInterface } from "@/modules/cities/typings";

import { OrderType } from "../typings";

let myMap;

const convertOrderToMapOrder = (order: OrderType): MapOrder => {
  return {
    id: order.id,
    datetime: order.datetime,
    address: order.address,
    latitude: order.latitude,
    longitude: order.longitude,
    workers: order.orderWorkers.map((item) => {
      return {
        id: item.worker.id,
        name: item.worker.surname + " " + item.worker.name,
        number: item.worker.number,
        distanceMeters: item.distance,
        isArchive: false,
      };
    }),
  };
};

export default defineComponent({
  name: "Map",
  components: {},
  props: {
    city: {
      type: CityInterface,
      default: null,
    },
    orders: {
      type: Array<OrderType>,
      default: [],
    },
  },
  setup(props) {
    console.log(props.city);
    function creatingPlaceMarks() {
      const clusterer: any = new window.ymaps.Clusterer({
        preset: "islands#darkGreenClusterIcons",
        minClusterSize: 3,
        clusterHideIconOnBalloonOpen: false,
      });

      const geoObjects: Array<window.ymaps.Placemark> = [];
      props.orders.forEach((order) => {
        geoObjects.push(createOrderPlacemark(convertOrderToMapOrder(order)));
      });

      clusterer.add(geoObjects);
      myMap.geoObjects.add(clusterer);
    }

    window.ymaps.ready(() => {
      if (!document.getElementById("map")) return;

      myMap = new window.ymaps.Map(
        "map",
        {
          center: [props.city.latitude, props.city.longitude],
          zoom: props.city.zoom,
          controls: ["zoomControl"],
        },
        {
          suppressMapOpenBlock: true,
        }
      );

      creatingPlaceMarks();
    });

    return {};
  },
});
