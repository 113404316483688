import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-1d775594"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "promo-code-form" }
const _hoisted_2 = { class: "promo-code-form__input" }
const _hoisted_3 = { class: "promo-code-form__button" }
const _hoisted_4 = {
  key: 1,
  class: "promo-code-result"
}
const _hoisted_5 = { class: "promo-code-result__inner" }
const _hoisted_6 = { class: "promo-code-result__code" }
const _hoisted_7 = { class: "promo-code-result__discount" }
const _hoisted_8 = { class: "promo-code-result__footer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_InputLabel = _resolveComponent("InputLabel")!

  return (!_ctx.model)
    ? (_openBlock(), _createElementBlock("form", {
        key: 0,
        onSubmit: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.onSubmit && _ctx.onSubmit(...args)))
      }, [
        _createElementVNode("div", _hoisted_1, [
          _createElementVNode("div", _hoisted_2, [
            _createVNode(_component_FormField, {
              value: _ctx.code,
              "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.code) = $event)),
              label: "Промокод",
              name: "promocode",
              error: _ctx.error,
              disabled: _ctx.isPromoCodeLoading
            }, null, 8, ["value", "error", "disabled"])
          ]),
          _createElementVNode("div", _hoisted_3, [
            _createVNode(_component_BaseButton, {
              type: "submit",
              loading: _ctx.isPromoCodeLoading,
              disabled: _ctx.code.trim().length === 0
            }, {
              default: _withCtx(() => [
                _createTextVNode(" Применить ")
              ]),
              _: 1
            }, 8, ["loading", "disabled"])
          ])
        ])
      ], 32))
    : (_openBlock(), _createElementBlock("div", _hoisted_4, [
        _createVNode(_component_InputLabel, null, {
          default: _withCtx(() => [
            _createTextVNode("Промокод:")
          ]),
          _: 1
        }),
        _createElementVNode("div", _hoisted_5, [
          _createElementVNode("span", _hoisted_6, _toDisplayString(_ctx.model.code), 1),
          _createElementVNode("span", _hoisted_7, _toDisplayString(`-${_ctx.model.discount}%`), 1),
          _createElementVNode("div", _hoisted_8, [
            _createElementVNode("button", {
              class: "promo-code-result__cancel",
              onClick: _cache[2] || (_cache[2] = 
//@ts-ignore
(...args) => (_ctx.onCancel && _ctx.onCancel(...args)))
            }, " Отменить ")
          ])
        ])
      ]))
}