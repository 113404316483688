import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0b75ec62"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "header-city-wrapper"
}
const _hoisted_2 = { class: "header-city-selector" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CitySelector = _resolveComponent("CitySelector")!

  return (_ctx.enabled)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", _hoisted_2, [
          _createVNode(_component_CitySelector)
        ])
      ]))
    : _createCommentVNode("", true)
}