import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-565283d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-form" }
const _hoisted_2 = { class: "address-form__row address-form__row--1" }
const _hoisted_3 = { class: "address-form__row address-form__row--2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormField = _resolveComponent("FormField")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormFieldSelect, {
        value: _ctx.valueRef.city,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.valueRef.city) = $event)),
        options: _ctx.citiesOptions,
        label: "Город",
        name: "city",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        clearable: false,
        searchable: false,
        onChange: _ctx.onChange
      }, null, 8, ["value", "options", "disabled", "onChange"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.street,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.valueRef.street) = $event)),
        label: "Улица",
        name: "str",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.house,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.valueRef.house) = $event)),
        type: "number",
        label: "Дом",
        name: "house",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.wing,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.valueRef.wing) = $event)),
        label: "Корпус",
        name: "wing",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.apartment,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.valueRef.apartment) = $event)),
        label: "Квартира",
        name: "apartment",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.floor,
        "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.valueRef.floor) = $event)),
        type: "number",
        label: "Этаж",
        name: "floor",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.entrance,
        "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.valueRef.entrance) = $event)),
        label: "Подъезд",
        name: "entrance",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"]),
      _createVNode(_component_FormField, {
        value: _ctx.valueRef.intercomCode,
        "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.valueRef.intercomCode) = $event)),
        label: "Код от домофона",
        name: "intercomCode",
        disabled: _ctx.disabled,
        "no-error-padding": true,
        onInput: _ctx.onChange
      }, null, 8, ["value", "disabled", "onInput"])
    ])
  ]))
}