
import { defineComponent, onMounted, PropType, watch } from "vue";

import {
  AdvancedSearch,
  FormField,
  FormFieldSelect,
  FormFieldCheckbox,
  AdvancedSearchDateFilter,
} from "@tager/admin-ui";

import {
  useAdvancedSearch,
  ordersFilterApprovedOptions,
} from "@/modules/orders/hooks/useAdvancedSearch";

export default defineComponent({
  name: "OrdersListActiveFilter",
  components: {
    AdvancedSearchDateFilter,
    FormField,
    FormFieldCheckbox,
    AdvancedSearch,
    FormFieldSelect,
  },
  props: {
    fetchData: {
      type: Function as PropType<() => void>,
      required: true,
    },
  },
  emits: ["update:loading", "update:value"],
  setup(props, context) {
    const {
      load,
      dateFilter,
      approvedFilter,
      workerFilter,
      workerFilterOptions,
      promocodeFilter,
      noSmsFilter,
      noWorkersFilter,
      filterParams,
      tags,
      tagRemovalHandler,
      dataLoading,
    } = useAdvancedSearch({ fetchEntityList: props.fetchData });

    onMounted(() => {
      context.emit("update:loading", dataLoading.value);
      load();
    });

    watch(dataLoading, () => {
      context.emit("update:loading", dataLoading.value);
    });

    watch(filterParams, () => context.emit("update:value", filterParams.value));
    onMounted(() => context.emit("update:value", filterParams.value));

    return {
      ordersFilterApprovedOptions,
      dateFilter,
      approvedFilter,
      workerFilter,
      workerFilterOptions,
      promocodeFilter,
      noSmsFilter,
      noWorkersFilter,
      filterParams,
      tags,
      tagRemovalHandler,
    };
  },
});
