import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/router/paths";
import { LINKS } from "@/router/links";

import GuestOrdersList from "./GuestOrdersList/GuestOrdersList.vue";

export const GUEST_ORDERS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.GUEST_ORDER_LIST,
  component: GuestOrdersList,
  name: "Гостевые заказы",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.GUEST_ORDER_LIST],
  },
};
