import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";
import CitiesList from "@/modules/cities/cities-list/CitiesList.vue";
import CitiesForm from "@/modules/cities/cities-form/CitiesForm.vue";

export const CITIES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_LIST,
  component: CitiesList,
  name: "Список городов",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.USERS_LIST],
  },
};

export const CITIES_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_CREATE,
  component: CitiesForm,
  name: "Новый город",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.CITIES_LIST],
  },
};

export const CITIES_EDIT_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_EDIT,
  component: CitiesForm,
  name: "Форма города",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.CITIES_LIST],
  },
};
