
import { computed, defineComponent, onMounted, onUnmounted, watch } from "vue";

import { NavigationGrid, NavigationGridItem } from "@tager/admin-ui";
import {
  isNotNullish,
  Nullable,
  useResource,
  useUserPermission,
  useUserStore,
} from "@tager/admin-services";
import { getPageCount } from "@tager/admin-pages";

import { LINKS } from "@/router/links";
import {
  getPromocodeCount,
  getServicesCount,
  getSubscriptionCount,
} from "@/requests";
import { getGuestOrdersCount } from "@/modules/guest-orders";
import { getReviewsCount } from "@/modules/reviews/services";
import { getUsersCount } from "@/modules/users/services";
import { getCitiesCount } from "@/modules/cities/services";
import { getWorkerCount } from "@/modules/workers/requests";
import { getActiveOrdersCount } from "@/modules/orders/services";
import { useCityId, useCityStore } from "@/store/city";
import { Scope } from "@/rbac/Scope";
import { useOnlyCompletedOrdersAllowed } from "@/rbac/hooks";

export default defineComponent({
  name: "HomePage",
  components: { NavigationGrid },

  setup() {
    const cityStore = useCityStore();

    const isOnlyCompletedOrdersAllowed = useOnlyCompletedOrdersAllowed();

    onMounted(() => {
      cityStore.enableSelectCity();
      cityStore.enableSelectAll();
    });

    onUnmounted(() => {
      cityStore.disableSelectAll();
      cityStore.disableSelectCity();
    });

    const cityId = useCityId();

    watch([cityId], () => {
      load();
    });

    const [
      fetchCities,
      { data: citiesCountData, status: citiesCountDataStatus },
    ] = useResource({
      fetchResource: getCitiesCount,
      resourceName: "Cities count",
      initialValue: null,
      scopes: Scope.CitiesUpdate,
    });

    const [
      fetchGuestOrderCount,
      { data: guestOrderCountData, status: guestOrderCountDataStatus },
    ] = useResource({
      fetchResource: getGuestOrdersCount,
      resourceName: "Order Guest count",
      initialValue: null,
      scopes: Scope.OrdersViewGuest,
    });

    const [
      fetchActiveOrdersCount,
      { data: orderActiveCountData, status: orderActiveCountDataStatus },
    ] = useResource({
      fetchResource: getActiveOrdersCount,
      resourceName: "Order active count",
      initialValue: null,
      scopes: Scope.OrdersViewActive,
    });

    const [
      fetchReviewsCount,
      { data: reviewsCountData, status: reviewsCountDataStatus },
    ] = useResource({
      fetchResource: getReviewsCount,
      resourceName: "Reviews Count",
      initialValue: null,
      scopes: Scope.OrdersViewReviews,
    });

    const [
      fetchPrimaryServicesCount,
      { data: primaryServicesCount, status: primaryServicesCountStatus },
    ] = useResource({
      fetchResource: () => getServicesCount("PRIMARY"),
      resourceName: "Services Primary Count",
      initialValue: null,
      scopes: Scope.ServicesView,
    });

    const [
      fetchSecondaryServicesCount,
      { data: secondaryServicesCount, status: secondaryServicesCountStatus },
    ] = useResource({
      fetchResource: () => getServicesCount("SECONDARY"),
      resourceName: "Services Secondary Count",
      initialValue: null,
      scopes: Scope.ServicesView,
    });

    const [
      fetchSubscriptionCount,
      { data: subscriptionCountData, status: subscriptionCountDataStatus },
    ] = useResource({
      fetchResource: getSubscriptionCount,
      resourceName: "Subscription count",
      initialValue: null,
      scopes: Scope.SubscriptionsView,
    });

    const [
      fetchUserCount,
      { data: userCountData, status: userCountDataStatus },
    ] = useResource({
      fetchResource: getUsersCount,
      resourceName: "User count",
      initialValue: null,
      scopes: Scope.UsersView,
    });

    const [
      fetchPageCount,
      { data: pageCountData, status: pageCountDataStatus },
    ] = useResource({
      fetchResource: () => getPageCount(),
      resourceName: "Page count",
      initialValue: null,
      scopes: Scope.PagesView,
    });

    const [
      fetchWorkerCount,
      { data: workerCountData, status: workerCountDataStatus },
    ] = useResource({
      fetchResource: getWorkerCount,
      resourceName: "Worker count",
      initialValue: null,
      scopes: Scope.WorkersView,
    });

    const [
      fetchPromocodeCount,
      { data: promocodeCountData, status: promocodeCountDataStatus },
    ] = useResource({
      fetchResource: getPromocodeCount,
      resourceName: "Promocode count",
      initialValue: null,
      scopes: Scope.PromocodesView,
    });

    const scopes = useUserStore();
    watch(scopes, () => load());

    const load = () => {
      fetchActiveOrdersCount(cityId.value);
      fetchSubscriptionCount(cityId.value);
      fetchGuestOrderCount(cityId.value);
      fetchWorkerCount(cityId.value);
      fetchReviewsCount(cityId.value);
      fetchUserCount(cityId.value);

      fetchPageCount();
      fetchPromocodeCount();
      fetchSecondaryServicesCount();
      fetchPrimaryServicesCount();
      fetchCities();
    };

    onMounted(() => {
      load();
    });

    const navItemList = computed<Array<NavigationGridItem>>(() => {
      let itemList: Array<Nullable<NavigationGridItem>> = [
        {
          name: LINKS.ORDERS_CREATE.text,
          url: LINKS.ORDERS_CREATE.url,
          width: 33.3333,
          scopes: Scope.OrdersCreate,
        },
        {
          name: LINKS.ORDERS_FOR_DAY.text,
          url: LINKS.ORDERS_FOR_DAY.url,
          width: 33.33333,
          scopes: Scope.OrdersViewActive,
        },
        {
          name: LINKS.ORDERS_MAP.text,
          url: LINKS.ORDERS_MAP.url,
          width: 33.33333,
          scopes: Scope.OrdersViewActive,
        },
        {
          name: "Заказы",
          url: LINKS.ORDERS_ACTIVE_LIST.url,
          total: {
            value: orderActiveCountData.value?.count ?? 0,
            status: orderActiveCountDataStatus.value,
          },
          width: 33.3333,
          scopes: Scope.OrdersViewActive,
        },
        isOnlyCompletedOrdersAllowed.value
          ? {
              name: "Заказы",
              url: LINKS.ORDERS_COMPLETED_LIST.url,
              width: 33.3333,
              scopes: Scope.OrdersViewCompleted,
            }
          : null,
        {
          name: "Подписки",
          url: LINKS.SUBSCRIPTION_LIST.url,
          total: {
            value: subscriptionCountData.value?.count ?? 0,
            status: subscriptionCountDataStatus.value,
          },
          width: 33.3333,
          scopes: Scope.SubscriptionsView,
        },
        {
          name: "Гостевые заказы",
          url: LINKS.GUEST_ORDER_LIST.url,
          total: {
            value: guestOrderCountData.value?.count ?? 0,
            status: guestOrderCountDataStatus.value,
          },
          width: 33.33333,
          scopes: Scope.OrdersViewGuest,
        },
        {
          name: "Клинеры",
          url: LINKS.WORKERS_ACTIVE.url,
          total: {
            value: workerCountData.value?.count ?? 0,
            status: workerCountDataStatus.value,
          },
          width: 33.33333,
          scopes: Scope.WorkersView,
        },
        {
          name: LINKS.TIMETABLE.text,
          url: LINKS.TIMETABLE.url,
          width: 33.3333,
          scopes: Scope.WorkersTimetableView,
        },
        {
          name: LINKS.CALENDAR.text,
          url: LINKS.CALENDAR.url,
          width: 33.3333,
          scopes: Scope.CalendarView,
        },
        {
          name: LINKS.REVIEWS_LIST.text,
          url: LINKS.REVIEWS_LIST.url,
          total: {
            value: reviewsCountData.value?.count ?? 0,
            status: reviewsCountDataStatus.value,
          },
          width: 33.33333,
          scopes: Scope.OrdersViewReviews,
        },
        {
          name: "Клиенты",
          url: LINKS.USERS_LIST.url,
          total: {
            value: userCountData.value?.count ?? 0,
            status: userCountDataStatus.value,
          },
          width: 33.3333,
          scopes: Scope.UsersView,
        },
        {
          name: LINKS.STATS.text,
          url: LINKS.STATS.url,
          width: 33.33333,
          scopes: Scope.CommonStats,
        },
      ];

      if (!cityId.value) {
        itemList = [
          ...itemList,
          {
            name: LINKS.EXPORT_LIST.text,
            url: LINKS.EXPORT_LIST.url,
            width: 33.33333,
            scopes: Scope.CommonExport,
          },
          {
            name: LINKS.PROMOCODE_LIST.text,
            url: LINKS.PROMOCODE_LIST_ACTIVE.url,
            total: {
              value: promocodeCountData.value?.count ?? 0,
              status: promocodeCountDataStatus.value,
            },
            width: 33.3333,
            scopes: Scope.PromocodesView,
          },
          {
            name: "Услуги",
            url: LINKS.SERVICE_CATEGORY_LIST.url,
            total: {
              value: primaryServicesCount.value?.count ?? 0,
              status: primaryServicesCountStatus.value,
            },
            width: 33.3333,
            scopes: Scope.ServicesView,
          },
          {
            name: "Доп. услуги",
            url: LINKS.SECONDARY_SERVICE_LIST.url,
            total: {
              value: secondaryServicesCount.value?.count ?? 0,
              status: secondaryServicesCountStatus.value,
            },
            width: 33.3333,
            scopes: Scope.ServicesView,
          },
          {
            name: LINKS.CITIES_LIST.text,
            url: LINKS.CITIES_LIST.url,
            total: {
              value: citiesCountData.value?.count ?? 0,
              status: citiesCountDataStatus.value,
            },
            width: 33.3333,
            scopes: Scope.CitiesUpdate,
          },
          {
            name: LINKS.PAGE_LIST.text,
            url: LINKS.PAGE_LIST.url,
            total: {
              value: pageCountData.value?.count ?? 0,
              status: pageCountDataStatus.value,
            },
            width: 33.3333,
            scopes: Scope.PagesView,
          },
          {
            name: "Настройки",
            linkList: [LINKS.SETTINGS, LINKS.MENU_HEADER, LINKS.MENU_FOOTER],
            width: 33.3333,
            scopes: Scope.SettingsView,
          },
          {
            name: "E-Mail",
            linkList: [LINKS.EMAIL_TEMPLATE_LIST, LINKS.EMAIL_LOG_LIST],
            width: 33.3333,
            scopes: Scope.MailEditTemplates,
          },
          {
            name: "SMS",
            linkList: [LINKS.SMS_TEMPLATE_LIST, LINKS.SMS_LOG_LIST],
            width: 33.3333,
            scopes: Scope.SmsEditTemplates,
          },
        ];
      }

      return itemList.filter(isNotNullish);
    });

    return {
      navItemList,
      cityId,
    };
  },
});
