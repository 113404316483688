
import { defineComponent, PropType, computed } from "vue";

import { FieldValue } from "@tager/admin-ui";

import { newPhoneFormat } from "@/utils/common";
import {
  OrderFullInterface,
  subscriptionPeriodLabel,
} from "@/modules/orders/typings";
import OrdersEditApproved from "@/modules/orders/views/orders-edit/components/OrdersEditApproved.vue";
import OrdersEditSMS from "@/modules/orders/views/orders-edit/components/OrdersEditSMS.vue";
import OrdersEditReview from "@/modules/orders/containers/OrdersEditReview.vue";
import OrdersEditAdminComment from "@/modules/orders/views/orders-edit/components/OrdersEditAdminComment.vue";
import OrdersViewServices from "@/modules/orders/containers/OrdersViewServices.vue";

import OrdersEditAddress from "../../../containers/OrdersViewAddress.vue";
import OrdersEditPromocode from "../../../containers/OrdersEditPromocode.vue";

import OrdersEditDateTime from "./OrdersEditDateTime.vue";

export default defineComponent({
  name: "OrdersEditCommon",
  components: {
    OrdersViewServices,
    OrdersEditAdminComment,
    OrdersEditReview,
    OrdersEditSMS,
    OrdersEditApproved,
    FieldValue,
    OrdersEditDateTime,
    OrdersEditAddress,
    OrdersEditPromocode,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  emits: ["click:services"],
  setup: (props, { emit }) => {
    const onServicesChangeClick = () => {
      emit("click:services");
    };

    const userText = computed<string>(() =>
      props.order
        ? props.order.user.name +
          " - " +
          props.order.user.phone +
          " - ID " +
          props.order.user.id
        : ""
    );

    return {
      newPhoneFormat,
      onServicesChangeClick,
      userText,
      subscriptionPeriodLabel,
    };
  },
});
