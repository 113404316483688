import { request, ResponseBody } from "@tager/admin-services";

import { CountData } from "@/requests";
import { CityInterface, CitySavePayload } from "@/modules/cities/typings";

export function getCitiesCount(): Promise<ResponseBody<CountData>> {
  return request.get({ path: "/admin/cities/count" });
}

export function getCities(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<CityInterface>>> {
  return request.get({ path: "/admin/cities", params });
}

export function getCity(id: number): Promise<ResponseBody<CityInterface>> {
  return request.get({ path: "/admin/cities/" + id });
}

export function createCity(
  payload: CitySavePayload
): Promise<ResponseBody<CityInterface>> {
  return request.post({ path: "/admin/cities", body: payload });
}

export function updateCity(
  id: number,
  payload: CitySavePayload
): Promise<ResponseBody<CityInterface>> {
  return request.put({
    path: `/admin/cities/${id}`,
    body: payload,
  });
}

export function moveCity(
  cityId: string | number,
  direction: "up" | "down"
): Promise<{ success: true }> {
  return request.post({
    path: `/admin/cities/${cityId}/move/${direction}`,
  });
}

export function deleteCity(
  cityId: string | number
): Promise<{ success: true }> {
  return request.delete({
    path: `/admin/cities/${cityId}`,
  });
}
