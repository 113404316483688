import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!

  return (_openBlock(), _createBlock(_component_BaseTextArea, {
    value: _ctx.data,
    "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.data) = $event)),
    disabled: _ctx.disabled,
    onBlur: _ctx.onBlur
  }, null, 8, ["value", "disabled", "onBlur"]))
}