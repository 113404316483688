
import { defineComponent, ref } from "vue";
import { useRoute, useRouter } from "vue-router";

import { Page } from "@tager/admin-layout";
import { FormField, FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import {
  convertRequestErrorToMap,
  navigateBack,
  useToast,
} from "@tager/admin-services";

import { createUser } from "@/modules/users/services";
import { getUsersListUrl, getUsersUpdateUrl } from "@/modules/users";

import {
  FormValues,
  initFormValues,
  convertFormValuesToCreateUserPayload,
} from "./UsersCreate.helpers";

export default defineComponent({
  name: "UsersCreate",
  components: {
    FormFooter,
    FormField,
    Page,
  },
  setup() {
    const router = useRouter();
    const toast = useToast();

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);
    const values = ref<FormValues>(Object.assign({}, initFormValues));

    async function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;
      errors.value = {};

      try {
        const response = await createUser(
          convertFormValuesToCreateUserPayload(values.value)
        );

        if (event.type === "create") {
          await router.push(getUsersUpdateUrl(response.data.id));
        } else if (event.type === "create_exit" || event.type === "save_exit") {
          navigateBack(router, getUsersListUrl());
        } else if (event.type === "create_create-another") {
          values.value = Object.assign({}, initFormValues);
        }

        toast.show({
          variant: "success",
          title: "Success",
          body: "Клиент успешно создан",
        });
      } catch (error: any) {
        errors.value = convertRequestErrorToMap(error);
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка создания клиента",
        });
      } finally {
        isSubmitting.value = false;
      }
    }

    return {
      submitForm,
      isSubmitting,
      values,
      errors,
      backButtonUrl: getUsersListUrl(),
    };
  },
});
