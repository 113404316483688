import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseTextArea = _resolveComponent("BaseTextArea")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_BaseTextArea, {
      value: _ctx.value,
      onInput: _ctx.onChange
    }, null, 8, ["value", "onInput"])
  ]))
}