
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import _ from "lodash";
import { useRoute, useRouter } from "vue-router";

import {
  AdvancedSearch,
  BaseButton,
  ColumnDefinition,
  DeleteIcon,
  EditIcon,
  FormFieldCheckbox,
  FormFieldMultiSelect,
  getFilterParamAsStringArray,
  getFilterParams,
  OptionType,
  useDataTable,
  DataTable,
} from "@tager/admin-ui";
import { useResourceDelete } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { TagType } from "@/types";
import { PromocodeType } from "@/types";
import { deletePromocode, getPromocodeList } from "@/requests";
import { newDateFormat } from "@/utils/common";
import { getPromocodeFormUrl } from "@/utils/paths";

import { promocodeModesList } from "../Promocodes.helpers";

const COLUMN_DEFS: Array<ColumnDefinition<PromocodeType>> = [
  {
    id: 1,
    name: "Код",
    field: "code",
    style: { width: "150px" },
    headStyle: { width: "150px" },
  },
  {
    id: 2,
    name: "Статус",
    field: "mode",
    style: { width: "100px", maxWidth: "100px", textAlign: "center" },
    headStyle: { width: "100px", maxWidth: "100px", textAlign: "center" },
  },
  {
    id: 3,
    name: "Скидка",
    field: "discount",
    style: { width: "80px", maxWidth: "80px", textAlign: "center" },
    headStyle: { width: "80px", maxWidth: "80px", textAlign: "center" },
  },
  {
    id: 4,
    name: "Применений",
    field: "usesCount",
    width: "120px",
    style: { textAlign: "center" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 5,
    name: "Срок действия",
    field: "period",
    type: "html",
    format: ({ row }) => {
      let period, days;

      if (!row.dateEnd) {
        period = "с " + newDateFormat(row.dateStart);
      } else {
        period =
          "с " +
          newDateFormat(row.dateStart) +
          " по " +
          newDateFormat(row.dateEnd);
      }

      if (row.dates.length) {
        if (row.dates.length === 1) {
          days = newDateFormat(row.dates[0]);
        } else {
          let prefix =
            row.dates.length + (row.dates.length < 5 ? " дня" : " дней");

          if (row.dates.length <= 5) {
            prefix =
              prefix +
              " - " +
              row.dates
                .slice(0, 5)
                .map((item) => newDateFormat(item))
                .join(", ");
          }

          days = prefix;
        }
      }

      return period + (days ? "<br/>Доступен: " + days : "");
    },
  },
  {
    id: 7,
    name: "Опции",
    field: "info",
  },
  {
    id: 8,
    name: "Действия",
    field: "actions",
    style: { width: "100px", whiteSpace: "nowrap" },
    headStyle: { width: "100px" },
  },
];

export default defineComponent({
  name: "PromocodeList",
  components: {
    DeleteIcon,
    EditIcon,
    BaseButton,
    FormFieldCheckbox,
    FormFieldMultiSelect,
    AdvancedSearch,
    Page,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();

    /** Mode filter */
    const initialModeFilter = computed<Array<OptionType<string>>>(() => {
      const queryValue = getFilterParamAsStringArray(route.query, "mode");

      return promocodeModesList.filter((option) =>
        queryValue.some((selected) => option.value === selected)
      );
    });

    const modeFilter = ref<Array<OptionType<string>>>(initialModeFilter.value);

    watch(initialModeFilter, () => {
      modeFilter.value = initialModeFilter.value;
    });

    const filterParams = computed(() => {
      return getFilterParams({
        mode: modeFilter.value.map((tag) => tag.value),
      });
    });

    const {
      isLoading: isPromocodeListLoading,
      rowData: promocodeList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchPromocodeList,
    } = useDataTable<PromocodeType>({
      fetchEntityList: (params) =>
        getPromocodeList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...filterParams.value,
        }),
      initialValue: [],
      resourceName: "Promocode list",
    });

    onMounted(() => {
      fetchPromocodeList();
    });

    const { handleResourceDelete: handlePromocodeDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deletePromocode,
        resourceName: "Promocode",
        onSuccess: fetchPromocodeList,
      });

    const isRowDataLoading = computed<boolean>(
      () => isPromocodeListLoading.value
    );

    function isBusy(promocodeId: number): boolean {
      return isDeleting(promocodeId) || isRowDataLoading.value;
    }

    watch(filterParams, () => {
      const newQuery = {
        ...filterParams.value,
        query: route.query.query,
      };

      if (!_.isEqual(route.query, newQuery)) {
        router.replace({
          query: newQuery,
        });
        fetchPromocodeList();
      }
    });

    /** Filter Tags */
    function handleTagRemove(event: TagType) {
      if (event.name === "mode") {
        modeFilter.value = modeFilter.value.filter(
          (tag) => String(tag.value) !== event.value
        );
      }
    }

    const tags = computed<Array<TagType>>(() => [
      ...modeFilter.value.map((tag) => ({
        value: String(tag.value),
        label: tag.label,
        name: "mode",
        title: "Статус",
      })),
    ]);

    return {
      columnDefs: COLUMN_DEFS,
      rowData: promocodeList,
      isRowDataLoading: isPromocodeListLoading,
      errorMessage,
      newDateFormat,
      isBusy,
      fetchPromocodeList,
      getPromocodeFormUrl,
      handlePromocodeDelete,

      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      tags,
      handleTagRemove,

      modeFilter,
      promocodeModesList,
    };
  },
});
