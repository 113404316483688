import { computed, ComputedRef, Ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  FilterTagType,
  getFilterParams,
  type AdvancedSearchDateFilterType,
  useAdvancedSearchDateFilter,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

interface Params {
  fetchEntityList: () => void;
}

interface State {
  dateCreatedFilter: Ref<AdvancedSearchDateFilterType>;
  dateArchivedFilter: Ref<AdvancedSearchDateFilterType>;

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;
  tagRemovalHandler(event: FilterTagType): void;
}

export function useAdvancedSearch({ fetchEntityList }: Params): State {
  const route = useRoute();
  const router = useRouter();

  const { value: dateCreatedFilter, ...dateCreatedFilterData } =
    useAdvancedSearchDateFilter({
      label: "Дата заключения договора",
      queryParams: {
        date: "date-created",
        dateFrom: "date-created-from",
        dateTo: "date-created-to",
      },
    });

  const { value: dateArchivedFilter, ...dateArchivedFilterData } =
    useAdvancedSearchDateFilter({
      label: "Дата архивации",
      queryParams: {
        date: "date-archived",
        dateFrom: "date-archived-from",
        dateTo: "date-archived-to",
      },
    });

  /** Params **/

  const filterParams = computed(() => {
    return getFilterParams({
      ...dateCreatedFilterData.filterParams(),
      ...dateArchivedFilterData.filterParams(),
    });
  });

  const tagRemovalHandler = (event: FilterTagType): void => {
    dateCreatedFilterData.tagRemovalHandler(event);
    dateArchivedFilterData.tagRemovalHandler(event);
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [...dateCreatedFilterData.tags(), ...dateArchivedFilterData.tags()].filter(
      isNotNullish
    )
  );

  watch([filterParams], () => {
    const newQuery = {
      ...pick(route.query, ["query", "pageNumber", "sort"]),
      ...filterParams.value,
    };

    if (!isEqual(route.query, newQuery)) {
      router.replace({ query: newQuery });
    } else {
      fetchEntityList();
    }
  });

  return {
    dateCreatedFilter,
    dateArchivedFilter,

    filterParams,
    tags,
    tagRemovalHandler,
  };
}
