
import { defineComponent } from "vue";

import { TimetableOrderType } from "@/types";
import { getTime } from "@/utils/common";
import { getOrdersViewUrl } from "@/modules/orders";

export default defineComponent({
  name: "OrderList",

  components: {},
  props: {
    orderList: {
      type: Array,
      required: true,
    },
  },

  setup() {
    function sort(orderList: Array<TimetableOrderType>) {
      return orderList.sort(function (firstOrder, secondOrder) {
        const firstOrderDateInMs = Date.parse(firstOrder.datetime);
        const secondOrderDateInMs = Date.parse(secondOrder.datetime);
        return firstOrderDateInMs - secondOrderDateInMs;
      });
    }

    return {
      getTime,
      getOrdersViewUrl,
      sort,
    };
  },
});
