import { ColumnDefinition, formatDate } from "@tager/admin-ui";

import { getNoteStatusLabel, NoteInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<NoteInterface>> = [
  {
    id: 1,
    name: "Дата / Время",
    type: "string",
    field: "date",
    format: ({ row }) => {
      return (
        formatDate(new Date(row.date)) +
        (row.time ? " " + row.time.substring(0, 5) : "")
      );
    },
    width: "168px",
  },
  {
    id: 2,
    name: "Менеджер",
    type: "string",
    field: "manager",
    format: ({ row }) => {
      return row.manager.name;
    },
    width: "200px",
  },
  {
    id: 3,
    name: "Статус",
    field: "status",
    format: ({ row }) => getNoteStatusLabel(row.status),
    width: "100px",
  },
  {
    id: 4,
    name: "Клиент",
    type: "string",
    field: "user",
    width: "300px",
  },
  {
    id: 5,
    name: "Сообщение",
    field: "message",
  },
  {
    id: 5,
    name: "",
    field: "actions",
    width: "60px",
  },
];
