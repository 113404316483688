import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-030645ba"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "order-list-block" }
const _hoisted_2 = { class: "order-time" }
const _hoisted_3 = { class: "order-address" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_RouterLink = _resolveComponent("RouterLink")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.sort(_ctx.orderList), (order) => {
      return (_openBlock(), _createBlock(_component_RouterLink, {
        key: order.id,
        class: "order",
        to: _ctx.getOrdersViewUrl(order.id),
        target: "_blank"
      }, {
        default: _withCtx(() => [
          _createElementVNode("span", _hoisted_2, _toDisplayString(_ctx.getTime(order.datetime)) + " (" + _toDisplayString(order.workTime / 60) + " ч.) ", 1),
          _createElementVNode("span", _hoisted_3, _toDisplayString(order.address), 1)
        ]),
        _: 2
      }, 1032, ["to"]))
    }), 128))
  ]))
}