import { computed, onMounted, Ref } from "vue";

import { useResource } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

import { CityInterface } from "@/modules/cities/typings";

import { getCities } from "../services";

type Result = {
  loading: Ref<boolean>;
  items: Ref<Array<CityInterface>>;
  options: Ref<Array<OptionType<number>>>;
};

export function useCities(): Result {
  const [fetchCities, { data: cities, loading: citiesLoading }] = useResource<
    Array<CityInterface>
  >({
    fetchResource: getCities,
    initialValue: [],
    resourceName: "Cities",
  });

  onMounted(async () => {
    await fetchCities();
  });

  const options = computed<Array<OptionType<number>>>(() =>
    cities.value.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    })
  );

  return {
    items: cities,
    loading: citiesLoading,
    options,
  };
}
