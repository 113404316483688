import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormFieldSelect = _resolveComponent("FormFieldSelect")!
  const _component_FormFieldFileInput = _resolveComponent("FormFieldFileInput")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.PageTitle,
    "is-content-loading": _ctx.isContentLoading,
    footer: {
      backHref: _ctx.backButtonUrl,
      onSubmit: _ctx.submitForm,
      isSubmitting: _ctx.isSubmitting,
    }
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[12] || (_cache[12] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.price,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.price) = $event)),
          type: "number",
          name: "price",
          error: _ctx.errors.price,
          label: "Цена"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.isFinalPrice,
          "onUpdate:checked": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.isFinalPrice) = $event)),
          name: "isFinalPrice",
          error: _ctx.errors.isFinalPrice,
          label: "Не применять скидки"
        }, null, 8, ["checked", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.minutes,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.minutes) = $event)),
          type: "number",
          name: "minutes",
          error: _ctx.errors.minutes,
          label: "Трудоемкость, мин"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.step,
          "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.step) = $event)),
          type: "number",
          name: "step",
          error: _ctx.errors.step,
          label: "Шаг, мин"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldSelect, {
          value: _ctx.values.mode,
          "onUpdate:value": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.mode) = $event)),
          name: "mode",
          error: _ctx.errors.mode,
          label: "Тип услуги",
          options: _ctx.modeOptions
        }, null, 8, ["value", "error", "options"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.values.icon,
          "onUpdate:value": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.icon) = $event)),
          name: "icon",
          error: _ctx.errors.icon,
          label: "Иконка (серая иконка) ",
          "file-type": "image"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldFileInput, {
          value: _ctx.values.iconActive,
          "onUpdate:value": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.iconActive) = $event)),
          name: "iconActive",
          error: _ctx.errors.iconActive,
          label: "Иконка активная (зеленая иконка) ",
          "file-type": "image"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.tooltip,
          "onUpdate:value": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.tooltip) = $event)),
          name: "tooltip",
          error: _ctx.errors.tooltip,
          label: "Описание",
          type: "textarea"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.isNew,
          "onUpdate:checked": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.values.isNew) = $event)),
          name: "isNew",
          error: _ctx.errors.isNew,
          label: "Бедж NEW"
        }, null, 8, ["checked", "error"]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.hidden,
          "onUpdate:checked": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.values.hidden) = $event)),
          name: "hidden",
          error: _ctx.errors.hidden,
          label: "Скрывать на сайте"
        }, null, 8, ["checked", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.fullWorkerPrice,
          "onUpdate:value": _cache[11] || (_cache[11] = ($event: any) => ((_ctx.values.fullWorkerPrice) = $event)),
          type: "number",
          name: "fullWorkerPrice",
          error: _ctx.errors.fullWorkerPrice,
          label: "Вознаграждение клинера"
        }, null, 8, ["value", "error"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading", "footer"]))
}