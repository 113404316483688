import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0fba53d0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "period" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_DynamicField = _resolveComponent("DynamicField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.pageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[5] || (_cache[5] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.code,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.code) = $event)),
          type: "text",
          name: "code",
          error: _ctx.errors.code,
          label: "Промокод"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.discount,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.discount) = $event)),
          type: "number",
          name: "discount",
          error: _ctx.errors.discount,
          label: "Размер скидки, %"
        }, null, 8, ["value", "error"]),
        _createElementVNode("div", _hoisted_1, [
          _createVNode(_component_FormField, {
            value: _ctx.values.dateStart,
            "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.dateStart) = $event)),
            type: "date",
            name: "dateStart",
            error: _ctx.errors.dateStart,
            label: "Срок действия - Начало"
          }, null, 8, ["value", "error"]),
          _createVNode(_component_FormField, {
            value: _ctx.values.dateEnd,
            "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.dateEnd) = $event)),
            type: "date",
            name: "dateEnd",
            error: _ctx.errors.dateEnd,
            label: "Срок действия - Конец"
          }, null, 8, ["value", "error"])
        ]),
        _createVNode(_component_FormFieldCheckbox, {
          checked: _ctx.values.reusable,
          "onUpdate:checked": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.reusable) = $event)),
          name: "reusable",
          error: _ctx.errors.reusable,
          label: "Многоразовый"
        }, null, 8, ["checked", "error"]),
        _createVNode(_component_DynamicField, { field: _ctx.dates }, null, 8, ["field"]),
        _createVNode(_component_DynamicField, { field: _ctx.emails }, null, 8, ["field"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}