export const minutesToTimeString = (minutes: number): string => {
  const h = Math.floor(minutes / 60);

  if (h > 0) {
    return h + " ч." + (minutes % 60 > 0 ? " " + (minutes % 60) + " мин." : "");
  }

  return minutes + " мин.";
};

export function formatMoney(
  amount: number | undefined,
  decimalCount = 2,
  decimal = ".",
  thousands = " "
) {
  if (typeof amount === "undefined") return "";
  try {
    decimalCount = Math.abs(decimalCount);
    decimalCount = isNaN(decimalCount) ? 2 : decimalCount;

    const negativeSign = amount < 0 ? "-" : "";

    const i = parseInt(
      Math.abs(Number(amount) || 0).toFixed(decimalCount)
    ).toString();
    const j = i.length > 3 ? i.length % 3 : 0;

    return (
      negativeSign +
      (j ? i.substr(0, j) + thousands : "") +
      i.substr(j).replace(/(\d{3})(?=\d)/g, "$1" + thousands) +
      (decimalCount
        ? decimal +
          Math.abs(amount - +i)
            .toFixed(decimalCount)
            .slice(2)
        : "")
    );
  } catch (e) {
    console.log(e);
  }
}
