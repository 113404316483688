import { computed, ComputedRef, ref, Ref, watch } from "vue";
import { RouteLocationNormalizedLoaded } from "vue-router";

import {
  AdvancedSearchDateFilterType,
  FilterTagType,
  getFilterParamAsString,
  getFilterParams,
  OptionType,
  useAdvancedSearchDateFilter,
} from "@tager/admin-ui";
import { isNotNullish } from "@tager/admin-services";

interface Params {
  route: RouteLocationNormalizedLoaded;
}

enum FilterTypes {
  Rating = "rating",
  Worker = "worker",
}

enum FilterRating {
  All = "ALL",
  Good = "GOOD",
  Bad = "BAD",
}

interface State {
  dateFilter: Ref<AdvancedSearchDateFilterType>;
  dateOrderFilter: Ref<AdvancedSearchDateFilterType>;
  ratingFilter: Ref<OptionType<FilterRating>>;
  workerFilter: Ref<string | null>;

  filterParams: ComputedRef<Record<string, string | string[]>>;
  tags: ComputedRef<FilterTagType[]>;
  handleTagRemove(event: FilterTagType): void;
}

export const filterRatingOptions: Array<OptionType<FilterRating>> = [
  {
    value: FilterRating.All,
    label: "Все",
  },
  {
    value: FilterRating.Good,
    label: "Положительные",
  },
  {
    value: FilterRating.Bad,
    label: "Отрицательные",
  },
];

export function useReviewsAdvancedSearch({ route }: Params): State {
  const { value: dateFilter, ...dateFilterData } = useAdvancedSearchDateFilter({
    label: "Дата",
  });

  const { value: dateOrderFilter, ...dateOrderFilterData } =
    useAdvancedSearchDateFilter({
      label: "Дата уборки",
      queryParams: {
        date: "date-order",
        dateFrom: "date-order-from",
        dateTo: "date-order-to",
      },
    });

  const initialRatingFilter = computed<OptionType<FilterRating>>(() => {
    const value =
      getFilterParamAsString(route.query, FilterTypes.Rating) ||
      FilterRating.All;

    return (
      filterRatingOptions.find((item) => item.value === value) ||
      filterRatingOptions[0]
    );
  });
  const ratingFilter = ref<OptionType<FilterRating>>(initialRatingFilter.value);
  watch(initialRatingFilter, () => {
    ratingFilter.value = initialRatingFilter.value;
  });

  const initialWorkerFilter = computed<string | null>(() => {
    return getFilterParamAsString(route.query, FilterTypes.Worker) || null;
  });
  const workerFilter = ref<string | null>(initialWorkerFilter.value);
  watch(initialWorkerFilter, () => {
    workerFilter.value = initialWorkerFilter.value;
  });

  /** Params **/

  const filterParams = computed(() =>
    getFilterParams({
      ...dateFilterData.filterParams(),
      ...dateOrderFilterData.filterParams(),
      [FilterTypes.Rating]: ratingFilter.value.value || "",
      [FilterTypes.Worker]: workerFilter.value || "",
    })
  );

  const handleTagRemove = (event: FilterTagType): void => {
    dateFilterData.tagRemovalHandler(event);
    dateOrderFilterData.tagRemovalHandler(event);

    if (event.name === FilterTypes.Rating) {
      ratingFilter.value = filterRatingOptions[0];
    } else if (event.name === FilterTypes.Worker) {
      workerFilter.value = null;
    }
  };

  /** Tags **/

  const tags = computed<FilterTagType[]>(() =>
    [
      ...dateFilterData.tags(),
      ...dateOrderFilterData.tags(),
      ratingFilter.value && ratingFilter.value.value !== FilterRating.All
        ? {
            value: String(ratingFilter.value.value),
            label: String(ratingFilter.value.label),
            name: FilterTypes.Rating,
            title: "Рейтинг",
          }
        : null,
      workerFilter.value
        ? {
            value: String(workerFilter.value),
            label: String(workerFilter.value),
            name: FilterTypes.Worker,
            title: "Клинер",
          }
        : null,
    ].filter(isNotNullish)
  );

  return {
    dateOrderFilter,
    dateFilter,
    ratingFilter,
    workerFilter,
    filterParams,
    tags,
    handleTagRemove,
  };
}
