import { ColumnDefinition } from "@tager/admin-ui";

import { ReviewInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<ReviewInterface>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    width: "40px",
  },
  {
    id: 5,
    name: "Оценка",
    field: "rating",
    width: "60px",
    style: { textAlign: "center", fontWeight: "bold", fontSize: "120%" },
    headStyle: { textAlign: "center" },
  },
  {
    id: 6,
    name: "Клинеры",
    field: "workers",
    width: "260px",
  },
  {
    id: 2,
    name: "Дата, время",
    field: "createdAt",
    type: "datetime",
    width: "165px",
  },
  {
    id: 3,
    name: "Заказ",
    field: "order",
    width: "190px",
  },
  {
    id: 4,
    name: "Клиент",
    field: "user",
    width: "190px",
  },
  {
    id: 7,
    name: "Текст",
    field: "text",
  },
  {
    id: 8,
    width: "400px",
    name: "Решение",
    field: "solution",
  },
];
