
import { computed, defineComponent, PropType, ref } from "vue";

import { FieldValue } from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";

import OrdersEditReviewForm from "../views/orders-edit/components/OrdersEditReviewForm.vue";

export default defineComponent({
  name: "OrdersEditReview",
  components: {
    OrdersEditReviewForm,
    FieldValue,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const rating = ref<number | null>(
      props.order.review ? props.order.review.rating : null
    );
    const text = ref<string | null>(
      props.order.review ? props.order.review.text : null
    );
    const solution = ref<string | null>(
      props.order.review ? props.order.review.solution : null
    );

    const value = computed<string>(() =>
      rating.value
        ? rating.value + (text.value ? " - " + text.value : "")
        : "Без отзыва"
    );

    const formOpened = ref<boolean>(false);

    const onStart = () => (formOpened.value = true);
    const onCancel = () => (formOpened.value = false);

    const onSubmit = async (data: {
      rating: number;
      text: string;
      solution: string;
    }) => {
      rating.value = data.rating;
      text.value = data.text;
      solution.value = data.solution;

      formOpened.value = false;
    };

    return {
      value,
      rating,
      text,
      solution,
      formOpened,
      onStart,
      onCancel,
      onSubmit,
    };
  },
});
