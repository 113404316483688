
import { defineComponent, PropType } from "vue";

import {
  FormFieldOptionsSwitcherTrueFalse,
  FieldValue,
  FormField,
} from "@tager/admin-ui";

import { OrderFullInterface } from "@/modules/orders/typings";
import { PriceFormValues } from "@/modules/orders/views/orders-edit/OrdersEdit.vue";

export default defineComponent({
  name: "OrdersEditPrice",
  components: {
    FormFieldOptionsSwitcherTrueFalse,
    FieldValue,
    FormField,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    value: {
      type: Object as PropType<PriceFormValues>,
      required: false,
      default: undefined,
    },
  },
  emits: ["change"],
  setup(props) {
    return {};
  },
});
