
import { defineComponent, PropType } from "vue";

import { BaseButton, formatDateTime } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { OrderFullInterface } from "@/modules/orders/typings";
import {
  cancelOrder,
  restoreOrder,
  setOrderAsSingle,
} from "@/modules/orders/services";
export default defineComponent({
  name: "OrdersEditTopRight",
  components: { BaseButton },
  methods: { formatDateTime },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  emits: ["update"],
  setup(props, context) {
    const toast = useToast();

    const onCancel = async () => {
      if (!confirm("Вы уверены, что хотите отменить заказ?")) return;

      try {
        await cancelOrder(props.order.id);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Заказ успешно отменен",
        });

        context.emit("update");
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка отмены заказа",
        });
      }
    };

    const onMakeSingle = async () => {
      if (
        !confirm(
          "Вы уверены, что хотите отменить подписку и сделать заказ разовым?"
        )
      )
        return;

      try {
        await setOrderAsSingle(props.order.id);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Заказ стал разовым",
        });

        context.emit("update");
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка смены статуса заказа",
        });
      }
    };

    const onRestore = async () => {
      if (!confirm("Вы уверены, что хотите восстановить заказ")) return;

      try {
        await restoreOrder(props.order.id);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Заказ успешно восстановлен",
        });

        context.emit("update");
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка восстановления заказа",
        });
      }
    };

    return {
      onCancel,
      onMakeSingle,
      onRestore,
    };
  },
});
