import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_BaseTable = _resolveComponent("BaseTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: `Услуги категории - ${_ctx.serviceCategory?.name || ''}`,
    "is-content-loading": _ctx.isRowDataLoading,
    "header-buttons": [
      {
        text: 'Изменить для всех',
        href: _ctx.getServiceCategoryMultipleChangeUrl(_ctx.serviceCategoryId),
      },
      {
        text: 'Добавить услугу',
        href: _ctx.getAdditionalServiceCategoryFormUrl({
          serviceCategoryId: _ctx.serviceCategoryId,
          additionalServiceId: 'create-service',
        }),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_BaseTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        enumerable: true
      }, {
        "cell(actions)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Edit",
              disabled: _ctx.isBusy(row.id),
              href: 
                _ctx.getAdditionalServiceCategoryFormUrl({
                  serviceCategoryId: _ctx.serviceCategoryId,
                  additionalServiceId: row.id,
                })
              
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EditIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "href"]),
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Delete",
              disabled: _ctx.isBusy(row.id) || !row.canDelete,
              onClick: ($event: any) => (_ctx.handleAdditionalServiceCategoryDelete(row.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeleteIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message"])
    ]),
    _: 1
  }, 8, ["title", "is-content-loading", "header-buttons"]))
}