import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Услуги",
    value: _ctx.servicesItems,
    type: "list",
    "with-edit": _ctx.withEdit,
    onEdit: _ctx.onServicesChangeClick
  }, null, 8, ["value", "with-edit", "onEdit"]))
}