import { computed, onMounted, ref, Ref, watch } from "vue";
import { useRoute } from "vue-router";

import {
  OptionType,
  getFilterParamAsNumber,
  FilterTagType,
  type AdvancedSearchFilterType,
} from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";
import { getAdminList } from "@tager/admin-administrators";
import { AdminType } from "@tager/admin-administrators/src/typings/model";

export type ManagerFilterType = Nullable<OptionType<number>>;

type State = AdvancedSearchFilterType<ManagerFilterType> & {
  options: Ref<Array<OptionType<number>>>;
};

export function useAdvancedSearchManagersFilters(
  queryParam: string,
  title: string
): State {
  const route = useRoute();

  const [fetchManagers, { data: managers }] = useResource<Array<AdminType>>({
    fetchResource: getAdminList,
    initialValue: [],
    resourceName: "Notes Admin List",
  });

  onMounted(fetchManagers);

  const options = computed<Array<OptionType<number>>>(() =>
    managers.value.map((item) => {
      return {
        value: item.id,
        label: item.name,
      };
    })
  );

  const initialManagerFilter = computed<ManagerFilterType>(
    () =>
      options.value.find(
        (item) => item.value === getFilterParamAsNumber(route.query, queryParam)
      ) ?? null
  );

  const managerFilter = ref<ManagerFilterType>(initialManagerFilter.value);
  watch(initialManagerFilter, () => {
    managerFilter.value = initialManagerFilter.value;
  });

  const tags = () =>
    managerFilter.value && managerFilter.value.value
      ? [
          {
            value: String(managerFilter.value?.value) || "",
            label: managerFilter.value?.label || "",
            name: queryParam,
            title,
          },
        ]
      : [];

  const filterParams = () => {
    return {
      [queryParam]: managerFilter.value?.value
        ? String(managerFilter.value?.value)
        : "",
    };
  };

  const tagRemovalHandler = (event: FilterTagType) => {
    if (event.name === queryParam) managerFilter.value = null;
  };

  return {
    value: managerFilter,
    tags,
    filterParams,
    tagRemovalHandler,

    options,
  };
}
