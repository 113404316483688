import { getEmailLogListUrl, getEmailTemplateListUrl } from "@tager/admin-mail";
import { LinkType } from "@tager/admin-ui";
import { getMenuPageUrl } from "@tager/admin-menus";
import { getPageListUrl } from "@tager/admin-pages";
import { getSettingItemListUrl } from "@tager/admin-settings";
import { getSmsLogListUrl, getSmsTemplateListUrl } from "@tager/admin-sms";
import { getSeoSettingsUrl } from "@tager/admin-seo";
import { getAdminListUrl, getRoleListUrl } from "@tager/admin-administrators";
import { getExportListUrl } from "@tager/admin-export";

import {
  getActiveWorkerListUrl,
  getArchiveWorkerListUrl,
  getStatisticListUrl,
  getTimetableListUrl,
  getTrainingListUrl,
  getCalendarUrl,
  getCompanyListUrl,
  getPromocodesListUrl,
  getSubscriptionListUrl,
  getStatsUrl,
  getGuestOrdersUrl,
  getSubscriptionCompletedListUrl,
} from "@/utils/paths";
import {
  getServiceCategoryListUrl,
  getSecondaryServiceListUrl,
} from "@/modules/services";
import {
  getOrdersMapUrl,
  getOrdersCreateUrl,
  getOrdersForDayUrl,
  getActiveOrdersListUrl,
  getOrdersCompletedListUrl,
  getOrdersCancelledListUrl,
} from "@/modules/orders";
import { getReviewsListUrl } from "@/modules/reviews";
import { getUsersCreateUrl, getUsersListUrl } from "@/modules/users";
import { getCitiesListUrl } from "@/modules/cities";
import { getNotesListUrl } from "@/modules/notes";

type LinkKey =
  | "HOME"
  | "TIMETABLE"
  | "EMAIL_TEMPLATE_LIST"
  | "EMAIL_LOG_LIST"
  | "SMS_TEMPLATE_LIST"
  | "SMS_LOG_LIST"
  | "MENU_HEADER"
  | "MENU_FOOTER"
  | "PAGE_LIST"
  | "WORKERS_ACTIVE"
  | "WORKERS_ARCHIVE"
  | "TRAINING_LIST"
  | "TIMETABLE_LIST"
  | "SERVICE_CATEGORY_LIST"
  | "SECONDARY_SERVICE_LIST"
  | "COMPANY_LIST"
  | "PROMOCODE_LIST"
  | "PROMOCODE_LIST_ACTIVE"
  | "PROMOCODE_LIST_ARCHIVED"
  | "EXPORT_LIST"
  | "CALENDAR"
  | "STATS"
  | "GUEST_ORDER_LIST"
  | "ORDERS_MAP"
  | "ORDERS_FOR_DAY"
  | "ORDERS_CREATE"
  | "ORDERS_ACTIVE_LIST"
  | "ORDERS_COMPLETED_LIST"
  | "ORDERS_CANCELLED_LIST"
  | "CITIES_LIST"
  | "SUBSCRIPTION_LIST"
  | "SUBSCRIPTION_LIST_COMPLETED"
  | "NOTES_LIST"
  | "USERS_LIST"
  | "USERS_CREATE"
  | "STATISTIC_LIST"
  | "SETTINGS"
  | "SEO_SETTINGS"
  | "REVIEWS_LIST"
  | "ADMIN_ROLES"
  | "ADMIN_ADMINS";

export const LINKS: Readonly<Record<LinkKey, LinkType>> = {
  HOME: { url: "/", text: "Главная" },

  TIMETABLE: { url: "/timetable", text: "Расписание" },

  EMAIL_TEMPLATE_LIST: {
    url: getEmailTemplateListUrl(),
    text: "E-Mail шаблоны",
  },
  EMAIL_LOG_LIST: {
    url: getEmailLogListUrl(),
    text: "E-Mail сообщения",
  },
  SMS_TEMPLATE_LIST: {
    url: getSmsTemplateListUrl(),
    text: "SMS шаблоны",
  },
  SMS_LOG_LIST: {
    url: getSmsLogListUrl(),
    text: "SMS сообщения",
  },
  MENU_HEADER: {
    url: getMenuPageUrl("header"),
    text: "Верхнее меню",
  },
  MENU_FOOTER: {
    url: getMenuPageUrl("footer"),
    text: "Нижнее меню",
  },
  PAGE_LIST: {
    url: getPageListUrl(),
    text: "Страницы",
  },
  WORKERS_ACTIVE: {
    url: getActiveWorkerListUrl(),
    text: "Активные клинеры",
  },
  WORKERS_ARCHIVE: {
    url: getArchiveWorkerListUrl(),
    text: "Архивные клинеры",
  },
  STATISTIC_LIST: {
    url: getStatisticListUrl(),
    text: "Статистика клинеров",
  },
  TRAINING_LIST: {
    url: getTrainingListUrl(),
    text: "Тренинги",
  },
  GUEST_ORDER_LIST: {
    url: getGuestOrdersUrl(),
    text: "Гостевые заказы",
  },
  PROMOCODE_LIST: {
    url: getPromocodesListUrl(),
    text: "Промокоды",
  },
  PROMOCODE_LIST_ACTIVE: {
    url: getPromocodesListUrl("ACTIVE"),
    text: "Активные",
  },
  PROMOCODE_LIST_ARCHIVED: {
    url: getPromocodesListUrl("ARCHIVED"),
    text: "Архивные",
  },
  TIMETABLE_LIST: {
    url: getTimetableListUrl(),
    text: "Расписание",
  },
  SUBSCRIPTION_LIST: {
    url: getSubscriptionListUrl(),
    text: "Подписки",
  },
  SUBSCRIPTION_LIST_COMPLETED: {
    url: getSubscriptionCompletedListUrl(),
    text: "Завершенные",
  },
  USERS_LIST: {
    url: getUsersListUrl(),
    text: "Клиенты",
  },
  USERS_CREATE: {
    url: getUsersCreateUrl(),
    text: "Новый клиент",
  },
  SERVICE_CATEGORY_LIST: {
    url: getServiceCategoryListUrl(),
    text: "Основные услуги",
  },
  SECONDARY_SERVICE_LIST: {
    url: getSecondaryServiceListUrl(),
    text: "Дополнительные услуги",
  },
  COMPANY_LIST: {
    url: getCompanyListUrl(),
    text: "Компании",
  },
  CALENDAR: {
    url: getCalendarUrl(),
    text: "Календарь",
  },
  STATS: {
    url: getStatsUrl(),
    text: "Статистика",
  },
  SETTINGS: {
    url: getSettingItemListUrl(),
    text: "Основные",
  },
  SEO_SETTINGS: {
    url: getSeoSettingsUrl(),
    text: "SEO Настройки",
  },
  ADMIN_ROLES: {
    url: getRoleListUrl(),
    text: "Роли",
  },
  ADMIN_ADMINS: {
    url: getAdminListUrl(),
    text: "Администраторы",
  },
  EXPORT_LIST: {
    url: getExportListUrl(),
    text: "Экспорт",
  },
  ORDERS_MAP: {
    url: getOrdersMapUrl(),
    text: "Карта заказов",
  },
  ORDERS_ACTIVE_LIST: {
    url: getActiveOrdersListUrl(),
    text: "Активные заказы",
  },
  ORDERS_COMPLETED_LIST: {
    url: getOrdersCompletedListUrl(),
    text: "Выполненные заказы",
  },
  ORDERS_CANCELLED_LIST: {
    url: getOrdersCancelledListUrl(),
    text: "Отмененные заказы",
  },
  ORDERS_FOR_DAY: {
    url: getOrdersForDayUrl(),
    text: "Заказы за день",
  },
  ORDERS_CREATE: {
    url: getOrdersCreateUrl(),
    text: "Новый заказ",
  },
  REVIEWS_LIST: {
    url: getReviewsListUrl(),
    text: "Отзывы",
  },
  CITIES_LIST: {
    url: getCitiesListUrl(),
    text: "Города",
  },
  NOTES_LIST: {
    url: getNotesListUrl(),
    text: "Заметки",
  },
};
