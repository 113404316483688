
import { defineComponent, PropType, ref } from "vue";

import { FieldValue, BaseButton, FormField } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { OrderFullInterface } from "@/modules/orders/typings";
import { setUserText, setUserWorkersComment } from "@/modules/users";

export default defineComponent({
  name: "OrdersEditUserText",
  components: {
    FieldValue,
    FormField,
    BaseButton,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const value = ref<string>(props.order.user?.text || "");
    const formValue = ref<string>(props.order.user?.text || "");
    const loading = ref<boolean>(false);
    const formOpened = ref<boolean>(false);

    const workersValue = ref<string>(props.order.user?.workersText || "");
    const workersFormValue = ref<string>(props.order.user?.workersText || "");
    const workersLoading = ref<boolean>(false);
    const workersFormOpened = ref<boolean>(false);

    const onStart = () => {
      formOpened.value = true;
      formValue.value = value.value;
    };
    const onCancel = () => (formOpened.value = false);

    const workersOnStart = () => {
      workersFormOpened.value = true;
      workersFormValue.value = workersValue.value;
    };

    const workersOnCancel = () => (workersFormOpened.value = false);

    const onSubmit = async (e: Event) => {
      e.preventDefault();

      loading.value = true;

      try {
        await setUserText(props.order.user.id, formValue.value);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Сохранено",
        });

        value.value = formValue.value;
        formOpened.value = false;
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения",
        });
      } finally {
        loading.value = false;
      }
    };

    const workersOnSubmit = async (e: Event) => {
      e.preventDefault();

      workersLoading.value = true;

      try {
        await setUserWorkersComment(
          props.order.user.id,
          workersFormValue.value
        );

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Сохранено",
        });

        workersValue.value = workersFormValue.value;
        workersFormOpened.value = false;
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения",
        });
      } finally {
        workersLoading.value = false;
      }
    };

    return {
      value,
      loading,
      formOpened,
      formValue,
      onStart,
      onCancel,
      onSubmit,

      workersValue,
      workersLoading,
      workersFormOpened,
      workersFormValue,
      workersOnStart,
      workersOnCancel,
      workersOnSubmit,
    };
  },
});
