
import { defineComponent, ref } from "vue";

import { BaseCheckbox, OptionType, isValidSelectOption } from "@tager/admin-ui";

export default defineComponent({
  name: "CustomMultiSelect",
  components: { BaseCheckbox },
  model: {
    event: "change",
    prop: "selectedOptions",
  },
  props: {
    options: {
      type: Array,
      default: () => [],
      validator(options: Array<OptionType>) {
        return options.every(isValidSelectOption);
      },
    },
    selectedOptions: {
      type: Array,
      default: () => [],
      validator(options: Array<OptionType>) {
        return options.every(isValidSelectOption);
      },
    },
    name: {
      type: String,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: true,
    },
    isFullDay: {
      type: Boolean,
      required: true,
    },
  },

  setup(props, { emit }) {
    const focusedOption = ref<OptionType | null>(null);

    function isCheckedOption(option: OptionType) {
      return props.selectedOptions.includes(option.value);
    }

    function isFocusedOption(option: OptionType) {
      return focusedOption.value
        ? focusedOption.value.value === option.value
        : false;
    }

    function toggleOption(option: OptionType) {
      const isChecked = isCheckedOption(option);

      const newSelectedOptions = isChecked
        ? props.selectedOptions.filter(
            (selectedOption: any) => selectedOption !== option.value
          )
        : [...props.selectedOptions, option.value];

      console.log("toggleOption", option, newSelectedOptions);
      emit("change", newSelectedOptions);
    }

    function handleOptionFocus(option: OptionType) {
      focusedOption.value = option;
    }

    function handleOptionBlur(option: OptionType) {
      if (focusedOption.value && focusedOption.value.value === option.value) {
        focusedOption.value = null;
      }
    }

    return {
      isCheckedOption,
      isFocusedOption,
      toggleOption,
      handleOptionFocus,
      handleOptionBlur,
    };
  },
});
