
import { defineComponent } from "vue";

import StatsWidgetWrapper from "@/modules/stats/StatsView/components/StatsWidgetWrapper.vue";

export default defineComponent({
  name: "StatsWidget",
  components: { StatsWidgetWrapper },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      required: false,
      default: "",
    },
    value: {
      type: Array,
      default: () => [],
    },
    maxWidth: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    return {};
  },
});
