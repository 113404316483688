import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-759d7f90"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "user-table-cell" }
const _hoisted_2 = { key: 1 }
const _hoisted_3 = { class: "user-table-cell__name" }
const _hoisted_4 = { class: "user-table-cell__phone" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.canViewUser)
      ? (_openBlock(), _createBlock(_component_router_link, {
          key: 0,
          to: `/users/${_ctx.id}`,
          class: "user-table-cell__id"
        }, {
          default: _withCtx(() => [
            _createTextVNode(" № " + _toDisplayString(_ctx.id), 1)
          ]),
          _: 1
        }, 8, ["to"]))
      : (_openBlock(), _createElementBlock("span", _hoisted_2, " № " + _toDisplayString(_ctx.id), 1)),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.name), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.newPhoneFormat(_ctx.phone)), 1)
  ]))
}