import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-01f62174"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  key: 0,
  class: "orders-table-cell-subscription"
}
const _hoisted_2 = { key: 1 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!

  return (_ctx.model)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createVNode(_component_router_link, {
          to: `/subscriptions/${_ctx.model.id}`
        }, {
          default: _withCtx(() => [
            _createTextVNode(" № " + _toDisplayString(_ctx.model.id), 1)
          ]),
          _: 1
        }, 8, ["to"]),
        _createElementVNode("span", null, _toDisplayString(_ctx.getSubscriptionPeriodLabel(_ctx.model.mode)), 1),
        _createElementVNode("span", null, [
          _createElementVNode("strong", null, _toDisplayString(_ctx.model.index) + "-й заказ", 1)
        ])
      ]))
    : (_openBlock(), _createElementBlock("div", _hoisted_2, "Без подписки"))
}