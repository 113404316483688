import { request, ResponseBody } from "@tager/admin-services";

import { OrderType } from "./typings";

export function getOrderList(
  dateFrom: string,
  dateTo: string,
  city: number
): Promise<ResponseBody<Array<OrderType>>> {
  return request.get({
    path: `/admin/orders/addresses`,
    params: { dateFrom, dateTo, city },
  });
}
