
import { defineComponent, onMounted, watch } from "vue";

import {
  BaseButton,
  DataTable,
  EyeIcon,
  getFilterParams,
} from "@tager/admin-ui";
import { useDataTable } from "@tager/admin-ui";

import { getUsersCreateUrl, getUsersUpdateUrl } from "@/modules/users";
import PageRegional from "@/pages/PageRegional.vue";
import { useCityId } from "@/store/city";

import { getUsers } from "../services";
import { UserInterface } from "../typings";

import { COLUMN_DEFS } from "./UsersList.helpers";

export default defineComponent({
  name: "UsersList",
  components: {
    PageRegional,
    BaseButton,
    EyeIcon,
    DataTable,
  },
  setup() {
    const cityId = useCityId();

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: ordersList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
    } = useDataTable<UserInterface>({
      fetchEntityList: (params) =>
        getUsers({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          ...getFilterParams({
            city: cityId.value,
          }),
        }),
      initialValue: [],
      resourceName: "Список клиентов",
    });

    watch(cityId, fetchEntityList);

    onMounted(() => {
      fetchEntityList();
    });

    function isBusy(): boolean {
      return isDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: ordersList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      getUsersCreateUrl,
      getUsersUpdateUrl,
    };
  },
});
