
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { FormField, FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import { Page } from "@tager/admin-layout";

import { FullServiceCategoryType } from "@/types";
import {
  createServiceCategory,
  getServiceCategory,
  updateServiceCategory,
} from "@/requests";
import {
  convertFormValuesToServiceCategoryCreationPayload,
  convertFormValuesToServiceCategoryUpdatePayload,
  convertServiceCategoryToFormValues,
  FormValues,
} from "@/modules/services/ServiceCategoryForm/ServiceCategoryForm.helpers";
import {
  getServiceCategoryFormUrl,
  getServiceCategoryListUrl,
} from "@/modules/services";

export default defineComponent({
  name: "ServiceCategoryForm",
  components: { FormField, Page, FormFooter },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const serviceCategoryId = computed<string>(
      () => route.params.serviceCategoryId as string
    );

    const isCreation = computed<boolean>(
      () => serviceCategoryId.value === "create-category"
    );

    const [
      fetchServiceCategory,
      { data: serviceCategory, loading: isServiceCategoryLoading },
    ] = useResource<Nullable<FullServiceCategoryType>>({
      fetchResource: () => getServiceCategory(serviceCategoryId.value),
      initialValue: null,
      resourceName: "serviceCategory",
    });

    onMounted(() => {
      if (isCreation.value) return;
      fetchServiceCategory();
    });

    const values = ref<FormValues>(
      convertServiceCategoryToFormValues(serviceCategory.value)
    );

    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(serviceCategory, () => {
      values.value = convertServiceCategoryToFormValues(serviceCategory.value);
    });

    function submitForm(event: TagerFormSubmitEvent) {
      isSubmitting.value = true;

      const creationBody = convertFormValuesToServiceCategoryCreationPayload(
        values.value
      );

      const updateBody = convertFormValuesToServiceCategoryUpdatePayload(
        values.value
      );
      const requestPromise = isCreation.value
        ? createServiceCategory(creationBody)
        : updateServiceCategory(serviceCategoryId.value, updateBody);

      requestPromise
        .then((response) => {
          errors.value = {};

          if (event.type === "create") {
            router.push(
              getServiceCategoryFormUrl({ serviceCategoryId: response.data.id })
            );
          }
          if (event.type === "create_exit" || event.type === "save_exit") {
            navigateBack(router, getServiceCategoryListUrl());
          }
          if (event.type === "create_create-another") {
            values.value = convertServiceCategoryToFormValues(null);
          }

          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? `Категория успешно добавлена`
              : "Категория успешно обновлена",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? `Ошибка при добавление категории`
              : "Ошибка при редактирование категории",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(
      () => isServiceCategoryLoading.value
    );

    const PageTitle = computed<string>(() =>
      isCreation.value ? "Добавление категории" : "Редактирование категории"
    );

    return {
      backButtonUrl: getServiceCategoryListUrl(),
      values,
      errors,
      isSubmitting,
      submitForm,
      serviceCategory,
      isCreation,
      isContentLoading,
      PageTitle,
    };
  },
});
