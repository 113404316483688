import { RouteRecordRaw } from "vue-router";

import { ROUTE_PATHS } from "@/router/paths";
import { LINKS } from "@/router/links";

import ServiceCategoryList from "./ServiceCategoryList";
import ServiceCategoryForm from "./ServiceCategoryForm";
import AdditionalServiceCategoryList from "./AdditionalServiceCategoryList";
import AdditionalServiceCategoryForm from "./AdditionalServiceCategoryForm";
import SecondaryServiceList from "./SecondaryServiceList";
import SecondaryServiceForm from "./SecondaryServiceForm";
import ServiceCategoryMultipleChange from "./ServiceCategoryMultipleChange";

export const SERVICE_CATEGORY_MULTIPLE_CHANGE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_CATEGORY_MULTIPLE_CHANGE,
  component: ServiceCategoryMultipleChange,
  name: "Множественное редактирование",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.SERVICE_CATEGORY_LIST],
  },
};

export const SERVICE_CATEGORY_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_CATEGORY_LIST,
  component: ServiceCategoryList,
  name: "Основые услуги",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.SERVICE_CATEGORY_LIST],
  },
};

export const SERVICE_CATEGORY_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SERVICE_CATEGORY_FORM,
  component: ServiceCategoryForm,
  name: "Форма категории",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.SERVICE_CATEGORY_LIST],
  },
};

export const ADDITIONAL_SERVICE_CATEGORY_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ADDITIONAL_SERVICE_CATEGORY_LIST,
  component: AdditionalServiceCategoryList,
  name: "Услуги категории",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      { url: route.path, text: "Услуги категории" },
    ],
  },
};

export const ADDITIONAL_SERVICE_CATEGORY_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.ADDITIONAL_SERVICE_CATEGORY_FORM,
  component: AdditionalServiceCategoryForm,
  name: "Форма доп сервиса",
  meta: {
    getBreadcrumbs: (route) => [
      LINKS.HOME,
      { url: route.path, text: "Услуги категории" },
      { url: route.path, text: "Форма категории" },
    ],
  },
};

export const SECONDARY_SERVICE_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SECONDARY_SERVICE_LIST,
  component: SecondaryServiceList,
  name: "Дополнительные услуги",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.SECONDARY_SERVICE_LIST],
  },
};

export const SECONDARY_SERVICE_FORM_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SECONDARY_SERVICE_FORM,
  component: SecondaryServiceForm,
  name: "Форма услуги",
  meta: {
    getBreadcrumbs: (route) => [LINKS.HOME, LINKS.SECONDARY_SERVICE_LIST],
  },
};
