import { OptionType } from "@tager/admin-ui";
import { Nullable } from "@tager/admin-services";

import {
  NoteFullInterface,
  NoteCreatePayload,
  NoteUpdatePayload,
  NoteStatus,
  NOTE_STATUS_OPTIONS,
} from "../typings";

export type FormValues = {
  date: string;
  time: string | null;
  status: OptionType<NoteStatus>;
  user: Nullable<OptionType<number>>;
  message: string;
};

export const getInitialFormValues = (
  model: NoteFullInterface | null
): FormValues => {
  if (!model) {
    return {
      date: "",
      time: null,
      status: NOTE_STATUS_OPTIONS[0],
      user: null,
      message: "",
    };
  }

  return {
    date: model.date,
    time: model.time,
    status:
      NOTE_STATUS_OPTIONS.find((item) => item.value === model.status) ||
      NOTE_STATUS_OPTIONS[0],
    user: model.user
      ? {
          value: model.user.id,
          label: model.user.searchName,
        }
      : null,
    message: model.message,
  };
};

export function convertFormValuesToCreateNotePayload(
  values: FormValues
): NoteCreatePayload {
  return {
    user: values.user?.value || null,
    date: values.date,
    time: values.time,
    message: values.message,
  };
}

export function convertFormValuesToUpdateNotePayload(
  values: FormValues
): NoteUpdatePayload {
  return {
    user: values.user?.value || null,
    date: values.date,
    time: values.time,
    message: values.message,
    status: values.status.value,
  };
}
