import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-c6349d4c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "row-cols-2" }
const _hoisted_2 = { class: "left" }
const _hoisted_3 = { class: "right" }
const _hoisted_4 = { class: "row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormFieldOptionsSwitcherTrueFalse = _resolveComponent("FormFieldOptionsSwitcherTrueFalse")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormFieldOptionsSwitcherTrueFalse, {
        value: _ctx.value.isFree,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.value.isFree) = $event)),
        label: "Бесплатный заказ"
      }, null, 8, ["value"]),
      _createVNode(_component_FormFieldOptionsSwitcherTrueFalse, {
        value: _ctx.value.isHourPayment,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.value.isHourPayment) = $event)),
        label: "Почасовая оплата"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.price,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.value.price) = $event)),
        label: "Цена",
        type: "number"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.discount,
        "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.value.discount) = $event)),
        label: "Скидка, %,",
        type: "number"
      }, null, 8, ["value"]),
      _createVNode(_component_FormField, {
        value: _ctx.value.coefficient,
        "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.value.coefficient) = $event)),
        label: "Коэффициент",
        type: "number"
      }, null, 8, ["value"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_FieldValue, {
          label: "Стоимость заказа:",
          value: Math.round(_ctx.order.price.base * 100) / 100 + ' руб.'
        }, null, 8, ["value"]),
        _createVNode(_component_FieldValue, {
          label: "Скидка / Наценка:",
          value: 
            Math.round((_ctx.order.price.user - _ctx.order.price.base) * 100) / 100 +
            ' руб.'
          
        }, null, 8, ["value"])
      ]),
      _createVNode(_component_FieldValue, {
        label: "К оплате:",
        value: Math.round(_ctx.order.price.user * 100) / 100 + ' руб.'
      }, null, 8, ["value"]),
      _createVNode(_component_FieldValue, {
        label: "Вознаграждение клинеров:",
        value: Math.round(_ctx.order.price.workers * 100) / 100 + ' руб.'
      }, null, 8, ["value"]),
      _createVNode(_component_FieldValue, {
        label: "Наше вознаграждение:",
        value: 
          Math.round((_ctx.order.price.user - _ctx.order.price.workers) * 100) / 100 +
          ' руб.'
        
      }, null, 8, ["value"])
    ])
  ]))
}