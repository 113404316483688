
import { defineComponent, ref, watch } from "vue";

import { FormField, BaseButton, InputLabel } from "@tager/admin-ui";
import { useResource, Nullable } from "@tager/admin-services";

import { checkPromo } from "@/modules/orders/views/orders-create/services";
import { CheckPromoResponse } from "@/modules/orders/views/orders-create/typings";
import { PromoCode } from "@/modules/orders/views/orders-create/typings";

export default defineComponent({
  name: "OrdersCreatePromocode",
  components: {
    FormField,
    BaseButton,
    InputLabel,
  },
  props: {
    userId: {
      type: Number,
      required: false,
    },
    date: {
      type: String,
      required: false,
    },
  },
  emits: ["change", "change:loading"],
  setup(props, { emit }) {
    const model = ref<Nullable<PromoCode>>(null);
    const error = ref<Nullable<string>>(null);
    const code = ref<string>("");

    const [
      checkPromoCode,
      { data: promoCodeResponse, loading: isPromoCodeLoading },
    ] = useResource<Nullable<CheckPromoResponse>>({
      fetchResource: () =>
        checkPromo(code.value, props.date || "", props.userId || null),
      initialValue: null,
      resourceName: "Check Promo",
    });

    watch([isPromoCodeLoading], () => {
      emit("change:loading", isPromoCodeLoading.value);
    });

    const onSubmit = (e: FormDataEvent) => {
      e.preventDefault();

      error.value = null;
      checkPromoCode();
      emit("change", null);
    };

    const onCancel = () => {
      model.value = null;
      emit("change", null);
    };

    watch([promoCodeResponse], () => {
      if (promoCodeResponse.value?.error) {
        error.value = promoCodeResponse.value?.error || null;
      } else {
        const newValue =
          promoCodeResponse.value &&
          promoCodeResponse.value?.code &&
          promoCodeResponse.value?.discount
            ? {
                code: promoCodeResponse.value.code,
                discount: promoCodeResponse.value.discount,
              }
            : null;

        emit("change", newValue);
        model.value = newValue;
      }
    });

    return {
      code,
      onSubmit,
      model,
      error,
      isPromoCodeLoading,
      onCancel,
    };
  },
});
