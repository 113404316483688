import { UserCreatePayload } from "@/modules/users/typings";

export type FormValues = {
  name: string;
  phone: string;
  email: string;
};

export const initFormValues: FormValues = {
  name: "",
  phone: "",
  email: "",
};

export function convertFormValuesToCreateUserPayload(
  values: FormValues
): UserCreatePayload {
  return {
    name: values.name,
    phone: values.phone,
    email: values.email,
  };
}
