import { Nullable } from "@tager/admin-services";
import { OptionType } from "@tager/admin-ui";

export enum NoteStatus {
  Active = "ACTIVE",
  Done = "DONE",
}

export const NOTE_STATUS_OPTIONS: Array<OptionType<NoteStatus>> = [
  { value: NoteStatus.Active, label: "Активна" },
  { value: NoteStatus.Done, label: "Выполнена" },
];

export function getNoteStatusLabel(status: NoteStatus): string {
  return (
    NOTE_STATUS_OPTIONS.find((item) => item.value === status)?.label ||
    "Неизвестный"
  );
}

export type NoteInterface = {
  id: number;
  status: NoteStatus;
  date: string;
  time: string | null;
  user: Nullable<{
    id: number;
    name: string;
    phone: string;
    searchName: string;
  }>;
  manager: {
    id: number;
    name: string;
  };
  message: string;
  isToday: boolean;
};

export type NoteFullInterface = NoteInterface;

export type NoteCreatePayload = {
  user: number | null;
  date: string;
  time: string | null;
  message: string;
};

export type NoteUpdatePayload = NoteCreatePayload & {
  status: NoteStatus;
};
