
import { defineComponent } from "vue";

export default defineComponent({
  name: "PromocodeBadge",
  props: {
    code: String,
    discount: Number,
    noCancel: Boolean,
    cancelConfirm: String,
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  emits: ["cancel"],
  setup(props, { emit }) {
    const onCancel = () => {
      if (props.cancelConfirm) {
        if (confirm(props.cancelConfirm)) {
          emit("cancel");
        }
      } else {
        emit("cancel");
      }
    };

    return {
      onCancel,
    };
  },
});
