import { Nullable } from "@tager/admin-services";

import { FullCompanyType } from "@/types";
import { CompanyCreationPayload, CompanyUpdatePayload } from "@/requests";

export type FormValues = {
  name: string;
  fullName: string;
  headName: string;
  headNameParentCase: string;
  fromEmail: string;
  fromName: string;
};

export function convertCompanyToFormValues(
  company: Nullable<FullCompanyType>
): FormValues {
  if (!company) {
    return {
      name: "",
      fullName: "",
      headName: "",
      headNameParentCase: "",
      fromEmail: "",
      fromName: "",
    };
  }
  return {
    name: company.name ?? "",
    fullName: company.fullName ?? "",
    headName: company.headName ?? "",
    headNameParentCase: company.headNameParentCase ?? "",
    fromEmail: company.fromEmail ?? "",
    fromName: company.fromName ?? "",
  };
}

export function convertFormValuesToCompanyCreationPayload(
  values: FormValues
): CompanyCreationPayload {
  return {
    name: values.name ?? "",
    fullName: values.fullName ?? "",
    headName: values.headName ?? "",
    headNameParentCase: values.headNameParentCase ?? "",
    fromEmail: values.fromEmail ?? "",
    fromName: values.fromName ?? "",
  };
}

export function convertFormValuesToCompanyUpdatePayload(
  values: FormValues
): CompanyUpdatePayload {
  return {
    ...convertFormValuesToCompanyCreationPayload(values),
  };
}
