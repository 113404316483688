import { Nullable } from "@tager/admin-services";

import {
  ServiceCategoryCreationPayload,
  ServiceCategoryUpdatePayload,
} from "@/requests";
import { FullServiceCategoryType } from "@/types";

export type FormValues = {
  name: string;
  systemName: string;
  formLabel: string;
  emptyValueLabel: string;
};

export function convertServiceCategoryToFormValues(
  serviceCategory: Nullable<FullServiceCategoryType>
): FormValues {
  if (!serviceCategory) {
    return {
      name: "",
      systemName: "",
      formLabel: "",
      emptyValueLabel: "",
    };
  }
  return {
    name: serviceCategory.name,
    systemName: serviceCategory.systemName,
    formLabel: serviceCategory.formLabel,
    emptyValueLabel: serviceCategory.emptyValueLabel,
  };
}

export function convertFormValuesToServiceCategoryCreationPayload(
  values: FormValues
): ServiceCategoryCreationPayload {
  return {
    name: values.name,
    systemName: values.systemName,
    formLabel: values.formLabel,
    emptyValueLabel: values.emptyValueLabel,
  };
}

export function convertFormValuesToServiceCategoryUpdatePayload(
  values: FormValues
): ServiceCategoryUpdatePayload {
  return { ...convertFormValuesToServiceCategoryCreationPayload(values) };
}
