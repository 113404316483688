import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_AdvancedSearchDateFilter = _resolveComponent("AdvancedSearchDateFilter")!
  const _component_AdvancedSearch = _resolveComponent("AdvancedSearch")!

  return (_openBlock(), _createBlock(_component_AdvancedSearch, {
    tags: _ctx.tags,
    "onClick:tag": _ctx.tagRemovalHandler
  }, {
    default: _withCtx(() => [
      _createVNode(_component_AdvancedSearchDateFilter, {
        filter: _ctx.dateCreatedFilter,
        "onUpdate:filter": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.dateCreatedFilter) = $event)),
        label: "Дата найма",
        name: "date-created"
      }, null, 8, ["filter"]),
      _createVNode(_component_AdvancedSearchDateFilter, {
        filter: _ctx.dateArchivedFilter,
        "onUpdate:filter": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.dateArchivedFilter) = $event)),
        label: "Дата архивации",
        name: "date-archived"
      }, null, 8, ["filter"])
    ]),
    _: 1
  }, 8, ["tags", "onClick:tag"]))
}