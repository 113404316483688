import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_router_link = _resolveComponent("router-link")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_OrderDateTimeEdit = _resolveComponent("OrderDateTimeEdit")!

  return (_ctx.subscription.isCompleted)
    ? (_openBlock(), _createBlock(_component_FieldValue, {
        key: 0,
        label: "Последний заказ"
      }, {
        value: _withCtx(() => [
          _createVNode(_component_router_link, {
            to: `${_ctx.getOrderUrl(_ctx.lastOrderId)}`
          }, {
            default: _withCtx(() => [
              _createTextVNode("ID " + _toDisplayString(_ctx.lastOrderId), 1)
            ]),
            _: 1
          }, 8, ["to"]),
          _createTextVNode(" - № " + _toDisplayString(_ctx.lastOrderNumber) + " - " + _toDisplayString(_ctx.formatDateTime(_ctx.lastOrderDateTime)), 1)
        ]),
        _: 1
      }))
    : (_openBlock(), _createBlock(_component_FieldValue, {
        key: 1,
        label: "Следующий заказ",
        "edit-active": _ctx.editDateActive,
        loading: _ctx.skipLoading,
        buttons: 
      [
        _ctx.subscription.lastOrder
          ? {
              label: 'Просмотреть',
              variant: 'secondary-link',
              to: _ctx.getOrdersViewUrl(_ctx.subscription.lastOrder.id),
              useRouter: true,
            }
          : null,
        {
          label: 'Отменить',
          variant: 'red-link',
          onClick: _ctx.onSkipClick,
        },
        {
          label: 'Перенести',
          variant: 'primary-link',
          onClick: _ctx.onEditStart,
        },
      ].filter(_ctx.isNotNullish)
    
      }, {
        value: _withCtx(() => [
          _createTextVNode(" ID " + _toDisplayString(_ctx.lastOrderId) + " - № " + _toDisplayString(_ctx.lastOrderNumber) + " - " + _toDisplayString(_ctx.formatDateTime(_ctx.lastOrderDateTime)), 1)
        ]),
        edit: _withCtx(() => [
          _createVNode(_component_OrderDateTimeEdit, {
            "order-id": _ctx.subscription.lastOrder.id,
            datetime: _ctx.lastOrderDateTime,
            onCancel: _ctx.onEditCancel,
            onSubmit: _ctx.onEditSubmit
          }, null, 8, ["order-id", "datetime", "onCancel", "onSubmit"])
        ]),
        _: 1
      }, 8, ["edit-active", "loading", "buttons"]))
}