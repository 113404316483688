
import { defineComponent, ref } from "vue";

import { useUserPermission } from "@tager/admin-services";

import { PhoneIcon, TelegramIcon, ViberIcon } from "@/assets/icons";
import WorkDayBlock from "@/components/WorkDayBlock.vue";
import Report from "@/components/Report.vue";
import InfoForTimetable from "@/components/InfoForTimetable.vue";
import { getWorkerFormUrl } from "@/utils/paths";
import { newPhoneFormat } from "@/utils/common";
import { newDateFormat } from "@/utils/common";
import { Scope } from "@/rbac/Scope";

export default defineComponent({
  name: "CleanerWidget",

  components: {
    WorkDayBlock,
    PhoneIcon,
    TelegramIcon,
    ViberIcon,
    Report,
    InfoForTimetable,
  },
  props: {
    worker: {
      type: Object,
      required: true,
    },
    workDay: {
      type: Boolean,
      default: false,
    },
    from: {
      type: String,
    },
    to: {
      type: String,
    },
    report: {
      type: Boolean,
      default: false,
    },
    isInfoTimetable: {
      type: Boolean,
      default: false,
    },
    isInfoBusyDaysEnabled: {
      type: Boolean,
      default: false,
    },
    isInfoBusyDaysLoading: {
      type: Boolean,
      default: false,
    },
    infoBusyDaysValue: {
      type: Number,
      default: 0,
    },
    hourRate: {
      type: Number,
      default: 0,
    },
  },

  setup() {
    const isOpenInfoTimetable = ref<boolean>(false);

    function handleOpenInfoTimetable() {
      isOpenInfoTimetable.value = !isOpenInfoTimetable.value;
    }

    const canViewWorker = useUserPermission(Scope.WorkersView);

    return {
      getWorkerFormUrl,
      newPhoneFormat,
      newDateFormat,
      handleOpenInfoTimetable,
      isOpenInfoTimetable,
      canViewWorker,
    };
  },
});
