
import { computed, defineComponent, ref, watch } from "vue";

import { BaseSelect, OptionType } from "@tager/admin-ui";

import { CloseIcon } from "@/assets/icons";
import { setWorkingDay } from "@/requests";
import { getTime } from "@/utils/common";
import { getOrdersViewUrl } from "@/modules/orders";

import OrderList from "./components/OrderList.vue";

export default defineComponent({
  name: "WorkCell",

  components: {
    OrderList,
    CloseIcon,
    BaseSelect,
  },
  props: {
    timetable: {
      type: Object,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    isDisable: {
      type: Function,
      required: true,
    },
    orderList: {
      type: Array,
      required: true,
    },
    onChange: {
      type: Function,
      required: false,
    },
  },

  setup(props) {
    const timeOptionList = computed<Array<OptionType>>(() => [
      {
        value: "08:00",
        label: "08:00",
      },
      {
        value: "08:30",
        label: "08:30",
      },
      {
        value: "09:00",
        label: "09:00",
      },
      {
        value: "09:30",
        label: "09:30",
      },
      {
        value: "10:00",
        label: "10:00",
      },
      {
        value: "10:30",
        label: "10:30",
      },
      {
        value: "11:00",
        label: "11:00",
      },
      {
        value: "11:30",
        label: "11:30",
      },
      {
        value: "12:00",
        label: "12:00",
      },
      {
        value: "12:30",
        label: "12:30",
      },
      {
        value: "13:00",
        label: "13:00",
      },
      {
        value: "13:30",
        label: "13:30",
      },
      {
        value: "14:00",
        label: "14:00",
      },
      {
        value: "14:30",
        label: "14:30",
      },
      {
        value: "15:00",
        label: "15:00",
      },
      {
        value: "15:30",
        label: "15:30",
      },
      {
        value: "16:00",
        label: "16:00",
      },
      {
        value: "16:30",
        label: "16:30",
      },
      {
        value: "17:00",
        label: "17:00",
      },
      {
        value: "17:30",
        label: "17:30",
      },
      {
        value: "18:00",
        label: "18:00",
      },
      {
        value: "18:30",
        label: "18:30",
      },
      {
        value: "19:00",
        label: "19:00",
      },
      {
        value: "19:30",
        label: "19:30",
      },
      {
        value: "20:00",
        label: "20:00",
      },
    ]);
    const isWork = ref<boolean>(!!props.timetable.range);
    const values = ref<{ from: OptionType; to: OptionType }>({
      from: {
        value: props.timetable.range ? props.timetable.range.from : "08:00",
        label: props.timetable.range ? props.timetable.range.from : "08:00",
      },
      to: {
        value: props.timetable.range ? props.timetable.range.to : "20:00",
        label: props.timetable.range ? props.timetable.range.to : "20:00",
      },
    });

    function toggleIsWork() {
      isWork.value = !isWork.value;
      if (props.onChange) {
        props.onChange(isWork.value);
      }
    }

    function setTimeWorkingDay(from: string, to: string) {
      setWorkingDay(props.id, {
        date: props.timetable.date,
        value: {
          startAt: from,
          finishAt: to,
        },
      });
    }

    watch(
      () => props.timetable,
      () => {
        isWork.value = !!props.timetable.range;

        values.value = {
          from: {
            value: props.timetable.range ? props.timetable.range.from : "08:00",
            label: props.timetable.range ? props.timetable.range.from : "08:00",
          },
          to: {
            value: props.timetable.range ? props.timetable.range.to : "20:00",
            label: props.timetable.range ? props.timetable.range.to : "20:00",
          },
        };
      }
    );

    function onFromChange(e: OptionType) {
      setTimeWorkingDay(e.value, values.value.to.value);
    }

    function onToChange(e: OptionType) {
      setTimeWorkingDay(values.value.from.value, e.value);
    }

    return {
      setWorkingDay,
      timeOptionList,
      getTime,
      getOrdersViewUrl,
      toggleIsWork,
      setTimeWorkingDay,
      isWork,
      values,
      onFromChange,
      onToChange,
    };
  },
});
