import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-5e5e974a"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "address-table-cell" }
const _hoisted_2 = {
  key: 0,
  class: "address-table-cell__type"
}
const _hoisted_3 = { class: "address-table-cell__value" }
const _hoisted_4 = ["href"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.type === 'HOUSE')
      ? (_openBlock(), _createElementBlock("span", _hoisted_2, "Частный дом"))
      : _createCommentVNode("", true),
    _createElementVNode("span", _hoisted_3, _toDisplayString(_ctx.address), 1),
    (_ctx.latitude && _ctx.longitude)
      ? (_openBlock(), _createElementBlock("a", {
          key: 1,
          href: `https://maps.yandex.ru/?text=${_ctx.latitude}+${_ctx.longitude}`,
          target: "_blank"
        }, " На карте ", 8, _hoisted_4))
      : _createCommentVNode("", true)
  ]))
}