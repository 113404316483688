import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EditIcon = _resolveComponent("EditIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_SouthIcon = _resolveComponent("SouthIcon")!
  const _component_NorthIcon = _resolveComponent("NorthIcon")!
  const _component_DeleteIcon = _resolveComponent("DeleteIcon")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Города",
    "header-buttons": [
      {
        text: 'Новый город',
        href: _ctx.getCitiesCreateUrl(),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        "cell(actions)": _withCtx(({ row, rowIndex }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Edit",
              disabled: _ctx.isBusy(row.id),
              href: _ctx.getCitiesUpdateUrl(row.id)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EditIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "href"]),
            _createVNode(_component_BaseButton, {
              variant: "icon",
              disabled: _ctx.isBusy(row.id) || rowIndex === _ctx.rowData.length - 1,
              onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'down'))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_SouthIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"]),
            _createVNode(_component_BaseButton, {
              variant: "icon",
              disabled: _ctx.isBusy(row.id) || rowIndex === 0,
              onClick: ($event: any) => (_ctx.handleResourceMove(row.id, 'up'))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_NorthIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"]),
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Delete",
              disabled: _ctx.isBusy(row.id) || !row.canDelete,
              onClick: ($event: any) => (_ctx.handleResourceDelete(row.id))
            }, {
              default: _withCtx(() => [
                _createVNode(_component_DeleteIcon)
              ]),
              _: 2
            }, 1032, ["disabled", "onClick"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}