
import { computed, defineComponent, onMounted } from "vue";

import { MenuItemType, PageLayout } from "@tager/admin-layout";

import { getMenuItemList } from "@/config/sidebarMenu";
import { HeaderCitySelector } from "@/modules/layout";
import { useCityStore } from "@/store/city";
import { useNotesStore } from "@/store/notes";

export default defineComponent({
  name: "App",
  components: { HeaderCitySelector, PageLayout },
  setup() {
    const cityStore = useCityStore();
    const notesStore = useNotesStore();

    onMounted(() => {
      notesStore.update();
    });

    const sidebarMenuList = computed<Array<MenuItemType>>(() =>
      getMenuItemList({
        citySelected: cityStore.selectedCity !== 0,
        notesCount: notesStore.getCounterValue,
      })
    );

    return {
      sidebarMenuList,
    };
  },
});
