import { request, ResponseBody } from "@tager/admin-services";

import { CountData } from "@/requests";
import { ReviewInterface } from "@/modules/reviews/typings";

export function getReviewsCount(
  city?: number
): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/reviews/count", params: query });
}

export function getReviews(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<ReviewInterface>>> {
  return request.get({ path: "/admin/reviews", params });
}

export function saveReviewSolution(id: number, solution: string) {
  return request.post({ path: "/admin/reviews/" + id, body: { solution } });
}
