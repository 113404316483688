
import { computed, defineComponent } from "vue";

import {
  BaseButton,
  ColumnDefinition,
  CountButton,
  DeleteIcon,
  EditIcon,
  useDataTable,
  DataTable,
} from "@tager/admin-ui";
import { useResourceDelete } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { deleteCompany, getCompanyList } from "@/requests";
import { CompanyType } from "@/types";
import {
  getCompanyListUrl,
  getCompanyFormUrl,
  getActiveWorkerListUrl,
} from "@/utils/paths";

const COLUMN_DEFS: Array<ColumnDefinition<CompanyType>> = [
  {
    id: 1,
    name: "ID",
    field: "id",
    style: { width: "50px", textAlign: "center" },
    headStyle: { width: "50px", textAlign: "center" },
  },
  {
    id: 2,
    name: "Название",
    field: "name",
  },
  {
    id: 3,
    name: "Полное название",
    field: "fullName",
  },

  {
    id: 4,
    name: "Директор",
    field: "headName",
  },

  {
    id: 5,
    name: "",
    field: "workers",
    style: { width: "158px", whiteSpace: "nowrap" },
  },
  {
    id: 6,
    name: "Действия",
    field: "actions",
    style: { width: "93px", textAlign: "center", whiteSpace: "nowrap" },
    headStyle: { width: "93px", textAlign: "center" },
  },
];

export default defineComponent({
  name: "CompanyList",
  components: {
    DeleteIcon,
    EditIcon,
    BaseButton,
    CountButton,
    Page,
    DataTable,
  },
  setup() {
    const {
      isLoading: isCompanyListLoading,
      rowData: companyList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      fetchEntityList: fetchCompanyList,
    } = useDataTable<CompanyType>({
      fetchEntityList: (params) =>
        getCompanyList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
        }),
      initialValue: [],
      resourceName: "Company list",
    });

    const { handleResourceDelete: handleCompanyDelete, isDeleting } =
      useResourceDelete({
        deleteResource: deleteCompany,
        resourceName: "Company",
        onSuccess: fetchCompanyList,
      });

    const isRowDataLoading = computed<boolean>(
      () => isCompanyListLoading.value
    );

    function isBusy(companyId: number): boolean {
      return isDeleting(companyId) || isRowDataLoading.value;
    }

    return {
      columnDefs: COLUMN_DEFS,
      rowData: companyList,
      isRowDataLoading,
      errorMessage,
      isBusy,
      getCompanyFormUrl,
      getCompanyListUrl,
      getActiveWorkerListUrl,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      handleCompanyDelete,
    };
  },
});
