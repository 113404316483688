import { resolveComponent as _resolveComponent, createVNode as _createVNode, withModifiers as _withModifiers, createElementVNode as _createElementVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: _ctx.PageTitle,
    "is-content-loading": _ctx.isContentLoading
  }, {
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "back-href": _ctx.backButtonUrl,
        "is-submitting": _ctx.isSubmitting,
        "is-creation": _ctx.isCreation,
        "can-create-another": _ctx.isCreation,
        onSubmit: _ctx.submitForm
      }, null, 8, ["back-href", "is-submitting", "is-creation", "can-create-another", "onSubmit"])
    ]),
    default: _withCtx(() => [
      _createElementVNode("form", {
        novalidate: "",
        onSubmit: _cache[4] || (_cache[4] = _withModifiers(() => {}, ["prevent"]))
      }, [
        _createVNode(_component_FormField, {
          value: _ctx.values.name,
          "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
          name: "name",
          error: _ctx.errors.name,
          label: "Название"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.systemName,
          "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.systemName) = $event)),
          name: "systemName",
          error: _ctx.errors.systemName,
          label: "Системное название "
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.formLabel,
          "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.formLabel) = $event)),
          name: "formLabel",
          error: _ctx.errors.formLabel,
          label: "Название на форме"
        }, null, 8, ["value", "error"]),
        _createVNode(_component_FormField, {
          value: _ctx.values.emptyValueLabel,
          "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.emptyValueLabel) = $event)),
          name: "emptyValueLabel",
          error: _ctx.errors.emptyValueLabel,
          label: "Значение, когда выбрано 0 элементов"
        }, null, 8, ["value", "error"])
      ], 32)
    ]),
    _: 1
  }, 8, ["title", "is-content-loading"]))
}