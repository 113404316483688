// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
import { format } from "date-fns";

import { Nullable } from "@tager/admin-services";

import { getOrderUrl } from "@/modules/orders";

export type MapOrder = {
  id: number;
  datetime: string;
  address: string;
  latitude: number;
  longitude: number;
  workers: Array<{
    id: number;
    name: string;
    number: string;
    isArchive: boolean;
    distanceMeters: number;
  }>;
};

export function createOrderPlacemark(order: MapOrder): window.ymaps.Placemark {
  const contentHeaderBalloon = `Заказ № ${order.id}<br/>`;
  const contentBodyBalloon: Nullable<Array<string>> = order.workers.length
    ? order.workers.map(
        (worker: any) =>
          `Клинер: <a
                href="/admin/workers/${worker.id}"
                target="_blank"
                class="user-link"
                ><b>${worker.name} (№ ${worker.number})</b></a> - ${(
            worker.distanceMeters / 1000
          ).toFixed(1)} км
                <i>
                   ${worker.isArchive ? worker.isArchive : ""}
                </i>
                <br/>`
      )
    : null;

  return new window.ymaps.Placemark(
    [order.latitude, order.longitude],
    {
      balloonContentHeader: contentHeaderBalloon,
      balloonContentBody:
        `${format(new Date(order.datetime), "dd.MM.yyyy kk:mm")}` +
        "<br/>" +
        order.address +
        "<br/><br/>" +
        (contentBodyBalloon?.length
          ? contentBodyBalloon?.join("")
          : "Нет клинера"),
      balloonContentFooter: `<a href="/admin${getOrderUrl(
        order.id
      )}" target="_blank" class="button-link">Просмотреть заказ</a>`,
    },
    {
      preset: "islands#darkGreenIcon",
    }
  );
}
