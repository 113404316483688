
import { addWeeks, endOfWeek, format, startOfWeek, subWeeks } from "date-fns";
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import { ru } from "date-fns/locale";

import {
  BaseButton,
  ColumnDefinition,
  BaseSelect,
  OptionType,
} from "@tager/admin-ui";
import { useResource } from "@tager/admin-services";

import {
  getTimetableList,
  getTimetableOrderList,
  getTimetableStats,
  getTimetableWorkingDays,
  setWorkingDays,
} from "@/requests";
import { generateReport } from "@/modules/workers/requests";
import {
  FullTimetableType,
  TimetableOrderType,
  TimetableStatsType,
  TimetableWorkingDaysItem,
} from "@/types";
import { dateToString, newDateFormat, newPhoneFormat } from "@/utils/common";
import { getWorkerFormUrl } from "@/utils/paths";
import { router } from "@/router/router";
import CleanerWidget from "@/components/CleanerWidget.vue";
import PageRegional from "@/pages/PageRegional.vue";
import { useCityId, useCityStore } from "@/store/city";

import WorkCell from "./components/WorkCell";
import ProgressBar from "./components/ProgressBar";

export default defineComponent({
  name: "TimetableList",
  components: {
    PageRegional,
    BaseButton,
    WorkCell,
    CleanerWidget,
    ProgressBar,
    BaseSelect,
  },
  setup() {
    const route = useRoute();

    const cityStore = useCityStore();
    const city = computed<number | null>(() => cityStore.activeCityId);

    const selectDate = ref<Date>(
      route.query.date ? new Date(String(route.query.date)) : new Date()
    );

    const isSidebarCollapsed = ref<boolean>(
      localStorage.getItem("isSidebarCollapsed") === "true"
    );

    const dateForUrl = ref<Date>(new Date());

    const pageTitle = ref<string>("");

    const isStatistic = ref<boolean>(false);

    const COLUMN_DEFS = ref<Array<ColumnDefinition<FullTimetableType>>>([]);

    const week = ref<Array<string>>([]);

    const busyDatesByWorker = ref<Array<Array<string>>>([]);

    function getWeekDate() {
      week.value = [];
      for (let i = 1; i <= 7; i++) {
        const first =
          selectDate.value.getDate() - selectDate.value.getDay() + i;
        const day = dateToString(new Date(selectDate.value.setDate(first)));

        week.value.push(day.toString());
      }
      dateForUrl.value = new Date(week.value[0]);
    }

    function tableCreate() {
      COLUMN_DEFS.value = [
        {
          id: 1,
          name: "Клинер",
          field: "cleaner",
          headStyle: {
            minWidth: "280px",
            maxWidth: "280px",
            width: "280px",
          },
        },
        {
          id: 2,
          name: "ПН\n" + newDateFormat(week.value[0]),
          field: "monday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 3,
          name: "ВТ" + "\n" + newDateFormat(week.value[1]),
          field: "tuesday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 4,
          name: "СР" + "\n" + newDateFormat(week.value[2]),
          field: "wednesday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 5,
          name: "ЧТ" + "\n" + newDateFormat(week.value[3]),
          field: "thursday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 6,
          name: "ПТ" + "\n" + newDateFormat(week.value[4]),
          field: "friday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 7,
          name: "СБ" + "\n" + newDateFormat(week.value[5]),
          field: "saturday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
        {
          id: 8,
          name: "ВСК".trim() + "\n" + newDateFormat(week.value[6]),
          field: "sunday",
          headStyle: {
            minWidth: "200px",
          },
          style: {
            padding: "0",
            position: "relative",
            maxWidth: "200px",
            height: "100px",
          },
        },
      ];
    }

    function createPageTitle() {
      pageTitle.value =
        "Расписание с " +
        newDateFormat(week.value[0]) +
        " по " +
        newDateFormat(week.value[6]);
    }

    const [
      fetchTimetableList,
      { data: timetableList, loading: isTimetableListLoading, error },
    ] = useResource<Array<FullTimetableType>>({
      fetchResource: () =>
        getTimetableList(
          format(new Date(week.value[0]), "yyyy-MM-dd"),
          format(new Date(week.value[6]), "yyyy-MM-dd"),
          useCityId().value
        ),
      initialValue: [],
      resourceName: "Timetable list",
    });

    const [
      fetchTimetableBusyDays,
      { data: timetableBusyList, loading: isTimetableBusyListLoading },
    ] = useResource<Array<TimetableWorkingDaysItem>>({
      fetchResource: () => {
        const startOfWeek = new Date(Date.parse(week.value[0]));
        const startOfMonth = new Date(
          startOfWeek.getFullYear(),
          startOfWeek.getMonth(),
          1
        );
        const endOfMonth = new Date(
          startOfWeek.getFullYear(),
          startOfWeek.getMonth() + 1,
          0
        );
        return getTimetableWorkingDays(
          format(startOfMonth, "yyyy-MM-dd"),
          format(endOfMonth, "yyyy-MM-dd"),
          useCityId().value
        );
      },
      initialValue: [],
      resourceName: "Timetable Busy list",
    });

    const [
      fetchTimetableOrderList,
      { data: timetableOrderList, loading: isTimetableOrderListLoading },
    ] = useResource<Array<TimetableOrderType>>({
      fetchResource: () =>
        getTimetableOrderList(
          format(new Date(week.value[0]), "yyyy-MM-dd"),
          format(new Date(week.value[6]), "yyyy-MM-dd"),
          useCityId().value
        ),
      initialValue: [],
      resourceName: "Timetable order list",
    });

    const [
      fetchTimetableStats,
      { data: timetableStats, loading: isTimetableStatsLoading },
    ] = useResource<Array<TimetableStatsType>>({
      fetchResource: () =>
        getTimetableStats(
          format(new Date(week.value[0]), "yyyy-MM-dd"),
          format(new Date(week.value[6]), "yyyy-MM-dd"),
          useCityId().value
        ),
      initialValue: [],
      resourceName: "Timetable order list",
    });

    const load = () => {
      if (city.value) {
        fetchTimetableList();
        fetchTimetableOrderList();
        fetchTimetableStats();
        fetchTimetableBusyDays();
      }
    };
    onMounted(() => {
      getWeekDate();
      tableCreate();
      createPageTitle();
      load();
    });
    watch(city, load);

    function updateWeek(flipDays: number) {
      dateForUrl.value.setDate(dateForUrl.value.getDate() + flipDays);
      dateForUrl.value = new Date(dateForUrl.value);
      selectDate.value = new Date(dateForUrl.value);
      router.push({
        query: { date: dateForUrl.value.toISOString().slice(0, 10) },
      });
    }

    function orderFilter(id: number, date: string) {
      const orderList = timetableOrderList.value.filter((order) =>
        order.workers.some((worker) => worker.id === id)
      );
      return orderList.filter(
        (order) => newDateFormat(order.datetime) === newDateFormat(date)
      );
    }

    function isBusy(): boolean {
      return (
        isTimetableListLoading.value ||
        isTimetableOrderListLoading.value ||
        isTimetableStatsLoading.value
      );
    }

    function toggleShowStatistic() {
      isStatistic.value = !isStatistic.value;
    }

    watch(selectDate, () => {
      getWeekDate();
      tableCreate();
      createPageTitle();
      fetchTimetableList();
      fetchTimetableOrderList();
      fetchTimetableStats();
      fetchTimetableBusyDays();
    });

    watch([timetableBusyList, timetableList, timetableOrderList], () => {
      busyDatesByWorker.value = [];

      timetableList.value.forEach((item) => {
        const found = timetableBusyList.value.find(
          (_item) => item.worker.id === _item.workerId
        );
        if (found) {
          busyDatesByWorker.value[item.worker.id] = found.busyDates;
        }
      });
    });

    const isRowDataLoading = computed<boolean>(
      () =>
        isTimetableListLoading.value ||
        isTimetableOrderListLoading.value ||
        isTimetableStatsLoading.value
    );

    function onChangeBusy(workerId: number, dayInd: number, value: boolean) {
      const day = dateForUrl.value.getDate() + dayInd;
      const startOfWeek = new Date(Date.parse(week.value[0]));
      const date = new Date(
        startOfWeek.getFullYear(),
        startOfWeek.getMonth(),
        day
      );

      const dateFormat = format(date, "yyyy-MM-dd");

      busyDatesByWorker.value = busyDatesByWorker.value.map((item, ind) => {
        if (ind !== workerId) return item;

        if (value) {
          return [...item, dateFormat];
        } else {
          return item.filter((value) => value !== dateFormat);
        }
      });
    }

    function getStandardNumberOfPersonDays() {
      const totalDays = timetableList.value.reduce((acc, timetable) => {
        return (acc += Number(timetable.worker.workingDays));
      }, 0);
      return totalDays / 4;
    }

    function getActualNumberOfPersonDays() {
      return timetableList.value.reduce((acc, timetable) => {
        return (acc += Number(
          timetable.timetable.reduce((acc, timetable) => {
            return (acc += Number(!!timetable.range));
          }, 0)
        ));
      }, 0);
    }

    function getWeekOptions(): Array<OptionType<string>> {
      let date = startOfWeek(addWeeks(new Date(), 2), { locale: ru });

      let result = [];

      for (let i = 0; i < 100; i++) {
        result.push({
          label:
            format(date, "dd.MM.yyyy") +
            " - " +
            format(endOfWeek(date, { locale: ru }), "dd.MM.yyyy"),
          value: format(date, "yyyy-MM-dd"),
        });

        date = subWeeks(date, 1);
      }

      return result;
    }

    const selectedDate = computed<OptionType<string>>(() => {
      return {
        label:
          format(startOfWeek(selectDate.value, { locale: ru }), "dd.MM.yyyy") +
          " - " +
          format(endOfWeek(selectDate.value, { locale: ru }), "dd.MM.yyyy"),
        value: format(
          startOfWeek(selectDate.value, { locale: ru }),
          "yyyy-MM-dd"
        ),
      };
    });

    const onChangeWeek = (value: OptionType<string>) => {
      selectDate.value = new Date(value.value);
      router.push({
        query: { date: value.value },
      });
    };

    return {
      columnDefs: COLUMN_DEFS,
      rowData: timetableList,
      isRowDataLoading,
      errorMessage: error,
      generateReport,
      fetchTimetableList,
      isBusy,
      newDateFormat,
      updateWeek,
      getWorkerFormUrl,
      setWorkingDays,
      pageTitle,
      newPhoneFormat,
      timetableOrderList,
      orderFilter,
      week,
      isSidebarCollapsed,
      timetableStats,
      toggleShowStatistic,
      isStatistic,

      timetableBusyList,
      isTimetableBusyListLoading,

      getStandardNumberOfPersonDays,
      getActualNumberOfPersonDays,
      busyDatesByWorker,
      onChangeBusy,

      weekOptions: getWeekOptions(),
      selectedDate,
      onChangeWeek,
    };
  },
});
