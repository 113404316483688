import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-4148b2c0"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "close-select-block" }
const _hoisted_2 = { class: "checkbox-block full-close-block" }
const _hoisted_3 = ["for"]
const _hoisted_4 = { class: "checkbox-block close-time-slots-block" }
const _hoisted_5 = ["for"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseCheckbox = _resolveComponent("BaseCheckbox")!
  const _component_CustomMultiSelect = _resolveComponent("CustomMultiSelect")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_BaseCheckbox, {
          id: `${_ctx.newDateFormat}-fullClose`,
          checked: _ctx.fullClose,
          "onUpdate:checked": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.fullClose) = $event)),
          class: "checkbox",
          onChange: _ctx.closeOrOpenFull
        }, null, 8, ["id", "checked", "onChange"]),
        _createElementVNode("label", {
          for: `${_ctx.newDateFormat}-fullClose`
        }, " Закрыть полностью ", 8, _hoisted_3)
      ]),
      _createElementVNode("div", _hoisted_4, [
        _createVNode(_component_BaseCheckbox, {
          id: `${_ctx.newDateFormat}-closeTimeSlots`,
          checked: _ctx.closeTimeSlots,
          "onUpdate:checked": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.closeTimeSlots) = $event)),
          class: "checkbox",
          onChange: _ctx.openDay
        }, null, 8, ["id", "checked", "onChange"]),
        _createElementVNode("label", {
          for: `${_ctx.newDateFormat}-closeTimeSlots`
        }, " Закрыть частично ", 8, _hoisted_5)
      ])
    ]),
    _createElementVNode("div", {
      class: _normalizeClass(["time-select", { blur: !_ctx.fullClose && !_ctx.closeTimeSlots }])
    }, [
      _createVNode(_component_CustomMultiSelect, {
        "selected-options": _ctx.closeTimeSlotsValues,
        name: _ctx.newDateFormat,
        options: _ctx.timeOptionList,
        "is-disabled": _ctx.closeTimeSlots,
        "is-full-day": _ctx.fullClose,
        onChange: _ctx.closeOrOpenTimeSlot
      }, null, 8, ["selected-options", "name", "options", "is-disabled", "is-full-day", "onChange"])
    ], 2)
  ]))
}