
import { defineComponent, ref, computed, nextTick } from "vue";
import { clone } from "lodash";
import { useRouter } from "vue-router";

import { FormFooter, TagerFormSubmitEvent } from "@tager/admin-ui";
import { navigateBack, useToast } from "@tager/admin-services";
import { Page } from "@tager/admin-layout";

import { createOrder } from "@/modules/orders/views/orders-create/services";
import {
  CustomUserData,
  SecondaryService,
} from "@/modules/orders/views/orders-create/typings";
import { minutesToTimeString } from "@/utils/formatter";
import { getActiveOrdersListUrl, getOrdersViewUrl } from "@/modules/orders";
import { useFetchServices } from "@/modules/orders/hooks/useFetchServices";
import { AddressFormFields } from "@/modules/orders/typings";
import OrderFooterInfo from "@/modules/orders/components/OrderFooterInfo.vue";
import { OrderHouseType } from "@/enums/OrderHouseType";

import Step from "./components/Step.vue";
import OrdersCreateCustomer from "./components/OrdersCreateCustomer.vue";
import OrdersCreateDateTime from "./components/OrdersCreateDateTime.vue";
import OrdersCreateOrderAddress from "./components/OrdersCreateAddress.vue";
import OrdersCreateServices from "./components/OrdersCreateServices.vue";
import OrdersCreateAdditional from "./components/OrdersCreateAdditional.vue";
import {
  convertFormValuesToCreatePayload,
  defaultFormValues,
  FormValues,
  priceAndTimeCalculate,
} from "./OrdersCreate.helpers";

export default defineComponent({
  name: "OrdersCreate",
  components: {
    OrderFooterInfo,
    Page,

    OrdersCreateCustomer,
    OrdersCreateDateTime,
    OrdersCreateOrderAddress,
    OrdersCreateServices,
    OrdersCreateAdditional,
    Step,
    FormFooter,
  },
  setup() {
    const renderComponent = ref(true);

    const toast = useToast();
    const router = useRouter();
    const values = ref<FormValues>(clone(defaultFormValues));
    const submitLoading = ref<boolean>(false);
    const promoCodeLoading = ref<boolean>(false);

    const forceRerender = async () => {
      renderComponent.value = false;
      await nextTick();
      renderComponent.value = true;
      values.value = clone(defaultFormValues);
      submitLoading.value = false;
    };

    const {
      primary: primaryServiceCategories,
      secondary: secondaryServices,
      loading: servicesLoading,
    } = useFetchServices();

    const onCustomerChange = (value: null | number | CustomUserData) => {
      values.value = clone(defaultFormValues);
      values.value.user = value;
    };

    const onDateTimeChange = (dateTimeValue: any) => {
      values.value.datetime = dateTimeValue
        ? dateTimeValue.date + " " + dateTimeValue.time
        : null;
      values.value.period = dateTimeValue?.period || null;
      values.value.promoCode = null;
      values.value.cityCoefficients = dateTimeValue?.coefficients || [];
    };

    const onAddressChange = (value: AddressFormFields) => {
      values.value.address = value;
    };

    const onHouseTypeChange = (value: OrderHouseType) => {
      values.value.houseType = value;
    };

    const onServicesChange = (value: any) => {
      values.value.primaryServices = value ? value.primary : [];
      values.value.secondaryServices = value ? value.secondary : [];
      values.value.keysDeliveryAddress = value
        ? value.keysDeliveryAddress
        : null;
    };

    const onAdditionalChange = (value: any) => {
      values.value.comment = value.comment;
      values.value.promoCode = value.promoCode;
    };

    const onAdditionalLoadingChange = (value: boolean) => {
      promoCodeLoading.value = value;
    };

    const isUserStepCompleted = computed<boolean>(() => !!values.value.user);
    const isDateTimeStepCompleted = computed<boolean>(
      () => !!values.value.datetime && !!values.value.period
    );
    const isServicesStepCompleted = computed<boolean>(
      () => values.value.primaryServices.length === 2
    );
    const isAddressStepCompleted = computed<boolean>(
      () => !!values.value.address
    );

    const isServicesOpened = computed(
      () => isDateTimeStepCompleted.value || values.value.primaryServices.length
    );

    const userId = computed<number | null>(() =>
      typeof values.value.user === "number" ? values.value.user : null
    );
    const date = computed<string | null>(() =>
      values.value.datetime ? values.value.datetime.substring(0, 10) : null
    );

    const total = computed<{
      price: number;
      time: number;
    }>(() =>
      priceAndTimeCalculate(
        values.value,
        primaryServiceCategories.value,
        secondaryServices.value
      )
    );

    const withKeysDelivery = computed<boolean>(() => {
      const deliveryKeyService = secondaryServices.value?.find(
        (item: SecondaryService) => item.mode === "DELIVERY_KEYS"
      );
      if (!deliveryKeyService) {
        return false;
      }

      return !!values.value.secondaryServices.find(
        (item) => item.id === deliveryKeyService.id
      );
    });

    const submitButtonEnabled = computed<boolean>(
      () =>
        isUserStepCompleted.value &&
        isDateTimeStepCompleted.value &&
        isAddressStepCompleted.value &&
        isServicesStepCompleted.value &&
        !promoCodeLoading.value
    );

    function onSubmit(event: TagerFormSubmitEvent) {
      submitLoading.value = true;

      createOrder(convertFormValuesToCreatePayload(values.value))
        .then((response) => {
          toast.show({
            variant: "success",
            title: "Заказ №" + response.data.id,
            body: "Заказ успешно создан",
          });

          if (event.type === "create") {
            router.push(getOrdersViewUrl(response.data.id));
          } else if (event.type === "create_exit") {
            navigateBack(router, getActiveOrdersListUrl());
          } else if (event.type === "create_create-another") {
            forceRerender();
          }
        })
        .catch((error) => {
          submitLoading.value = false;
          console.log(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: "Ошибка создания заказа",
          });
        });
    }

    return {
      renderComponent,
      userId,
      date,

      onCustomerChange,
      onDateTimeChange,
      onAddressChange,
      onServicesChange,
      onHouseTypeChange,
      onAdditionalChange,
      onAdditionalLoadingChange,

      isUserStepCompleted,
      isDateTimeStepCompleted,
      isServicesStepCompleted,
      isAddressStepCompleted,
      isServicesOpened,

      submitButtonEnabled,

      servicesLoading,
      primaryServiceCategories,
      secondaryServices,
      withKeysDelivery,

      minutesToTimeString,
      total,
      onSubmit,
      submitLoading,
    };
  },
});
