import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";

import NotesList from "./notes-list";
import NotesForm from "./notes-form";

export const NOTES_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.NOTES_LIST,
  component: NotesList,
  name: "Список заметок",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.NOTES_LIST],
  },
};

export const NOTES_CREATE_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.CITIES_CREATE,
  component: NotesForm,
  name: "Новая заметка",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.NOTES_LIST],
  },
};

export const NOTES_EDIT_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.NOTES_EDIT,
  component: NotesForm,
  name: "Форма заметки",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.NOTES_LIST],
  },
};
