
import { defineComponent, PropType } from "vue";

import { useUserPermission } from "@tager/admin-services";

import { OrderListItemWorker } from "@/modules/orders/typings";
import { getWorkerFormUrl } from "@/utils/paths";
import { Scope } from "@/rbac/Scope";

export default defineComponent({
  name: "OrdersTableCellWorkers",
  methods: { getWorkerFormUrl },
  props: {
    workers: {
      type: Array as PropType<Array<OrderListItemWorker>>,
      required: true,
    },
  },
  setup() {
    return {
      canViewWorker: useUserPermission(Scope.WorkersView),
    };
  },
});
