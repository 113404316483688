import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-56237c68"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "datetime-form" }
const _hoisted_2 = { class: "datetime-form__row" }
const _hoisted_3 = { class: "submit-buttons" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_FormField = _resolveComponent("FormField")!
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createVNode(_component_FormField, {
        value: _ctx.ratingValue,
        "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.ratingValue) = $event)),
        disabled: _ctx.loading,
        label: "Оценка",
        type: "number",
        name: "rating"
      }, null, 8, ["value", "disabled"]),
      _createVNode(_component_FormField, {
        value: _ctx.textValue,
        "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.textValue) = $event)),
        disabled: _ctx.loading,
        label: "Отзыв",
        type: "textarea",
        name: "text"
      }, null, 8, ["value", "disabled"]),
      _createVNode(_component_FormField, {
        value: _ctx.solutionValue,
        "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.solutionValue) = $event)),
        disabled: _ctx.loading,
        label: "Решение",
        type: "textarea",
        name: "solution"
      }, null, 8, ["value", "disabled"])
    ]),
    _createElementVNode("div", _hoisted_3, [
      _createVNode(_component_BaseButton, {
        variant: "secondary",
        disabled: _ctx.loading,
        onClick: _ctx.onCancel
      }, {
        default: _withCtx(() => [
          _createTextVNode("Отменить")
        ]),
        _: 1
      }, 8, ["disabled", "onClick"]),
      _createVNode(_component_BaseButton, {
        variant: "primary",
        loading: _ctx.loading,
        onClick: _ctx.onSubmit
      }, {
        default: _withCtx(() => [
          _createTextVNode("Применить")
        ]),
        _: 1
      }, 8, ["loading", "onClick"]),
      (_ctx.canDelete)
        ? (_openBlock(), _createBlock(_component_BaseButton, {
            key: 0,
            variant: "red-link",
            disabled: _ctx.loading,
            onClick: _ctx.onDelete
          }, {
            default: _withCtx(() => [
              _createTextVNode("Удалить отзыв")
            ]),
            _: 1
          }, 8, ["disabled", "onClick"]))
        : _createCommentVNode("", true)
    ])
  ]))
}