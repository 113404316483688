
import { computed, defineComponent, PropType, ref } from "vue";

import { FieldValue, FormFieldMultiSelect, OptionType } from "@tager/admin-ui";
import { useResource, useToast } from "@tager/admin-services";

import { OrderFullInterface } from "@/modules/orders/typings";
import OrdersEditUserText from "@/modules/orders/views/orders-edit/components/OrdersEditUserText.vue";
import { newPhoneFormat } from "@/utils/common";
import { WorkerTypeList } from "@/types";
import { getAllWorkersList } from "@/modules/workers/requests";
import { updateUserWorkers } from "@/modules/users";

export default defineComponent({
  name: "OrdersEditClient",
  components: {
    FormFieldMultiSelect,
    OrdersEditUserText,
    FieldValue,
  },
  methods: { newPhoneFormat },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
  },
  setup(props) {
    const toast = useToast();

    const likeWorkersSelected = ref<Array<string>>(
      props.order.user.likeWorkers.map((item) => item.name)
    );

    const dislikeWorkersSelected = ref<Array<string>>(
      props.order.user.dislikeWorkers.map((item) => item.name)
    );

    const isEditWorkersOpened = ref<boolean>(false);

    const editWorkersSaveLoading = ref<boolean>(false);

    const likeWorkers = ref<Array<OptionType<number>>>(
      props.order.user.likeWorkers.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );

    const dislikeWorkers = ref<Array<OptionType<number>>>(
      props.order.user.dislikeWorkers.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      })
    );

    const [fetchWorkers, { data: workersList, loading: workersLoading }] =
      useResource<Array<WorkerTypeList>>({
        fetchResource: getAllWorkersList,
        initialValue: [],
        resourceName: "Workers list",
      });

    const openEditWorkers = () => {
      isEditWorkersOpened.value = true;
      fetchWorkers();
    };

    const cancelEditWorkers = () => {
      isEditWorkersOpened.value = false;
    };

    const saveEditWorkers = async () => {
      editWorkersSaveLoading.value = true;

      const likeWorkersIds = likeWorkers.value.map((item) => item.value);
      const dislikeWorkersIds = dislikeWorkers.value.map((item) => item.value);

      try {
        const response = await updateUserWorkers(props.order.user.id, {
          likeWorkers: likeWorkersIds,
          dislikeWorkers: dislikeWorkersIds,
        });

        const { likeWorkers, dislikeWorkers } = response.data;

        toast.show({
          variant: "success",
          title: "Успех",
          body: "Клинеры успешно сохранены",
        });

        isEditWorkersOpened.value = false;

        likeWorkersSelected.value = likeWorkers.map((item) => item.name);
        dislikeWorkersSelected.value = dislikeWorkers.map((item) => item.name);
      } catch (e) {
        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка сохранения клинеров",
        });
      } finally {
        editWorkersSaveLoading.value = false;
      }
    };

    const workersOptions = computed<Array<OptionType<number>>>(() => {
      return workersList.value.map((item) => {
        return {
          value: item.id,
          label: item.worker.fullName,
        };
      });
    });

    return {
      workersLoading,
      likeWorkers,
      dislikeWorkers,

      workersOptions,

      isEditWorkersOpened,
      openEditWorkers,
      cancelEditWorkers,
      saveEditWorkers,
      editWorkersSaveLoading,

      likeWorkersSelected,
      dislikeWorkersSelected,
    };
  },
});
