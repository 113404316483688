import { request, ResponseBody } from "@tager/admin-services";

import { CountData } from "@/requests";
import {
  UserCreatePayload,
  UserFullInterface,
  UserInterface,
  UserUpdatePayload,
  UserWorkersUpdatePayload,
} from "@/modules/users/typings";

export function getUsersCount(city?: number): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/users/count", params: query });
}

export function getUsers(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<UserInterface>>> {
  return request.get({ path: "/admin/users", params });
}

export function getUser(id: number): Promise<ResponseBody<UserFullInterface>> {
  return request.get({ path: "/admin/users/" + id });
}

export function createUser(
  payload: UserCreatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.post({ path: "/admin/users", body: payload });
}

export function updateUser(
  userId: number,
  payload: UserUpdatePayload
): Promise<ResponseBody<UserFullInterface>> {
  return request.put({
    path: `/admin/users/${userId}`,
    body: payload,
  });
}

export async function setUserText(userId: number, value: string) {
  return request.put({
    path: "/admin/users/" + userId + "/text",
    body: {
      text: value,
    },
  });
}

export async function setUserWorkersComment(userId: number, value: string) {
  return request.put({
    path: "/admin/users/" + userId + "/workers-text",
    body: {
      text: value,
    },
  });
}

type UpdateUserWorkersResponse = {
  likeWorkers: Array<{
    id: number;
    name: string;
  }>;
  dislikeWorkers: Array<{
    id: number;
    name: string;
  }>;
};

export function updateUserWorkers(
  userId: number,
  payload: UserWorkersUpdatePayload
): Promise<ResponseBody<UpdateUserWorkersResponse>> {
  return request.put({
    path: `/admin/users/${userId}/workers`,
    body: payload,
  });
}
