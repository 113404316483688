import { compile } from "path-to-regexp";

import { ROUTE_PATHS } from "@/router/paths";

export function getNotesCreateUrl() {
  return ROUTE_PATHS.NOTES_CREATE;
}

export function getNotesUpdateUrl(id: number): string {
  return compile(ROUTE_PATHS.NOTES_EDIT)({ id });
}

export function getNotesListUrl(): string {
  return ROUTE_PATHS.NOTES_LIST;
}
