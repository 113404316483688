import { Nullable } from "@tager/admin-services";
import { OptionType, SingleFileInputValueType } from "@tager/admin-ui";

import {
  SecondaryServiceCreationPayload,
  SecondaryServiceUpdatePayload,
} from "@/requests";
import { FullSecondaryServiceType } from "@/types";
import { createFileInputValue } from "@/utils/common";

export type FormValues = {
  name: string;
  mode: Nullable<OptionType>;
  price: string;
  step: string;
  minutes: string;
  tooltip: string;
  isNew: boolean;
  isFinalPrice: boolean;
  hidden: boolean;
  icon: Nullable<SingleFileInputValueType>;
  iconActive: Nullable<SingleFileInputValueType>;
  fullWorkerPrice: string;
};
export const modeOptions = [
  {
    value: "QUANTITY",
    label: "Количественная",
  },
  {
    value: "TIME",
    label: "Минутная",
  },
  {
    value: "TRUE_FALSE",
    label: "Да/Нет",
  },
  {
    value: "DELIVERY_KEYS",
    label: "Доставка ключей",
  },
];

export function convertSecondaryServiceToFormValues(
  secondaryService: Nullable<FullSecondaryServiceType>
): FormValues {
  if (!secondaryService) {
    return {
      name: "",
      mode: null,
      price: "0",
      step: "0",
      minutes: "0",
      tooltip: "",
      isNew: false,
      hidden: false,
      isFinalPrice: false,
      icon: null,
      iconActive: null,
      fullWorkerPrice: "",
    };
  }

  const currentMode = modeOptions.find(
    (modeOption) => modeOption.value === secondaryService.mode
  );

  return {
    name: secondaryService.name,
    mode: currentMode ?? null,
    price: String(secondaryService.price),
    fullWorkerPrice: secondaryService.fullWorkerPrice
      ? String(secondaryService.fullWorkerPrice)
      : "",
    step: String(secondaryService.step),
    minutes: String(secondaryService.minutes),
    tooltip: secondaryService.tooltip,
    isNew: secondaryService.isNew,
    hidden: secondaryService.hidden,
    isFinalPrice: secondaryService.isFinalPrice,
    icon: secondaryService.icon
      ? createFileInputValue(secondaryService.icon)
      : null,
    iconActive: secondaryService.icon
      ? createFileInputValue(secondaryService.iconActive)
      : null,
  };
}

export function convertFormValuesToSecondaryServiceCreationPayload(
  values: FormValues
): SecondaryServiceCreationPayload {
  return {
    name: values.name,
    mode: values.mode?.value ?? null,
    price: Number(values.price),
    fullWorkerPrice: Number(values.fullWorkerPrice),
    step: Number(values.step),
    minutes: Number(values.minutes),
    tooltip: values.tooltip,
    isNew: values.isNew,
    hidden: values.hidden,
    isFinalPrice: values.isFinalPrice,
    icon: values.icon?.file.id ?? null,
    iconActive: values.iconActive?.file.id ?? null,
  };
}

export function convertFormValuesToSecondaryServiceUpdatePayload(
  values: FormValues
): SecondaryServiceUpdatePayload {
  return {
    ...convertFormValuesToSecondaryServiceCreationPayload(values),
  };
}
