import { resolveComponent as _resolveComponent, createVNode as _createVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, createSlots as _createSlots, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersEditStatusBadge = _resolveComponent("OrdersEditStatusBadge")!
  const _component_OrdersEditModeSwitcher = _resolveComponent("OrdersEditModeSwitcher")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_router_link = _resolveComponent("router-link")!
  const _component_OrdersViewServices = _resolveComponent("OrdersViewServices")!
  const _component_OrdersEditAddress = _resolveComponent("OrdersEditAddress")!
  const _component_OrdersEditPromocode = _resolveComponent("OrdersEditPromocode")!
  const _component_OrdersEditAdminComment = _resolveComponent("OrdersEditAdminComment")!
  const _component_OrdersEditReview = _resolveComponent("OrdersEditReview")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "is-content-loading": _ctx.isContentLoading,
    title: 
      _ctx.orderLoadingStatus === 'LOADING'
        ? `Загрузка заказа № ${_ctx.orderId}...`
        : `Заказ № ${_ctx.orderId}`
    ,
    tabs: _ctx.orderLoadingStatus === 'SUCCESS' ? [] : []
  }, _createSlots({
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "hide-submit-buttons": true,
        "is-submitting": false,
        "is-creation": false
      })
    ]),
    _: 2
  }, [
    (_ctx.order)
      ? {
          name: "topBottom",
          fn: _withCtx(() => [
            _createVNode(_component_OrdersEditStatusBadge, { order: _ctx.order }, null, 8, ["order"])
          ]),
          key: "0"
        }
      : undefined,
    (_ctx.order)
      ? {
          name: "topCenter",
          fn: _withCtx(() => [
            _createVNode(_component_OrdersEditModeSwitcher, {
              "order-id": _ctx.order.id,
              "is-edit-active": false
            }, null, 8, ["order-id"])
          ]),
          key: "1"
        }
      : undefined,
    (!_ctx.isContentLoading)
      ? {
          name: "content",
          fn: _withCtx(() => [
            (_ctx.orderLoadingStatus === 'FAILURE')
              ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                  _createTextVNode(" Ошибка загрузки заказа: " + _toDisplayString(_ctx.orderLoadingMessageError), 1)
                ], 64))
              : (_ctx.order)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 1 }, [
                    _createVNode(_component_FieldValue, {
                      label: "Дата создания",
                      type: "datetime",
                      value: _ctx.order.createdAt
                    }, null, 8, ["value"]),
                    _createVNode(_component_FieldValue, {
                      label: "Дата и время уборки",
                      type: "datetime",
                      value: _ctx.order.datetime
                    }, null, 8, ["value"]),
                    (_ctx.order.subscription)
                      ? (_openBlock(), _createBlock(_component_FieldValue, {
                          key: 0,
                          label: "Подписка",
                          "edit-open-new-tab": true
                        }, _createSlots({ _: 2 }, [
                          (_ctx.canSubscriptionEdit)
                            ? {
                                name: "value",
                                fn: _withCtx(() => [
                                  _createVNode(_component_router_link, {
                                    to: `/subscriptions/${_ctx.order.subscription.id}`
                                  }, {
                                    default: _withCtx(() => [
                                      _createTextVNode("ID " + _toDisplayString(_ctx.order.subscription.id), 1)
                                    ]),
                                    _: 1
                                  }, 8, ["to"]),
                                  _createTextVNode(" - " + _toDisplayString(_ctx.subscriptionPeriodLabel(_ctx.order.subscription.period)) + " - Заказ № " + _toDisplayString(_ctx.order.subscription.index), 1)
                                ]),
                                key: "0"
                              }
                            : {
                                name: "value",
                                fn: _withCtx(() => [
                                  _createElementVNode("span", null, "ID " + _toDisplayString(_ctx.order.subscription.id), 1),
                                  _createTextVNode(" - " + _toDisplayString(_ctx.subscriptionPeriodLabel(_ctx.order.subscription.period)) + " - Заказ № " + _toDisplayString(_ctx.order.subscription.index), 1)
                                ]),
                                key: "1"
                              }
                        ]), 1024))
                      : _createCommentVNode("", true),
                    _createVNode(_component_OrdersViewServices, {
                      order: _ctx.order,
                      "with-edit": false
                    }, null, 8, ["order"]),
                    _createVNode(_component_OrdersEditAddress, {
                      order: _ctx.order,
                      "with-edit": false
                    }, null, 8, ["order"]),
                    _createVNode(_component_OrdersEditPromocode, {
                      order: _ctx.order,
                      "with-edit": false
                    }, null, 8, ["order"]),
                    _createVNode(_component_OrdersEditAdminComment, {
                      order: _ctx.order,
                      "with-edit": false
                    }, null, 8, ["order"]),
                    _createVNode(_component_FieldValue, {
                      label: "Комментарий клиента",
                      value: _ctx.order.userComment ?? '-'
                    }, null, 8, ["value"]),
                    _createVNode(_component_OrdersEditReview, {
                      order: _ctx.order,
                      "with-edit": false
                    }, null, 8, ["order"])
                  ], 64))
                : _createCommentVNode("", true)
          ]),
          key: "2"
        }
      : undefined
  ]), 1032, ["is-content-loading", "title", "tabs"]))
}