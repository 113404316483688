
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import { useRoute, useRouter } from "vue-router";

import {
  convertRequestErrorToMap,
  navigateBack,
  Nullable,
  useResource,
  useToast,
} from "@tager/admin-services";
import { Page } from "@tager/admin-layout";
import { FormField } from "@tager/admin-ui";

import { TrainingType } from "@/types";
import { createTraining, getTraining, updateTraining } from "@/requests";
import {
  convertFormValuesToTrainingCreationPayload,
  convertFormValuesToTrainingUpdatePayload,
  convertTrainingToFormValues,
  FormValues,
} from "@/modules/trainings/TrainingForm/TrainingForm.helpers";
import { getTrainingListUrl } from "@/utils/paths";

export default defineComponent({
  name: "TrainingForm",
  components: { FormField, Page },
  setup(props, context) {
    const route = useRoute();
    const router = useRouter();
    const toast = useToast();

    const trainingId = computed<string>(
      () => route.params.trainingId as string
    );

    const isCreation = computed<boolean>(() => trainingId.value === "create");

    const [fetchTraining, { data: training, loading: isTrainingLoading }] =
      useResource<Nullable<TrainingType>>({
        fetchResource: () => getTraining(trainingId.value),
        initialValue: null,
        resourceName: "training",
      });

    onMounted(() => {
      if (isCreation.value) return;
      fetchTraining();
    });

    const values = ref<FormValues>(convertTrainingToFormValues(training.value));
    const errors = ref<Record<string, string>>({});
    const isSubmitting = ref<boolean>(false);

    watch(training, () => {
      values.value = convertTrainingToFormValues(training.value);
    });

    function submitForm() {
      isSubmitting.value = true;

      const creationBody = convertFormValuesToTrainingCreationPayload(
        values.value
      );

      const updateBody = convertFormValuesToTrainingUpdatePayload(values.value);
      const requestPromise = isCreation.value
        ? createTraining(creationBody)
        : updateTraining(trainingId.value, updateBody);

      requestPromise
        .then(() => {
          errors.value = {};
          navigateBack(router, getTrainingListUrl());
          toast.show({
            variant: "success",
            title: "Success",
            body: isCreation.value
              ? `Тренинг успешно создан`
              : "Тренинг успешно обновлен",
          });
        })
        .catch((error) => {
          console.error(error);
          errors.value = convertRequestErrorToMap(error);
          toast.show({
            variant: "danger",
            title: "Ошибка",
            body: isCreation.value
              ? `Ошибка при создании тренинг`
              : "Ошибка при обновлении тренинга",
          });
        })
        .finally(() => {
          isSubmitting.value = false;
        });
    }

    const isContentLoading = computed<boolean>(() => isTrainingLoading.value);

    const PageTitle = isCreation.value
      ? "Создание Тренинга"
      : "Редактирование Тренинга";

    return {
      backButtonUrl: getTrainingListUrl(),
      values,
      errors,
      isSubmitting,
      submitForm,
      training,
      isCreation,
      isContentLoading,
      PageTitle,
    };
  },
});
