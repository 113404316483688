
import { defineComponent, PropType, ref } from "vue";

import { FieldValue, BaseButton, FormField } from "@tager/admin-ui";
import { useToast } from "@tager/admin-services";

import { OrderFullInterface } from "@/modules/orders/typings";
import { setOrderAdminComment } from "@/modules/orders/services";

export default defineComponent({
  name: "OrdersEditReview",
  components: {
    FieldValue,
    FormField,
    BaseButton,
  },
  props: {
    order: {
      type: Object as PropType<OrderFullInterface>,
      required: true,
    },
    withEdit: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup(props) {
    const toast = useToast();
    const value = ref<string>(
      props.order.adminComment && props.order.adminComment !== ""
        ? props.order.adminComment
        : ""
    );
    const formValue = ref<string>(props.order.adminComment || "");

    const loading = ref<boolean>(false);
    const formOpened = ref<boolean>(false);

    const onStart = () => {
      formOpened.value = true;
      formValue.value = value.value;
    };

    const onCancel = () => (formOpened.value = false);

    const onSubmit = async (e: Event) => {
      e.preventDefault();

      loading.value = true;

      try {
        await setOrderAdminComment(props.order.id, formValue.value);

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Комментарий успешно изменен",
        });

        value.value = formValue.value;
        formOpened.value = false;
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Ошибка изменения комментария",
        });
      } finally {
        loading.value = false;
      }
    };

    return {
      value,
      loading,
      formOpened,
      formValue,
      onStart,
      onCancel,
      onSubmit,
    };
  },
});
