import { Nullable, request, ResponseBody } from "@tager/admin-services";

import {
  SubscriptionFullInterface,
  SubscriptionInterface,
  SubscriptionOrderInterface,
} from "@/modules/subscriptions/typings";
import { AddressFormFields } from "@/modules/orders/typings";

export function getActiveSubscriptions(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<SubscriptionInterface>>> {
  return request.get({ path: "/admin/subscriptions/active", params });
}

export function getCompletedSubscriptions(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
}): Promise<ResponseBody<Array<SubscriptionInterface>>> {
  return request.get({ path: "/admin/subscriptions/completed", params });
}

export function getSubscription(
  orderId: number
): Promise<ResponseBody<Nullable<SubscriptionFullInterface>>> {
  return request.get({ path: "/admin/subscriptions/" + orderId });
}

export function updateSubscriptionPeriod(orderId: number, period: string) {
  return request.put({
    path: "/admin/subscriptions/" + orderId,
    body: { period },
  });
}

export async function cancelSubscription(
  id: number,
  reason: string
): Promise<ResponseBody> {
  return request.post({
    path: "/admin/subscriptions/" + id + "/cancel",
    body: {
      reason,
    },
  });
}

type SkipNextOrderResponse = {
  id: number;
  number: number;
  datetime: string;
};

export async function skipSubscriptionNextOrder(
  id: number
): Promise<ResponseBody<SkipNextOrderResponse>> {
  return request.post({
    path: "/admin/subscriptions/" + id + "/skip-next-order",
  });
}

export function getSubscriptionOrders(
  id: number
): Promise<ResponseBody<Array<SubscriptionOrderInterface>>> {
  return request.get({ path: `/admin/subscriptions/${id}/orders` });
}
