
import { computed, defineComponent } from "vue";

import { BaseSelect, OptionType, BaseOptionType } from "@tager/admin-ui";

import { useCities, useCityStore } from "@/store/city";

export default defineComponent({
  name: "CitySelector",
  components: { BaseSelect },
  setup() {
    const cityStore = useCityStore();
    const { items: cities, loading: isDataLoading } = useCities();

    const citiesOptions = computed<Array<OptionType<number>>>(() => {
      const citiesAll = cities.value.map((item) => {
        return {
          value: item.id,
          label: item.name,
        };
      });

      if (cityStore.isSelectAllDisabled) {
        return citiesAll;
      }

      return [
        {
          value: 0,
          label: "Все города",
        },
        ...citiesAll,
      ];
    });

    const onChangeCity = (value: any) => {
      cityStore.selectCity(value.value);
    };

    const selected = computed<BaseOptionType | null>(() => {
      return (
        citiesOptions.value.find(
          (item) => item.value === cityStore.selectedCity
        ) || null
      );
    });

    return {
      loading: isDataLoading,
      citiesOptions,
      onChangeCity,
      selected,
    };
  },
});
