export type SubscriptionPeriod = "EVERY_WEEK" | "EVERY_2_WEEKS" | "EVERY_MONTH";

export function subscriptionPeriodLabel(period: SubscriptionPeriod): string {
  switch (period) {
    case "EVERY_WEEK":
      return "Еженедельно";
    case "EVERY_2_WEEKS":
      return "Раз в 2 недели";
    case "EVERY_MONTH":
      return "Ежемесячно";
    default:
      return "";
  }
}

export const subscriptionPeriodOptions = [
  { value: "EVERY_MONTH", label: "Раз в месяц" },
  { value: "EVERY_2_WEEKS", label: "Раз в 2 недели" },
  { value: "EVERY_WEEK", label: "Раз в неделю" },
];
