import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";

import SubscriptionsList from "./subscriptions-list/SubscriptionsList.vue";
import SubscriptionsListCompleted from "./subscriptions-list/SubscriptionsListCompleted.vue";
import SubscriptionsView from "./subscriptions-view/SubscriptionsView.vue";

export const SUBSCRIPTIONS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SUBSCRIPTION_LIST,
  component: SubscriptionsList,
  name: "Список подписок",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.SUBSCRIPTION_LIST],
  },
};

export const SUBSCRIPTIONS_LIST_COMPLETED_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SUBSCRIPTION_LIST_COMPETED,
  component: SubscriptionsListCompleted,
  name: "Список завершенных подписок",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.SUBSCRIPTION_LIST],
  },
};

export const SUBSCRIPTIONS_VIEW_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.SUBSCRIPTION_FORM,
  component: SubscriptionsView,
  name: "Просмотр подписки",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.SUBSCRIPTION_LIST],
  },
};
