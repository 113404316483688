import { resolveComponent as _resolveComponent, createVNode as _createVNode, normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, withModifiers as _withModifiers, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

const _hoisted_1 = { class: "row-cols-3" }
const _hoisted_2 = { class: "row-cols-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersListTable = _resolveComponent("OrdersListTable")!
  const _component_FieldValue = _resolveComponent("FieldValue")!
  const _component_FormField = _resolveComponent("FormField")!
  const _component_FormFieldMultiSelect = _resolveComponent("FormFieldMultiSelect")!
  const _component_FormFieldCheckbox = _resolveComponent("FormFieldCheckbox")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    "tab-id": _ctx.selectedTabId,
    "onUpdate:tab-id": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.selectedTabId) = $event)),
    title: `Профиль клиента${_ctx.model ? ' - № ' + _ctx.model.id : ''}`,
    "is-content-loading": _ctx.isLoading,
    tabs: !_ctx.isLoading ? _ctx.tabList : []
  }, _createSlots({ _: 2 }, [
    (!_ctx.isLoading && _ctx.model)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createElementVNode("form", {
              novalidate: "",
              onSubmit: _cache[9] || (_cache[9] = _withModifiers(() => {}, ["prevent"]))
            }, [
              _createElementVNode("div", {
                style: _normalizeStyle({ display: _ctx.selectedTabId !== 'orders' ? 'none' : '' })
              }, [
                _createVNode(_component_OrdersListTable, { "fetch-orders": _ctx.fetchOrders }, null, 8, ["fetch-orders"])
              ], 4),
              _createElementVNode("div", {
                style: _normalizeStyle({ display: _ctx.selectedTabId !== 'common' ? 'none' : '' })
              }, [
                _createElementVNode("div", _hoisted_1, [
                  _createVNode(_component_FieldValue, {
                    label: "Номер клиента",
                    value: String(_ctx.model.id)
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Баланс",
                    value: String(_ctx.model.balance)
                  }, null, 8, ["value"]),
                  _createVNode(_component_FieldValue, {
                    label: "Реферральный код",
                    value: String(_ctx.model.referralCode)
                  }, null, 8, ["value"])
                ]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.name,
                  "onUpdate:value": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.values.name) = $event)),
                  name: "name",
                  error: _ctx.errors.name,
                  label: "Имя"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.phone,
                  "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.values.phone) = $event)),
                  name: "phone",
                  error: _ctx.errors.phone,
                  label: "Телефон"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.email,
                  "onUpdate:value": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.values.email) = $event)),
                  name: "email",
                  error: _ctx.errors.email,
                  label: "Электронная почта"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.description,
                  "onUpdate:value": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.values.description) = $event)),
                  type: "textarea",
                  name: "description",
                  error: _ctx.errors.description,
                  rows: "20",
                  label: "Описание"
                }, null, 8, ["value", "error"]),
                _createVNode(_component_FormField, {
                  value: _ctx.values.infoForWorkers,
                  "onUpdate:value": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.values.infoForWorkers) = $event)),
                  type: "textarea",
                  name: "infoForWorkers",
                  error: _ctx.errors.infoForWorkers,
                  rows: "20",
                  label: "Описание клинерам для заказа"
                }, null, 8, ["value", "error"]),
                _createElementVNode("div", _hoisted_2, [
                  _createVNode(_component_FormFieldMultiSelect, {
                    "selected-options": _ctx.values.likeWorkers,
                    "onUpdate:selected-options": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.values.likeWorkers) = $event)),
                    options: _ctx.workersOptions,
                    label: "Любимые клинеры",
                    name: "likeWorkers",
                    error: _ctx.errors.likeWorkers
                  }, null, 8, ["selected-options", "options", "error"]),
                  _createVNode(_component_FormFieldMultiSelect, {
                    "selected-options": _ctx.values.dislikeWorkers,
                    "onUpdate:selected-options": _cache[6] || (_cache[6] = ($event: any) => ((_ctx.values.dislikeWorkers) = $event)),
                    options: _ctx.workersOptions,
                    label: "Нелюбимые клинеры",
                    name: "dislikeWorkers",
                    error: _ctx.errors.dislikeWorkers
                  }, null, 8, ["selected-options", "options", "error"])
                ]),
                _createVNode(_component_FormFieldCheckbox, {
                  checked: _ctx.values.allowExportPhone,
                  "onUpdate:checked": _cache[7] || (_cache[7] = ($event: any) => ((_ctx.values.allowExportPhone) = $event)),
                  name: "allowExportPhone",
                  error: _ctx.errors.allowExportPhone,
                  label: "Добавлять в экспорт телефон"
                }, null, 8, ["checked", "error"]),
                _createVNode(_component_FormFieldCheckbox, {
                  checked: _ctx.values.allowExportEmail,
                  "onUpdate:checked": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.values.allowExportEmail) = $event)),
                  name: "allowExportEmail",
                  error: _ctx.errors.allowExportEmail,
                  label: "Добавлять в экспорт e-mail"
                }, null, 8, ["checked", "error"])
              ], 4)
            ], 32)
          ]),
          key: "0"
        }
      : undefined,
    (!_ctx.isLoading)
      ? {
          name: "footer",
          fn: _withCtx(() => [
            _createVNode(_component_FormFooter, {
              "back-href": _ctx.backButtonUrl,
              "is-submitting": _ctx.isSubmitting,
              "is-creation": false,
              "can-create-another": false,
              onSubmit: _ctx.submitForm
            }, null, 8, ["back-href", "is-submitting", "onSubmit"])
          ]),
          key: "1"
        }
      : undefined
  ]), 1032, ["tab-id", "title", "is-content-loading", "tabs"]))
}