
import { defineComponent, ref, watch } from "vue";
import { format } from "date-fns";

import { BaseInput } from "@tager/admin-ui";

import { useCityStore } from "@/store/city";

import { updateCoefficientRequest } from "../../requests";

import CheckBoxBlock from "./CheckBoxBlock.vue";

export default defineComponent({
  name: "DayCell",
  components: { BaseInput, CheckBoxBlock },
  props: {
    day: {
      type: Date,
      required: true,
    },
    currDateStart: {
      type: Date,
      required: true,
    },
    isCurrentMonth: {
      type: Function,
      required: true,
    },
    dayParam: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const cityStore = useCityStore();

    const values = ref<{ coefficient: string; date: string }>({
      coefficient: "",
      date: "",
    });

    const fullClose = ref<boolean>(false);
    const closeTimeSlots = ref<boolean>(false);

    watch(props, () => {
      values.value.coefficient = props.dayParam.coefficient
        ? props.dayParam.coefficient.toFixed(2).toString()
        : "";
      values.value.date = props.dayParam.date ? props.dayParam.date : "";
      fullClose.value = !!props.dayParam.closed;
      closeTimeSlots.value = !!props.dayParam.closedTimeSlots;
    });

    function updateCoefficient() {
      updateCoefficientRequest(values.value.date, {
        city: cityStore.activeCityId,
        value: Number(values.value.coefficient),
      });
    }

    return { format, values, updateCoefficient };
  },
});
