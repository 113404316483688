import { ColumnDefinition } from "@tager/admin-ui";

import { CityInterface } from "../typings";

export const COLUMN_DEFS: Array<ColumnDefinition<CityInterface>> = [
  {
    id: 1,
    name: "Имя",
    type: "string",
    field: "name",
  },
  {
    id: 2,
    name: "URL алиас",
    type: "string",
    field: "urlAlias",
  },
  {
    id: 3,
    name: "Город по-умолчанию",
    type: "boolean",
    field: "default",
    view: "TICK",
  },
  {
    id: 6,
    name: "",
    field: "actions",
    width: "200px",
    style: { textAlign: "center" },
  },
];
