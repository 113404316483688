
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import {
  eachDayOfInterval,
  format,
  startOfMonth,
  endOfMonth,
  getDate,
  getDay,
  addDays,
  addMonths,
  differenceInDays,
} from "date-fns";
import { useRoute } from "vue-router";

import { useResource } from "@tager/admin-services";
import { BaseButton } from "@tager/admin-ui";

import { router } from "@/router/router";
import { useCityStore } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";

import { CalendarType } from "../types";
import { getDayList } from "../requests";

import DayCell from "./components/DayCell.vue";

const month = [
  "Январь",
  "Февраль",
  "Март",
  "Апрель",
  "Май",
  "Июнь",
  "Июль",
  "Август",
  "Сентябрь",
  "Октябрь",
  "Ноябрь",
  "Декабрь",
];

export default defineComponent({
  name: "Calendar",
  components: {
    PageRegional,
    BaseButton,
    DayCell,
  },
  setup() {
    const route = useRoute();

    const cityStore = useCityStore();
    const cityId = computed<number>(() => cityStore.selectedCity);

    function getStartOfMonth() {
      return route.query.month
        ? startOfMonth(new Date(String(route.query.month)))
        : startOfMonth(new Date());
    }

    function getEndOfMonth() {
      return route.query.month
        ? endOfMonth(new Date(String(route.query.month)))
        : endOfMonth(new Date());
    }

    const values = ref<{ startDate: Date; endDate: Date }>({
      startDate: getStartOfMonth(),
      endDate: getEndOfMonth(),
    });

    const today = new Date();

    const currDateStart = ref<Date>(values.value.startDate);

    function getDaysOfMonth() {
      const daysNeededForLastMonth =
        getDay(values.value.startDate) === 0
          ? getDay(values.value.startDate) + 6
          : getDay(values.value.startDate) - 1;

      const daysNeededForNextMonth =
        7 - (getDay(values.value.endDate) + 1) >= 6
          ? 0
          : 7 - getDay(values.value.endDate);

      values.value.startDate = addDays(
        values.value.startDate,
        -daysNeededForLastMonth
      );

      values.value.endDate = addDays(
        values.value.endDate,
        daysNeededForNextMonth
      );

      return eachDayOfInterval({
        start: values.value.startDate,
        end: values.value.endDate,
      });
    }

    const daysOfMonth = ref(getDaysOfMonth());

    const [fetchDayList, { data: dayList, loading: isDayListLoading }] =
      useResource<Array<CalendarType>>({
        fetchResource: () =>
          getDayList(
            cityId.value,
            format(values.value.startDate, "yyyy-MM-dd"),
            format(values.value.endDate, "yyyy-MM-dd")
          ),
        initialValue: [],
        resourceName: "Day list",
      });

    const load = () => {
      if (cityId.value) {
        fetchDayList();
      }
    };
    onMounted(load);
    watch(cityId, load);

    function selectMonth(flipMonth: number) {
      currDateStart.value = addMonths(currDateStart.value, flipMonth);
      values.value.startDate = startOfMonth(currDateStart.value);
      values.value.endDate = endOfMonth(currDateStart.value);
      daysOfMonth.value = getDaysOfMonth();
      fetchDayList();
      router.push({
        query: { month: format(currDateStart.value, "yyyy-MM") },
      });
    }

    function isCurrentMonth(day: Date): boolean {
      return format(currDateStart.value, "L") === format(day, "L");
    }

    function getDayParam(dayOfWeek: Date): CalendarType | undefined {
      return dayList.value.find(
        (day) => day.date === format(dayOfWeek, "yyyy-MM-dd")
      );
    }

    function isBusy(): boolean {
      return isDayListLoading.value;
    }

    return {
      cityId,
      daysOfMonth,
      isDayListLoading,
      getDate,
      format,
      values,
      currDateStart,
      selectMonth,
      month,
      isCurrentMonth,
      getDayParam,
      isBusy,
      differenceInDays,
      today,
    };
  },
});
