
import { defineComponent, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { isEqual, pick } from "lodash";

import {
  useDataTable,
  DataTable,
  AdvancedSearch,
  FormFieldCheckbox,
  AdvancedSearchDateFilter,
} from "@tager/admin-ui";

import { getGuestOrdersList } from "@/modules/guest-orders/requests";
import { GuestOrderType } from "@/modules/guest-orders/typings";
import { useAdvancedSearch } from "@/modules/guest-orders/GuestOrdersList/hooks/useAdvancedSearch";
import { useCityId } from "@/store/city";
import PageRegional from "@/pages/PageRegional.vue";
import UserTableCell from "@/components/table-cells/UserTableCell.vue";
import OrderTableCell from "@/components/table-cells/OrderTableCell.vue";

import GuestOrdersCommentCell from "./components/GuestOrdersCommentCell.vue";
import { COLUMN_DEFS, SORT_OPTIONS } from "./GuestOrdersList.helpers";

export default defineComponent({
  name: "GuestOrdersList",
  components: {
    OrderTableCell,
    UserTableCell,
    AdvancedSearchDateFilter,
    PageRegional,
    AdvancedSearch,
    FormFieldCheckbox,
    GuestOrdersCommentCell,
    DataTable,
  },
  setup() {
    const route = useRoute();
    const router = useRouter();
    const cityId = useCityId();

    const {
      isLoading: isDataLoading,
      fetchEntityList,
      rowData: companyList,
      errorMessage,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,
      sort,
    } = useDataTable<GuestOrderType>({
      fetchEntityList: (params) =>
        getGuestOrdersList({
          query: params.searchQuery,
          pageNumber: params.pageNumber,
          pageSize: params.pageSize,
          sort: params.sort,
          ...{ ...filterParams.value, "filter[city]": cityId.value },
        }),
      initialValue: [],
      resourceName: "Guest Orders list",
      defaultSort: SORT_OPTIONS[0].value,
    });

    function isBusy(): boolean {
      return isDataLoading.value;
    }

    watch([cityId], () => {
      fetchEntityList();
    });

    const {
      dateFilter,
      withoutOrderFilter,
      withoutCommentFilter,
      withSourceFilter,
      filterParams,
      tags,
      tagRemovalHandler,
    } = useAdvancedSearch({
      route,
    });

    watch(filterParams, () => {
      const newQuery = {
        ...pick(route.query, ["query", "pageNumber"]),
        ...filterParams.value,
      };

      if (!isEqual(route.query, newQuery)) {
        router.replace({ query: newQuery });
        fetchEntityList();
      }
    });

    return {
      columnDefs: COLUMN_DEFS,
      sortOptions: SORT_OPTIONS,
      sortValue: sort,
      rowData: companyList,
      isRowDataLoading: isDataLoading,
      errorMessage,
      isBusy,
      searchQuery,
      handleChange,
      pageNumber,
      pageCount,
      pageSize,

      dateFilter,
      withoutOrderFilter,
      withoutCommentFilter,
      withSourceFilter,
      filterParams,
      tags,
      tagRemovalHandler,
    };
  },
});
