import { request, ResponseBody } from "@tager/admin-services";

import { GuestOrderType } from "@/modules/guest-orders/typings";
import { CountData } from "@/requests";

export function getGuestOrdersCount(
  city?: number
): Promise<ResponseBody<CountData>> {
  const query = city ? { city } : {};
  return request.get({ path: "/admin/guest-orders/count", params: query });
}

export function getGuestOrdersList(params?: {
  query?: string;
  pageNumber?: number;
  pageSize?: number;
  sort?: string | null;
}): Promise<ResponseBody<Array<GuestOrderType>>> {
  return request.get({ path: "/admin/guest-orders", params });
}

type GuestOrderUpdatePayload = {
  comment: string;
};

export function updateGuestOrder(
  id: number,
  payload: GuestOrderUpdatePayload
): Promise<ResponseBody<GuestOrderType>> {
  return request.put({
    path: `/admin/guest-orders/${id}`,
    body: payload,
  });
}
