
import { defineComponent, ref } from "vue";

import {
  BaseButton,
  FormField,
  FormFieldSelect,
  OptionType,
} from "@tager/admin-ui";
import { Nullable, useToast } from "@tager/admin-services";

import { changeOrderDateTime } from "@/modules/orders/services";

const getTimeOptions = (): Array<OptionType> => {
  const result = [];

  for (let h = 8; h <= 20; h++) {
    for (let m = 0; m < (h === 20 ? 30 : 60); m += 30) {
      const slot = (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m;

      result.push({
        label: slot,
        value: slot,
      });
    }
  }

  return result;
};

export default defineComponent({
  name: "OrderDateTimeEdit",
  components: {
    BaseButton,
    FormField,
    FormFieldSelect,
  },
  props: {
    orderId: {
      type: Number,
      required: true,
    },
    datetime: {
      type: String,
      required: true,
    },
  },
  emits: ["submit", "cancel"],
  setup(props, { emit }) {
    const dateTimeValue = ref<string>(props.datetime);
    const toast = useToast();
    const timeOptions = getTimeOptions();
    const loading = ref<boolean>(false);

    const dateValue = ref<string>(props.datetime.substring(0, 10));
    const timeValue = ref<Nullable<OptionType>>(
      timeOptions.find(
        (item) => item.value === props.datetime.substring(11, 16)
      ) || null
    );

    const onSubmit = async () => {
      loading.value = true;
      try {
        await changeOrderDateTime(
          props.orderId,
          dateValue.value,
          timeValue.value?.value || ""
        );

        toast.show({
          variant: "success",
          title: "Успешно",
          body: "Дата успешно изменена",
        });

        emit("submit", dateValue.value + " " + (timeValue.value?.value || ""));
      } catch (error) {
        console.error(error);

        toast.show({
          variant: "danger",
          title: "Ошибка",
          body: "Дата не изменена",
        });
      } finally {
        loading.value = false;
      }
    };

    const onCancel = () => emit("cancel");

    return {
      loading,
      dateValue,
      timeValue,
      timeOptions,
      onSubmit,
      onCancel,
      dateTimeValue,
    };
  },
});
