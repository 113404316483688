import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createSlots as _createSlots, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersCreateCustomer = _resolveComponent("OrdersCreateCustomer")!
  const _component_Step = _resolveComponent("Step")!
  const _component_OrdersCreateDateTime = _resolveComponent("OrdersCreateDateTime")!
  const _component_OrdersCreateServices = _resolveComponent("OrdersCreateServices")!
  const _component_OrdersCreateOrderAddress = _resolveComponent("OrdersCreateOrderAddress")!
  const _component_OrdersCreateAdditional = _resolveComponent("OrdersCreateAdditional")!
  const _component_OrderFooterInfo = _resolveComponent("OrderFooterInfo")!
  const _component_FormFooter = _resolveComponent("FormFooter")!
  const _component_Page = _resolveComponent("Page")!

  return (_openBlock(), _createBlock(_component_Page, {
    title: "Новый заказ",
    "is-overlay-loading": _ctx.submitLoading
  }, _createSlots({
    footer: _withCtx(() => [
      _createVNode(_component_FormFooter, {
        "is-submitting": _ctx.submitLoading,
        "is-submit-button-disabled": !_ctx.submitButtonEnabled,
        "is-creation": true,
        "can-create-another": true,
        "add-another-label": "Создать еще",
        onSubmit: _ctx.onSubmit
      }, {
        left: _withCtx(() => [
          _createVNode(_component_OrderFooterInfo, {
            price: _ctx.total.price,
            minutes: _ctx.total.time
          }, null, 8, ["price", "minutes"])
        ]),
        _: 1
      }, 8, ["is-submitting", "is-submit-button-disabled", "onSubmit"])
    ]),
    _: 2
  }, [
    (_ctx.renderComponent)
      ? {
          name: "content",
          fn: _withCtx(() => [
            _createVNode(_component_Step, {
              number: 1,
              name: "Клиент",
              opened: true
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OrdersCreateCustomer, { onChange: _ctx.onCustomerChange }, null, 8, ["onChange"])
              ]),
              _: 1
            }),
            _createVNode(_component_Step, {
              number: 2,
              name: "Дата и время",
              opened: _ctx.isUserStepCompleted
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OrdersCreateDateTime, { onChange: _ctx.onDateTimeChange }, null, 8, ["onChange"])
              ]),
              _: 1
            }, 8, ["opened"]),
            _createVNode(_component_Step, {
              number: 3,
              name: "Услуги",
              opened: _ctx.isServicesOpened
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OrdersCreateServices, {
                  loading: _ctx.servicesLoading,
                  primary: _ctx.primaryServiceCategories,
                  secondary: _ctx.secondaryServices,
                  onChange: _ctx.onServicesChange,
                  "onChange:house_type": _ctx.onHouseTypeChange
                }, null, 8, ["loading", "primary", "secondary", "onChange", "onChange:house_type"])
              ]),
              _: 1
            }, 8, ["opened"]),
            _createVNode(_component_Step, {
              number: 4,
              name: "Адрес",
              opened: _ctx.isServicesStepCompleted
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OrdersCreateOrderAddress, {
                  "user-id": _ctx.userId,
                  onChange: _ctx.onAddressChange
                }, null, 8, ["user-id", "onChange"])
              ]),
              _: 1
            }, 8, ["opened"]),
            _createVNode(_component_Step, {
              number: 5,
              name: "Дополнительно",
              opened: _ctx.isServicesStepCompleted && _ctx.isAddressStepCompleted
            }, {
              default: _withCtx(() => [
                _createVNode(_component_OrdersCreateAdditional, {
                  "user-id": _ctx.userId,
                  date: _ctx.date,
                  "with-keys-delivery": _ctx.withKeysDelivery,
                  "onChange:loading": _ctx.onAdditionalLoadingChange,
                  onChange: _ctx.onAdditionalChange
                }, null, 8, ["user-id", "date", "with-keys-delivery", "onChange:loading", "onChange"])
              ]),
              _: 1
            }, 8, ["opened"])
          ]),
          key: "0"
        }
      : undefined
  ]), 1032, ["is-overlay-loading"]))
}