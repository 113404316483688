import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, createBlock as _createBlock, createElementVNode as _createElementVNode, withCtx as _withCtx, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-af0524a6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "content-wrapper" }
const _hoisted_2 = { key: 0 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_PeriodSelect = _resolveComponent("PeriodSelect")!
  const _component_Map = _resolveComponent("Map")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, { title: _ctx.pageTitle }, {
    content: _withCtx(() => [
      _createVNode(_component_PeriodSelect, {
        "selected-period": [_ctx.selectedPeriod.dateFrom, _ctx.selectedPeriod.dateTo],
        "on-change": _ctx.submit
      }, null, 8, ["selected-period", "on-change"]),
      _createElementVNode("div", _hoisted_1, [
        (_ctx.isBusy)
          ? (_openBlock(), _createElementBlock("div", _hoisted_2, "Загрузка..."))
          : (_openBlock(), _createBlock(_component_Map, {
              key: 1,
              city: _ctx.activeCity,
              orders: _ctx.orders
            }, null, 8, ["city", "orders"]))
      ])
    ]),
    _: 1
  }, 8, ["title"]))
}