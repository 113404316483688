
import { ref, computed, defineComponent, watch } from "vue";
import { format } from "date-fns";

import { Nullable, useResource } from "@tager/admin-services";

import DateRangeFilter, {
  DateRangeFilterSubmitEvent,
} from "@/components/DateRangeFilter.vue";
import {
  getLtvStats,
  getOrdersStats,
  getPrimaryServicesStats,
  getRatingStats,
  getRetentionStats,
  getServicesStats,
  getSubscriptionsStats,
  getUsersStats,
  getWorkersStats,
  LtvStats,
  OrdersStatsResponse,
  PrimaryServicesStats,
  RatingStatsResponse,
  RetentionStats,
  ServicesStats,
  SubscriptionsStats,
  UsersStatsResponse,
  WorkersStatsResponse,
} from "@/modules/stats/requests";
import StatsWidget from "@/modules/stats/StatsView/components/StatsWidget.vue";
import { useCityStore } from "@/store/city";
import { formatMoney } from "@/utils/formatter";
import PageRegional from "@/pages/PageRegional.vue";

export default defineComponent({
  name: "StatsView",

  components: {
    PageRegional,
    StatsWidget,
    DateRangeFilter,
  },

  setup() {
    const cityStore = useCityStore();
    const cityId = computed<number>(() => cityStore.selectedCity);

    const dateFrom = ref<Date | null>(null);
    const dateTo = ref<Date | null>(null);

    const [
      fetchOrdersStats,
      { data: ordersStats, loading: isOrdersStatsLoading },
    ] = useResource<Nullable<OrdersStatsResponse>>({
      fetchResource: () =>
        getOrdersStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Orders Stats",
    });

    const [
      fetchUsersStats,
      { data: usersStats, loading: isUsersStatsLoading },
    ] = useResource<Nullable<UsersStatsResponse>>({
      fetchResource: () =>
        getUsersStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Users Stats",
    });

    const [
      fetchSubscriptionsStats,
      { data: subscriptionsStats, loading: isSubscriptionsStatsLoading },
    ] = useResource<Nullable<SubscriptionsStats>>({
      fetchResource: () =>
        getSubscriptionsStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Subscription Stats",
    });

    const [
      fetchRetentionStats,
      { data: retentionStats, loading: isRetentionStatsLoading },
    ] = useResource<Nullable<RetentionStats>>({
      fetchResource: () =>
        getRetentionStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Retention Stats",
    });

    const [
      fetchServicesStats,
      { data: servicesStats, loading: isServicesStatsLoading },
    ] = useResource<Nullable<ServicesStats>>({
      fetchResource: () =>
        getServicesStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Services Stats",
    });

    const [
      fetchRatingStats,
      { data: ratingStatus, loading: isRatingStatsLoading },
    ] = useResource<Nullable<RatingStatsResponse>>({
      fetchResource: () =>
        getRatingStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Rating Stats",
    });

    const [
      fetchWorkersStats,
      { data: workersStats, loading: isWorkersStatsLoading },
    ] = useResource<Nullable<WorkersStatsResponse>>({
      fetchResource: () =>
        getWorkersStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Workers Stats",
    });

    const [
      fetchOrdersExtendedStats,
      { data: primaryServicesStats, loading: isPrimaryServicesStatsLoading },
    ] = useResource<Nullable<PrimaryServicesStats>>({
      fetchResource: () =>
        getPrimaryServicesStats(
          dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
          dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
          cityId.value
        ),
      initialValue: null,
      resourceName: "Primary Services Stats",
    });

    const [fetchLtvStats, { data: ltvStats, loading: isLtvStatsLoading }] =
      useResource<Nullable<LtvStats>>({
        fetchResource: () =>
          getLtvStats(
            dateFrom.value ? format(dateFrom.value, "yyyy-MM-dd") : "",
            dateTo.value ? format(dateTo.value, "yyyy-MM-dd") : "",
            cityId.value
          ),
        initialValue: null,
        resourceName: "Orders LTV Stats",
      });

    const isLoading = computed<boolean>(() => {
      return isOrdersStatsLoading.value;
    });

    const fetchData = () => {
      fetchOrdersStats();
      fetchUsersStats();
      fetchRatingStats();
      fetchWorkersStats();
      fetchOrdersExtendedStats();
      fetchSubscriptionsStats();
      fetchServicesStats();
      fetchLtvStats();
      fetchRetentionStats();
    };

    watch(cityId, fetchData);

    const onDateChange = (range: DateRangeFilterSubmitEvent) => {
      dateFrom.value = range.from;
      dateTo.value = range.to;

      fetchData();
    };

    const ordersStatsValue = computed<any[]>(() => {
      return [
        { label: "Выполненных заказов", value: ordersStats.value?.orders },
        {
          label: "Заказов на клиента",
          value: ordersStats.value?.averageOrdersPerCustomer,
        },
        { label: "Кол-во отработанных часов", value: ordersStats.value?.hours },
        {
          label: "Выручка, BYN",
          value: formatMoney(ordersStats.value?.revenue),
        },
        {
          label: "Средний чек, BYN",
          value: ordersStats.value?.averageRevenuePerOrder,
        },
        {
          label: "Вознаграждение клинеров, BYN",
          value: formatMoney(ordersStats.value?.salary),
        },
        {
          label: "Наше вознаграждение, BYN",
          value: formatMoney(ordersStats.value?.income),
        },
      ];
    });

    const usersStatsValue = computed<any[]>(() => {
      return [
        {
          label: "Новых клиентов, оформивших минимум 1 уборку",
          value: usersStats.value?.newUsersCount,
        },
        {
          label: "Новых клиентов, выполнивших минимум 2 уборки",
          value: usersStats.value?.newUsersCountWithMoreThanOrder,
        },
      ];
    });

    const primaryServicesStatsValue = computed<any[]>(() => {
      return [
        {
          label: "1 комната",
          value: primaryServicesStats?.value?.rooms_1.count,
        },
        {
          label: "1 комната (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_1.count_no_services,
        },
        {
          label: "2 комнаты",
          value: primaryServicesStats?.value?.rooms_2.count,
        },
        {
          label: "2 комнаты (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_2.count_no_services,
        },
        {
          label: "3 комнаты",
          value: primaryServicesStats?.value?.rooms_3.count,
        },
        {
          label: "3 комнаты (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_3.count_no_services,
        },
        {
          label: "4 комнат",
          value: primaryServicesStats?.value?.rooms_4.count,
        },
        {
          label: "4 комнат (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_5.count_no_services,
        },
        {
          label: "5 комнат",
          value: primaryServicesStats?.value?.rooms_5.count,
        },
        {
          label: "5 комнат (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_5.count_no_services,
        },
        {
          label: "6 и более комнат",
          value: primaryServicesStats?.value?.rooms_6_plus.count_no_services,
        },
        {
          label: "6 и более комнат (без доп. услуг)",
          value: primaryServicesStats?.value?.rooms_6_plus.count_no_services,
        },
      ];
    });

    const ratingStatusValue = computed<any[]>(() => {
      return [
        {
          label: "Количество заказов с рейтингом",
          value: ratingStatus.value?.ordersWithRating,
        },
        { label: "Средний рейтинг", value: ratingStatus.value?.averageRating },
        {
          label: "Заказов с оценкой 5",
          value: ratingStatus.value?.ordersWithRating_5,
        },
        {
          label: "Заказов с оценкой 4",
          value: ratingStatus.value?.ordersWithRating_4,
        },
        {
          label: "Заказов с оценкой 3",
          value: ratingStatus.value?.ordersWithRating_3,
        },
        {
          label: "Заказов с оценкой 2",
          value: ratingStatus.value?.ordersWithRating_2,
        },
        {
          label: "Заказов с оценкой 1",
          value: ratingStatus.value?.ordersWithRating_1,
        },
      ];
    });

    const workersStatsValue = computed<any[]>(() => {
      return [
        {
          label: "Среднее кол-во заказов на одного клинера",
          value: workersStats.value?.averageOrdersCount,
        },
        {
          label: "Средняя общая зарплата",
          value: workersStats.value?.averageTotalSalary,
        },
        {
          label: "Средняя зарплата за заказ",
          value: workersStats.value?.averageOrderSalary,
        },
        {
          label: "Среднее расстояние",
          value: workersStats.value?.averageDistance + " км.",
        },
      ];
    });

    const subscriptionsStatsValue = computed<any[]>(() => {
      return [
        {
          label: "% конверсии в подписку новых клиентов",
          value: subscriptionsStats.value?.percent,
        },
        {
          label: "количество оформленных подписок новыми клиентами",
          value: subscriptionsStats.value?.newClients,
        },
        {
          label:
            "Количество подписок, оформленных старыми клиентами за период (клиентами у которых уже был выполнен хотя бы 1 заказ)\t",
          value: subscriptionsStats.value?.oldClients,
        },
      ];
    });

    const ltvStatsValue = computed<any[]>(() => {
      return [
        {
          label:
            "Количество всех пользователей, которые заказывали уборку когда-либо(и она выполнялась)",
          value: ltvStats.value?.usersCount,
        },
        {
          label: "Выручка GetClean (без учета скидки)",
          value: ltvStats.value?.ordersRevenue,
        },
        {
          label: "Выручка GetClean (с учетом скидки)",
          value: ltvStats.value?.ordersRevenueWithDiscount,
        },
        {
          label: "LTV",
          value: ltvStats.value?.ltv,
        },
        {
          label: "LTV c учетом скидки",
          value: ltvStats.value?.ltvUser,
        },
      ];
    });

    const retentionStatsValue = computed<any[]>(() => {
      return [
        {
          label: "Пользователей сделавших 1 заказ",
          value: retentionStats.value?.oneTime,
        },
        {
          label: "Пользователей сделавших 2 заказ2",
          value: retentionStats.value?.twoTime,
        },
        {
          label: "Пользователей сделавших 3 заказа",
          value: retentionStats.value?.threeTime,
        },
        {
          label: "Пользователей сделавших 4 заказа",
          value: retentionStats.value?.fourTime,
        },
        {
          label: "Пользователей сделавших 5 заказов",
          value: retentionStats.value?.fiveTime,
        },
        {
          label: "Пользователей сделавших 6 заказов",
          value: retentionStats.value?.sixTime,
        },
        {
          label: "Вне подписки - 1 заказ",
          value: retentionStats.value?.withoutSubscription1,
        },
        {
          label: "Вне подписки - 2 заказа",
          value: retentionStats.value?.withoutSubscription2,
        },
        {
          label: "Вне подписки - 3 заказа",
          value: retentionStats.value?.withoutSubscription3,
        },
        {
          label: "Вне подписки - 4 заказа",
          value: retentionStats.value?.withoutSubscription4,
        },
        {
          label: "С подпиской - 1 заказ",
          value: retentionStats.value?.withSubscription1,
        },
        {
          label: "С подпиской - 2 заказа",
          value: retentionStats.value?.withSubscription2,
        },
        {
          label: "С подпиской - 3 заказа",
          value: retentionStats.value?.withSubscription3,
        },
        {
          label: "С подпиской - 4 заказа",
          value: retentionStats.value?.withSubscription4,
        },
      ];
    });

    const servicesStatsValue = computed<any[]>(() => {
      return (
        servicesStats.value?.map((item) => {
          return {
            label: item.name,
            value: item.count,
          };
        }) || []
      );
    });

    const pageTitle = computed<string>(
      () =>
        "Статистика с " +
        (dateFrom.value ? format(dateFrom.value as Date, "dd.MM.yyyy") : "") +
        " по " +
        (dateFrom.value ? format(dateTo.value as Date, "dd.MM.yyyy") : "")
    );

    return {
      pageTitle,
      isLoading,
      onDateChange,

      ordersStats: ordersStatsValue,
      isOrdersStatsLoading,

      ratingStatus: ratingStatusValue,
      isRatingStatsLoading,

      workersStats: workersStatsValue,
      isWorkersStatsLoading,

      isPrimaryServicesStatsLoading,
      primaryServicesStats: primaryServicesStatsValue,

      subscriptionsStats: subscriptionsStatsValue,
      isSubscriptionsStatsLoading,

      servicesStats: servicesStatsValue,
      isServicesStatsLoading,

      ltvStats: ltvStatsValue,
      isLtvStatsLoading,

      retentionStats: retentionStatsValue,
      isRetentionStatsLoading,

      usersStats: usersStatsValue,
      isUsersStatsLoading,
    };
  },
});
