
import { defineComponent, ref, watch } from "vue";

import { FormField } from "@tager/admin-ui";

import { isValidDate } from "@/utils/dates";

export default defineComponent({
  name: "DateSelect",
  components: {
    FormField,
  },
  props: {
    selectedPeriod: {
      type: Array<string>,
      default: [],
    },
    onChange: {
      type: Function,
      default: null,
    },
  },
  setup(props) {
    const fromValue = ref<string>();
    const toValue = ref<string>();

    watch(
      () => props.selectedPeriod,
      () => {
        fromValue.value = props.selectedPeriod[0];
        toValue.value = props.selectedPeriod[1];
      }
    );

    watch([fromValue, toValue], () => {
      if (props.onChange && fromValue.value && toValue.value) {
        if (isValidDate(fromValue.value) && isValidDate(toValue.value)) {
          props.onChange([fromValue.value, toValue.value]);
        }
      }
    });

    return {
      fromValue,
      toValue,
    };
  },
});
