import { compile } from "path-to-regexp";
import { format, parse } from "date-fns";

import { ROUTE_PATHS } from "@/router/paths";

/** Workers */

export function getActiveWorkerListUrl() {
  return ROUTE_PATHS.WORKER_ACTIVE_LIST;
}

export function getArchiveWorkerListUrl() {
  return ROUTE_PATHS.WORKER_ARCHIVE_LIST;
}

export function getWorkerFormUrl(params: {
  workerId: string | number;
}): string {
  return compile(ROUTE_PATHS.WORKER_FORM)(params);
}

/** Trainings */

export function getTrainingListUrl() {
  return ROUTE_PATHS.TRAINING_LIST;
}

export function getTrainingFormUrl(params: {
  trainingId: string | number;
}): string {
  return compile(ROUTE_PATHS.TRAINING_FORM)(params);
}

/** Timetable */

export function getTimetableListUrl() {
  return ROUTE_PATHS.TIMETABLE_LIST;
}

/** Statistic */

export function getStatisticListUrl() {
  return ROUTE_PATHS.STATISTIC_LIST;
}

/** Map */

/** Subscriptions */

export function getSubscriptionListUrl() {
  return ROUTE_PATHS.SUBSCRIPTION_LIST;
}
export function getSubscriptionCompletedListUrl() {
  return ROUTE_PATHS.SUBSCRIPTION_LIST_COMPETED;
}

/** Calendar */
export function getCalendarUrl() {
  return ROUTE_PATHS.CALENDAR;
}

/** Stats */
export function getStatsUrl() {
  return ROUTE_PATHS.STATS;
}

/** Companies */

export function getCompanyListUrl() {
  return ROUTE_PATHS.COMPANY_LIST;
}

export function getCompanyFormUrl(params: {
  companyId: string | number;
}): string {
  return compile(ROUTE_PATHS.COMPANY_FORM)(params);
}

/** Promocodes */
export function getPromocodesListUrl(mode: string | null = null) {
  return ROUTE_PATHS.PROMOCODE_LIST + (mode ? "?filter[mode]=" + mode : "");
}

export function getPromocodeFormUrl(params: {
  promocodeId: string | number;
}): string {
  return compile(ROUTE_PATHS.PROMOCODE_FORM)(params);
}

/** Guest Orders */
export function getGuestOrdersUrl() {
  const todayDate = format(new Date(), "yyyy-MM-dd");
  const yesterdayDate = format(
    new Date(new Date().setDate(new Date().getDate() - 1)),
    "yyyy-MM-dd"
  );

  return (
    compile(ROUTE_PATHS.GUEST_ORDER_LIST)({}) +
    `?filter[date-from]=${yesterdayDate}&filter[date-to]=${todayDate}&filter[without-order]=1`
  );
}
