import { MenuItemType } from "@tager/admin-layout";
import { HomeIcon, SettingsIcon, ViewListIcon } from "@tager/admin-ui";
import { isNotNullish, useI18n } from "@tager/admin-services";
import {
  getEmailLogListUrl,
  getEmailTemplateListUrl,
  getTestMailUrl,
} from "@tager/admin-mail";

import { LINKS } from "@/router/links";
import { getNotesCreateUrl } from "@/modules/notes";
import { Scope } from "@/rbac/Scope";
import { useOnlyCompletedOrdersAllowed } from "@/rbac/hooks";

type Options = {
  citySelected: boolean;
  notesCount: number;
};

export const getMenuItemList = (options: Options): Array<MenuItemType> => {
  const { citySelected, notesCount } = options;

  const i18n = useI18n();
  const isOnlyCompletedOrdersAllowed = useOnlyCompletedOrdersAllowed();

  let result: Array<MenuItemType | null> = [
    { id: "home", text: "Главная", icon: HomeIcon, url: "/" },
    {
      id: "orders",
      text: "Заказы",
      icon: ViewListIcon,
      children: [
        LINKS.ORDERS_CREATE,
        LINKS.ORDERS_ACTIVE_LIST,
        LINKS.ORDERS_COMPLETED_LIST,
        LINKS.ORDERS_CANCELLED_LIST,
        LINKS.GUEST_ORDER_LIST,
        LINKS.ORDERS_FOR_DAY,
        LINKS.ORDERS_MAP,
      ],
      scopes: Scope.OrdersCreate,
    },
    isOnlyCompletedOrdersAllowed.value
      ? {
          id: "orders",
          icon: ViewListIcon,
          ...LINKS.ORDERS_COMPLETED_LIST,
          text: "Заказы",
          scopes: Scope.OrdersViewCompleted,
        }
      : null,
    {
      id: "subscriptions",
      text: "Подписки",
      icon: ViewListIcon,
      children: [
        {
          url: LINKS.SUBSCRIPTION_LIST.url,
          text: "Активные",
        },
        LINKS.SUBSCRIPTION_LIST_COMPLETED,
      ],
      scopes: Scope.SubscriptionsView,
    },
    {
      id: "reviews",
      icon: ViewListIcon,
      ...LINKS.REVIEWS_LIST,
      scopes: Scope.OrdersViewReviews,
    },
    {
      id: "workers",
      text: "Клинеры",
      icon: ViewListIcon,
      children: [
        LINKS.WORKERS_ACTIVE,
        LINKS.WORKERS_ARCHIVE,
        LINKS.TRAINING_LIST,
        {
          url: LINKS.STATISTIC_LIST.url,
          text: "Статистика",
        },
        LINKS.COMPANY_LIST,
      ],
      scopes: Scope.WorkersView,
    },
    {
      id: "users",
      icon: ViewListIcon,
      ...LINKS.USERS_LIST,
      scopes: Scope.UsersView,
    },
    {
      id: "notes",
      text: "Заметки" + (notesCount ? " - " + notesCount : ""),
      icon: ViewListIcon,
      children: [
        {
          url: getNotesCreateUrl(),
          text: "Новая заметка",
        },
        {
          url: LINKS.NOTES_LIST.url + "?filter[status]=ACTIVE",
          text: "Активные",
        },
        {
          url: LINKS.NOTES_LIST.url,
          text: "Все заметки",
        },
      ],
      scopes: Scope.NotesView,
    },
    {
      id: "timetable",
      icon: ViewListIcon,
      ...LINKS.TIMETABLE_LIST,
      scopes: Scope.WorkersTimetableView,
    },
    {
      id: "calendar",
      icon: ViewListIcon,
      ...LINKS.CALENDAR,
      scopes: Scope.CalendarView,
    },
    {
      id: "stats",
      icon: ViewListIcon,
      ...LINKS.STATS,
      scopes: Scope.CommonStats,
    },
  ];

  if (!citySelected) {
    result = [
      ...result,
      {
        id: "promocodes",
        icon: ViewListIcon,
        text: "Промокоды",
        children: [LINKS.PROMOCODE_LIST_ACTIVE, LINKS.PROMOCODE_LIST_ARCHIVED],
        scopes: Scope.PromocodesView,
      },
      {
        id: "services",
        text: "Услуги",
        icon: ViewListIcon,
        children: [LINKS.SERVICE_CATEGORY_LIST, LINKS.SECONDARY_SERVICE_LIST],
        scopes: Scope.ServicesView,
      },
      {
        id: "cities",
        icon: ViewListIcon,
        ...LINKS.CITIES_LIST,
        scopes: Scope.CitiesUpdate,
      },
      {
        id: "pages",
        icon: ViewListIcon,
        ...LINKS.PAGE_LIST,
        scopes: Scope.PagesView,
      },
      {
        id: "export",
        icon: ViewListIcon,
        ...LINKS.EXPORT_LIST,
        scopes: Scope.CommonExport,
      },
      {
        id: "settings",
        text: "Настройки",
        icon: SettingsIcon,
        children: [LINKS.SETTINGS, LINKS.MENU_HEADER, LINKS.MENU_FOOTER],
        scopes: Scope.SettingsView,
      },
      {
        id: "mail",
        text: i18n.t("mail:EMailNotifications"),
        icon: ViewListIcon,
        children: [
          { text: i18n.t("mail:templates"), url: getEmailTemplateListUrl() },
          { text: i18n.t("mail:logs"), url: getEmailLogListUrl() },
          { text: i18n.t("mail:testMail"), url: getTestMailUrl() },
        ],
        scopes: Scope.MailEditTemplates,
      },
      {
        id: "sms",
        text: "SMS",
        icon: ViewListIcon,
        children: [LINKS.SMS_TEMPLATE_LIST, LINKS.SMS_LOG_LIST],
        scopes: Scope.SmsViewTemplates,
      },
      {
        id: "admins",
        text: "Администраторы",
        icon: SettingsIcon,
        children: [LINKS.ADMIN_ADMINS, LINKS.ADMIN_ROLES],
        scopes: Scope.AdministratorsView,
      },
    ];
  }

  return result.filter(isNotNullish);
};
