import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_EyeIcon = _resolveComponent("EyeIcon")!
  const _component_BaseButton = _resolveComponent("BaseButton")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    title: "Клиенты",
    "allow-all": true,
    "header-buttons": [
      {
        text: 'Новый клиент',
        href: _ctx.getUsersCreateUrl(),
      },
    ]
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        onChange: _ctx.handleChange
      }, {
        "cell(actions)": _withCtx(({ row }) => [
          _createElementVNode("div", null, [
            _createVNode(_component_BaseButton, {
              variant: "icon",
              title: "Профиль клиента",
              href: _ctx.getUsersUpdateUrl(row.id)
            }, {
              default: _withCtx(() => [
                _createVNode(_component_EyeIcon)
              ]),
              _: 2
            }, 1032, ["href"])
          ])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "onChange"])
    ]),
    _: 1
  }, 8, ["header-buttons"]))
}