import { Nullable } from "@tager/admin-services";

import { TrainingCreationPayload, TrainingUpdatePayload } from "@/requests";
import { TrainingType } from "@/types";

export type FormValues = {
  name: string;
  phone: string;
  email: string;
  dateStart: string;
  dateEnd: string;
};

export function convertTrainingToFormValues(
  training: Nullable<TrainingType>
): FormValues {
  if (!training) {
    return {
      name: "",
      phone: "",
      email: "",
      dateStart: "",
      dateEnd: "",
    };
  }
  return {
    name: training.name,
    phone: training.phone,
    email: training.email,
    dateStart: training.dateStart,
    dateEnd: training.dateEnd,
  };
}

export function convertFormValuesToTrainingCreationPayload(
  values: FormValues
): TrainingCreationPayload {
  return {
    name: values.name,
    phone:
      values.phone.split("+").length === 1 ? "+" + values.phone : values.phone,
    email: values.email,
    dateStart: values.dateStart,
    dateEnd: values.dateEnd,
  };
}

export function convertFormValuesToTrainingUpdatePayload(
  values: FormValues
): TrainingUpdatePayload {
  return { ...convertFormValuesToTrainingCreationPayload(values) };
}
