import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersListActiveFilter = _resolveComponent("OrdersListActiveFilter")!
  const _component_UserTableCell = _resolveComponent("UserTableCell")!
  const _component_AddressTableCell = _resolveComponent("AddressTableCell")!
  const _component_OrdersTableCellWorkers = _resolveComponent("OrdersTableCellWorkers")!
  const _component_OrdersTableCellSubscription = _resolveComponent("OrdersTableCellSubscription")!
  const _component_DataTable = _resolveComponent("DataTable")!
  const _component_PageRegional = _resolveComponent("PageRegional")!

  return (_openBlock(), _createBlock(_component_PageRegional, {
    title: _ctx.title,
    "allow-all": true,
    "header-buttons": 
      _ctx.canCreateOrder
        ? [
            {
              text: 'Новый заказ',
              href: _ctx.getOrdersCreateUrl(),
            },
          ]
        : []
    ,
    "is-content-loading": _ctx.searchLoading
  }, {
    content: _withCtx(() => [
      _createVNode(_component_DataTable, {
        "column-defs": _ctx.columnDefs,
        "row-data": _ctx.rowData,
        loading: _ctx.isRowDataLoading,
        "error-message": _ctx.errorMessage,
        "search-query": _ctx.searchQuery,
        pagination: {
          pageNumber: _ctx.pageNumber,
          pageCount: _ctx.pageCount,
          pageSize: _ctx.pageSize,
          disabled: _ctx.isRowDataLoading,
        },
        sort: { options: _ctx.sortOptions, value: _ctx.sortValue },
        "row-css-class": _ctx.rowCssClass,
        "row-no-hover": true,
        onChange: _ctx.handleChange
      }, {
        filters: _withCtx(() => [
          _createVNode(_component_OrdersListActiveFilter, {
            loading: _ctx.searchLoading,
            "onUpdate:loading": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.searchLoading) = $event)),
            value: _ctx.filterParams,
            "onUpdate:value": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.filterParams) = $event)),
            "fetch-data": _ctx.fetchEntityList
          }, null, 8, ["loading", "value", "fetch-data"])
        ]),
        "cell(user)": _withCtx(({ row }) => [
          _createVNode(_component_UserTableCell, {
            id: row.user.id,
            name: row.user.name,
            phone: row.user.phone
          }, null, 8, ["id", "name", "phone"])
        ]),
        "cell(address)": _withCtx(({ row }) => [
          _createVNode(_component_AddressTableCell, {
            type: row.type,
            address: row.address.raw,
            latitude: row.address.latitude,
            longitude: row.address.longitude
          }, null, 8, ["type", "address", "latitude", "longitude"])
        ]),
        "cell(workers)": _withCtx(({ row }) => [
          _createVNode(_component_OrdersTableCellWorkers, {
            workers: row.workers
          }, null, 8, ["workers"])
        ]),
        "cell(subscription)": _withCtx(({ row }) => [
          _createVNode(_component_OrdersTableCellSubscription, {
            model: row.subscription
          }, null, 8, ["model"])
        ]),
        _: 1
      }, 8, ["column-defs", "row-data", "loading", "error-message", "search-query", "pagination", "sort", "row-css-class", "onChange"])
    ]),
    _: 1
  }, 8, ["title", "header-buttons", "is-content-loading"]))
}