import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-858fd48e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "status-row" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_BaseButton = _resolveComponent("BaseButton")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.order.subscription?.index === 1)
      ? (_openBlock(), _createBlock(_component_BaseButton, {
          key: 0,
          variant: "primary",
          onClick: _ctx.onMakeSingle
        }, {
          default: _withCtx(() => [
            _createTextVNode("Сделать разовым")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : _createCommentVNode("", true),
    (_ctx.order.canCancel)
      ? (_openBlock(), _createBlock(_component_BaseButton, {
          key: 1,
          variant: "red",
          onClick: _ctx.onCancel
        }, {
          default: _withCtx(() => [
            _createTextVNode("Отменить заказ")
          ]),
          _: 1
        }, 8, ["onClick"]))
      : (_ctx.order.status === 'CANCELLED')
        ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
            (_ctx.order.canRestore)
              ? (_openBlock(), _createBlock(_component_BaseButton, {
                  key: 0,
                  variant: "green",
                  onClick: _ctx.onRestore
                }, {
                  default: _withCtx(() => [
                    _createTextVNode("Восстановить заказ")
                  ]),
                  _: 1
                }, 8, ["onClick"]))
              : _createCommentVNode("", true)
          ], 64))
        : _createCommentVNode("", true)
  ]))
}