import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_OrdersEditReviewForm = _resolveComponent("OrdersEditReviewForm")!
  const _component_FieldValue = _resolveComponent("FieldValue")!

  return (_openBlock(), _createBlock(_component_FieldValue, {
    label: "Отзыв",
    value: _ctx.value,
    "with-edit": _ctx.withEdit,
    "edit-active": _ctx.formOpened,
    onEdit: _ctx.onStart
  }, {
    edit: _withCtx(() => [
      _createVNode(_component_OrdersEditReviewForm, {
        "order-id": _ctx.order.id,
        rating: _ctx.rating,
        text: _ctx.text,
        solution: _ctx.solution,
        onCancel: _ctx.onCancel,
        onSubmit: _ctx.onSubmit
      }, null, 8, ["order-id", "rating", "text", "solution", "onCancel", "onSubmit"])
    ]),
    _: 1
  }, 8, ["value", "with-edit", "edit-active", "onEdit"]))
}