import { RouteRecordRaw } from "vue-router";

import { LINKS } from "@/router/links";
import { ROUTE_PATHS } from "@/router/paths";

import ReviewsList from "./reviews-list/ReviewsList.vue";

export const REVIEWS_LIST_ROUTE: RouteRecordRaw = {
  path: ROUTE_PATHS.REVIEWS_LIST,
  component: ReviewsList,
  name: "Список отзывыов",
  meta: {
    getBreadcrumbs: () => [LINKS.HOME, LINKS.REVIEWS_LIST],
  },
};

export function getReviewsListUrl(): string {
  return ROUTE_PATHS.REVIEWS_LIST;
}
