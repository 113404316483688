
import { computed, defineComponent, ref, watch } from "vue";

import {
  FormField,
  FormFieldSelect,
  OptionType,
  Spinner,
} from "@tager/admin-ui";
import { Nullable, useResource } from "@tager/admin-services";

import { checkDate } from "@/modules/orders/views/orders-create/services";
import { CheckDateResponse } from "@/modules/orders/views/orders-create/typings";
import { orderPeriodOptions } from "@/modules/orders/views/orders-create/OrdersCreate.helpers";

export default defineComponent({
  name: "OrdersCreateDateTime",
  components: { FormField, FormFieldSelect, Spinner },
  emits: ["change"],
  setup(props, { emit }) {
    const periodValue = ref<OptionType>();
    const dateValue = ref<string | null>(null);
    const timeValue = ref<OptionType | null>(null);

    const [fetchDateData, { data: dateData, loading: isDateDataLoading }] =
      useResource<Nullable<CheckDateResponse>>({
        fetchResource: () => checkDate(dateValue.value || ""),
        initialValue: null,
        resourceName: "Check Date",
      });

    watch(dateValue, () => {
      emit("change", null);
      timeValue.value = null;

      fetchDateData();
    });

    watch([dateValue, timeValue, periodValue], () => {
      if (dateValue.value && timeValue.value && periodValue.value) {
        emit("change", {
          date: dateValue.value,
          time: timeValue.value?.value,
          coefficients: dateData.value?.map((city) => {
            return { city: city.city.id, coefficient: city.coefficient || 1 };
          }),
          period: periodValue.value?.value,
        });
      } else {
        emit("change", null);
      }
    });

    const timeOptions = computed<Array<OptionType>>(() => {
      const result = [];

      for (let h = 8; h <= 20; h++) {
        for (let m = 0; m < (h === 20 ? 30 : 60); m += 30) {
          const slot = (h < 10 ? "0" : "") + h + ":" + (m < 10 ? "0" : "") + m;

          result.push({
            label: slot,
            value: slot,
          });
        }
      }

      return result;
    });

    return {
      periodValue,
      dateValue,
      timeValue,
      timeOptions,
      dateData,
      loading: isDateDataLoading,
      orderPeriodOptions,
    };
  },
});
